import React, { useEffect, useState,useContext }  from 'react';
import restaurantLogin from './images/login.jpg';
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import {Text, View, TouchableOpacity, Image, TextInput,ScrollView,Platform } from 'react-native';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import {auth} from './firebase';
import { Auth } from "./AuthContext";
import { useNavigate } from 'react-router-dom';

export default function Login(){
    let navigate = useNavigate();
    setTimeout(()=>navigate("/"),300)


  
      return (
          <View style={{width:"100%",height: Platform.OS === 'web' ? '100vh' : '100%',overflowX:"hidden",backgroundColor:"#68dc91"}}>
         
 
          </View>
      )

}