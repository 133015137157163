import React, { Component } from 'react';
import { establishment } from './dataTest';
//import firebase from "@react-native-firebase/app";
//import "@react-native-firebase/firestore";
//create context with an empty object
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, setDoc, doc, orderBy, limit, startAt, startAfter } from "firebase/firestore";
import { withSnackbar } from 'react-simple-snackbar'

import storage, { refFromURL, uploadString, deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { AuthContext } from './AuthContext';
import { withTranslation } from 'react-i18next';
import { es } from './translations/es'
import { ca } from './translations/ca.js'

const GeneralContext = React.createContext();
const FIVE_MINUTES = 5 * 60 * 1000;

const translations = {
  "es": es,
  "ca": ca
}


const firebaseConfig = {
  apiKey: "AIzaSyAv_fIuS-epLY2brq47zge04zT3bmTuIuQ",
  authDomain: "close2you-a7817.firebaseapp.com",
  projectId: "close2you-a7817",
  storageBucket: "close2you-a7817.appspot.com",
  messagingSenderId: "446673714165",
  appId: "1:446673714165:web:4680f5ccfae5ba11e7bebc"
};


class GeneralProvider extends Component {
  // Context state
  constructor(props) {
    super(props);
    this.filterProducts = this.filterProducts.bind(this);
    this.filterCategories = this.filterCategories.bind(this);
    this.generateAuxiliarData = this.generateAuxiliarData.bind(this);
    this.setActiveCategory = this.setActiveCategory.bind(this);
    this.setActiveProduct = this.setActiveProduct.bind(this);
    this.pushDataToDB = this.pushDataToDB.bind(this);
    this.updateProductAvailability = this.updateProductAvailability.bind(this);
    this.updateCategoryAvailability = this.updateCategoryAvailability.bind(this);
    this.updateMenuAvailability = this.updateMenuAvailability.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.updateSupplement = this.updateSupplement.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.deleteSupplement = this.deleteSupplement.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.createSupplement = this.createSupplement.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.updateCategoriesOrder = this.updateCategoriesOrder.bind(this);
    this.updateEstablishment = this.updateEstablishment.bind(this);
    this.saveEstablishmentImage = this.saveEstablishmentImage.bind(this);
    this.saveHoursChanges = this.saveHoursChanges.bind(this);
    this.removeEstablishmentImage = this.removeEstablishmentImage.bind(this);
    this.removeProductImage = this.removeProductImage.bind(this);
    this.saveProductImage = this.saveProductImage.bind(this);
    this.getInfoFromDB = this.getInfoFromDB.bind(this);
    this.editProductImage = this.editProductImage.bind(this);
    this.translate = this.translate.bind(this);
    this.createMenu = this.createMenu.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
    this.updateMenu = this.updateMenu.bind(this);
    this.updateEstablishmentBarTerrace = this.updateEstablishmentBarTerrace.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.getInitialReceipts = this.getInitialReceipts.bind(this);
    this.getMoreReceipts = this.getMoreReceipts.bind(this);
    this.state = {
      establishmentInfo: [],
      activeProducts: [],
      activeCategories: [],
      activeMenus: {},
      activeSupplements: {},
      productsData: [],
      menusData: {},
      categoriesData: [],
      activeCategoryIndex: -1,
      activeMenuIndex: -1,
      activeProductIndex: -1,
      activeProductQuery: "",
      activeCategoryQuery: "",
      usuario: {},
      auth: {},
      establishmentDocID: -1,
      preferredLocale: this.generateLanguage(),
      activeProductsIDs: {},
      activeNoCategoriesProducts: {},
      activeReceipts: [],
      receiptsIDs: {},
      haveReceipts: true
    }



  }

  saveHoursChanges(index, hours) {
    var establishmentInfo = this.state.establishmentInfo;
    var cleanHours = []

    var values = [false, false, false]
    if (hours[0].init != "Introduce una hora" && hours[0].final != "Introduce una hora") {
      values[0] = true
    }
    if (hours[1].init != "Introduce una hora" && hours[1].final != "Introduce una hora") {
      values[1] = true

    }
    if (hours[2].init != "Introduce una hora" && hours[2].final != "Introduce una hora") {
      values[2] = true

    }
    var cleanHours = []
    for (var i = 0; i < values.length; i++) {
      if (values[i])
        cleanHours.push(hours[i])
    }

    establishmentInfo.hours[index] = {
      "index": index,
      hours: cleanHours
    }
    this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())
  }

  //works like a charm
  removeEstablishmentImage() {

    const storage = getStorage();

    const storageReference = storageRef(storage, "establishments/establishment_" + this.state.establishmentInfo.id);
    deleteObject(storageReference).then(() => {

      var establishmentInfo = this.state.establishmentInfo;
      establishmentInfo.img = ""
      this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())

    })

  }





  removeProductImage(index) {
    const storage = getStorage();

    const storageReference = storageRef(storage, "products/establishment_" + this.state.establishmentInfo.id + "_product_" + index);
    deleteObject(storageReference).then(() => {
      var establishmentInfo = this.state.establishmentInfo
      establishmentInfo.allProducts[index].img = ""
      this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())

    })


  }

  async editProductImage(index, file) {

    const storage = getStorage();
    const storageReference = storageRef(storage, "products/establishment_" + this.state.establishmentInfo.id + "_product_" + index);

    // Creating an upload task 
    const uploadTask = uploadBytesResumable(storageReference, file, {
      owner: this.state.establishmentInfo.owner
    });
    // Monitoring upload progress
    await uploadTask.on("state_changed", (snapshot) => {

      // render progress
    }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {

        var establishmentInfo = this.state.establishmentInfo
        establishmentInfo.allProducts[index]["img"] = url
        this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())

        // ...
      });
    })
  }

  async saveProductImage(index, file) {

    const storage = getStorage();
    const storageReference = storageRef(storage, "products/establishment_" + this.state.establishmentInfo.id + "_product_" + index);

    // Creating an upload task 
    const uploadTask = uploadBytesResumable(storageReference, file, {
      owner: this.state.establishmentInfo.owner
    });
    // Monitoring upload progress
    await uploadTask.on("state_changed", (snapshot) => {

      // render progress
    }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {

        var establishmentInfo = this.state.establishmentInfo
        establishmentInfo.allProducts[index]["img"] = url
        this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())

        // ...
      });
    })

  }

  //works like a charm
  async saveEstablishmentImage(imageToPost, callback) {

    const storage = getStorage();
    const storageReference = storageRef(storage, "establishments/establishment_" + this.state.establishmentInfo.id);

    // Creating an upload task 
    const uploadTask = uploadBytesResumable(storageReference, imageToPost, {
      owner: this.state.establishmentInfo.owner
    });
    // Monitoring upload progress
    await uploadTask.on("state_changed", (snapshot) => {

      // render progressc
    }, () => { }, () => {

      getDownloadURL(uploadTask.snapshot.ref).then((url) => {

        var establishmentInfo = this.state.establishmentInfo
        establishmentInfo["img"] = url
        this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); callback(url) })

        // ...
      });
    })


  }
  updateEstablishmentBarTerrace(barTerrace) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.bar_terrace = barTerrace
    this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())
  }

  updateEstablishment(establishment) {

    //NOW ACCEPTS ORDERS
    if ((establishment.accept_orders == true && this.state.establishmentInfo.accept_orders == false) ||
      (establishment.accept_orders == true && this.state.establishmentInfo.accept_orders == true)) {
      this.getInitialReceipts()
      this.receiptsInterval = setInterval(() => this.getInitialReceipts(), FIVE_MINUTES)


    }
    //DOESNT ACCEPTS ORDERS SINCE NOW
    if ((establishment.accept_orders == false && this.state.establishmentInfo.accept_orders == true) ||
      (establishment.accept_orders == false && this.state.establishmentInfo.accept_orders == false)) {
      var interval_id = window.setInterval(() => { }, 99999);
      for (var i = 0; i < interval_id; i++)
        window.clearInterval(i);
    }

    this.setState({ establishmentInfo: establishment }, () => this.pushDataToDB())
  }

  async getMoreReceipts(callback) {
    const db = getFirestore(this.props.app);
    const q = query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      orderBy("placed_at", "desc"),
      startAfter(this.state.activeReceipts[this.state.activeReceipts.length - 1].placed_at),
      limit(7));

    const querySnapshot = await getDocs(q);
    var orders = [];
    var receiptsIDs = {};
    querySnapshot.forEach((doc) => {
      orders.push(doc.data())
      receiptsIDs[doc.data().id] = doc.id
    })

    if (orders.length < 7 && callback != null)
      callback()

    this.setState({ activeReceipts: [...this.state.activeReceipts, ...orders], receiptsIDs: { ...this.state.receiptsIDs, ...receiptsIDs } })

  }


  async getInitialReceipts(callback) {

    const db = getFirestore(this.props.app);


    var promises = []
    var finalSortedOrdersIDs = {}
    var finalSortedOrders = {}
    var haveReceipts = false
    //AWAITING_CONFIRMATION
    const ACQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "AWAITING_CONFIRMATION"),
      orderBy("estimated_pick_up", "desc"))
    );
    promises.push(ACQuery)

    //PENDING_PREPARATION
    const PPQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "PENDING_PREPARATION"),
      orderBy("accepted_at", "desc"))
    );
    promises.push(PPQuery)

    //PREPARING 
    const PQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "PREPARING"),
      orderBy("estimated_pick_up", "desc"))
    );
    promises.push(PQuery)

    //READY_FOR_SENDING
    const RSQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "READY_FOR_SENDING"),
      orderBy("estimated_pick_up", "desc"))
    );
    promises.push(RSQuery)

    //READY_FOR_PICK_UP
    const RFPQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "READY_FOR_PICK_UP"),
      orderBy("estimated_pick_up", "desc")
    )
    );
    promises.push(RFPQuery)

    //DELIVERING
    const DQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "DELIVERING"),
      orderBy("last_modified", "desc")
    )
    );
    promises.push(DQuery)

    //RECEIVED
    const RECQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "RECEIVED"),
      orderBy("arrived_at", "desc")
    )
    );
    promises.push(RECQuery)


    //PICKED_UP CORRECT
    const PUQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "PICKED_UP"),
      orderBy("picked_at", "desc")
    )
    );
    promises.push(PUQuery)


    //CANCELED_BY_ESTABLISHMENT CORRECT
    const CBEQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "CANCELED_BY_ESTABLISHMENT"),
      orderBy("canceled_at", "desc")
    )
    );
    promises.push(CBEQuery)

    //CANCELED_BY_ESTABLISHMENT CORRECT
    const CBUQuery = getDocs(query(
      collection(db, "orders"),
      where("store_id", "==", this.state.establishmentInfo.id),
      where("current_state", "==", "CANCELED_BY_USER"),
      orderBy("canceled_at", "desc")
    )
    );
    promises.push(CBUQuery)

    const latestEventDataSnapshot = await Promise.all(promises)


    const allInfo = {}
    const allInfoIDs = {}

    latestEventDataSnapshot.forEach((querySnapshot, index) => {


      querySnapshot.docs.forEach(queryDocumentSnapshot => {
        haveReceipts = true
        switch (index) {
          case 0:
            if (allInfo["AWAITING_CONFIRMATION"]) {
              allInfo["AWAITING_CONFIRMATION"].push(queryDocumentSnapshot.data())
              allInfoIDs["AWAITING_CONFIRMATION"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["AWAITING_CONFIRMATION"] = [queryDocumentSnapshot.data()]
              allInfoIDs["AWAITING_CONFIRMATION"] = [queryDocumentSnapshot.id]
            }
            break;
          case 1:
            if (allInfo["PENDING_PREPARATION"]) {
              allInfo["PENDING_PREPARATION"].push(queryDocumentSnapshot.data())
              allInfoIDs["PENDING_PREPARATION"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["PENDING_PREPARATION"] = [queryDocumentSnapshot.data()]
              allInfoIDs["PENDING_PREPARATION"] = [queryDocumentSnapshot.id]
            }
            break;
          case 2:
            if (allInfo["PREPARING"]) {
              allInfo["PREPARING"].push(queryDocumentSnapshot.data())
              allInfoIDs["PREPARING"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["PREPARING"] = [queryDocumentSnapshot.data()]
              allInfoIDs["PREPARING"] = [queryDocumentSnapshot.id]
            }
            break;
          case 3:
            if (allInfo["READY_FOR_SENDING"]) {
              allInfo["READY_FOR_SENDING"].push(queryDocumentSnapshot.data())
              allInfoIDs["READY_FOR_SENDING"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["READY_FOR_SENDING"] = [queryDocumentSnapshot.data()]
              allInfoIDs["READY_FOR_SENDING"] = [queryDocumentSnapshot.id]
            }
            break;
          case 4:
            if (allInfo["READY_FOR_PICK_UP"]) {
              allInfo["READY_FOR_PICK_UP"].push(queryDocumentSnapshot.data())
              allInfoIDs["READY_FOR_PICK_UP"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["READY_FOR_PICK_UP"] = [queryDocumentSnapshot.data()]
              allInfoIDs["READY_FOR_PICK_UP"] = [queryDocumentSnapshot.id]
            }
            break;
          case 5:
            if (allInfo["DELIVERING"]) {
              allInfo["DELIVERING"].push(queryDocumentSnapshot.data())
              allInfoIDs["DELIVERING"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["DELIVERING"] = [queryDocumentSnapshot.data()]
              allInfoIDs["DELIVERING"] = [queryDocumentSnapshot.id]
            }
            break;
          case 6:
            if (allInfo["RECEIVED"]) {
              allInfo["RECEIVED"].push(queryDocumentSnapshot.data())
              allInfoIDs["RECEIVED"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["RECEIVED"] = [queryDocumentSnapshot.data()]
              allInfoIDs["RECEIVED"] = [queryDocumentSnapshot.id]
            }
            break;
          case 7:
            if (allInfo["PICKED_UP"]) {
              allInfo["PICKED_UP"].push(queryDocumentSnapshot.data())
              allInfoIDs["PICKED_UP"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["PICKED_UP"] = [queryDocumentSnapshot.data()]
              allInfoIDs["PICKED_UP"] = [queryDocumentSnapshot.id]
            }
            break;
          case 8:
            if (allInfo["CANCELED_BY_ESTABLISHMENT"]) {
              allInfo["CANCELED_BY_ESTABLISHMENT"].push(queryDocumentSnapshot.data())
              allInfoIDs["CANCELED_BY_ESTABLISHMENT"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["CANCELED_BY_ESTABLISHMENT"] = [queryDocumentSnapshot.data()]
              allInfoIDs["CANCELED_BY_ESTABLISHMENT"] = [queryDocumentSnapshot.id]
            }
            break;
          case 9:
            if (allInfo["CANCELED_BY_USER"]) {
              allInfo["CANCELED_BY_USER"].push(queryDocumentSnapshot.data())
              allInfoIDs["CANCELED_BY_USER"].push(queryDocumentSnapshot.id)
            }
            else {
              allInfo["CANCELED_BY_USER"] = [queryDocumentSnapshot.data()]
              allInfoIDs["CANCELED_BY_USER"] = [queryDocumentSnapshot.id]
            }
            break;
          default:
            break
        }
        //...

      })


    })


    //here allInfo contains all orders classified
    finalSortedOrders = {
      "NEWS": {
        "ALL": allInfo["AWAITING_CONFIRMATION"] || []
      },
      "ACCEPTED": {
        "ALL": allInfo["PENDING_PREPARATION"] || [],

      },
      "PREPARING": {
        "ALL": [
          ...(allInfo["PREPARING"] || []),
          ...(allInfo["READY_FOR_SENDING"] || [])
        ],
        "PREPARING_ORDER": allInfo["PREPARING"] || [],
        "PENDING_SEND": allInfo["READY_FOR_SENDING"] || [],
      },
      "READY": {
        "ALL": [
          ...(allInfo["READY_FOR_PICK_UP"] || []),
          ...(allInfo["DELIVERING"] || [])
        ],
        "READY_FOR_PICK_UP": allInfo["READY_FOR_PICK_UP"] || [],
        "DELIVERING": allInfo["DELIVERING"] || []
      },
      "DONE": {
        "ALL": [
          ...(allInfo["PICKED_UP"] || []),
          ...(allInfo["RECEIVED"] || []),
          ...(allInfo["CANCELED_BY_ESTABLISHMENT"] || []),
          ...(allInfo["CANCELED_BY_USER"] || [])],
        "PICKED_UP": allInfo["PICKED_UP"] || [],
        "RECEIVED": allInfo["RECEIVED"] || [],
        "CANCELED_BY_ESTABLISHMENT": allInfo["CANCELED_BY_ESTABLISHMENT"] || [],
        "CANCELED_BY_USER": allInfo["CANCELED_BY_USER"] || []
      }
    }


    finalSortedOrdersIDs = {
      "NEWS": {
        "ALL": allInfoIDs["AWAITING_CONFIRMATION"] || []
      },
      "ACCEPTED": {
        "ALL": allInfoIDs["PENDING_PREPARATION"] || [],

      },
      "PREPARING": {
        "ALL": [
          ...(allInfoIDs["PREPARING"] || []),
          ...(allInfoIDs["READY_FOR_SENDING"] || [])
        ],
        "PREPARING_ORDER": allInfoIDs["PREPARING"] || [],
        "PENDING_SEND": allInfoIDs["READY_FOR_SENDING"] || [],
      },
      "READY": {
        "ALL": [
          ...(allInfoIDs["READY_FOR_PICK_UP"] || []),
          ...(allInfoIDs["DELIVERING"] || [])
        ],
        "READY_FOR_PICK_UP": allInfoIDs["READY_FOR_PICK_UP"] || [],
        "DELIVERING": allInfoIDs["DELIVERING"] || []
      },
      "DONE": {
        "ALL": [
          ...(allInfoIDs["PICKED_UP"] || []),
          ...(allInfoIDs["RECEIVED"] || []),
          ...(allInfoIDs["CANCELED_BY_ESTABLISHMENT"] || []),
          ...(allInfoIDs["CANCELED_BY_USER"] || [])],
        "PICKED_UP": allInfoIDs["PICKED_UP"] || [],
        "RECEIVED": allInfoIDs["RECEIVED"] || [],
        "CANCELED_BY_ESTABLISHMENT": allInfoIDs["CANCELED_BY_ESTABLISHMENT"] || [],
        "CANCELED_BY_USER": allInfoIDs["CANCELED_BY_USER"] || []
      }
    }


    this.setState({ haveReceipts: haveReceipts, activeReceipts: finalSortedOrders, receiptsIDs: finalSortedOrdersIDs }, () => { if (callback) callback() })
  }

  async getInfoFromDB() {

    const db = getFirestore(this.props.app);
    const q = query(collection(db, "establishments"), where("owner", "==", this.props.usuario));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.warn(doc.data())

      this.setState({ establishmentDocID: doc.id, establishmentInfo: doc.data() }, () => {
        if (doc.data().accept_orders) {
          this.getInitialReceipts()
          this.receiptsInterval = setInterval(() => this.getInitialReceipts(), FIVE_MINUTES)

        }

        this.generateAuxiliarData()

      })
    })
  }

  async componentDidMount() {
    if (this.props.usuario) {
      await this.getInfoFromDB();

    }

  }



  generateLanguage() {
    //this.setState({establishmentInfo:establishment},()=>this.generateAuxiliarData())
    // this.props.openSnackbar('This is the content of the Snackbar.')
    var userLang = navigator.language || navigator.userLanguage;

    if (userLang.indexOf("es") >= 0) {
      userLang = "es"
    } else {
      if (userLang.indexOf("ca") >= 0)
        userLang = "ca"
      else
        userLang = "es"
    }
    console.warn(userLang)
    return userLang


  }

  //checked works well
  updateCategory(index, category) {
    var establishmentInfo = this.state.establishmentInfo

    establishmentInfo.products[index] = category

    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })

  }

  //checked works well
  updateMenu(index, menu) {
    menu.price = menu.price ? parseFloat(menu.price) : 0
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.menus[index] = menu
    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })

  }


  setActiveCategory(index) {
    this.setState({ activeCategoryIndex: index })
  }
  setActiveProduct(index) {
    this.setState({ activeCategoryIndex: index })
  }

  //check but will work with new logic
  createProduct(product, categories, file) {

    var establishmentInfo = this.state.establishmentInfo

    product.id = establishmentInfo.allProducts.length
    if (product.allergens)
      product.allergens = product.allergens.sort()

    establishmentInfo.allProducts.push(JSON.parse(JSON.stringify(product)))

    for (var i = 0; i < establishmentInfo.products.length; i++) {

      if (categories.indexOf(establishmentInfo.products[i].title) >= 0) {

        establishmentInfo.products[i].data.push(JSON.parse(JSON.stringify(product.id)))


      }
    }




    this.setState({ establishmentInfo: establishmentInfo }, () => {
      if (file)
        this.saveProductImage(product.id, file);
      else
        this.pushDataToDB()
    })
  }

  createSupplement(supplement) {

    var establishmentInfo = this.state.establishmentInfo;
    if (establishmentInfo.supplements) {
      supplement.id = establishmentInfo.supplements.length
      establishmentInfo.supplements.push(supplement)
    }
    else {
      supplement.id = 0
      establishmentInfo.supplements = [supplement]

    }
    this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())
  }

  updateSupplement(supplement) {
    var establishmentInfo = this.state.establishmentInfo
    for (var i = 0; i < establishmentInfo.supplements.length; i++) {
      if (establishmentInfo.supplements[i].id == supplement.id)
        establishmentInfo.supplements[i] = supplement
    }

    this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())
  }


  updateProduct(index, product, categories, file) {
    var indexFound = -1
    if (!product.offer)
      product.price_offer = null
    var establishmentInfo = this.state.establishmentInfo
    for (var i = 0; i < establishmentInfo.allProducts.length; i++) {
      if (product.id == establishmentInfo.allProducts[i].id) {
        indexFound = i
        break
      }
    }

    if (product.allergens)
      product.allergens = product.allergens.sort()

    const imageRemoved = !file && !product.img && !!establishmentInfo.allProducts[indexFound].img ? true : false
    const imageEdited = file && (product.img != establishmentInfo.allProducts[indexFound].img) ? true : false

    if (imageRemoved)
      product.img = ""

    establishmentInfo.allProducts[indexFound] = JSON.parse(JSON.stringify(product))


    this.setState({ establishmentInfo: establishmentInfo }, () => {

      if (imageEdited)
        this.editProductImage(indexFound, file)

      if (imageRemoved)
        this.removeProductImage(indexFound)


      if (!imageEdited && !imageRemoved)
        this.pushDataToDB();

    })
  }



  async updateOrder(order, tab, subtab, index, callback) {
    order.last_modified = new Date()
    const db = getFirestore(this.props.app);
    await setDoc(doc(db, "orders", this.state.receiptsIDs[tab][subtab][index]), order).then(() => {
      this.getInitialReceipts(callback)

    }).catch(e => {});


  }

  deleteSupplement(id) {
    var establishmentInfo = this.state.establishmentInfo
    //update all indexes that change with this delete
    var indexToDelete = -1
    for (var i = 0; i < this.state.establishmentInfo.supplements.length; i++) {
      if (this.state.establishmentInfo.supplements[i].id == id) {
        indexToDelete = i
        break
      }
    }

    if (indexToDelete >= 0)
      establishmentInfo.supplements.splice(indexToDelete, 1)

    for (var i = 0; i < establishmentInfo.allProducts.length; i++) {
      if (establishmentInfo.allProducts[i].supplements) {
        if (establishmentInfo.allProducts[i].supplements.id > id)
          establishmentInfo.allProducts[i].supplements.id -= 1
        if (establishmentInfo.allProducts[i].supplements.id == id)
          establishmentInfo.allProducts[i].supplements.splice(i, 1)
      }
    }
    this.setState({ establishmentInfo: establishmentInfo }, () => this.pushDataToDB())
  }


  deleteProduct(index) {

    var establishmentInfo = this.state.establishmentInfo
    //remove of list of products
    establishmentInfo.allProducts.splice(index, 1)
    //update all indexes that change with this delete
    for (var i = 0; i < establishmentInfo.allProducts.length; i++) {
      if (establishmentInfo.allProducts[i].id > index)
        establishmentInfo.allProducts[i].id -= 1
    }
    //remove index from all sections
    for (var i = 0; i < establishmentInfo.products.length; i++) {

      if (establishmentInfo.products[i].data.indexOf(index) >= 0) {
        establishmentInfo.products[i].data.splice(establishmentInfo.products[i].data.indexOf(index), 1)
      }
    }

    //remove index from all sections
    for (var i = 0; i < establishmentInfo.menus.length; i++) {

      if (establishmentInfo.menus[i].data.indexOf(index) >= 0) {
        establishmentInfo.menus[i].data.splice(establishmentInfo.menus[i].data.indexOf(index), 1)
      }
    }

    //update all sections index
    for (var i = 0; i < establishmentInfo.products.length; i++) {
      for (var j = 0; j < establishmentInfo.products[i].data.length; j++) {
        if (establishmentInfo.products[i].data[j] > index) {
          establishmentInfo.products[i].data[j] -= 1

        }
      }
    }


    //update all sections index
    for (var i = 0; i < establishmentInfo.menus.length; i++) {
      for (var j = 0; j < establishmentInfo.menus[i].data.length; j++) {
        if (establishmentInfo.menus[i].data[j] > index) {
          establishmentInfo.menus[i].data[j] -= 1

        }
      }
    }

    this.removeProductImage(index)
    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })

  }


  createCategory(category) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.products.push(JSON.parse(JSON.stringify(category)));

    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })
  }



  createMenu(menu) {
    var establishmentInfo = this.state.establishmentInfo
    if (!establishmentInfo.menus || establishmentInfo.menus.length == 0)
      establishmentInfo.menus = [menu]
    else
      establishmentInfo.menus.push(JSON.parse(JSON.stringify(menu)));

    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })
  }

  //works with the new logic
  deleteMenu(index) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.menus.splice(index, 1)
    for (var i = 0; i < establishmentInfo.menus.length; i++) {
      establishmentInfo.menus[i].index = i
    }

    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })
  }



  //works with the new logic
  deleteCategory(index) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.products.splice(index, 1)
    for (var i = 0; i < establishmentInfo.products.length; i++) {
      establishmentInfo.products[i].index = i
    }

    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })
  }


  updateCategoriesOrder(newOrder) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.products = newOrder
    this.setState({ establishmentInfo: establishmentInfo }, () => { this.pushDataToDB(); })

  }



  generateAuxiliarData() {
    var categoriesData = {}
    var productsData = {}
    var productsIDs = {}
    var noCategoriesProducts = {}
    var menusData = {}
    var supplementsData = {}

    if (this.state.establishmentInfo.supplements) {
      for (var i = 0; i < this.state.establishmentInfo.supplements.length; i++) {
        supplementsData[this.state.establishmentInfo.supplements[i].title] = {
          id: i,
          title: this.state.establishmentInfo.supplements[i].title,
          price: this.state.establishmentInfo.supplements[i].price
        }
      }
    }


    for (var i = 0; i < this.state.establishmentInfo.allProducts.length; i++) {
      productsIDs[i] = this.state.establishmentInfo.allProducts[i].title
      noCategoriesProducts[this.state.establishmentInfo.allProducts[i].title] = true
      productsData[this.state.establishmentInfo.allProducts[i].title] = {
        id: i,
        price: this.state.establishmentInfo.allProducts[i].price,
        price_offer: this.state.establishmentInfo.allProducts[i].price_offer,
        options: this.state.establishmentInfo.allProducts[i].options,
        supplements: this.state.establishmentInfo.allProducts[i].supplements,
        options_active: this.state.establishmentInfo.allProducts[i].options_active,
        supplements_active: this.state.establishmentInfo.allProducts[i].supplements_active,
        available: this.state.establishmentInfo.allProducts[i].available,
        categories: [],
        offer: this.state.establishmentInfo.allProducts[i].offer,
        kg: this.state.establishmentInfo.allProducts[i].kg,
        custom_made: this.state.establishmentInfo.allProducts[i].custom_made,
        custom_unity: this.state.establishmentInfo.allProducts[i].custom_unity,
        custom_unity_text: this.state.establishmentInfo.allProducts[i].custom_unity_text
      }


    }

    for (var i = 0; i < this.state.establishmentInfo.menus.length; i++) {
      menusData[this.state.establishmentInfo.menus[i].title] = {
        index: i,
        description: this.state.establishmentInfo.menus[i].description,
        price: this.state.establishmentInfo.menus[i].price,
        visible: this.state.establishmentInfo.menus[i].visible,
        data: this.state.establishmentInfo.menus[i].data

      }
    }

    for (var i = 0; i < this.state.establishmentInfo.products.length; i++) {
      categoriesData[this.state.establishmentInfo.products[i].title] = {
        index: i,
        description: this.state.establishmentInfo.products[i].description,
        visible: this.state.establishmentInfo.products[i].visible,
        data: this.state.establishmentInfo.products[i].data

      }



      for (var j = 0; j < this.state.establishmentInfo.products[i].data.length; j++) {
        //prevents crashing but i dont know why yet


        try {
          productsData[Object.keys(productsData)[this.state.establishmentInfo.products[i].data[j]]].categories.push(this.state.establishmentInfo.products[i].id)
        } catch (e) {
          
        }
      }
    }

    for (var i = 0; i < Object.keys(productsData).length; i++) {

      if (productsData[Object.keys(productsData)[i]].categories.length > 0) {

        noCategoriesProducts[Object.keys(productsData)[i]] = false
      }
    }



    for (const [key, value] of Object.entries(noCategoriesProducts)) {
      if (!value)
        delete noCategoriesProducts[key]
    }

    this.setState({
      activeNoCategoriesProducts: noCategoriesProducts,
      activeProductsIDs: productsIDs,
      activeSupplements: supplementsData,
      activeMenus: menusData,
      activeProducts: productsData,
      activeCategories: categoriesData, productsData: productsData, categoriesData: categoriesData
    })
  }

  async pushDataToDB() {
    const db = getFirestore(this.props.app);
    await setDoc(doc(db, "establishments", this.state.establishmentDocID), this.state.establishmentInfo).then(() => this.generateAuxiliarData());
  }



  //USERS FUNCTIONS

  filterProducts(newText) {
    if (!newText) {
      this.setState({ activeProducts: this.state.productsData })
    } else {

      var filteredProducts = {}
      for (var i = 0; i < Object.keys(this.state.productsData).length; i++) {
        if (Object.keys(this.state.productsData)[i].toLowerCase().indexOf(newText.toLowerCase()) >= 0) {

          filteredProducts[Object.keys(this.state.productsData)[i]] = this.state.productsData[Object.keys(this.state.productsData)[i]]
        }
      }
      this.setState({ activeProductQuery: newText, activeProducts: filteredProducts })

    }
  }



  updateProductAvailability(index) {

    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.allProducts[index].available = !establishmentInfo.allProducts[index].available

    this.setState({
      establishmentInfo: establishmentInfo
    }, () => { this.pushDataToDB(); })

  }

  updateCategoryAvailability(index) {

    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.products[index].visible = !establishmentInfo.products[index].visible



    this.setState({
      establishmentInfo: establishmentInfo
    }, () => { this.pushDataToDB(); })

  }

  updateMenuAvailability(index) {

    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo.menus[index].visible = !establishmentInfo.menus[index].visible



    this.setState({
      establishmentInfo: establishmentInfo
    }, () => { this.pushDataToDB(); })

  }


  //DISHES FUNCTIONS
  filterCategories(newText) {
    if (!newText) {
      this.setState({ activeCategories: this.state.categoriesData })
    } else {

      var filteredProducts = {}
      for (var i = 0; i < Object.keys(this.state.categoriesData).length; i++) {
        if (Object.keys(this.state.categoriesData)[i].toLowerCase().indexOf(newText.toLowerCase()) >= 0) {

          filteredProducts[Object.keys(this.state.categoriesData)[i]] = this.state.categoriesData[Object.keys(this.state.categoriesData)[i]]
        }
      }
      this.setState({ activeCategoryQuery: newText, activeCategories: filteredProducts })

    }
  }





  translate(label) {
    return translations[this.state.preferredLocale][label]

  }




  render() {
    const { children } = this.props
    const { activeCategories,
      activeProducts,
      productsData,
      categoriesData, userData, menusData,
      activeCategoryIndex,
      activeProductIndex, establishmentInfo,
      usuario,
      activeReceipts,
      auth,
      activeMenuIndex,
      activeMenus,
      activeSupplements,
      activeProductsIDs,
      activeNoCategoriesProducts,
      haveReceipts
    } = this.state;
    const {
      //PROFILE

      //DISHES
      filterProducts,
      setActiveCategory,
      setActiveProduct,
      //CATEGORIES
      filterCategories,
      updateProductAvailability,
      updateCategoryAvailability,
      updateProduct,
      updateSupplement,
      deleteProduct,
      deleteSupplement,
      createProduct,
      createSupplement,

      createCategory,
      createMenu,
      deleteCategory,
      deleteMenu,
      updateCategoriesOrder,
      updateCategory,
      updateMenu,
      updateEstablishment,
      saveEstablishmentImage,
      removeEstablishmentImage,

      saveHoursChanges,
      saveProductImage,
      removeProductImage,
      getInfoFromDB,
      translate,
      updateMenuAvailability,
      updateEstablishmentBarTerrace,
      updateOrder,
      getInitialReceipts,
      getMoreReceipts
      //PAYMENTS
    } = this



    return (

      <GeneralContext.Provider
        value={{
          //STATE DATA
          userData,
          productsData,
          menusData,
          categoriesData,
          activeCategories,
          activeProducts,
          productsData,
          categoriesData,
          activeProductIndex,
          activeCategoryIndex,
          establishmentInfo,
          activeMenuIndex,
          usuario,
          activeReceipts,
          auth,
          activeMenus,
          activeSupplements,
          activeProductsIDs,
          activeNoCategoriesProducts,
          haveReceipts,
          updateProduct,
          updateSupplement,
          deleteProduct,
          deleteSupplement,
          createProduct,
          createSupplement,
          createCategory,
          createMenu,
          deleteCategory,
          deleteMenu,

          //PROFILE

          //DISHES
          filterProducts,

          //CATEGORIES
          filterCategories,
          setActiveCategory,
          setActiveProduct,
          updateProductAvailability,
          updateCategoryAvailability,
          updateCategoriesOrder,
          updateCategory,
          updateMenu,
          updateEstablishment,
          saveEstablishmentImage,
          saveHoursChanges,
          removeEstablishmentImage,
          saveProductImage,
          removeProductImage,
          getInfoFromDB,
          translate,
          updateMenuAvailability,
          updateEstablishmentBarTerrace,
          updateOrder,
          getInitialReceipts,
          getMoreReceipts




          //PAYMENTS


        }}
      >
        {children}
      </GeneralContext.Provider>
    )
  }
}


export default GeneralContext
GeneralContext.contextType = AuthContext;
export { GeneralProvider }
