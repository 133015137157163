import * as React from 'react';
import restaurantLogin from './images/login.jpg';
import { SectionList, Text, View, TouchableOpacity, img, TextInput, Modal, Image, Touchable, Animated, Linking } from 'react-native';
import restaurant from './images/restaurant.jpg'
import Stick from 'react-stick'
import { Platform, ScrollView } from 'react-native-web';
import { establishment } from './dataTest'
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { AuthContext } from './AuthContext';
import Loader from "react-loader-spinner";
import { IoTrash, IoLogoWhatsapp } from "react-icons/io5";
import { IoIosClose, } from "react-icons/io";
import { MdError } from "react-icons/md";
import GeneralContext from './Provider';
import {
  FishIcon, GlutenIcon, CrustaceanIcon,
  EggIcon, PeanutIcon, SoyaIcon,
  MilkIcon, NutsIcon, CeleryIcon,
  MustardIcon, SesameIcon, SulphiteIcon,
  LupinIcon, MolluscIcon
} from 'react-allergens';
import { Link } from 'react-router-dom'
import Sticky from 'react-sticky-el';
import { MdChevronRight, MdChevronLeft } from "react-icons/md"
import ShowMoreText from "react-show-more-text";
import './Menu.css'
import ReactWhatsapp from 'react-whatsapp';

import reserve from './images/reserve.png'
import group from './images/group.png'
import takeAway from './images/take-away.png'
import fastDelivery from './images/fast-delivery.png'
import { BottomSheet } from 'react-spring-bottom-sheet'
import Sheet from 'react-modal-sheet';
import Ticker from 'react-ticker'
import Calendar from 'react-calendar'
import './MenuPhone.css'
import 'react-spring-bottom-sheet/dist/style.css'
import { isIOS, isAndroid } from 'react-device-detect';


const daysNames = ["monday.shop", "tuesday.shop", "wednesday.shop", "thursday.shop", "friday.shop", "saturday.shop", "sunday.shop"]

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}



export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <App
      translate={context.translate}
      {...props}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: null,
      categories: [],
      activeCategory: 0,
      sizeScreen: { width: window.screen.width, height: window.screen.height },
      activeItem: null,
      offsets: [],
      visibleHours: false,
      categoriesListOffsets: [],
      categoriesOffsets: [],
      emptyMenu: false,

      activeMenu: {},
      visibleAllergensInfo: false,
      activeMenuTop: false,
      imageListSize: (window.innerWidth - 20) * 0.15,
      detailsOpen: false,
      activeProductWithOptions: {},
      visibleEstablishmentOptions: false,
      whatsAppOrderType: "take_away",
      whatsAppOrderTypeTime: "now",
      visibleWhatsAppBanner: false,
      whatsappSelectedDay: 0,
      whatsappSelectedHour: 0,
      whatsappOrder: false,
      selectedDayWhatsAppOrder: new Date().getDay() || 7 - 1,
      visibleWhatsAppOrderDetails: false,
      whatsAppSend: false,
      activeWhatsAppProduct: { quantity: 1, type: "unity", additionalInfo: "", price: 0 },
      activeWhatsAppSelectedOptions: {},
      activeWhatsAppSelectedOptionsFixedPrice: {},
      activeWhatsAppSelectedOptionsPrices: {},
      activeWhatsAppSelectedSupplements: [],
      whatsappProducts: [],
      showBottomMessage: true

    }
    this.renderMenuHeader = this.renderMenuHeader.bind(this);
    this.renderMenuFooter = this.renderMenuFooter.bind(this);
    this.refCategoriesListHorizontal = React.createRef()
    this.moveCategoryList = this.moveCategoryList.bind(this);
  }
  static contextType = AuthContext;
  async getFromDB() {
    const db = getFirestore(this.context.app);
    const q = query(collection(db, "establishments"), where("id", "==", window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      this.cleanData(doc)

    });

    if (querySnapshot.docs.length == 0)
      this.setState({ emptyMenu: true })
  }

  zeroPad = (num, places) => String(num).padStart(places, '0')

  getTimes(from, until) {
    //"01/01/2001" is just an arbitrary date
    var until = Date.parse("01/01/2001 " + until);
    var from = Date.parse("01/01/2001 " + from);
    //*2 because because we want every 30 minutes instead of every hour
    var max = (Math.abs(until - from) / (60 * 60 * 1000)) * 2;
    var time = new Date(from);
    var hours = [];
    for (var i = 0; i <= max; i++) {
      //doubleZeros just adds a zero in front of the value if it's smaller than 10.
      var hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
      var minute = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      hours.push(hour + ":" + minute);
      time.setMinutes(time.getMinutes() + 30);
    }
    return hours;
  }

  checkIfActiveWhatsAppProductWithOptionsAndSupplementsContainsAll() {
    var totalOptions = 0
    for (const [key, value] of Object.entries(this.state.activeWhatsAppSelectedOptions)) {

      if (!!value)
        totalOptions += 1
    }
    if (this.state.activeProductWithOptions.options)
      return totalOptions != this.state.activeProductWithOptions.options.length

    return false
  }

  checkIfActiveWhatsAppProductWithOptionsFixedPriceAndSupplementsContainsAll() {
    var totalOptions = 0
    for (const [key, value] of Object.entries(this.state.activeWhatsAppSelectedOptions)) {

      if (!!value)
        totalOptions += 1
    }
    if (this.state.activeProductWithOptionsFixedPrice.options)
      return totalOptions != this.state.activeProductWithOptionsFixedPrice.options.length

    return false
  }


  selectDayWhatsAppOrder(index) {
    this.setState({ selectedDayWhatsApp: index % 7 })
  }

  redirectToDownload() {

    if (isIOS) {
      window.open('https://apps.apple.com/es/app/buyness/id1616714361')
    }
    if (isAndroid) {
      window.open('https://play.google.com/store/apps/details?id=com.close2youapp')
    }

  }



  updateActiveWhatsAppProductOptionsAndSupplements(type, key, value, price) {
    var activeWhatsAppSelectedOptionsPrices = this.state.activeWhatsAppSelectedOptionsPrices
    var activeWhatsAppSelectedOptions = this.state.activeWhatsAppSelectedOptions
    var activeWhatsAppSelectedSupplements = this.state.activeWhatsAppSelectedSupplements

    if (type == "option_fixed_price") {

      if (activeWhatsAppSelectedOptions[key] == value) {
        activeWhatsAppSelectedOptions[key] = ""
      }
      else {
        activeWhatsAppSelectedOptions[key] = value
        activeWhatsAppSelectedOptionsPrices[key] = price
      }
    }

    if (type == "option") {
      if (activeWhatsAppSelectedOptions[key] == value) {
        activeWhatsAppSelectedOptionsPrices[key] = 0
        activeWhatsAppSelectedOptions[key] = ""
      }
      else {
        activeWhatsAppSelectedOptions[key] = value
        activeWhatsAppSelectedOptionsPrices[key] = price
      }
    }
    if (type == "supplement") {
      //type=supplement
      if (activeWhatsAppSelectedSupplements.indexOf(key) >= 0) {
        activeWhatsAppSelectedSupplements.splice(activeWhatsAppSelectedSupplements.indexOf(key), 1)
      }
      else {
        activeWhatsAppSelectedSupplements.push(key)
      }
    }




    this.setState({
      activeWhatsAppSelectedOptions: activeWhatsAppSelectedOptions,
      activeWhatsAppSelectedSupplements: activeWhatsAppSelectedSupplements
    })

  }






  updateActiveWhatsAppProductQuantityDirectly(quantity) {
    var activeWhatsAppProduct = this.state.activeWhatsAppProduct
    if (!isNaN(quantity)) {
      if (activeWhatsAppProduct.type == "unity" && quantity >= 1 && quantity <= 50)
        activeWhatsAppProduct.quantity = quantity
      if (activeWhatsAppProduct.type == "weight" && quantity >= 100 && quantity <= 10000)
        activeWhatsAppProduct.quantity = quantity

    }
    this.setState({ activeWhatsAppProduct: activeWhatsAppProduct })
  }

  updateActiveWhatsAppProductQuantityButtons(type) {

    var activeWhatsAppProduct = this.state.activeWhatsAppProduct

    if (activeWhatsAppProduct.type == "unity") {
      if (type == "LESS" && activeWhatsAppProduct.quantity > 1)
        activeWhatsAppProduct.quantity -= 1

      if (type == "MORE" && activeWhatsAppProduct.quantity < 50)
        activeWhatsAppProduct.quantity += 1
    }
    if (activeWhatsAppProduct.type == "weight") {
      if (type == "LESS" && activeWhatsAppProduct.quantity > 100)
        activeWhatsAppProduct.quantity -= 50

      if (type == "MORE" && activeWhatsAppProduct.quantity < 10000)
        activeWhatsAppProduct.quantity += 50
    }

    this.setState({ activeWhatsAppProduct: activeWhatsAppProduct })
  }


  setActiveWhatsAppProduct(item) {
 
    var activeWhatsAppProduct = {
      title: item.title,
      price: item.price,
      quantity: item.kg ? 100 : 1,
      type: item.kg ? "weight" : "unity",
      additionalInfo: ""
    }

    this.setState({ activeWhatsAppProduct: activeWhatsAppProduct })
  }

  updateActiveWhatsAppProductAdditionalInfo(t) {
    var activeWhatsAppProduct = this.state.activeWhatsAppProduct
    activeWhatsAppProduct.additionalInfo = t
    this.setState({ activeWhatsAppProduct: activeWhatsAppProduct })
  }

  generatePossibleHours(hours) {
    var hoursClean = []
    for (var i = 0; i < hours.length; i++) {
      hoursClean.push(this.getTimes(hours[i].init, hours[i].final))
    }
    var hoursToShow = []
    for (var i = 0; i < hoursClean.length; i++) {
      for (var j = 0; j < hoursClean[i].length; j++) {
        if (j != 0)
          hoursToShow.push(hoursClean[i][j - 1] + "h - " + hoursClean[i][j] + "h")
      }
    }

    this.setState({ whatsAppPossibleHours: hoursToShow })
  }

  renderAllergenItem(allergen) {
    switch (allergen) {
      case "fish":
        return <FishIcon width={25} height={25} />
      case "gluten":
        return <GlutenIcon width={25} height={25} />
      case "crustacean":
        return <CrustaceanIcon width={25} height={25} />
      case "egg":
        return <EggIcon width={25} height={25} />
      case "peanut":
        return <PeanutIcon width={25} height={25} />
      case "soya":
        return <SoyaIcon width={25} height={25} />
      case "milk":
        return <MilkIcon width={25} height={25} />
      case "nuts":
        return <NutsIcon width={25} height={25} />
      case "celery":
        return <CeleryIcon width={25} height={25} />
      case "mustard":
        return <MustardIcon width={25} height={25} />
      case "sesame":
        return <SesameIcon width={25} height={25} />
      case "sulphite":
        return <SulphiteIcon width={25} height={25} />
      case "lupin":
        return <LupinIcon width={25} height={25} />
      case "mollusc":
        return <MolluscIcon width={25} height={25} />
      default:
        return <View />
    }
  }

  renderAllergenActiveItem(allergen) {

    switch (allergen) {
      case "fish":
        return <FishIcon width={30} height={30} />
      case "gluten":
        return <GlutenIcon width={30} height={30} />
      case "crustacean":
        return <CrustaceanIcon width={30} height={30} />
      case "egg":
        return <EggIcon width={30} height={30} />
      case "peanut":
        return <PeanutIcon width={30} height={30} />
      case "soya":
        return <SoyaIcon width={30} height={30} />
      case "milk":
        return <MilkIcon width={30} height={30} />
      case "nuts":
        return <NutsIcon width={30} height={30} />
      case "celery":
        return <CeleryIcon width={30} height={30} />
      case "mustard":
        return <MustardIcon width={30} height={30} />
      case "sesame":
        return <SesameIcon width={30} height={30} />
      case "sulphite":
        return <SulphiteIcon width={30} height={30} />
      case "lupin":
        return <LupinIcon width={30} height={30} />
      case "mollusc":
        return <MolluscIcon width={30} height={30} />
      default:
        return <View />
    }
  }


  generatePrice(item) {
    if (!!item.price && item.options_fixed_price_active) {
      return (
        <View>
          {!!item.price &&

            <Text style={{ textDecorationLine: item.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left" }}>
              {!isNaN(item.price) ?
                `${parseFloat(item.price).toFixed(2)} €`
                : item.price + " €"}
            </Text>
          }


        </View>
      )
    }

    if (!item.price && !item.options_active && !item.supplements_active) {
      return (
        <View>
          {!!item.price &&

            <Text style={{ textDecorationLine: item.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left" }}>
              Precio no especificado
            </Text>
          }


        </View>
      )
    }

    if (!!item.price) {

      return (
        <View>
          {!!item.price &&

            <Text style={{ textDecorationLine: item.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left" }}>

              {
                !isNaN(item.price) ?
                  `${parseFloat(item.price).toFixed(2)} € ${item.kg ? "/ kg" : item.custom_unity ? "/ " + item.custom_unity_text : ""}`
                  :
                  ""
              }
            </Text>
          }
          {item.offer &&

            <Text style={{ color: "red", fontWeight: "500", fontSize: "0.8rem", textAlign: "left" }}>
              {
                !isNaN(item.price_offer) ?
                  `${parseFloat(item.price_offer).toFixed(2)} € ${item.kg ? "/ kg" : item.custom_unity ? "/ " + item.custom_unity_text : ""}`
                  :
                  ""
              }

            </Text>


          }
        </View>
      )
    }

    if (item.options_active) {
      var minimumValue = 99999999
      for (var i = 0; i < item.options.length; i++) {
        for (var j = 0; j < item.options[i].data.length; j++) {
          if (!isNaN(item.options[i].data[j].price)) {
            if (parseFloat(item.options[i].data[j].price) < minimumValue)
              minimumValue = parseFloat(item.options[i].data[j].price)
          }
        }
      }
      return (
        <View>

          <Text style={{ color: "#000", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left" }}>

            {
              minimumValue != 99999999 ?

                `A partir de ${minimumValue.toFixed(2)} € ${item.kg ? "/ kg" : item.custom_unity ? "/ " + item.custom_unity_text : ""}`
                :
                "Precio no especificado"
            }
          </Text>

        </View>
      )

    }

  }


  cleanData(doc) {

    var data = doc.data()

    var cleanCategories = []
    for (var i = 0; i < data.products.length; i++) {
      data.products[i].type = "product"
      if (data.products[i].visible && data.products[i].data.length > 0)
        cleanCategories.push(data.products[i])
    }

    var menus = []
    for (var i = 0; i < data.menus.length; i++) {
      data.menus[i].index = i
      var temporalMenu = data.menus[i]
      if (data.menus[i].visible)
        menus.push(temporalMenu)
    }
    var menusData = {
      title: "Menús",
      type: "menu",
      data: menus
    }
    data.products = []
    if (menusData.data.length > 0)
      data.products = [menusData, ...cleanCategories]
    else
      data.products = cleanCategories




    // doc.data() is never undefined for query doc snapshots
    this.setState({ menu: data, visibleWhatsAppBanner: true }, () => {
      this.generateCategories(this.state.menu.products)
      window.addEventListener("scroll", this.moveCategoryList);
      setTimeout(() =>
        this.getSectionsOffset(), 1000)
    })


  }

  generateCategories(list) {
    var cleanCategories = []
    for (var i = 0; i < list.length; i++) {
      cleanCategories.push(list[i].title)

    }
    this.setState({ categories: cleanCategories })
  }

  getSectionsOffset() {
    var categoriesOffsets = []
    categoriesOffsets.push(0)
    for (var i = 0; i < this.state.menu.products.length; i++) {
      if (document.getElementById("section_" + i))
        categoriesOffsets.push(document.getElementById("section_" + i).getBoundingClientRect().top - 160)

    }

    this.setState({ categoriesOffsets: categoriesOffsets })
  }


  renderMenuSectionHeader(item) {
    return (
      <View style={{ backgroundColor: "#F5F5F5", width: "100%", paddingVertical: 10 }}>
        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
          {item.section.title}
        </Text>
      </View>
    )
  }

  renderMenuSectionItem(item) {
    return (
      <View style={{ zIndex: -1 }}>
        <View style={{ height: 1, backgroundColor: "#EDEDED" }} />
        <Text style={{ paddingTop: 20, paddingBottom: 10, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
          {this.state.menu.allProducts[item].title}
        </Text>
        <Text style={{ paddingBottom: 10, color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
          {this.state.menu.allProducts[item].description}
        </Text>
        {
          this.state.menu.allProducts[item].allergens && this.state.menu.allProducts[item].allergens.length > 0 &&
          <View style={{ width: "100%", paddingHorizontal: 10 }}>
            <View style={{ height: 10 }} />
            <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
              {
                this.state.menu.allProducts[item].allergens.map((item, index) => (
                  <View style={{ marginLeft: index > 0 ? 5 : 0, width: 30, height: 30 }}>
                    {this.renderAllergenItem(item)}
                  </View>
                ))
              }
            </View>
            <View style={{ height: 10 }} />
          </View>
        }

      </View>
    )
  }

  renderMenuFooter() {
    return (
      <View>
        <View style={{ paddingTop: 30, paddingBottom: 20 }}>
          <Text style={{ color: "gray", fontWeight: "500", fontSize: "0.8rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
            {this.state.activeMenu.description}
          </Text>
        </View>
        <View style={{ paddingVertical: 20 }}>
          <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
            {this.props.translate("price.shop")}: <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, width: "100%", textAlign: "left" }}>
              {
                !isNaN(this.state.activeMenu.price) ?
                  `${this.state.activeMenu.price.toFixed(2)} €`
                  :
                  ""
              }
            </Text>
          </Text>
        </View>
        <View style={{ height: 30 }} />
      </View>
    )
  }

  renderMenuHeader() {
    return (
      <Text style={{ paddingTop: 10, paddingBottom: 20, color: "#000", fontWeight: "600", fontSize: "2rem", paddingLeft: 10, paddingRight: 70, width: "100%", textAlign: "left" }}>
        {this.state.activeMenu.title}
      </Text>
    )
  }


  getEstablishmentInfo(){
    this.generatePossibleHours([{ init: "08:00", final: "11:30" }, { init: "15:00", final: "16:30" }])
    this.getFromDB()
    this.removeBlockScroll();
    window.addEventListener("resize", this.updateWindowDimensions, false);
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
   // navigator.geolocation.getCurrentPosition((coords) => this.setState({ userLocation: coords }, () => console.warn(this.state.userLocation)), () => { }, options);

  }

  componentDidMount() {
    Linking.canOpenURL("buyness-app://establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]).then((supported) => {

      if (supported && (isIOS || isAndroid)){
        window.location="buyness-app://establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        this.getEstablishmentInfo() 
      }else {
  this.getEstablishmentInfo()
       
      }
    }).catch(e => {return true})



  }

  generatePossibleHoursFromDate(date) {
    var index = (date.getDay() - 1) % 7
    if (index < 0)
      index = 6

    this.generatePossibleHours(this.state.menu.hours[index].hours)
  }


  toRad(Value) {
    return Value * Math.PI / 180;
  }
  calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000;
  }


  distanceToEstablishment(item) {
    if (this.state.userLocation && item.coords)
      var result = Math.round(this.calcDistance(this.state.userLocation.coords.latitude,
        this.state.userLocation.coords.longitude,
        item.coords.latitude, item.coords.longitude) / 10) * 10

    if (result > 1000)
      return (result / 1000).toFixed(2) + " km."
    else
      return result + " m."
  }



  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ imageListSize: (window.innerWidth - 20) * 0.15 });
  }

  moveCategoryList() {
    var index = 0
    var actualScroll = window.pageYOffset
    for (var i = 0; i < this.state.categoriesOffsets.length; i++) {

      if (actualScroll > (this.state.categoriesOffsets[i])) {
        index = i

      }

      if (actualScroll + window.innerHeight >= document.documentElement.scrollHeight - 100) {
        index = this.state.categoriesOffsets.length - 1

      }


    }


    if (this.state.activeCategory != index && index != null && this.state.activeCategory != null) {
      this.refCategoriesListHorizontal.current.scrollTo({ x: this.state.categoriesListOffsets[index], y: 0 })
      this.setState({ activeCategory: index })
    }


  }




  addOffsetCategoriesList(offset) {
    var categoriesListOffsets = this.state.categoriesListOffsets;
    categoriesListOffsets.push(offset)
    this.setState({ categoriesListOffsets: categoriesListOffsets })
  }


  moveTo(index) {
    window.scrollTo({ top: this.state.categoriesOffsets[index] + 20, behavior: 'smooth' })
    this.setState({ visibleWhatsAppBanner: index == 0 ? true : false })
  }

  manageItemPress(item) {
    if (item.options_fixed_price_active) {
      if (item.supplements == null)
        item.supplements = []
      this.setState({ activeProductWithOptionsFixedPrice: item, activeProductWithOptions: null, activeItem: null }, () => { this.setActiveWhatsAppProduct(item); this.blockScroll() })
      return
    }

    if (item.options_active || item.supplements_active) {
      if (item.supplements == null)
        item.supplements = []
      this.setState({ activeProductWithOptionsFixedPrice: null, activeProductWithOptions: item, activeItem: null }, () => { this.setActiveWhatsAppProduct(item); this.blockScroll() })
    }
    else {
      if (item.type == "weight")
        item.quantity = 100

      this.setState({ activeProductWithOptionsFixedPrice: null, activeItem: item, activeProductWithOptions: null }, () => { this.setActiveWhatsAppProduct(item); this.blockScroll() })
    }

  }


  blockScroll() {
    document.querySelector("body").style.overflow = "hidden";
  }

  removeBlockScroll() {
    document.querySelector("body").style.removeProperty("overflow");
  }


  renderItem(section, item, index) {

    if (section.type == "menu") {

      return (
        <TouchableOpacity
          id={section.data[index].title}
          onPress={() => this.setState({ activeMenu: section.data[index] }, () => this.blockScroll())}
          onLongPress={() => this.setState({ activeMenu: section.data[index] }, () => this.blockScroll())}
          style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 10, borderWidth: 1, borderColor: "#F5F5F5", marginHorizontal: 10, borderRadius: 5 }}>

          <View style={{ width: "100%" }}>
            <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

              {section.data[index].title}
            </Text>
            {!!section.data[index].description &&
              <View>
                <View style={{ height: 10 }} />
                <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                  {section.data[index].description}
                </Text>
              </View>
            }
            <View style={{ height: 10 }} />
            <Text style={{ color: "#000", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

              {
                !isNaN(section.data[index].price) ?
                  `${parseFloat(section.data[index].price).toFixed(2)} €`
                  : ""
              }
            </Text>
          </View>
        </TouchableOpacity>
      )
    }
    if (section.type == "product") {
      if (item.available) {
        if (!!item.img)
          return (
            <TouchableOpacity
              id={item.title}
              onPress={() => this.manageItemPress(item)}
              onLongPress={() => this.manageItemPress(item)}
              style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 10, borderWidth: 1, borderColor: "#F5F5F5", marginHorizontal: 10, borderRadius: 5 }}>
              <View style={{ width: "20%", justifyContent: "center", alignItems: "center", paddingRight: 10 }}>
                <View style={{ width: this.state.imageListSize, height: this.state.imageListSize, backgroundColor: "#F5F5F5", overflow: "hidden", borderRadius: 10 }}>
                  <img src={item.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                </View>
              </View>
              <View style={{ width: "80%" }}>
                <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left" }}>

                  {item.title}
                </Text>
                {!!item.description &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                      {item.description}
                    </Text>
                  </View>
                }



                {
                  item.labels && item.labels.length > 0 &&
                  <View>
                    <View style={{ height: 10 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>

                      {
                        item.labels &&
                        item.labels.map((label, label_index) => (

                          <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                            <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                              {label.label}
                            </Text>
                          </View>


                        ))
                      }
                    </View>
                  </View>
                }
                <View style={{ height: 10 }} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>

                  {this.generatePrice(item)}

                </View>

                {
                  item.allergens && item.allergens.length > 0 &&
                  <View style={{ width: "100%" }}>
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        item.allergens.map((item, index) => (
                          <View style={{ marginLeft: index > 0 ? 5 : 0, width: 30, height: 30 }}>
                            {this.renderAllergenItem(item)}
                          </View>
                        ))
                      }
                    </View>
                  </View>
                }
              </View>
            </TouchableOpacity>

          )
        else
          return (
            <TouchableOpacity
              id={item.title}
              onPress={() => this.manageItemPress(item)}
              onLongPress={() => this.manageItemPress(item)}
              style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 10, borderWidth: 1, borderColor: "#F5F5F5", marginHorizontal: 10, borderRadius: 5 }}>

              <View style={{ width: "100%" }}>
                <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                  {item.title}
                </Text>
                {!!item.description &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                      {item.description}
                    </Text>
                  </View>
                }

                {
                  item.labels && item.labels.length > 0 &&
                  <View>
                    <View style={{ height: 10 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>

                      {
                        item.labels &&
                        item.labels.map((label, label_index) => (

                          <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                            <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                              {label.label}
                            </Text>
                          </View>


                        ))
                      }
                    </View>
                  </View>
                }

                <View style={{ height: 10 }} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>

                  {this.generatePrice(item)}

                </View>

                {
                  item.allergens && item.allergens.length > 0 &&
                  <View style={{ width: "100%" }}>
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        item.allergens.map((item, index) => (
                          <View style={{ marginLeft: index > 0 ? 5 : 0, width: 30, height: 30 }}>
                            {this.renderAllergenItem(item)}
                          </View>
                        ))
                      }
                    </View>
                  </View>
                }

              </View>

            </TouchableOpacity>

          )

      }

      if (!item.avaliable) {
        if (!!item.img)
          return (
            <TouchableOpacity
              id={item.title}
              onPress={() => this.manageItemPress(item)}
              onLongPress={() => this.manageItemPress(item)}
              style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 10, backgroundColor: "#F5F5F5", marginHorizontal: 10, borderRadius: 5 }}>
              <View style={{ width: 100, justifyContent: "center" }}>
                <View style={{ width: 80, height: 80, backgroundColor: "#F5F5F5", overflow: "hidden", borderRadius: 10 }}>
                  <img loading="lazy" src={item.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                </View>
              </View>
              <View style={{ width: "80%" }}>
                <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                  {item.title}
                </Text>
                {!!item.description &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                      {item.description}
                    </Text>
                  </View>
                }

                {
                  item.labels && item.labels.length > 0 &&
                  <View>
                    <View style={{ height: 10 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>

                      {
                        item.labels &&
                        item.labels.map((label, label_index) => (

                          <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                            <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                              {label.label}
                            </Text>
                          </View>


                        ))
                      }
                    </View>
                  </View>
                }

                <View style={{ height: 10 }} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>

                  {this.generatePrice(item)}

                </View>

                <View style={{ height: 20 }} />
                <Text style={{ color: "red", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>
                  {this.props.translate("available.shop")}
                </Text>
                {
                  item.allergens && item.allergens.length > 0 &&
                  <View style={{ width: "100%" }}>
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        item.allergens.map((item, index) => (
                          <View style={{ marginLeft: index > 0 ? 5 : 0, width: 30, height: 30 }}>
                            {this.renderAllergenItem(item)}
                          </View>
                        ))
                      }
                    </View>
                  </View>
                }
              </View>
            </TouchableOpacity>

          )
        else {
          return (
            <TouchableOpacity
              id={item.title}
              onPress={() => this.manageItemPress(item)}
              onLongPress={() => this.manageItemPress(item)}
              style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 10, backgroundColor: "#F5F5F5", marginHorizontal: 10, borderRadius: 5 }}>

              <View style={{ width: "100%" }}>
                <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                  {item.title}
                </Text>
                {!!item.description &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>

                      {item.description}
                    </Text>
                  </View>
                }

                {
                  item.labels && item.labels.length > 0 &&
                  <View>
                    <View style={{ height: 10 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>

                      {
                        item.labels &&
                        item.labels.map((label, label_index) => (

                          <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                            <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                              {label.label}
                            </Text>
                          </View>


                        ))
                      }
                    </View>
                  </View>
                }

                <View style={{ height: 10 }} />
                <View style={{ flexDirection: "row", alignItems: "center" }}>

                  {this.generatePrice(item)}

                </View>

                <View style={{ height: 10 }} />
                <Text style={{ color: "red", fontWeight: "500", fontSize: "0.8rem", paddingRight: 10, textAlign: "left", width: "100%" }}>
                  {this.props.translate("available.shop")}
                </Text>
                {
                  item.allergens && item.allergens.length > 0 &&
                  <View style={{ width: "100%" }}>
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        item.allergens.map((item, index) => (
                          <View style={{ marginLeft: index > 0 ? 5 : 0, width: 30, height: 30 }}>
                            {this.renderAllergenItem(item)}
                          </View>
                        ))
                      }
                    </View>
                  </View>
                }
              </View>
            </TouchableOpacity>

          )
        }
      }
    }
  }

  renderItemSeparator() {
    return (
      <View id={"separator"} style={{ height: 10 }} />
    )
  }

  renderFooter() {
    return (
      <View id={"footer"} style={{ width: "100%", height: 30 }}>

      </View>
    )
  }

  generateOrderText() {

    var finalString = "Hola, quería realizar un pedido a nombre de "
    finalString += this.state.whatsAppOrderName + " "

    var deliveryOption = ""
    if (this.state.whatsAppOrderTypeTime == "now")
      deliveryOption = "lo antes posible"
    else {
      if (this.state.whatsappSelectedDay == 0)
        deliveryOption += "Hoy " + new Date().addDays(this.state.whatsappSelectedDay).toLocaleString('es-ES', { day: 'numeric', month: 'short' }) + " a las "
      if (this.state.whatsappSelectedDay == 1)
        deliveryOption += "Mañana" + new Date().addDays(this.state.whatsappSelectedDay).toLocaleString('es-ES', { day: 'numeric', month: 'short' }) + " a las "
      if (this.state.whatsappSelectedDay > 1)
        deliveryOption += new Date().addDays(this.state.whatsappSelectedDay).toLocaleString('es-ES', { weekday: 'long', day: 'numeric', month: 'short' }) + " a las "

      deliveryOption += this.state.whatsAppPossibleHours[this.state.whatsappSelectedHour]
    }


    finalString += "para " + deliveryOption + "%0a"
    finalString += "%0a*****************************%0a"
    finalString += "Detalles del pedido:%0a%0a"
    for (var i = 0; i < this.state.whatsappProducts.length; i++) {
      finalString += "- " + this.state.whatsappProducts[i].title + " ("
      if (this.state.whatsappProducts[i].type == "unity")
        finalString += this.state.whatsappProducts[i].quantity + " uds. )" + "%0a%0a"
      else
        finalString += this.state.whatsappProducts[i].quantity + " gramos. )" + "%0a%0a"
      if (this.state.whatsappProducts[i].options && Object.keys(this.state.whatsappProducts[i].options).length > 0) {
        finalString += "   Opciones:%0a"
        for (const [key, value] of Object.entries(this.state.whatsappProducts[i].options)) {
          finalString += "    -" + key + ": " + value + "%0a"
        }

        finalString += "%0a"
      }
      //REVISAR
      if (this.state.whatsappProducts[i].options_fixed_price_active && Object.keys(this.state.whatsappProducts[i].options_fixed_price).length > 0) {
        finalString += "   Opciones:%0a"
        for (const [key, value] of Object.entries(this.state.whatsappProducts[i].options_fixed_price)) {
          finalString += "    -" + key + ": " + value + "%0a"
        }

        finalString += "%0a"
      }
      if (this.state.whatsappProducts[i].supplements && this.state.whatsappProducts[i].supplements.length > 0) {
        finalString += "  Suplementos:%0a"
        for (var j = 0; j < this.state.whatsappProducts[i].supplements.length; j++) {
          finalString += "    -" + this.state.whatsappProducts[i].supplements[j] + "%0a"
        }
        finalString += "%0a"
      }

      if (!!this.state.whatsappProducts[i].additionalInfo) {
        finalString += "  Información adicional: " + this.state.whatsappProducts[i].additionalInfo + "%0a"
      }
      if (i < this.state.whatsappProducts.length - 1)
        finalString += "------------------------%0a%0a"
      else
        finalString += "%0a"
    }

    if (this.state.whatsAppOrderType == "delivery") {
      finalString += "*****************************%0a"
      finalString += "Enviar a la siguiente dirección: " + this.state.whatsAppDeliveryDirection
    }

    if (this.state.whatsAppOrderType == "take_away") {
      finalString += "*****************************%0a"
      finalString += "Recogida en local"
    }
    finalString += "%0a*****************************%0a"
    if (this.state.whatsappProducts.length > 0 && this.state.menu && this.state.menu.whatsapp) {
      window.open(`https://wa.me/34${this.state.menu.whatsapp}?text=${finalString}`)
      this.setState({ whatsAppSend: true })
    }
    return finalString
  }

  removeProductFromWhatsApp(index) {
    var whatsappProducts = this.state.whatsappProducts
    whatsappProducts.splice(index, 1)

    this.setState({ visibleWhatsAppOrderDetails: whatsappProducts.length == 0 ? false : true, whatsappProducts: whatsappProducts }, () => { if (whatsappProducts.length == 0) this.removeBlockScroll() })
  }

  calculatePriceActiveWhatsAppProduct() {

    if (this.state.activeWhatsAppProduct.type == "unity") {

      return (this.state.activeWhatsAppProduct.price * this.state.activeWhatsAppProduct.quantity).toFixed(2)

    }
    if (this.state.activeWhatsAppProduct.type == "weight") {

      return (this.state.activeWhatsAppProduct.price * (this.state.activeWhatsAppProduct.quantity / 1000)).toFixed(2)

    }
  }

  calculatePriceActiveWhatsAppProductWithOptionsFixedPrice() {

    if (this.state.activeWhatsAppProduct.type == "unity") {

      return (this.state.activeWhatsAppProduct.price * this.state.activeWhatsAppProduct.quantity).toFixed(2)

    }
    if (this.state.activeWhatsAppProduct.type == "weight") {

      return (this.state.activeWhatsAppProduct.price * (this.state.activeWhatsAppProduct.quantity / 1000)).toFixed(2)

    }
  }

  calculatePriceActiveWhatsAppProductWithOptions() {
    var totalCost = 0

    if (Object.keys(this.state.activeWhatsAppSelectedOptions).length > 0) {

      for (var i = 0; i < Object.keys(this.state.activeWhatsAppSelectedOptions).length; i++) {

        if (!!this.state.activeWhatsAppSelectedOptionsPrices[Object.keys(this.state.activeWhatsAppSelectedOptions)[i]])
          totalCost += parseFloat(this.state.activeWhatsAppSelectedOptionsPrices[Object.keys(this.state.activeWhatsAppSelectedOptions)[i]])
      }
      if (this.state.activeWhatsAppSelectedSupplements.length > 0) {
        for (var i = 0; i < this.state.activeWhatsAppSelectedSupplements.length; i++) {
          for (var j = 0; j < this.state.menu.supplements.length; j++) {
            if (this.state.menu.supplements[j].title == this.state.activeWhatsAppSelectedSupplements[i] && !isNaN(this.state.menu.supplements[j].price)) {
              totalCost += parseFloat(this.state.menu.supplements[j].price)
              break
            }
          }
        }
      }
      if (this.state.activeWhatsAppProduct.type == "unity")
        return (totalCost * this.state.activeWhatsAppProduct.quantity).toFixed(2)
      else
        return (totalCost * (this.state.activeWhatsAppProduct.quantity / 1000)).toFixed(2)
    }
    else {
      if (this.state.menu)

        if (this.state.activeWhatsAppProduct.type == "unity") {
          var totalCost = 0
          totalCost += this.state.activeWhatsAppProduct.price
          if (this.state.activeWhatsAppSelectedSupplements.length > 0) {
            for (var i = 0; i < this.state.activeWhatsAppSelectedSupplements.length; i++) {
              for (var j = 0; j < this.state.menu.supplements.length; j++) {
                if (this.state.menu.supplements[j].title == this.state.activeWhatsAppSelectedSupplements[i] && !isNaN(this.state.menu.supplements[j].price)) {
                  totalCost += parseFloat(this.state.menu.supplements[j].price)
                  break
                }
              }
            }
          }

          return (totalCost * this.state.activeWhatsAppProduct.quantity).toFixed(2)

        }
      if (this.state.activeWhatsAppProduct.type == "weight") {
        var totalCost = 0
        totalCost += this.state.activeWhatsAppProduct.price
        if (this.state.activeWhatsAppSelectedSupplements.length > 0) {
          for (var i = 0; i < this.state.activeWhatsAppSelectedSupplements.length; i++) {
            for (var j = 0; j < this.state.menu.supplements.length; j++) {
              if (this.state.menu.supplements[j].title == this.state.activeWhatsAppSelectedSupplements[i] && !isNaN(this.state.menu.supplements[j].price)) {
                totalCost += parseFloat(this.state.menu.supplements[j].price)
                break
              }
            }
          }
        }

        return (totalCost * (this.state.activeWhatsAppProduct.quantity / 1000)).toFixed(2)

      }
    }
  }


  arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }


  addToWhatsAppOrder() {
    var whatsappProducts = this.state.whatsappProducts
    var activeWhatsAppProduct = JSON.parse(JSON.stringify(this.state.activeWhatsAppProduct))
    var foundItem = false
    var foundItemIndex = -1
    if (!activeWhatsAppProduct.price)
      activeWhatsAppProduct.price = 0

    for (var i = 0; i < whatsappProducts.length; i++) {
      if (this.state.activeProductWithOptionsFixedPrice == null) {

        if (
          whatsappProducts[i].title == activeWhatsAppProduct.title &&
          whatsappProducts[i].additionalInfo == activeWhatsAppProduct.additionalInfo &&
          whatsappProducts[i].type == activeWhatsAppProduct.type


        ) {
          if (!whatsappProducts[i].supplements && !activeWhatsAppProduct.supplements && !whatsappProducts[i].options && !activeWhatsAppProduct.options && !whatsappProducts[i].options_price && !activeWhatsAppProduct.options_price) {
            foundItem = true
            foundItemIndex = i
            break
          }

          if ((this.arrayEquals(whatsappProducts[i].supplements, activeWhatsAppProduct.supplements) ||
            whatsappProducts[i].supplements.length == 0 && activeWhatsAppProduct.supplements == null) &&
            JSON.stringify(whatsappProducts[i].options) == JSON.stringify(this.state.activeWhatsAppSelectedOptions)) {
            foundItem = true
            foundItemIndex = i
            break
          }

        }
      }
      else {


        if (
          whatsappProducts[i].title == this.state.activeWhatsAppProduct.title &&
          whatsappProducts[i].additionalInfo == this.state.activeWhatsAppProduct.additionalInfo &&
          whatsappProducts[i].type == this.state.activeWhatsAppProduct.type
          //OK

        ) {
          if (!whatsappProducts[i].supplements && !this.state.activeProductWithOptionsFixedPrice.supplements && !whatsappProducts[i].options_fixed_price && !this.state.activeProductWithOptionsFixedPrice.options_fixed_price) {
            foundItem = true
            foundItemIndex = i
            break
          }
          if (this.arrayEquals(whatsappProducts[i].supplements, this.state.activeProductWithOptionsFixedPrice.supplements) &&
            JSON.stringify(whatsappProducts[i].options_fixed_price) == JSON.stringify(this.state.activeWhatsAppSelectedOptions)) {
            foundItem = true
            foundItemIndex = i
            break
          }

        }

      }

    }

    if (foundItem) {
      whatsappProducts[foundItemIndex].quantity += activeWhatsAppProduct.quantity

      this.setState({
        activeProductWithOptionsFixedPrice: null,
        activeProductWithOptions: null,
        activeItem: null,
        whatsappProducts: whatsappProducts,
        activeWhatsAppProduct: { quantity: 1, type: "unity", additionalInfo: "", price: 0 },
        activeWhatsAppSelectedOptions: {},
        activeWhatsAppSelectedOptionsPrices: {},
        activeWhatsAppSelectedSupplements: []
      }, () => {
        this.removeBlockScroll()
      })

    }
    else {//simple product
      if (this.state.activeItem) {
        activeWhatsAppProduct.title = this.state.activeItem.title
        activeWhatsAppProduct.price = parseFloat(activeWhatsAppProduct.price)
        whatsappProducts.push(activeWhatsAppProduct)
      } else {
        if (this.state.activeProductWithOptionsFixedPrice == null) {
          activeWhatsAppProduct.options_fixed_price_active = false
          activeWhatsAppProduct.options = JSON.parse(JSON.stringify(this.state.activeWhatsAppSelectedOptions))
          activeWhatsAppProduct.options_price = JSON.parse(JSON.stringify(this.state.activeWhatsAppSelectedOptionsPrices))
          activeWhatsAppProduct.supplements = JSON.parse(JSON.stringify(this.state.activeWhatsAppSelectedSupplements))
        }
        else {

          activeWhatsAppProduct.options_fixed_price_active = true
          activeWhatsAppProduct.options_fixed_price = JSON.parse(JSON.stringify(this.state.activeWhatsAppSelectedOptions))
          activeWhatsAppProduct.price = parseFloat(this.state.activeWhatsAppProduct.price)
          activeWhatsAppProduct.supplements = JSON.parse(JSON.stringify(this.state.activeWhatsAppSelectedSupplements))

        }

        whatsappProducts.push(activeWhatsAppProduct)


      }


      this.setState({
        activeProductWithOptions: null,
        activeProductWithOptionsFixedPrice: null,
        activeItem: null,
        whatsappProducts: whatsappProducts,
        activeWhatsAppProduct: { quantity: 1, type: "unity", additionalInfo: "", price: 0 },
        activeWhatsAppSelectedOptions: {},
        activeWhatsAppSelectedOptionsPrices: {},
        activeWhatsAppSelectedSupplements: []
      }, () => {
        this.removeBlockScroll()
      })
    }
  }

  getSupplementPrice(supplement) {
    for (var i = 0; i < this.state.menu.supplements.length; i++) {
      if (this.state.menu.supplements[i].title == supplement) {
        return parseFloat(this.state.menu.supplements[i].price).toFixed(2)
      }
    }
  }

  calculateTotalPriceWhatsAppOrder() {
    var totalPrice = 0
    for (var i = 0; i < this.state.whatsappProducts.length; i++) {
      if (!this.state.whatsappProducts[i].options || Object.keys(this.state.whatsappProducts[i].options).length == 0) {
        if (this.state.whatsappProducts[i].type == "unity") {
          totalPrice += this.state.whatsappProducts[i].quantity * this.state.whatsappProducts[i].price

        }
        if (this.state.whatsappProducts[i].type == "weight") {
          totalPrice += (this.state.whatsappProducts[i].quantity / 1000) * this.state.whatsappProducts[i].price
        }
        if (this.state.whatsappProducts[i].supplements) {
          for (var k = 0; k < this.state.whatsappProducts[i].supplements.length; k++) {
            totalPrice += this.getSupplementPrice(this.state.whatsappProducts[i].supplements[k]) * this.state.whatsappProducts[i].quantity
          }
        }
      }
      else {
        if (this.state.whatsappProducts[i].options) {
          for (var j = 0; j < Object.keys(this.state.whatsappProducts[i].options).length; j++) {
            if (!!this.state.whatsappProducts[i].options_price[Object.keys(this.state.whatsappProducts[i].options)[j]])
              if (this.state.whatsappProducts[i].type == "unity")
                totalPrice += parseFloat(this.state.whatsappProducts[i].options_price[Object.keys(this.state.whatsappProducts[i].options)[j]]) * this.state.whatsappProducts[i].quantity
              else
                totalPrice += parseFloat(this.state.whatsappProducts[i].options_price[Object.keys(this.state.whatsappProducts[i].options)[j]]) * (this.state.whatsappProducts[i].quantity / 1000)
          }
        }
      }
      if (this.state.whatsappProducts[i].supplements && this.state.whatsappProducts[i].supplements.length > 0) {
        for (var j = 0; j < this.state.whatsappProducts[i].supplements.length; j++) {

          totalPrice += this.getSupplementPrice(this.state.whatsappProducts[i].supplements[j]) * this.state.whatsappProducts[i].quantity
        }
      }
    }

    return totalPrice.toFixed(2)
  }


  calculatePriceProduct(product) {

    var price = 0
    if (!product.options || Object.keys(product.options).length == 0 || product.options_fixed_price) {
      if (product.type == "unity") {
        return (product.price * product.quantity).toFixed(2) + "€"
      }
      if (product.type == "weight") {
        return ((product.price / 1000) * product.quantity).toFixed(2) + "€ /kg"
      }

    }
    else {
      if (product.options_price) {

        for (const [key, value] of Object.entries(product.options_price)) {

          if (!isNaN(value) && !!value)
            if (product.type == "unity")
              price += parseFloat(value)
            else
              price += parseFloat(value)
        }
      }
    }

    if (product.supplements && product.supplements.length > 0) {
      for (var i = 0; i < product.supplements.length; i++) {

        price += this.getSupplementPrice(product.supplements[i])
      }
    }


    return parseFloat(price).toFixed(2) + "€"
  }

  calculatePriceProductUnit(product) {

    var price = 0
    if (!product.options || Object.keys(product.options).length == 0 || product.options_fixed_price) {
      if (product.type == "unity") {
        return (product.price).toFixed(2) + "€"
      }
      if (product.type == "weight") {
        return ((product.price / 1000)).toFixed(2) + "€ /kg"
      }

    }
    else {
      if (product.options_price) {

        for (const [key, value] of Object.entries(product.options_price)) {

          if (!isNaN(value) && !!value)
            if (product.type == "unity")
              price += parseFloat(value)
            else
              price += parseFloat(value)
        }
      }
    }

    if (product.supplements && product.supplements.length > 0) {
      for (var i = 0; i < product.supplements.length; i++) {

        price += this.getSupplementPrice(product.supplements[i])
      }
    }


    return parseFloat(price).toFixed(2) + "€"

  }

  checkAndroidOrIOS() {
    return (isAndroid || isIOS)
  }

  render() {

    return (
      <div id="bodyMenu">
        {this.state.menu != null &&
          <div style={{ width: "100%", height: "100%" }}>

            <View style={{ width: "100%", height: 200, backgroundColor: "#f2f2f2", zIndex: 0 }}>
              {!!this.state.menu.img &&
                <img loading='lazy' src={this.state.menu.img} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
              }
              {
                !this.state.menu.img &&
                <View style={{ position: "absolute", top: 0, width: "100%", height: "100%", backgroundColor: "#68dc91" }} />
              }
              <View style={{ zIndex: 0, position: "absolute", top: 10, left: 10, }}>
                <Link
                  to="/"
                  style={{ zIndex: 0, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", borderRadius: 100, width: 30, height: 30, justifyContent: "center", alignItems: "center" }}>
                  <View style={{ justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                    <MdChevronLeft size="1.5rem" color={"#000"} />
                  </View>
                </Link>
              </View>
              {
                (
                  !!this.state.menu.take_away ||
                  !!this.state.menu.delivery ||
                  !!this.state.menu.reserve ||
                  !!this.state.menu.groups
                ) &&

                <TouchableOpacity
                  onPress={() => this.setState({ visibleEstablishmentOptions: true }, () => this.blockScroll())}
                  onLongPress={() => this.setState({ visibleEstablishmentOptions: true }, () => this.blockScroll())}
                  style={{ flexDirection: "row", paddingHorizontal: 10, paddingVertical: 5, position: "absolute", top: 10, right: 10, borderRadius: 100, backgroundColor: "#fff", boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", }}>
                  {
                    this.state.menu.take_away &&
                    <View style={{ paddingHorizontal: 10 }}>
                      <img loading='lazy' src={takeAway} style={{ paddingHorizontal: 10, alignSelf: "center", width: 25, height: 25, zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
                    </View>
                  }
                  {
                    this.state.menu.delivery &&
                    <View style={{ paddingHorizontal: 10 }}>
                      <img loading='lazy' src={fastDelivery} style={{ paddingHorizontal: 10, alignSelf: "center", width: 25, height: 25, zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
                    </View>
                  }
                  {
                    this.state.menu.reserve &&
                    <View style={{ paddingHorizontal: 10 }}>
                      <img loading='lazy' src={reserve} style={{ paddingHorizontal: 10, alignSelf: "center", width: 25, height: 25, zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
                    </View>
                  }
                  {
                    this.state.menu.groups &&
                    <View style={{ paddingHorizontal: 10 }}>
                      <img loading='lazy' src={group} style={{ paddingHorizontal: 10, alignSelf: "center", width: 25, height: 25, zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
                    </View>
                  }
                </TouchableOpacity>
              }
            </View>
            <TouchableOpacity
              onPress={() => this.setState({ detailsOpen: true }, () => this.blockScroll())}
              onLongPress={() => this.setState({ detailsOpen: true }, () => this.blockScroll())}
              style={{ width: "100%", flexDirection: "row" }}
            >
              <View style={{ width: "85%" }}>
                <View style={{ width: "100%", height: 20 }} />
                <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.state.menu.name}
                </Text>
                {!!this.state.menu.description &&
                  <View>
                    <View style={{ width: "100%", height: 10 }} />
                    <Text numberOfLines={3} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                      {this.state.menu.description}
                    </Text>
                  </View>
                }
                <View style={{ width: "100%", height: 10 }} />
                <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.state.menu.direction},  {this.state.menu.location}
                </Text>
                <View style={{ width: "100%", height: 20 }} />
                <Text style={{ fontStyle: "italic", color: "gray", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  Pulsa para ver los horarios, formas de contacto{this.state.menu.wifi_active ? ", WIFI" : ""} y más
                </Text>
                <View style={{ width: "100%", height: 20 }} />
              </View>
              <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                <MdChevronRight color={"#000"} size={30} />
              </View>
            </TouchableOpacity>

            <Sticky
              onFixedToggle={(state) => this.setState({ activeMenuTop: state })}
              stickyStyle={{ zIndex: 99, }}>
              {this.state.activeMenuTop ?
                <div id={"sticky-menu"} style={{ position: "absolute", boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", height: 60, overflowY: "auto", background: "#fff", width: "100%", zIndex: 99 }}>
                  <ScrollView ref={this.refCategoriesListHorizontal} horizontal style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}>
                    {this.state.categories.map((item, index) => (
                      <TouchableOpacity
                        id={item + "_scroll"}
                        onPress={() => this.moveTo(index)}
                        onLongPress={() => this.moveTo(index)}
                        onLayout={(e) => this.addOffsetCategoriesList(e.nativeEvent.layout.x)} style={{ paddingVertical: 10, height: "100%", justifyContent: "center", alignItems: 'center' }}>
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: this.state.activeCategory == index ? "600" : "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                          {item}
                        </Text>
                        {this.state.activeCategory == index &&
                          <View style={{ position: "absolute", bottom: 5, width: "90%", alignSelf: "center", height: 4, backgroundColor: "#68dc91" }} />
                        }
                      </TouchableOpacity>
                    ))}
                  </ScrollView>
                </div>
                :
                <View style={{ width: "100%", height: 1, backgroundColor: "#EDEDED" }} />
              }

            </Sticky>
            <View style={{ zIndex: 0, flexDirection: "row", justifyContent: "space-evenly", width: "100%" }}>
              {false &&
                <div style={{ position: "sticky", top: 0, height: this.state.sizeScreen.height * 0.9, overflowY: "auto", }}>
                  <View style={{ width: this.state.sizeScreen.width * 0.15 }}>
                    <View style={{ width: this.state.sizeScreen.width * 0.15 }}>
                      <View style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", marginTop: 10, paddingVertical: 10, width: "90%", backgroundColor: "#fff", alignSelf: "center", borderRadius: 10 }}>

                        {this.state.categories.map((item, index) => (
                          <View style={{ width: "100%", paddingVertical: 10 }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: this.state.activeCategory == index ? "600" : "400", fontSize: this.state.activeCategory == index ? "1.2rem" : "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                              {item}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View style={{ height: 20 }} />
                </div>
              }
              <div style={{ zIndex: 0, marginTop: 10, paddingVertical: 10, width: "100%", backgroundColor: "#fff" }}>
                <SectionList
                  onContentSizeChange={() => { this.getSectionsOffset() }}
                  style={{ zIndex: 0 }}
                  maxToRenderPerBatch={1000}
                  initialNumToRender={1000}
                  sections={this.state.menu.products}
                  keyExtractor={(item, index) => item + index}
                  ListFooterComponent={this.renderFooter}
                  ItemSeparatorComponent={this.renderItemSeparator}
                  renderItem={({ section, item, index }) => this.renderItem(section, this.state.menu.allProducts[item], index)}
                  renderSectionHeader={(section) => (
                    <div id={"section_" + section.section.index} style={{ flexDirection: "column", marginTop: 15, marginBottom: 10, paddingVertical: 20, paddingHorizontal: 10 }}>
                      <Text style={{ paddingHorizontal: 10, color: "#000", fontWeight: "600", fontSize: "1.3rem", textAlign: "left", }}>

                        {section.section.title}
                      </Text>
                      {!!section.section.description &&
                        <View>
                          <View style={{ height: 10 }} />
                          <Text style={{ paddingHorizontal: 10, color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", }}>

                            {section.section.description}
                          </Text>
                        </View>
                      }
                    </div>
                  )}

                />
              </div>

            </View>
            <View style={{ height: 20 }} />
            {!!this.state.menu.bar_terrace &&
              <View>
                <View

                  style={{ paddingVertical: 10, alignSelf: "center", paddingHorizontal: 20, backgroundColor: "#f5f5f5", borderRadius: 5 }}>
                  <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "center" }}>
                    Suplemento por terraza: {this.state.menu.bar_terrace}. Todos los precios incluyen IVA.
                  </Text>
                </View>
                <View style={{ height: 20 }} />
              </View>
            }
            {!this.state.menu.bar_terrace &&
              <View>
                <View

                  style={{ paddingVertical: 10, alignSelf: "center", paddingHorizontal: 20, backgroundColor: "#f5f5f5", borderRadius: 5 }}>
                  <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "center" }}>
                    Todos los precios incluyen IVA.
                  </Text>
                </View>
                <View style={{ height: 20 }} />
              </View>
            }
            <View>
              <TouchableOpacity
                onPress={() => this.setState({ visibleAllergensInfo: true }, () => this.blockScroll())}
                onLongPress={() => this.setState({ visibleAllergensInfo: true }, () => this.blockScroll())}
                style={{ paddingVertical: 10, alignSelf: "center", paddingHorizontal: 20 }}>
                <Text style={{ textDecorationLine: "underline", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "center" }}>
                  Información sobre alérgenos
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{ height: 20 }} />
            <View style={{ width: "100%", height: 50, backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>
              <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "center" }}>
                Buyness © 2022.
              </Text>
            </View>
            {this.state.whatsappOrder && this.state.whatsappProducts.length > 0 &&
              <View style={{ position: "fixed", bottom: 20, width: "100%", justifyContent: "center", alignItems: "center" }}>
                <TouchableOpacity
                  onPress={() => this.setState({ visibleWhatsAppOrderDetails: true }, () => this.blockScroll())}
                  onLongPress={() => this.setState({ visibleWhatsAppOrderDetails: true }, () => this.blockScroll())}
                  style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                    Detalles del pedido
                  </Text>
                </TouchableOpacity>
              </View>
            }

            {this.state.whatsappOrder && this.state.whatsappProducts.length == 0 &&
              <View style={{ position: "fixed", bottom: 20, width: "100%", justifyContent: "center", alignItems: "center" }}>
                <View

                  style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#EDEDED", borderRadius: 10, paddingVertical: 15 }}>
                  <View style={{ width: "70%" }}>
                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Añade al menos un producto para pedir
                    </Text>
                  </View>
                  <View style={{ width: "30%" }}>
                    <TouchableOpacity
                      onPress={() => this.setState({ whatsappOrder: false })}
                      onLongPress={() => this.setState({ whatsappOrder: false })}
                      style={{ height: 40, justifyContent: "center", alignItems: "center", width: "90%", alignSelf: "flex-start", backgroundColor: "#fff", borderRadius: 5, }}>
                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Cancelar
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            }



          </div>
        }
        {
          !this.state.menu &&
          <div style={{ flex: 1, width: "100%", height: "100%" }}>
            <View style={{ position: "absolute", top: 0, width: "100%", height: "100%", backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>
              <Loader
                secondaryColor={"#000"}
                type="Watch"
                color="#000"
                height={200}
                width={200}

              />
              <View style={{ height: 30 }} />
              <Text style={{ color: "#000", fontWeight: "600", fontSize: "2rem", paddingHorizontal: 10, width: "100%", textAlign: "center" }}>
                {this.props.translate("loading.shop")}
              </Text>

              <View style={{ height: 100 }} />
            </View>
          </div>
        }





        {
          this.state.emptyMenu &&
          <div style={{ flex: 1, width: "100%", height: "100%" }}>
            <View style={{ position: "fixed", top: 0, width: "100%", height: "100%", backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>
              <MdError size="5em" />
              <View style={{ height: 30 }} />
              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, width: "100%", textAlign: "center" }}>
                {this.props.translate("error.shop")}
              </Text>
              <View style={{ height: 100 }} />
            </View>
          </div>
        }



        <Sheet isOpen={this.state.visibleAllergensInfo} onClose={() => this.setState({ visibleAllergensInfo: false }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ visibleAllergensInfo: false }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ visibleAllergensInfo: false }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              <View style={{ height: 10 }} />
              <Text style={{ paddingHorizontal: 20, color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "left", width: "100%" }}>
                Información sobre alérgenos
              </Text>
              <View style={{ height: 30 }} />
              <View style={{ width: "100%", flexDirection: "row" }}>
                <View style={{ width: "50%" }}>
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <FishIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Pescado
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <GlutenIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Gluten
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <CrustaceanIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Crustáceos
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <EggIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Huevos
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <PeanutIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Cacahuetes
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <SoyaIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Soja
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <MilkIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Lácteos
                    </Text>
                  </View>

                </View>
                <View style={{ width: "50%" }}>
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <NutsIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Nueces
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <CeleryIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Apio
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <MustardIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Mostaza
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <SesameIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Sésamo
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <SulphiteIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Sulfitos
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <LupinIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Altramuces
                    </Text>
                  </View>
                  <View style={{ height: 10 }} />
                  <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                    <MolluscIcon width={30} height={30} />
                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                      Moluscos
                    </Text>
                  </View>
                </View>

              </View>
              <View style={{ height: 30 }} />
              <View style={{ paddingHorizontal: 20 }}>
                <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                  Si tienes cualquier duda sobre los alérgenos ponte en contacto con el establecimiento.
                </Text>
              </View>
              <View style={{ height: 30 }} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>



        <Sheet isOpen={this.state.activeProductWithOptionsFixedPrice && Object.keys(this.state.activeProductWithOptionsFixedPrice).length > 0 && !this.state.whatsappOrder} onClose={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.activeProductWithOptionsFixedPrice &&
                <ScrollView>
                  {!!this.state.activeProductWithOptionsFixedPrice.img &&
                    <View style={{ height: 250, backgroundColor: "#F5F5F5", width: "100%" }}>

                      {!!this.state.activeProductWithOptionsFixedPrice.img &&
                        <View style={{ width: "100%", height: 250, backgroundColor: "#F5F5F5", overflow: "hidden" }}>
                          <img loading="lazy" src={this.state.activeProductWithOptionsFixedPrice.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                        </View>
                      }
                    </View>
                  }


                  <View style={{ height: 30 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                    {this.state.activeProductWithOptionsFixedPrice.title}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 20 }}>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver más</Text></View>}
                      less={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver menos</Text></View>}
                      className="center-left"
                      //anchorClass="my-anchor-css-class"
                      //onClick={this.executeOnClick}
                      expanded={false}

                      truncatedEndingComponent={"... "}
                    >

                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                        {this.state.activeProductWithOptionsFixedPrice.description}
                      </Text>
                    </ShowMoreText>
                  </View>
                  {!!this.state.activeProductWithOptionsFixedPrice.price &&
                    <View>
                      <View style={{ height: 30 }} />

                      <View style={{ flexDirection: "row", alignItems: "flex-start", alignSelf: "flex-start", paddingHorizontal: 20 }}>

                        {!!this.state.activeProductWithOptionsFixedPrice.price &&

                          <Text style={{ textDecorationLine: this.state.activeProductWithOptionsFixedPrice.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", paddingRight: 10, textAlign: "left" }}>
                            {
                              !isNaN(this.state.activeProductWithOptionsFixedPrice.price) ?
                                `${parseFloat(this.state.activeProductWithOptionsFixedPrice.price).toFixed(2)} € ${this.state.activeProductWithOptionsFixedPrice.kg ? "/ kg" : this.state.activeProductWithOptionsFixedPrice.custom_unity ? "/ " + this.state.activeProductWithOptionsFixedPrice.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>
                        }
                        {this.state.activeProductWithOptionsFixedPrice.offer &&

                          <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeProductWithOptionsFixedPrice.price_offer) ?
                                `${parseFloat(this.state.activeProductWithOptionsFixedPrice.price_offer).toFixed(2)} € ${this.state.activeProductWithOptionsFixedPrice.kg ? "/ kg" : this.state.activeProductWithOptionsFixedPrice.custom_unity ? "/ " + this.state.activeProductWithOptionsFixedPrice.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>


                        }


                      </View>

                    </View>
                  }
                  {!this.state.activeProductWithOptionsFixedPrice.available &&
                    <View>
                      <View style={{ height: 30 }} />

                      <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", width: "100%", paddingHorizontal: 20 }}>
                        {this.props.translate("available.shop")}
                      </Text>
                    </View>
                  }


                  {
                    this.state.activeProductWithOptionsFixedPrice.labels && this.state.activeProductWithOptionsFixedPrice.labels.length > 0 &&
                    <View style={{ marginHorizontal: 20 }}>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}>

                        {
                          this.state.activeProductWithOptionsFixedPrice.labels &&
                          this.state.activeProductWithOptionsFixedPrice.labels.map((label, label_index) => (

                            <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                              <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                {label.label}
                              </Text>
                            </View>


                          ))
                        }
                      </View>
                    </View>
                  }



                  {
                    this.state.activeProductWithOptionsFixedPrice.allergens && this.state.activeProductWithOptionsFixedPrice.allergens.length > 0 &&
                    <View style={{ width: "90%", alignSelf: "center" }}>
                      <View style={{ height: 50 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                          this.state.activeProductWithOptionsFixedPrice.allergens.map((item, index) => (
                            <View style={{ marginLeft: index > 0 ? 5 : 0, width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                              {this.renderAllergenActiveItem(item)}
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  }


                  {this.state.activeProductWithOptionsFixedPrice.options_fixed_price_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      {
                        this.state.activeProductWithOptionsFixedPrice.options_fixed_price.map((option, index_option) => (
                          <View>
                            <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {option.title}
                              </Text>
                            </View>
                            {
                              this.state.activeProductWithOptionsFixedPrice.options_fixed_price[index_option].data.map((sub_option, sub_index) => (
                                <View style={{ flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                                  <View style={{ width: "100%", paddingHorizontal: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                      {sub_option.title}
                                    </Text>
                                    {!!sub_option.description &&
                                      <View>
                                        <View style={{ height: 5 }} />
                                        <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", width: "100%", textAlign: "left" }}>
                                          {sub_option.description}
                                        </Text>
                                      </View>
                                    }
                                  </View>

                                </View>
                              ))
                            }
                            <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                            <View style={{ height: 50 }} />
                          </View>
                        ))

                      }


                    </View>
                  }
                  {this.state.activeProductWithOptionsFixedPrice.supplements_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                          Suplementos
                        </Text>
                      </View>
                      {
                        this.state.activeProductWithOptionsFixedPrice.supplements.map((item, index) => (
                          <View style={{ flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                            <View style={{ width: "70%", paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {this.state.menu.supplements[item].title}
                              </Text>
                            </View>
                            <View style={{ width: "10%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "right", paddingRight: 10 }}>
                                {this.state.menu.supplements[item].type}
                              </Text>
                            </View>
                            <View style={{ width: "30%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {
                                  (this.state.menu.supplements[item].price && !isNaN(this.state.menu.supplements[item].price)) ?
                                    `${parseFloat(this.state.menu.supplements[item].price).toFixed(2)} €`
                                    :
                                    ""
                                }
                              </Text>
                            </View>
                          </View>
                        ))
                      }
                    </View>
                  }
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <Sheet isOpen={this.state.activeProductWithOptions && Object.keys(this.state.activeProductWithOptions).length > 0 && !this.state.whatsappOrder} onClose={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.activeProductWithOptions &&
                <ScrollView>
                  {!!this.state.activeProductWithOptions.img &&
                    <View style={{ height: 250, backgroundColor: "#F5F5F5", width: "100%" }}>

                      {!!this.state.activeProductWithOptions.img &&
                        <View style={{ width: "100%", height: 250, backgroundColor: "#F5F5F5", overflow: "hidden" }}>
                          <img loading="lazy" src={this.state.activeProductWithOptions.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                        </View>
                      }
                    </View>
                  }


                  <View style={{ height: 30 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                    {this.state.activeProductWithOptions.title}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 20 }}>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver más</Text></View>}
                      less={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver menos</Text></View>}
                      className="center-left"
                      //anchorClass="my-anchor-css-class"
                      //onClick={this.executeOnClick}
                      expanded={false}

                      truncatedEndingComponent={"... "}
                    >

                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                        {this.state.activeProductWithOptions.description}
                      </Text>
                    </ShowMoreText>
                  </View>
                  {!!this.state.activeProductWithOptions.price &&
                    <View>
                      <View style={{ height: 30 }} />

                      <View style={{ flexDirection: "row", alignItems: "flex-start", alignSelf: "flex-start", paddingHorizontal: 20 }}>

                        {!!this.state.activeProductWithOptions.price &&

                          <Text style={{ textDecorationLine: this.state.activeProductWithOptions.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", paddingRight: 10, textAlign: "left" }}>
                            {
                              !isNaN(this.state.activeProductWithOptions.price) ?
                                `${parseFloat(this.state.activeProductWithOptions.price).toFixed(2)} € ${this.state.activeProductWithOptions.kg ? "/ kg" : this.state.activeProductWithOptions.custom_unity ? "/ " + this.state.activeProductWithOptions.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>
                        }
                        {this.state.activeProductWithOptions.offer &&

                          <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeProductWithOptions.price_offer) ?
                                `${parseFloat(this.state.activeProductWithOptions.price_offer).toFixed(2)} € ${this.state.activeProductWithOptions.kg ? "/ kg" : this.state.activeProductWithOptions.custom_unity ? "/ " + this.state.activeProductWithOptions.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>


                        }


                      </View>

                    </View>
                  }
                  {!this.state.activeProductWithOptions.available &&
                    <View>
                      <View style={{ height: 30 }} />

                      <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", width: "100%", paddingHorizontal: 20 }}>
                        {this.props.translate("available.shop")}
                      </Text>
                    </View>
                  }


                  {
                    this.state.activeProductWithOptions.labels && this.state.activeProductWithOptions.labels.length > 0 &&
                    <View style={{ marginHorizontal: 20 }}>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}>

                        {
                          this.state.activeProductWithOptions.labels &&
                          this.state.activeProductWithOptions.labels.map((label, label_index) => (

                            <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                              <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                {label.label}
                              </Text>
                            </View>


                          ))
                        }
                      </View>
                    </View>
                  }



                  {
                    this.state.activeProductWithOptions.allergens && this.state.activeProductWithOptions.allergens.length > 0 &&
                    <View style={{ width: "90%", alignSelf: "center" }}>
                      <View style={{ height: 50 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                          this.state.activeProductWithOptions.allergens.map((item, index) => (
                            <View style={{ marginLeft: index > 0 ? 5 : 0, width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                              {this.renderAllergenActiveItem(item)}
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  }


                  {this.state.activeProductWithOptions.options_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      {
                        this.state.activeProductWithOptions.options.map((option, index_option) => (
                          <View>
                            <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {option.title}
                              </Text>
                            </View>
                            {
                              this.state.activeProductWithOptions.options[index_option].data.map((sub_option, sub_index) => (
                                <View style={{ flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                                  <View style={{ width: "70%", paddingHorizontal: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                      {sub_option.title}
                                    </Text>
                                    {!!sub_option.description &&
                                      <View>
                                        <View style={{ height: 5 }} />
                                        <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", width: "100%", textAlign: "left" }}>
                                          {sub_option.description}
                                        </Text>
                                      </View>
                                    }
                                  </View>
                                  <View style={{ width: "30%", paddingRight: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "right" }}>
                                      {
                                        !!sub_option.price && !isNaN(sub_option.price) ? `${parseFloat(sub_option.price).toFixed(2)} €` : "Sin precio"}    {this.state.activeProductWithOptions.kg ? "/ kg." : ""}

                                    </Text>
                                  </View>
                                </View>
                              ))
                            }
                            <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                            <View style={{ height: 50 }} />
                          </View>
                        ))

                      }


                    </View>
                  }
                  {this.state.activeProductWithOptions.supplements_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                          Suplementos
                        </Text>
                      </View>
                      {
                        this.state.activeProductWithOptions.supplements.map((item, index) => (
                          <View style={{ flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                            <View style={{ width: "70%", paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {this.state.menu.supplements[item].title}
                              </Text>
                            </View>
                            <View style={{ width: "10%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "right", paddingRight: 10 }}>
                                {this.state.menu.supplements[item].type}
                              </Text>
                            </View>
                            <View style={{ width: "30%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {
                                  (this.state.menu.supplements[item].price && !isNaN(this.state.menu.supplements[item].price)) ?
                                    `${parseFloat(this.state.menu.supplements[item].price).toFixed(2)} €`
                                    :
                                    ""
                                }
                              </Text>
                            </View>
                          </View>
                        ))
                      }
                    </View>
                  }
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <Sheet isOpen={this.state.activeProductWithOptions && Object.keys(this.state.activeProductWithOptions).length > 0 && this.state.whatsappOrder} onClose={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ activeProductWithOptions: null }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.activeProductWithOptions &&
                <ScrollView>
                  {!!this.state.activeProductWithOptions.img &&
                    <View style={{ height: 250, backgroundColor: "#F5F5F5", width: "100%" }}>

                      {!!this.state.activeProductWithOptions.img &&
                        <View style={{ width: "100%", height: 250, backgroundColor: "#F5F5F5", overflow: "hidden" }}>
                          <img loading="lazy" src={this.state.activeProductWithOptions.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                        </View>
                      }
                    </View>
                  }


                  <View style={{ height: 30 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                    {this.state.activeProductWithOptions.title}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 20 }}>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver más</Text></View>}
                      less={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver menos</Text></View>}
                      className="center-left"
                      //anchorClass="my-anchor-css-class"
                      //onClick={this.executeOnClick}
                      expanded={false}

                      truncatedEndingComponent={"... "}
                    >

                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                        {this.state.activeProductWithOptions.description}
                      </Text>
                    </ShowMoreText>
                  </View>
                  {!!this.state.activeProductWithOptions.price &&
                    <View>
                      <View style={{ height: 30 }} />

                      <View style={{ flexDirection: "row", alignItems: "flex-start", alignSelf: "flex-start", paddingHorizontal: 20 }}>

                        {!!this.state.activeProductWithOptions.price &&

                          <Text style={{ textDecorationLine: this.state.activeProductWithOptions.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", paddingRight: 10, textAlign: "left" }}>
                            {
                              !isNaN(this.state.activeProductWithOptions.price) ?
                                `${parseFloat(this.state.activeProductWithOptions.price).toFixed(2)} € ${this.state.activeProductWithOptions.kg ? "/ kg" : this.state.activeProductWithOptions.custom_unity ? "/ " + this.state.activeProductWithOptions.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>
                        }
                        {this.state.activeProductWithOptions.offer &&

                          <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeProductWithOptions.price_offer) ?
                                `${parseFloat(this.state.activeProductWithOptions.price_offer).toFixed(2)} € ${this.state.activeProductWithOptions.kg ? "/ kg" : this.state.activeProductWithOptions.custom_unity ? "/ " + this.state.activeProductWithOptions.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>


                        }


                      </View>

                    </View>
                  }
                  {!this.state.activeProductWithOptions.available &&
                    <View>
                      <View style={{ height: 30 }} />

                      <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", width: "100%", paddingHorizontal: 20 }}>
                        {this.props.translate("available.shop")}
                      </Text>
                    </View>
                  }

                  {
                    this.state.activeProductWithOptions.labels && this.state.activeProductWithOptions.labels.length > 0 &&
                    <View style={{ marginHorizontal: 20 }}>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}>

                        {
                          this.state.activeProductWithOptions.labels &&
                          this.state.activeProductWithOptions.labels.map((label, label_index) => (

                            <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                              <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                {label.label}
                              </Text>
                            </View>


                          ))
                        }
                      </View>
                    </View>
                  }

                  {
                    this.state.activeProductWithOptions.allergens && this.state.activeProductWithOptions.allergens.length > 0 &&
                    <View style={{ width: "90%", alignSelf: "center" }}>
                      <View style={{ height: 50 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                          this.state.activeProductWithOptions.allergens.map((item, index) => (
                            <View style={{ marginLeft: index > 0 ? 5 : 0, width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                              {this.renderAllergenActiveItem(item)}
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  }


                  {this.state.activeProductWithOptions.options_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      {
                        this.state.activeProductWithOptions.options.map((option, index_option) => (
                          <View>
                            <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {option.title}
                              </Text>
                            </View>
                            {
                              this.state.activeProductWithOptions.options[index_option].data.map((sub_option, sub_index) => (
                                <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                                  <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                                    <TouchableOpacity
                                      onPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("option", option.title, sub_option.title, sub_option.price)}
                                      onLongPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("option", option.title, sub_option.title, sub_option.price)}
                                      style={{ width: 30, height: 30, backgroundColor: this.state.activeWhatsAppSelectedOptions[option.title] == sub_option.title ? "#68dc91" : "#fff", borderWidth: 1, borderColor: this.state.activeWhatsAppSelectedOptions[option.title] == sub_option.title ? "#68dc91" : "#f5f5f5" }}>

                                    </TouchableOpacity>
                                  </View>
                                  <View style={{ width: "70%", paddingHorizontal: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                      {sub_option.title}
                                    </Text>
                                    {!!sub_option.description &&
                                      <View>
                                        <View style={{ height: 5 }} />
                                        <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", width: "100%", textAlign: "left" }}>
                                          {sub_option.description}
                                        </Text>
                                      </View>
                                    }
                                  </View>
                                  <View style={{ width: "15%", paddingRight: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "right" }}>
                                      {
                                        !!sub_option.price && !isNaN(sub_option.price) ? `${parseFloat(sub_option.price).toFixed(2)} €` : "Sin precio"}    {this.state.activeProductWithOptions.kg ? "/ kg." : ""}

                                    </Text>
                                  </View>
                                </View>
                              ))
                            }
                            <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                            <View style={{ height: 50 }} />
                          </View>
                        ))

                      }


                    </View>
                  }
                  {this.state.activeProductWithOptions.supplements_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                          Suplementos
                        </Text>
                      </View>
                      {
                        this.state.activeProductWithOptions.supplements.map((item, index) => (
                          <View style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: "center", flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                            <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                              <TouchableOpacity
                                onPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("supplement", this.state.menu.supplements[item].title, "", "")}
                                onLongPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("supplement", this.state.menu.supplements[item].title, "", "")}
                                style={{ width: 30, height: 30, backgroundColor: this.state.activeWhatsAppSelectedSupplements.indexOf(this.state.menu.supplements[item].title) >= 0 ? "#68dc91" : "#fff", borderWidth: 1, borderColor: this.state.activeWhatsAppSelectedSupplements.indexOf(this.state.menu.supplements[item].title) >= 0 ? "#68dc91" : "#f5f5f5" }}>

                              </TouchableOpacity>
                            </View>
                            <View style={{ width: "60%", paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {this.state.menu.supplements[item].title}
                              </Text>
                            </View>

                            <View style={{ width: "25%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {
                                  (this.state.menu.supplements[item].price && !isNaN(this.state.menu.supplements[item].price)) ?
                                    `${parseFloat(this.state.menu.supplements[item].price).toFixed(2)} €`
                                    :
                                    ""
                                }
                              </Text>
                            </View>
                          </View>
                        ))
                      }
                    </View>
                  }
                  {!this.checkIfActiveWhatsAppProductWithOptionsAndSupplementsContainsAll() &&
                    <View>

                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Cantidad {this.state.activeWhatsAppProduct.type == "unity" ? "" : "(en gramos)"}
                      </Text>
                      <View style={{ height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <TouchableOpacity
                          onPress={() => this.updateActiveWhatsAppProductQuantityButtons("LESS")}
                          onLongPress={() => this.updateActiveWhatsAppProductQuantityButtons("LESS")}
                          style={{ backgroundColor: "#68dc91", paddingHorizontal: 15, borderRadius: 5, height: 40, justifyContent: "center", alignItems: "center" }}>
                          <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                            -
                          </Text>
                        </TouchableOpacity>
                        <TextInput
                          onChangeText={t => this.updateActiveWhatsAppProductQuantityDirectly(t)}
                          value={this.state.activeWhatsAppProduct.quantity}
                          style={{ fontSize: "1.2rem", outlineStyle: 'none', textAlign: "center", borderRadius: 5, alignSelf: "center", width: "30%", marginHorizontal: 10, paddingHorizontal: 10, height: 40, backgroundColor: "#f5f5f5" }}
                        />
                        <TouchableOpacity
                          onPress={() => this.updateActiveWhatsAppProductQuantityButtons("MORE")}
                          onLongPress={() => this.updateActiveWhatsAppProductQuantityButtons("MORE")}
                          style={{ backgroundColor: "#68dc91", paddingHorizontal: 15, borderRadius: 5, height: 40, justifyContent: "center", alignItems: "center" }}>
                          <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                            +
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ height: 50 }} />
                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Información adicional
                      </Text>
                      <View style={{ height: 20 }} />
                      <TextInput
                        onChangeText={t => this.updateActiveWhatsAppProductAdditionalInfo(t)}
                        multiline
                        numberOfLines={4}
                        placeholder='Escribe aquí instrucciones de preparación adicionales que quieras para este producto (Carne muy hecha, extra de queso, ...)'
                        style={{ outlineStyle: 'none', textAlign: "left", paddingVertical: 10, borderRadius: 5, alignSelf: "center", width: "95%", marginHorizontal: 10, paddingHorizontal: 10, backgroundColor: "#f5f5f5" }}
                      />       <View style={{ height: 30 }} />

                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Precio: {this.calculatePriceActiveWhatsAppProductWithOptions()} €
                      </Text>
                      <View style={{ height: 20 }} />
                      <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "center", width: "100%" }}>
                        Este precio es orientativo y puede no corresponderse con el precio del producto en el establecimiento.
                      </Text>

                      <View style={{ height: 30 }} />
                    </View>
                  }
                  <TouchableOpacity
                    disabled={this.checkIfActiveWhatsAppProductWithOptionsAndSupplementsContainsAll()}
                    onPress={() => this.addToWhatsAppOrder()}
                    onLongPress={() => this.addToWhatsAppOrder()}
                    style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: this.checkIfActiveWhatsAppProductWithOptionsAndSupplementsContainsAll() ? "#f5f5f5" : "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Añadir al pedido
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 50 }} />
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <Sheet isOpen={this.state.activeProductWithOptionsFixedPrice && Object.keys(this.state.activeProductWithOptionsFixedPrice).length > 0 && this.state.whatsappOrder} onClose={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ activeProductWithOptionsFixedPrice: null }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.activeProductWithOptionsFixedPrice &&
                <ScrollView>
                  {!!this.state.activeProductWithOptionsFixedPrice.img &&
                    <View style={{ height: 250, backgroundColor: "#F5F5F5", width: "100%" }}>

                      {!!this.state.activeProductWithOptionsFixedPrice.img &&
                        <View style={{ width: "100%", height: 250, backgroundColor: "#F5F5F5", overflow: "hidden" }}>
                          <img loading="lazy" src={this.state.activeProductWithOptionsFixedPrice.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                        </View>
                      }
                    </View>
                  }


                  <View style={{ height: 30 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                    {this.state.activeProductWithOptionsFixedPrice.title}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ width: "100%", alignSelf: "center", paddingHorizontal: 20 }}>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver más</Text></View>}
                      less={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver menos</Text></View>}
                      className="center-left"
                      //anchorClass="my-anchor-css-class"
                      //onClick={this.executeOnClick}
                      expanded={false}

                      truncatedEndingComponent={"... "}
                    >

                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                        {this.state.activeProductWithOptionsFixedPrice.description}
                      </Text>
                    </ShowMoreText>
                  </View>
                  {!!this.state.activeProductWithOptionsFixedPrice.price &&
                    <View>
                      <View style={{ height: 30 }} />

                      <View style={{ flexDirection: "row", alignItems: "flex-start", alignSelf: "flex-start", paddingHorizontal: 20 }}>

                        {!!this.state.activeProductWithOptionsFixedPrice.price &&

                          <Text style={{ textDecorationLine: this.state.activeProductWithOptionsFixedPrice.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", paddingRight: 10, textAlign: "left" }}>
                            {
                              !isNaN(this.state.activeProductWithOptionsFixedPrice.price) ?
                                `${parseFloat(this.state.activeProductWithOptionsFixedPrice.price).toFixed(2)} € ${this.state.activeProductWithOptionsFixedPrice.kg ? "/ kg" : this.state.activeProductWithOptionsFixedPrice.custom_unity ? "/ " + this.state.activeProductWithOptionsFixedPrice.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>
                        }
                        {this.state.activeProductWithOptionsFixedPrice.offer &&

                          <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeProductWithOptionsFixedPrice.price_offer) ?
                                `${parseFloat(this.state.activeProductWithOptionsFixedPrice.price_offer).toFixed(2)} € ${this.state.activeProductWithOptionsFixedPrice.kg ? "/ kg" : this.state.activeProductWithOptionsFixedPrice.custom_unity ? "/ " + this.state.activeProductWithOptionsFixedPrice.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>


                        }


                      </View>

                    </View>
                  }
                  {!this.state.activeProductWithOptionsFixedPrice.available &&
                    <View>
                      <View style={{ height: 30 }} />

                      <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", width: "100%", paddingHorizontal: 20 }}>
                        {this.props.translate("available.shop")}
                      </Text>
                    </View>
                  }

                  {
                    this.state.activeProductWithOptionsFixedPrice.labels && this.state.activeProductWithOptionsFixedPrice.labels.length > 0 &&
                    <View style={{ marginHorizontal: 20 }}>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "flex-start" }}>

                        {
                          this.state.activeProductWithOptionsFixedPrice.labels &&
                          this.state.activeProductWithOptionsFixedPrice.labels.map((label, label_index) => (

                            <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                              <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                {label.label}
                              </Text>
                            </View>


                          ))
                        }
                      </View>
                    </View>
                  }

                  {
                    this.state.activeProductWithOptionsFixedPrice.allergens && this.state.activeProductWithOptionsFixedPrice.allergens.length > 0 &&
                    <View style={{ width: "90%", alignSelf: "center" }}>
                      <View style={{ height: 50 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                          this.state.activeProductWithOptionsFixedPrice.allergens.map((item, index) => (
                            <View style={{ marginLeft: index > 0 ? 5 : 0, width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                              {this.renderAllergenActiveItem(item)}
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  }


                  {this.state.activeProductWithOptionsFixedPrice.options_fixed_price_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      {
                        this.state.activeProductWithOptionsFixedPrice.options_fixed_price.map((option, index_option) => (
                          <View>
                            <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {option.title}
                              </Text>
                            </View>
                            {
                              this.state.activeProductWithOptionsFixedPrice.options_fixed_price[index_option].data.map((sub_option, sub_index) => (
                                <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                                  <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                                    <TouchableOpacity
                                      onPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("option_fixed_price", option.title, sub_option.title, null)}
                                      onLongPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("option_fixed_price", option.title, sub_option.title, null)}
                                      style={{ width: 30, height: 30, backgroundColor: this.state.activeWhatsAppSelectedOptions[option.title] == sub_option.title ? "#68dc91" : "#fff", borderWidth: 1, borderColor: this.state.activeWhatsAppSelectedOptions[option.title] == sub_option.title ? "#68dc91" : "#f5f5f5" }}>

                                    </TouchableOpacity>
                                  </View>
                                  <View style={{ width: "85%", paddingHorizontal: 20 }}>
                                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                      {sub_option.title}
                                    </Text>
                                    {!!sub_option.description &&
                                      <View>
                                        <View style={{ height: 5 }} />
                                        <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", width: "100%", textAlign: "left" }}>
                                          {sub_option.description}
                                        </Text>
                                      </View>
                                    }
                                  </View>

                                </View>
                              ))
                            }
                            <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                            <View style={{ height: 50 }} />
                          </View>
                        ))

                      }


                    </View>
                  }
                  {this.state.activeProductWithOptionsFixedPrice.supplements_active &&
                    <View>
                      <View style={{ height: 30 }} />
                      <View style={{ backgroundColor: "#F5F5F5", paddingVertical: 10, paddingHorizontal: 20 }}>
                        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                          Suplementos
                        </Text>
                      </View>
                      {
                        this.state.activeProductWithOptionsFixedPrice.supplements.map((item, index) => (
                          <View style={{ paddingHorizontal: 10, justifyContent: "center", alignItems: "center", flexDirection: "row", borderBottomColor: "#F5F5F5", borderBottomWidth: 1, paddingVertical: 10 }}>
                            <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                              <TouchableOpacity
                                onPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("supplement", this.state.menu.supplements[item].title, "", "")}
                                onLongPress={() => this.updateActiveWhatsAppProductOptionsAndSupplements("supplement", this.state.menu.supplements[item].title, "", "")}
                                style={{ width: 30, height: 30, backgroundColor: this.state.activeWhatsAppSelectedSupplements.indexOf(this.state.menu.supplements[item].title) >= 0 ? "#68dc91" : "#fff", borderWidth: 1, borderColor: this.state.activeWhatsAppSelectedSupplements.indexOf(this.state.menu.supplements[item].title) >= 0 ? "#68dc91" : "#f5f5f5" }}>

                              </TouchableOpacity>
                            </View>
                            <View style={{ width: "60%", paddingHorizontal: 20 }}>
                              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {this.state.menu.supplements[item].title}
                              </Text>
                            </View>

                            <View style={{ width: "25%" }}>
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", width: "100%", textAlign: "left" }}>
                                {
                                  (this.state.menu.supplements[item].price && !isNaN(this.state.menu.supplements[item].price)) ?
                                    `${parseFloat(this.state.menu.supplements[item].price).toFixed(2)} €`
                                    :
                                    ""
                                }
                              </Text>
                            </View>
                          </View>
                        ))
                      }
                    </View>
                  }
                  {this.checkIfActiveWhatsAppProductWithOptionsFixedPriceAndSupplementsContainsAll() &&
                    <View>

                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Cantidad {this.state.activeWhatsAppProduct.type == "unity" ? "" : "(en gramos)"}
                      </Text>
                      <View style={{ height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <TouchableOpacity
                          onPress={() => this.updateActiveWhatsAppProductQuantityButtons("LESS")}
                          onLongPress={() => this.updateActiveWhatsAppProductQuantityButtons("LESS")}
                          style={{ backgroundColor: "#68dc91", paddingHorizontal: 15, borderRadius: 5, height: 40, justifyContent: "center", alignItems: "center" }}>
                          <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                            -
                          </Text>
                        </TouchableOpacity>
                        <TextInput
                          onChangeText={t => this.updateActiveWhatsAppProductQuantityDirectly(t)}
                          value={this.state.activeWhatsAppProduct.quantity}
                          style={{ fontSize: "1.2rem", outlineStyle: 'none', textAlign: "center", borderRadius: 5, alignSelf: "center", width: "30%", marginHorizontal: 10, paddingHorizontal: 10, height: 40, backgroundColor: "#f5f5f5" }}
                        />
                        <TouchableOpacity
                          onPress={() => this.updateActiveWhatsAppProductQuantityButtons("MORE")}
                          onLongPress={() => this.updateActiveWhatsAppProductQuantityButtons("MORE")}
                          style={{ backgroundColor: "#68dc91", paddingHorizontal: 15, borderRadius: 5, height: 40, justifyContent: "center", alignItems: "center" }}>
                          <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                            +
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ height: 50 }} />
                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Información adicional
                      </Text>
                      <View style={{ height: 20 }} />
                      <TextInput
                        onChangeText={t => this.updateActiveWhatsAppProductAdditionalInfo(t)}
                        multiline
                        numberOfLines={4}
                        placeholder='Escribe aquí instrucciones de preparación adicionales que quieras para este producto (Carne muy hecha, extra de queso, ...)'
                        style={{ outlineStyle: 'none', textAlign: "left", paddingVertical: 10, borderRadius: 5, alignSelf: "center", width: "95%", marginHorizontal: 10, paddingHorizontal: 10, backgroundColor: "#f5f5f5" }}
                      />       <View style={{ height: 30 }} />

                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        Precio: {this.calculatePriceActiveWhatsAppProductWithOptionsFixedPrice()} €
                      </Text>
                      <View style={{ height: 20 }} />
                      <Text style={{ color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "center", width: "100%" }}>
                        Este precio es orientativo y puede no corresponderse con el precio del producto en el establecimiento.
                      </Text>

                      <View style={{ height: 30 }} />
                    </View>
                  }
                  <TouchableOpacity
                    disabled={!this.checkIfActiveWhatsAppProductWithOptionsFixedPriceAndSupplementsContainsAll()}
                    onPress={() => this.addToWhatsAppOrder()}
                    onLongPress={() => this.addToWhatsAppOrder()}
                    style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: !this.checkIfActiveWhatsAppProductWithOptionsFixedPriceAndSupplementsContainsAll() ? "#f5f5f5" : "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Añadir al pedido
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 50 }} />
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        <Sheet isOpen={this.state.detailsOpen} onClose={() => this.setState({ detailsOpen: false }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ detailsOpen: false }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ detailsOpen: false }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.menu &&
                <ScrollView>
                  <View style={{ width: "100%", height: 10 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                    {this.state.menu.name}
                  </Text>
                  {!!this.state.menu.description &&
                    <View>
                      <View style={{ width: "100%", height: 10 }} />
                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        {this.state.menu.description}
                      </Text>
                    </View>
                  }
                  <View style={{ width: "100%", height: 10 }} />
                  <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                    {this.state.menu.direction},  {this.state.menu.location}
                  </Text>
                  <View style={{ width: "100%", height: 50 }} />
                  {this.state.menu.wifi_active &&
                    <View>

                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        WIFI
                      </Text>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                      <View style={{ height: 20 }} />

                      <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        Nombre de la red:<Text style={{ paddingLeft: 10, color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                          {this.state.menu.wifi_net}
                        </Text>
                      </Text>
                      <View style={{ height: 20 }} />
                      <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        Contraseña:<Text style={{ paddingLeft: 10, color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                          {this.state.menu.wifi_password}
                        </Text>
                      </Text>
                      <View style={{ height: 20 }} />
                      <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
                      <View style={{ height: 50 }} />
                    </View>
                  }

                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                    {this.props.translate("schedule.shop")}
                  </Text>

                  <View style={{ height: 10 }} />
                  {this.state.menu && this.state.menu.hours.map((item, index) => (
                    <View style={{ paddingVertical: 15, width: "100%", flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                      <View>
                        <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingLeft: 10, textAlign: "left" }}>
                          {this.props.translate(daysNames[index])}
                        </Text>
                      </View>
                      <View style={{ alignSelf: "flex-end", paddingRight: 10 }}>
                        {item.hours.length == 0 &&
                          <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "right", width: "100%", paddingRight: 10 }}>
                            {this.props.translate("close.shop")}
                          </Text>

                        }
                        {item.hours.map((hour_i, index_i) => (
                          <Text style={{ paddingTop: index_i == 0 ? 0 : 5, color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "right", width: "100%", paddingRight: 10 }}>
                            {hour_i.init}h-{hour_i.final}h
                          </Text>
                        ))}
                      </View>
                    </View>
                  ))}
                  {!!this.state.menu.whatsapp &&
                    <View>
                      <View style={{ height: 50 }} />
                      <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        WhatsApp
                      </Text>
                      <View style={{ height: 20 }} />

                      <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                        Puedes usar WhatsApp para comunicarte con este establecimiento
                      </Text>
                      <View style={{ height: 20 }} />
                      <View style={{ marginHorizontal: 20, width: "100%", alignSelf: "center" }}>
                        <ReactWhatsapp style={{ borderWidth: 0, backgroundColor: "#fff" }} number={"+34" + this.state.menu.whatsapp} message={""}>

                          <View style={{ borderRadius: 5, alignSelf: "center", paaddingBottom: 10, alignItems: "center", width: "100%", backgroundColor: "#128C7E", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ color: "#fff", paddingVertical: 15, textDecoration: "none", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", textAlign: "center", width: "100%" }}>
                              {this.state.menu ? this.state.menu.whatsapp : ""}
                            </Text>


                          </View>
                        </ReactWhatsapp>
                      </View>
                    </View>

                  }

                  <View style={{ height: 50 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                    {this.props.translate("contact.shop")}
                  </Text>
                  <View style={{ height: 20 }} />

                  <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, textAlign: "left", width: "100%" }}>
                    {this.props.translate("doubt.shop")}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ borderRadius: 5, alignSelf: "center", marginBottom: 10, alignItems: "center", width: "95%", backgroundColor: "#68dc91" }}>
                    <TouchableOpacity

                      style={{ paddingVertical: 15, flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

                      <a style={{ textDecoration: "none", color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingVertical: 10, textAlign: "center", width: "100%" }} href={"tel:" + this.state.menu.phone}>
                        {this.state.menu ? this.state.menu.phone : ""}
                      </a>

                    </TouchableOpacity>

                  </View>

                  <View style={{ height: 50 }} />
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>




        <Sheet isOpen={this.state.activeItem != null && !this.state.whatsappOrder} onClose={() => this.setState({ activeItem: null }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header>

            </Sheet.Header>
            <TouchableOpacity
              onPress={() => this.setState({ activeItem: null }, () => this.removeBlockScroll())}
              onLongPress={() => this.setState({ activeItem: null }, () => this.removeBlockScroll())}
              style={{ zIndex: 99, boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", backgroundColor: "#fff", position: "absolute", borderRadius: 100, top: -20, right: 25, width: 50, height: 50, justifyContent: "center", alignItems: "center" }}>
              <IoIosClose size="2em" />
            </TouchableOpacity>
            <Sheet.Content>
              {this.state.activeItem &&
                <ScrollView>


                  {!!this.state.activeItem.img &&
                    <View style={{ width: "100%", height: 250, backgroundColor: "#F5F5F5", overflow: "hidden" }}>
                      <img loading="lazy" src={this.state.activeItem.img} style={{ objectFit: "cover", position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                    </View>
                  }


                  <View style={{ height: 30 }} />
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 10, textAlign: "center", width: "100%" }}>
                    {this.state.activeItem.title}
                  </Text>
                  <View style={{ height: 20 }} />
                  <View style={{ width: "90%", alignSelf: "center" }}>
                    <ShowMoreText
                      /* Default options */
                      lines={3}
                      more={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver más</Text></View>}
                      less={<View style={{ width: 100, paddingHorizontal: 5, paddingVertical: 5, backgroundColor: "#68dc91", borderRadius: 5, alignSelf: "flex-start", justifyContent: "center", alignItems: "center" }}><Text style={{ textDecorationStyle: "double" }}>Ver menos</Text></View>}
                      className="center-text"
                      //anchorClass="my-anchor-css-class"
                      //onClick={this.executeOnClick}
                      expanded={false}

                      truncatedEndingComponent={"... "}
                    >

                      <Text style={{ paddingHorizontal: 20, color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                        {this.state.activeItem.description}
                      </Text>
                    </ShowMoreText>
                  </View>



                  {!!this.state.activeItem.price &&
                    <View>
                      <View style={{ height: 30 }} />

                      <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "center" }}>

                        {!!this.state.activeItem.price &&

                          <Text style={{ textDecorationLine: this.state.activeItem.offer ? "line-through" : "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeItem.price) ?
                                `${parseFloat(this.state.activeItem.price).toFixed(2)} € ${this.state.activeItem.kg ? "/ kg" : this.state.activeItem.custom_unity ? "/ " + this.state.activeItem.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>
                        }
                        {this.state.activeItem.offer &&

                          <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>

                            {
                              !isNaN(this.state.activeItem.price_offer) ?
                                `${parseFloat(this.state.activeItem.price_offer).toFixed(2)} € ${this.state.activeItem.kg ? "/ kg" : this.state.activeItem.custom_unity ? "/ " + this.state.activeItem.custom_unity_text : ""}` :
                                ""
                            }
                          </Text>


                        }


                      </View>

                    </View>
                  }
                  {!this.state.activeItem.available &&
                    <View>
                      <View style={{ height: 30 }} />

                      <Text style={{ color: "red", fontWeight: "500", fontSize: "1.1rem", textAlign: "center", width: "100%" }}>
                        {this.props.translate("available.shop")}
                      </Text>
                    </View>
                  }

                  {
                    this.state.activeItem.labels && this.state.activeItem.labels.length > 0 &&
                    <View>
                      <View style={{ height: 10 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>

                        {
                          this.state.activeItem.labels &&
                          this.state.activeItem.labels.map((label, label_index) => (

                            <View style={{ marginLeft: label_index == 0 ? 0 : 10, alignSelf: "flex-start", paddingHorizontal: 10, paddingVertical: 5, backgroundColor: label.color.color, borderRadius: 100 }}>
                              <Text style={{ color: label.color.contrast, fontWeight: "600", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                {label.label}
                              </Text>
                            </View>


                          ))
                        }
                      </View>
                    </View>
                  }

                  {
                    this.state.activeItem.allergens && this.state.activeItem.allergens.length > 0 &&
                    <View style={{ width: "90%", alignSelf: "center" }}>
                      <View style={{ height: 50 }} />
                      <View style={{ width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        {
                          this.state.activeItem.allergens.map((item, index) => (
                            <View style={{ marginLeft: index > 0 ? 5 : 0, width: 35, height: 35, justifyContent: "center", alignItems: "center" }}>
                              {this.renderAllergenActiveItem(item)}
                            </View>
                          ))
                        }
                      </View>
                    </View>
                  }
                  <View style={{ height: 50 }} />
                </ScrollView>
              }
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>





   

        {/**modal establishment options*/}
        <Sheet isOpen={this.state.visibleEstablishmentOptions} onClose={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div style={{ overflowY: "scroll" }}>
                <View style={{ height: 10 }} />
                <View style={{ paddingHorizontal: 20 }}>
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "left", width: "100%" }}>
                    Este establecimiento ofrece
                  </Text>


                </View>


                <View style={{ height: 20 }} />
                {this.state.menu &&
                  <View style={{ height: "100%" }}>
                    {
                      this.state.menu.reserve &&
                      <View style={{ width: "100%" }}>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View style={{ width: "20%", paddingHorizontal: 15, justifyContent: "center", alignItems: "center" }}>
                            <img loading='lazy' src={reserve} style={{ paddingHorizontal: 10, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }} resizeMode="contain" />

                          </View>
                          <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                              Reservas
                            </Text>
                            <View style={{ height: 5 }} />
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                              Puedes realizar una reserva mediante una llamada{
                                this.state.menu.whatsapp
                                  ? " o un mensaje de WhatsApp." : "."}
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 20 }} />
                      </View>
                    }
                    {
                      this.state.menu.groups &&
                      <View style={{ width: "100%" }}>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View style={{ width: "20%", paddingHorizontal: 15, justifyContent: "center", alignItems: "center" }}>
                            <img loading='lazy' src={group} style={{ paddingHorizontal: 10, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }} resizeMode="contain" />

                          </View>
                          <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                              Opción para grupos
                            </Text>
                            <View style={{ height: 5 }} />
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                              Este establecimiento es ideal para eventos en grupo.
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 20 }} />
                      </View>
                    }
                    {
                      this.state.menu &&
                      this.state.menu.take_away &&
                      <View style={{ width: "100%" }}>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View style={{ width: "20%", paddingHorizontal: 15, justifyContent: "center", alignItems: "center" }}>
                            <img loading='lazy' src={takeAway} style={{ paddingHorizontal: 10, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }} resizeMode="contain" />

                          </View>
                          <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                              Para llevar
                            </Text>
                            <View style={{ height: 5 }} />
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                              Realiza un pedido por teléfono {
                                !!this.state.menu.whatsapp
                                  ? " o WhatsApp" : ""} y cómetelo dónde quieras.
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 20 }} />
                      </View>
                    }
                    {

                      this.state.menu.delivery &&
                      <View style={{ width: "100%" }}>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View style={{ width: "20%", paddingHorizontal: 15, justifyContent: "center", alignItems: "center" }}>
                            <img loading='lazy' src={fastDelivery} style={{ paddingHorizontal: 10, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }} resizeMode="contain" />

                          </View>
                          <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                              Entrega a domicilio
                            </Text>
                            <View style={{ height: 5 }} />
                            <Text style={{ paddingRight: 20, color: "#000", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                              Realiza un pedido por teléfono {
                                !!this.state.menu.whatsapp
                                  ? " o WhatsApp" : ""} y el establecimiento lo enviará a tu domicilio.
                            </Text>
                          </View>
                        </View>
                        <View style={{ height: 20 }} />
                      </View>
                    }
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                      <TouchableOpacity
                        onPress={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}
                        onLongPress={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}
                        style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                        <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                          Entendido
                        </Text>
                      </TouchableOpacity>
                      <View style={{ height: 20 }} />
                    </View>
                  </View>
                }
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

        {/**WhatsApp */}
        <Sheet isOpen={false} onClose={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <View style={{ height: 10 }} />
              <View style={{ paddingHorizontal: 20 }}>
                <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "left", width: "100%" }}>
                  Estás un poco lejos de este establecimiento
                </Text>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                  Puedes explorar los productos del establecimiento o realizar un pedido por WhatsApp y pasar a recogerlo cuando quieras o que lo entreguen a domicilio.
                </Text>
                <View style={{ height: 50 }} />
                <View style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}
                    onLongPress={() => this.setState({ visibleEstablishmentOptions: false }, () => this.removeBlockScroll())}
                    style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                    <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Explorar productos
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 20 }} />
                </View>
                <View style={{ height: 20 }} />
                <View style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => this.setState({ whatsappOrder: true })}
                    onLongPress={() => this.setState({ whatsappOrder: true })}
                    style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#128C7E", borderRadius: 10, paddingVertical: 15 }}>
                    <Text style={{ color: "#fff", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Empezar un pedido
                    </Text>
                  </TouchableOpacity>
                  <View style={{ height: 20 }} />
                </View>

              </View>

            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>


        {this.state.menu &&
          this.state.menu.accept_orders &&
          this.checkAndroidOrIOS() &&
          this.state.showBottomMessage &&
          <Animated.View style={{ flexDirection: "row", position: "fixed", bottom: 0, width: "100%", height: 50, backgroundColor: "#68DC91", justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "60%" }}>
              <Ticker speed={8}>
                {({ index }) => (
                  <>
                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <View style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ fontSize: "1rem", whiteSpace: "nowrap", fontWeight: "500", color: "#000" }}>

                          Este establecimiento permite pedidos a través de nuestra aplicación. Descarga la aplicación para iOS y Android.
                        </Text>
                      </View>
                    </View>
                  </>
                )}
              </Ticker>
            </View>
            <View style={{ width: "25%" }}>
              <TouchableOpacity
                onPress={() => this.redirectToDownload()}
                onLongPress={() => this.redirectToDownload()}
                style={{ height: 40, justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#fff", borderRadius: 5, }}>
                <Text style={{ color: "#128C7E", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  Descargar
                </Text>
              </TouchableOpacity>

            </View>
            <View style={{ width: "15%" }}>
              <TouchableOpacity
                onPress={() => this.setState({ showBottomMessage: false })}
                onLongPress={() => this.setState({ showBottomMessage: false })}
                style={{ height: 40, justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", borderRadius: 5, }}>
                <IoIosClose size="2em" />
              </TouchableOpacity>

            </View>
          </Animated.View>
        }

        {this.state.menu &&
          !this.checkAndroidOrIOS() &&
          this.state.showBottomMessage &&
          <Animated.View style={{ flexDirection: "row", position: "fixed", bottom: 0, width: "100%", height: 50, backgroundColor: "#68DC91", justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "85%" }}>
              <Ticker speed={8}>
                {({ index }) => (
                  <>
                    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <View style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ fontSize: "1rem", whiteSpace: "nowrap", fontWeight: "500", color: "#000" }}>
                          Descubre este establecimiento y muchos otros en nuestra aplicación Buyness disponible para iOS y Android.
                        </Text>
                      </View>
                    </View>
                  </>
                )}
              </Ticker>
            </View>
            <View style={{ width: "15%" }}>
              <TouchableOpacity
                onPress={() => this.setState({ showBottomMessage: false })}
                onLongPress={() => this.setState({ showBottomMessage: false })}
                style={{ height: 40, justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", borderRadius: 5, }}>
                <IoIosClose size="2em" />
              </TouchableOpacity>

            </View>

          </Animated.View>
        }

      </div>
    )
  }
}
