import * as React from 'react';
import { Text, View, TouchableOpacity, ScrollView, TextInput, FlatList, Dimensions } from 'react-native';
import GeneralContext from '../Provider.js';
import { withSnackbar } from 'react-simple-snackbar'
import { IoIosClose, IoIosCheckmark } from "react-icons/io";
import { Popover } from 'react-tiny-popover'

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { MdDeliveryDining, MdStoreMallDirectory } from "react-icons/md";

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const myCustomLocale = {
    // months list by order
    months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],

    // week days by order
    weekDays: [
        {
            name: 'Domingo', // used for accessibility 
            short: 'S', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: 'Lunes',
            short: 'L',
        },
        {
            name: 'Martes',
            short: 'M',
        },
        {
            name: 'Miércoles',
            short: 'X',
        },
        {
            name: 'Jueves',
            short: 'J',
        },
        {
            name: 'Viernes',
            short: 'V',
        },
        {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: 'Siguiente mes',
    previousMonth: 'Mes anterior',
    openMonthSelector: 'Abrir selector de mes',
    openYearSelector: 'Abrir selector de año',
    closeMonthSelector: 'Cerrar selector de mes',
    closeYearSelector: 'Cerrar selector de año',
    defaultPlaceholder: 'Seleccionar...',

    // for input range value
    from: 'Desde',
    to: 'hasta',


    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
}

const ordersStatus = [
    "Nuevos",
    "Aceptados",
    "En preparación",
    "Listo para entregar",
    "Terminados"

]

const orderStatusReferences = {
    "Nuevos": "NEWS",
    "Aceptados": "ACCEPTED",
    "En preparación": "PREPARING",
    "Listo para entregar": "READY",
    "Terminados": "DONE"
}

const activeStatusSubTabReferences = {
    "Todos": "ALL",
    "En preparación": "PREPARING_ORDER",
    "Pendiente de envío": "PENDING_SEND",
    "Listo para recoger": "READY_FOR_PICK_UP",
    "Recogidos en el establecimiento": "PICKED_UP",
    "Enviados al cliente": "RECEIVED",
    "Cancelados por el usuario": "CANCELED_BY_USER",
    "Cancelados por el establecimiento": "CANCELED_BY_ESTABLISHMENT"
}

const referencesEmptyListsTitles = {
    "NEWS": {
        "ALL": "No hay pedidos nuevos"
    },
    "ACCEPTED": {
        "ALL": "No tienes nuevos pedidos aceptados",

    },
    "PREPARING": {
        "ALL": "Actualmente no estás preparando ningún pedido nuevo",
        "PREPARING_ORDER": "Actualmente no tienes ningún pedido en preparación",
        "PENDING_SEND": "Actualmente no tienes ningún pedido pendiente de envío",
    },
    "READY": {
        "ALL": "Actualmente no tienes ningún pedido pendiente de ser recogido/entregado al el cliente",
        "READY_FOR_PICK_UP": "Actualmente no tienes ningún pedido pendiente de recoger",
        "DELIVERING": "Actualmente no tienes ningún pedido en reparto"
    },
    "DONE": {
        "ALL": "Aún no has terminado ningún pedido",
        "PICKED_UP": "Hasta la fecha ningún cliente ha pasado a recoger su pedido",
        "RECEIVED": "Hasta la fecha no has entregado ningún pedido a domicilio",
        "CANCELED_BY_ESTABLISHMENT": "Hasta la fecha no has cancelado ningún pedido",
        "CANCELED_BY_USER": "No tienes pedidos cancelados por los clientes"
    }
}

const referencesEmptyListsSubtitles = {
    "NEWS": {
        "ALL": "Los nuevos pedidos que vayas recibiendo aparecerán aquí."
    },
    "ACCEPTED": {
        "ALL": "Los pedidos que aceptes aparecerán aquí.",

    },
    "PREPARING": {
        "ALL": "Todos los pedidos que estén pedientes de preparación o pendientes de ser enviado (si realizas entregas a domicilio) aparecerán aquí.",
        "PREPARING_ORDER": "Los pedidos que se encuentren en preparación aparecerán aquí.",
        "PENDING_SEND": "Los pedidos que se encuentren pendientes de envío aparecerán aquí.",
    },
    "READY": {
        "ALL": "",
        "READY_FOR_PICK_UP": "",
        "DELIVERING": ""
    },
    "DONE": {
        "ALL": "Aquí aparecerán todos aquellos pedidos que se hayan entregado a tus clientes o que se hayan cancelado por algún motivo.",
        "PICKED_UP": "Aquí aparecerán los pedidos que hayan sido recogidos en el establecimiento por el cliente.",
        "RECEIVED": "Aquí aparecerán los pedidos que hayan sido entregados a domicilio.",
        "CANCELED_BY_ESTABLISHMENT": "Aquí aparecerán los pedidos que hayas cancelado.",
        "CANCELED_BY_USER": "Aquí aparecerán los pedidos que hayan sido cancelados por el cliente."
    }
}



class App extends React.Component {
    static contextType = GeneralContext;
    constructor(props) {
        super(props);
        this.state = {
            manageStatus: "",
            activeOrder: null,
            activeOrderInitial: null,
            activeEditingPrice: false,
            refreshingReceiptsList: false,
            showLoadMoreReceipts: true,
            days: [],
            activeDay: 0,
            activeStatus: "NEWS",
            activeStatusSubTab: "ALL",
            showCalendar: false,
            showLoadMoreReceiptsNewAll: true,
            showLoadMoreReceiptsAcceptedAll: true,
            showLoadMoreReceiptsPreparingAll: true,
            showLoadMoreReceiptsPreparingPreparing: true,
            showLoadMoreReceiptsPreparingAwaiting: true,
            showLoadMoreReceiptsPreparingSending: true,
            showLoadMoreReceiptsPickedUpAll: true,
            showLoadMoreReceiptsPickedUpPicked: true,
            showLoadMoreReceiptsPickedUpSend: true,
            showLoadMoreReceiptsCanceledAll: true,
            showLoadMoreReceiptsCanceled: true,
            activeOrderIndex: 0
        }

        this.updateOrder = this.updateOrder.bind(this)
        this.renderReceiptsHeader = this.renderReceiptsHeader.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.renderNoReceipts = this.renderNoReceipts.bind(this);
    }

    static initialDate = new Date()
    static finishDate = new Date().addDays(8)

    generateSubBar() {
        switch (this.state.activeStatus) {
            case "NEWS":
                return (
                    <View style={{ width: "100%", backgroundColor: "#f5f5f5", paddingVertical: 10, paddingHorizontal: 20, justifyContent: "space-between", flexDirection: "row" }}>
                        <TouchableOpacity
                            onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                            onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                            style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "ALL" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                            <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                Todos ({this.context.activeReceipts["NEWS"]["ALL"].length})
                            </Text>
                        </TouchableOpacity>
                        {false &&
                            <View style={{ flexDirection: "row" }}>
                                <Popover
                                    isOpen={this.state.showCalendar}
                                    align="end"
                                    containerStyle={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)", borderRadius: 20, bakgoundColor: "#fff" }}
                                    positions={['bottom', 'right']} // if you'd like, you can limit the positions
                                    padding={10} // adjust padding here!
                                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                                    onClickOutside={() => this.setState({ showCalendar: false })} // handle click events outside of the popover/target here!
                                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                                        <View style={{ justifyContent: "center", alignItems: "center", width: 330, height: 370, paddingVertical: 10, paddingHorizontal: 20, borderRadius: 20, zIndex: 99, backgroundColor: "#fff", shadowOpacity: 0.15, shadowRadius: 10, shadowOffset: { height: 0 } }}>
                                            <Calendar
                                                calendarPopperPosition={'bottom'}
                                                value={null}
                                                minimumDate={{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }}
                                                maximumDate={{ year: new Date().addDays(8).getFullYear(), month: new Date().addDays(8).getMonth() + 1, day: new Date().addDays(8).getDate() }}
                                                locale={myCustomLocale}
                                                colorPrimary="#0fbcf9"
                                            // onChange={setSelectedDay}
                                            //inputPlaceholder="Select a day"
                                            //shouldHighlightWeekends
                                            />
                                        </View>
                                    )}
                                >
                                    <TouchableOpacity
                                        onPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        onLongPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                            Filtrar
                                        </Text>
                                    </TouchableOpacity>
                                </Popover>
                            </View>
                        }
                    </View>)
            case "ACCEPTED":
                return (
                    <View style={{ width: "100%", backgroundColor: "#f5f5f5", paddingVertical: 10, paddingHorizontal: 20, justifyContent: "space-between", flexDirection: "row" }}>
                        <View style={{ flexDirection: "row" }}>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}

                                style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "ALL" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Todos ({this.context.activeReceipts["ACCEPTED"]["ALL"].length})
                                </Text>
                            </TouchableOpacity>

                        </View>
                        {false &&
                            <View style={{ flexDirection: "row" }}>
                                <Popover
                                    isOpen={this.state.showCalendar}
                                    align="end"
                                    containerStyle={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)", borderRadius: 20, bakgoundColor: "#fff" }}
                                    positions={['bottom', 'right']} // if you'd like, you can limit the positions
                                    padding={10} // adjust padding here!
                                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                                    onClickOutside={() => this.setState({ showCalendar: false })} // handle click events outside of the popover/target here!
                                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                                        <View style={{ justifyContent: "center", alignItems: "center", width: 330, height: 370, paddingVertical: 10, paddingHorizontal: 20, borderRadius: 20, zIndex: 99, backgroundColor: "#fff", shadowOpacity: 0.15, shadowRadius: 10, shadowOffset: { height: 0 } }}>
                                            <Calendar
                                                calendarPopperPosition={'bottom'}
                                                value={null}
                                                minimumDate={{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }}
                                                maximumDate={{ year: new Date().addDays(8).getFullYear(), month: new Date().addDays(8).getMonth() + 1, day: new Date().addDays(8).getDate() }}
                                                locale={myCustomLocale}
                                                colorPrimary="#0fbcf9"
                                            // onChange={setSelectedDay}
                                            //inputPlaceholder="Select a day"
                                            //shouldHighlightWeekends
                                            />
                                        </View>
                                    )}
                                >
                                    <TouchableOpacity
                                        onPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        onLongPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                            Filtrar
                                        </Text>
                                    </TouchableOpacity>
                                </Popover>
                            </View>
                        }
                    </View>)

            case "PREPARING":
                return (
                    <View style={{ width: "100%", backgroundColor: "#f5f5f5", paddingVertical: 10, paddingHorizontal: 20, justifyContent: "space-between", flexDirection: "row" }}>
                        <View style={{ flexDirection: "row" }}>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}

                                style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "ALL" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Todos ({this.context.activeReceipts["PREPARING"]["ALL"].length})
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity

                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["En preparación"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["En preparación"] })}

                                style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "PREPARING_ORDER" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    En preparación ({this.context.activeReceipts["PREPARING"]["PREPARING_ORDER"].length})
                                </Text>
                            </TouchableOpacity>
                            {false &&
                                <TouchableOpacity
                                    onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Pendiente de envío"] })}
                                    onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Pendiente de envío"] })}

                                    style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "PENDING_SEND" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                    <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                        Pendiente de envío ({this.context.activeReceipts["PREPARING"]["PENDING_SEND"].length})
                                    </Text>
                                </TouchableOpacity>
                            }
                        </View>
                        {false &&
                            <View style={{ flexDirection: "row" }}>
                                <Popover
                                    isOpen={this.state.showCalendar}
                                    align="end"
                                    containerStyle={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)", borderRadius: 20, bakgoundColor: "#fff" }}
                                    positions={['bottom', 'right']} // if you'd like, you can limit the positions
                                    padding={10} // adjust padding here!
                                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                                    onClickOutside={() => this.setState({ showCalendar: false })} // handle click events outside of the popover/target here!
                                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                                        <View style={{ justifyContent: "center", alignItems: "center", width: 330, height: 370, paddingVertical: 10, paddingHorizontal: 20, borderRadius: 20, zIndex: 99, backgroundColor: "#fff", shadowOpacity: 0.15, shadowRadius: 10, shadowOffset: { height: 0 } }}>
                                            <Calendar
                                                calendarPopperPosition={'bottom'}
                                                value={null}
                                                minimumDate={{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }}
                                                maximumDate={{ year: new Date().addDays(8).getFullYear(), month: new Date().addDays(8).getMonth() + 1, day: new Date().addDays(8).getDate() }}
                                                locale={myCustomLocale}
                                                colorPrimary="#0fbcf9"
                                            // onChange={setSelectedDay}
                                            //inputPlaceholder="Select a day"
                                            //shouldHighlightWeekends
                                            />
                                        </View>
                                    )}
                                >
                                    <TouchableOpacity
                                        onPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        onLongPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                            Filtrar
                                        </Text>
                                    </TouchableOpacity>
                                </Popover>
                            </View>
                        }
                    </View>)
            case "READY":
                return (
                    <View style={{ width: "100%", backgroundColor: "#f5f5f5", paddingVertical: 10, paddingHorizontal: 20, justifyContent: "space-between", flexDirection: "row" }}>
                        <View style={{ flexDirection: "row" }}>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}

                                style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "ALL" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Todos ({this.context.activeReceipts["READY"]["ALL"].length})
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Listo para recoger"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Listo para recoger"] })}

                                style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "READY_FOR_PICK_UP" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Listo para recoger ({this.context.activeReceipts["READY"]["READY_FOR_PICK_UP"].length})
                                </Text>
                            </TouchableOpacity>
                            {false &&
                                <TouchableOpacity
                                    onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                                    onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}

                                    style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "DELIVERING" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                    <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                        En reparto ({this.context.activeReceipts["READY"]["DELIVERING"].length})
                                    </Text>
                                </TouchableOpacity>
                            }

                        </View>
                        {false &&
                            <View style={{ flexDirection: "row" }}>
                                <Popover
                                    isOpen={this.state.showCalendar}
                                    align="end"
                                    containerStyle={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)", borderRadius: 20, bakgoundColor: "#fff" }}
                                    positions={['bottom', 'right']} // if you'd like, you can limit the positions
                                    padding={10} // adjust padding here!
                                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                                    onClickOutside={() => this.setState({ showCalendar: false })} // handle click events outside of the popover/target here!
                                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                                        <View style={{ justifyContent: "center", alignItems: "center", width: 330, height: 370, paddingVertical: 10, paddingHorizontal: 20, borderRadius: 20, zIndex: 99, backgroundColor: "#fff", shadowOpacity: 0.15, shadowRadius: 10, shadowOffset: { height: 0 } }}>
                                            <Calendar
                                                calendarPopperPosition={'bottom'}
                                                value={null}
                                                minimumDate={{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }}
                                                maximumDate={{ year: new Date().addDays(8).getFullYear(), month: new Date().addDays(8).getMonth() + 1, day: new Date().addDays(8).getDate() }}
                                                locale={myCustomLocale}
                                                colorPrimary="#0fbcf9"
                                            // onChange={setSelectedDay}
                                            //inputPlaceholder="Select a day"
                                            //shouldHighlightWeekends
                                            />
                                        </View>
                                    )}
                                >
                                    <TouchableOpacity
                                        onPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        onLongPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                            Filtrar
                                        </Text>
                                    </TouchableOpacity>
                                </Popover>
                            </View>
                        }
                    </View>)
            case "DONE":
                return (
                    <View style={{ width: "100%", backgroundColor: "#f5f5f5", paddingVertical: 10, paddingHorizontal: 20, justifyContent: "space-between", flexDirection: "row" }}>
                        <ScrollView horizontal style={{ width: "100%" }}>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Todos"] })}

                                style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "ALL" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Todos ({this.context.activeReceipts["DONE"]["ALL"].length})
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Recogidos en el establecimiento"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Recogidos en el establecimiento"] })}

                                style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "PICKED_UP" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Recogidos en el establecimiento ({this.context.activeReceipts["DONE"]["PICKED_UP"].length})
                                </Text>
                            </TouchableOpacity>
                            {false &&
                                <TouchableOpacity
                                    onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Enviados al cliente"] })}
                                    onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Enviados al cliente"] })}

                                    style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "RECEIVED" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                    <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                        Enviados al cliente ({this.context.activeReceipts["DONE"]["RECEIVED"].length})
                                    </Text>
                                </TouchableOpacity>
                            }
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Cancelados por el usuario"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Cancelados por el usuario"] })}

                                style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "CANCELED_BY_USER" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Cancelados por el usuario ({this.context.activeReceipts["DONE"]["CANCELED_BY_USER"].length})
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Cancelados por el establecimiento"] })}
                                onLongPress={() => this.setState({ activeStatusSubTab: activeStatusSubTabReferences["Cancelados por el establecimiento"] })}

                                style={{ marginLeft: 20, paddingVertical: 10, paddingHorizontal: 20, backgroundColor: this.state.activeStatusSubTab == "CANCELED_BY_ESTABLISHMENT" ? "#68dc91" : "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    Cancelados por el establecimiento ({this.context.activeReceipts["DONE"]["CANCELED_BY_ESTABLISHMENT"].length})
                                </Text>
                            </TouchableOpacity>
                        </ScrollView>
                        {false &&
                            <View style={{ flexDirection: "row" }}>
                                <Popover
                                    isOpen={this.state.showCalendar}
                                    align="end"
                                    containerStyle={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.3)", borderRadius: 20, bakgoundColor: "#fff" }}
                                    positions={['bottom', 'right']} // if you'd like, you can limit the positions
                                    padding={10} // adjust padding here!
                                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                                    onClickOutside={() => this.setState({ showCalendar: false })} // handle click events outside of the popover/target here!
                                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                                        <View style={{ justifyContent: "center", alignItems: "center", width: 330, height: 370, paddingVertical: 10, paddingHorizontal: 20, borderRadius: 20, zIndex: 99, backgroundColor: "#fff", shadowOpacity: 0.15, shadowRadius: 10, shadowOffset: { height: 0 } }}>
                                            <Calendar
                                                calendarPopperPosition={'bottom'}
                                                value={null}
                                                minimumDate={{ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }}
                                                maximumDate={{ year: new Date().addDays(8).getFullYear(), month: new Date().addDays(8).getMonth() + 1, day: new Date().addDays(8).getDate() }}
                                                locale={myCustomLocale}
                                                colorPrimary="#0fbcf9"
                                            // onChange={setSelectedDay}
                                            //inputPlaceholder="Select a day"
                                            //shouldHighlightWeekends
                                            />
                                        </View>
                                    )}
                                >
                                    <TouchableOpacity
                                        onPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        onLongPress={() => this.setState({ showCalendar: !this.state.showCalendar })}
                                        style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: "#fff", alignSelf: "flex-start", borderRadius: 100 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                            Filtrar
                                        </Text>
                                    </TouchableOpacity>
                                </Popover>
                            </View>
                        }
                    </View>)
            default:

        }
    }


    generateDays() {
        this.setState({
            activeDay: 0,
            days: [
                new Date().addDays(0),
                new Date().addDays(1),
                new Date().addDays(2),
                new Date().addDays(3),
                new Date().addDays(4),
                new Date().addDays(5),
                new Date().addDays(6),
                new Date().addDays(7),
                new Date().addDays(8)
            ]
        })
    }


    componentDidMount() {
        this.generateDays()
    }

    datesAreOnSameDay(first, second) {
        return (
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        )
    }

    updateActiveOrderFinalAmountPriceTemporal(price) {
        var activeOrder = this.state.activeOrder
        activeOrder.payment_info.final_amount = price
        this.setState({ activeOrder: activeOrder })
    }

    updateActiveOrderFinalAmountPrice(price) {
        var activeOrder = this.state.activeOrder
        if (!isNaN(parseFloat(this.state.activeOrder.payment_info.final_amount))) {

            activeOrder.payment_info.final_amount = parseFloat(this.state.activeOrder.payment_info.final_amount)
            this.setState({ activeOrder: activeOrder })
        }
        else {
            activeOrder.payment_info.final_amount = 0
            this.setState({ activeOrder: activeOrder })
        }
    }

    updateActiveOrderFinalAmountDescription(description) {
        var activeOrder = this.state.activeOrder
        activeOrder.payment_info.reason_changes = description
        this.setState({ activeOrder: activeOrder })

    }

    updateOrder() {
        //CHECK 
        this.context.updateOrder(this.state.activeOrder, this.state.activeStatus, this.state.activeStatusSubTab, this.state.activeOrderIndex, () => { this.setState({ activeOrder: null }, () => this.props.openSnackbar("Se ha actualizado el pedido y se le comunicará a tu cliente.", 5000)) })
    }

    checkASAP(date) {
        return date.getFullYear() == "1970"
    }

    generatePickUpDateTextList(receipt) {
        if (receipt) {
            if (this.checkASAP(receipt.estimated_pick_up.toDate()))
                return "lo antes posible"
            if (this.datesAreOnSameDay(receipt.placed_at.toDate(), receipt.estimated_pick_up.toDate()))
                return "hoy, a las " + ("0" + receipt.estimated_pick_up.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.estimated_pick_up.toDate().getMinutes()).slice(-2) + "h"
            if (this.datesAreOnSameDay(receipt.placed_at.toDate().addDays(1), receipt.estimated_pick_up.toDate()))
                return "mañana, a las " + ("0" + receipt.estimated_pick_up.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.estimated_pick_up.toDate().getMinutes()).slice(-2) + "h"

            return receipt.estimated_pick_up.toDate().toLocaleString('es-ES', { weekday: 'long', day: 'numeric', month: 'short' }) + ", a las " + ("0" + receipt.estimated_pick_up.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.estimated_pick_up.toDate().getMinutes()).slice(-2) + "h"
        }
    }

    generatePlacedDateTextList(receipt) {
        if (receipt) {

            if (this.datesAreOnSameDay(receipt.placed_at.toDate(), new Date()))
                return "hoy, a las " + ("0" + receipt.placed_at.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.placed_at.toDate().getMinutes()).slice(-2) + "h"
            if (this.datesAreOnSameDay(receipt.placed_at.toDate().addDays(1), new Date()))
                return "ayer, a las " + ("0" + receipt.placed_at.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.placed_at.toDate().getMinutes()).slice(-2) + "h"

            return receipt.placed_at.toDate().toLocaleString('es-ES', { weekday: 'long', day: 'numeric', month: 'short' }) + ", a las " + ("0" + receipt.placed_at.toDate().getHours()).slice(-2) + ":" + ("0" + receipt.placed_at.toDate().getMinutes()).slice(-2) + "h"
        }
    }

    manageOrderStatusTextList(receipt) {

        switch (receipt.current_state) {
            case "AWAITING_CONFIRMATION":
                return "Esperando confirmación"
            case "PENDING_PREPARATION":
                return "Pendiente de preparación"
            case "PREPARING":
                return "En preparación"
            case "READY_FOR_SENDING":
                return "Envío próximamente"
            case "READY_FOR_PICK_UP":
                return "Listo para recoger"
            case "DELIVERING":
                return "En reparto"
            case "RECEIVED":
                return "Entregado"
            case "PICKED_UP":
                return "Recogido"
            case "CANCELED_BY_ESTABLISHMENT":
                return "Cancelado (establecimiento)"
            case "CANCELED_BY_USER":
                return "Cancelado (usuario)"
            default:
                return "Sin estado"
        }


    }

    manageOrderStatusTextDetails(state) {

        switch (state) {
            case "AWAITING_CONFIRMATION":
                return "Esperando confirmación"
            case "PENDING_PREPARATION":
                return "Pendiente de preparación"
            case "PREPARING":
                return "En preparación"
            case "READY_FOR_SENDING":
                return "Envío próximamente"
            case "READY_FOR_PICK_UP":
                return "Listo para recoger"
            case "DELIVERING":
                return "En reparto"
            case "RECEIVED":
                return "Entregado"
            case "PICKED_UP":
                return "Recogido"
            case "CANCELED_BY_ESTABLISHMENT":
                return "Cancelado (establecimiento)"
            case "CANCELED_BY_USER":
                return "Cancelado (usuario)"
            default:
                return "Sin estado"
        }


    }

    updateActiveOrderStatus(status) {
        var activeOrder = this.state.activeOrder
        activeOrder.current_state = status
        this.setState({ activeOrder: activeOrder })
    }

    updateActiveOrderCancellationMessage(t) {
        var activeOrder = this.state.activeOrder
        activeOrder.payment_info.reason_changes = t
        this.setState({ activeOrder: activeOrder })
    }

    checkSaveActiveOrderChanges() {
        return (
            (this.state.activeOrder.current_state == "CANCELED_BY_ESTABLISHMENT" && !this.state.activeOrder.payment_info.reason_changes) ||
            (this.state.activeEditingPrice && (isNaN(this.state.activeOrder.payment_info.final_amount) || !this.state.activeOrder.payment_info.reason_changes)) ||
            !this.checkActiveOrderHaveChanges()
        )
    }

    checkActiveOrderHaveChanges() {
        return JSON.stringify(this.state.activeOrder) != this.state.activeOrderInitial
    }

    showUpdatedMessage() {
        this.props.openSnackbar("Se ha actualizado la información de tus pedidos.", 5000)
    }

    generateOrdersStates() {
        return this.state.activeOrder.order_type == "PICK_UP" ?
            ["AWAITING_CONFIRMATION", "PENDING_PREPARATION", "PREPARING", "READY_FOR_PICK_UP", "PICKED_UP", "CANCELED_BY_ESTABLISHMENT"]
            :
            ["AWAITING_CONFIRMATION", "PENDING_PREPARATION", "PREPARING", "READY_FOR_SENDING", "DELIVERING", "RECEIVED", "CANCELED_BY_ESTABLISHMENT"]
    }




    generateOrderArticles() {
        if (this.state.activeOrder) {
            return (
                <View style={{ width: "100%" }}>
                    <View style={{ height: 20 }} />
                    {
                        this.state.activeOrder.cart_info.final_items.length == 0 &&
                        this.state.activeOrder.cart_info.initial_items.map((item, index) => (
                            <View style={{ width: "100%" }}>
                                <View style={{ height: 20 }} />
                                <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <View style={{ width: "20%", justifyContent: "center", alignItems: "center" }}>
                                        <View style={{ paddingVertical: 10, paddingHorizontal: 15, backgroundColor: "#f5f5f5", borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
                                            <Text style={{ width: "100%", textAlign: "center", fontWeight: "600", fontSize: 16 }}>
                                                {item.quantity}{item.type == "weight" ? " g." : " uds."}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "80%" }}>
                                        <Text numberOfLines={2} style={{ width: "100%", fontSize: 20, fontWeight: "600" }}>
                                            {item.title}
                                        </Text>
                                        {
                                            item.kg &&
                                            <Text numberOfLines={2} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "gray" }}>
                                                (Este es un producto a peso)
                                            </Text>
                                        }
                                        {
                                            item.options && Object.keys(item.options).length > 0 &&
                                            <View>
                                                <View style={{ height: 10 }} />
                                                <Text numberOfLines={1} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "#000" }}>
                                                    Opciones
                                                </Text>
                                                <View style={{ height: 5 }} />
                                                {Object.entries(item.options).map((option, option_index) => (
                                                    <Text numberOfLines={1} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "gray" }}>
                                                        {option[0]}: {option[1]}
                                                    </Text>
                                                ))}
                                            </View>
                                        }
                                        {
                                            item.supplements && item.supplements.length > 0 &&
                                            <View>
                                                <View style={{ height: 10 }} />
                                                <Text numberOfLines={1} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "#000" }}>
                                                    Suplementos
                                                </Text>
                                                <View style={{ height: 5 }} />
                                                {Object.entries(item.supplements).map((supplement, supplement_index) => (
                                                    <Text numberOfLines={1} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "gray" }}>
                                                        {supplement[1]}
                                                    </Text>
                                                ))}
                                            </View>
                                        }
                                        {
                                            !!item.additionalInfo &&
                                            <View>
                                                <View style={{ height: 10 }} />
                                                <Text numberOfLines={1} style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "#000" }}>
                                                    Información adicional
                                                </Text>
                                                <View style={{ height: 5 }} />
                                                <Text style={{ width: "100%", fontSize: 16, fontWeight: "500", color: "gray" }}>
                                                    {item.additionalInfo}
                                                </Text>
                                            </View>
                                        }
                                    </View>
                                </View>
                                <View style={{ height: 20 }} />
                            </View>
                        ))
                    }
                </View>
            )
        }

    }


    generateStatusColorList(status) {
        switch (status) {
            case "AWAITING_CONFIRMATION":
                return "#E9ECF5"
            case "PENDING_PREPARATION":
                return "#EDEDED"
            case "PREPARING":
                return "#F3F9D2"
            case "READY_FOR_SENDING":
                return "#FAE9D2"
            case "READY_FOR_PICK_UP":
                return "#FAE9D2"
            case "DELIVERING":
                return "#FAD6D2"
            case "RECEIVED":
                return "#CCFFB4"
            case "PICKED_UP":
                return "#CCFFB4"
            case "CANCELED_BY_ESTABLISHMENT":
                return "#FFA3A3"
            case "CANCELED_BY_USER":
                return "#FFA3A3"
            default:
                return "#fff"
        }
    }


    cancelPriceChangeActiveOrder() {
        var activeOrder = this.state.activeOrder
        activeOrder.payment_info.final_amount = 0
        activeOrder.payment_info.reason_changes = ""
        this.setState({ activeOrder, activeEditingPrice: false })
    }


    refreshData() {
        this.setState({ refreshingReceiptsList: true, showLoadMoreReceipts: true })
        this.context.getInitialReceipts(() => this.setState({ refreshingReceiptsList: false }, () => this.showUpdatedMessage()))
    }

    renderReceiptsHeader() {
        return (
            <View style={{ width: "100%", backgroundColor: "#fff" }}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", paddingTop: 20, }}>
                    <View style={{ width: "60%", }}>
                        <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
                            Pedidos
                        </Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", marginLeft: 20 }}>
                            Los pedidos se actualizan de forma automática cada 5 minutos.
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={() => this.refreshData()}
                        onLongPress={() => this.refreshData()}
                        style={{ width: "20%", borderRadius: 10, marginRight: 20, backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center", alignSelf: "center" }}>

                        <Text style={{ paddingVertical: 15, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                            Actualizar
                        </Text>

                    </TouchableOpacity>
                </View>
                <View style={{ height: 20 }} />


                <View style={{ height: 2, width: "100%", backgroundColor: "#f5f5f5" }} />

                <View style={{ width: Dimensions.get("screen").width }}>

                    <View style={{ height: 10 }} />
                    <ScrollView horizontal style={{ width: Dimensions.get("screen").width, height: 70 }}>
                        {ordersStatus.map((status, index) =>
                            <TouchableOpacity
                                onPress={() => this.setState({ activeStatus: orderStatusReferences[status], activeStatusSubTab: "ALL" })}
                                onLongPress={() => this.setState({ activeStatus: orderStatusReferences[status], activeStatusSubTab: "ALL" })}
                                style={{ height: 60, alignItems: "center", justifyContent: "center", backgroundColor: this.state.activeStatus == orderStatusReferences[status] ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: this.state.activeStatus == orderStatusReferences[status] ? "600" : "400", fontSize: "1.1rem", textAlign: "center" }}>
                                    {status}  ({this.context.activeReceipts[orderStatusReferences[status]] ? this.context.activeReceipts[orderStatusReferences[status]]["ALL"].length : 0})
                                </Text>
                            </TouchableOpacity>
                        )}
                    </ScrollView>
                    <View style={{ position: "absolute", bottom: 0, height: 5, width: "100%", backgroundColor: "#ededed" }} />

                </View>
                {this.generateSubBar()}
                {this.context.activeReceipts[this.state.activeStatus][this.state.activeStatusSubTab] && this.context.activeReceipts[this.state.activeStatus][this.state.activeStatusSubTab].length > 0 &&
                    <View style={{ backgroundColor: "#fff", width: "100%", flexDirection: "row", marginTop: 5 }}>
                        <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                            <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                                Núm. pedido
                            </Text>
                        </View>
                        <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                            <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                                Tipo
                            </Text>
                        </View>
                        <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>

                            <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                                Nombre cliente
                            </Text>
                        </View>

                        <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>

                            <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                                Entregar
                            </Text>
                        </View>
                        <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>

                            <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                                Estado
                            </Text>
                        </View>
                    </View>
                }
                <View style={{ height: 5 }} />
                <View style={{ height: 5, width: "100%", backgroundColor: "#f5f5f5" }} />
            </View>
        )
    }



    
    renderFlatListReceipt(order) {
        const receipt = order.item

        return (
            <TouchableOpacity
                onPress={() => this.setState({ activeOrderIndex: order.index, activeOrder: receipt, activeOrderInitial: JSON.parse(JSON.stringify(receipt)), activeEditingPrice: receipt.payment_info.final_amount != 0 })}
                onLongPress={() => this.setState({ activeOrderIndex: order.index, activeOrder: receipt, activeOrderInitial: JSON.parse(JSON.stringify(receipt)), activeEditingPrice: receipt.payment_info.final_amount != 0 })}
                style={{ backgroundColor: "#fff", width: "100%", flexDirection: "row" }}>
                <View style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                        {receipt.display_id}
                    </Text>
                </View>
                <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                    {
                        receipt.order_type == "DELIVERY" ?

                            <MdDeliveryDining size="2em" />
                            :
                            <MdStoreMallDirectory size="2em" />
                    }
                </View>
                <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                        {receipt.client_info.name}
                    </Text>
                </View>

                <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingVertical: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                        {this.generatePickUpDateTextList(receipt)}
                    </Text>
                </View>
                <View style={{ width: "25%", justifyContent: "center", alignItems: "center" }}>
                    <View style={{ backgroundColor: this.generateStatusColorList(receipt.current_state), borderRadius: 5 }}>
                        <Text numberOfLines={1} style={{ paddingHorizontal: 10, paddingVertical: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center" }}>
                            {this.manageOrderStatusTextList(receipt)}
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    renderNoReceipts() {
        return (
            <View style={{ width: "95%" }}>

                <View style={{ height: 50 }} />

                <View style={{ width: "90%", backgroundColor: "#fff", alignSelf: "center" }}>
                    <View style={{ height: 20 }} />
                    <Text style={{ fontSize: 16, fontWeight: "700", paddingHorizontal: 20 }}>
                        {referencesEmptyListsTitles[this.state.activeStatus][this.state.activeStatusSubTab]}
                    </Text>
                    <View style={{ height: 10 }} />
                    <Text style={{ fontWeight: "400", paddingHorizontal: 20 }}>
                        {referencesEmptyListsSubtitles[this.state.activeStatus][this.state.activeStatusSubTab]}
                    </Text>
                    <View style={{ height: 20 }} />
                </View>

            </View>
        )
    }

    renderFooter() {
        return (
            <View style={{ justifyContent: "center", alignItems: "center" }}>

                <View>
                    <View style={{ height: 50 }} />
                    <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "center", }}>
                        Has llegado al final :)
                    </Text>
                    <View style={{ height: 50 }} />
                </View>


            </View>
        )
    }

    checkIfCanChangePrice() {

        switch (this.state.activeOrder.current_state) {
            case "AWAITING_CONFIRMATION":
                return true
            case "PENDING_PREPARATION":
                return true
            case "PREPARING":
                return true
            case "READY_FOR_SENDING":
                return false
            case "READY_FOR_PICK_UP":
                return false
            case "DELIVERING":
                return false
            case "RECEIVED":
                return false
            case "PICKED_UP":
                return false
            case "CANCELED_BY_ESTABLISHMENT":
                return false
            case "CANCELED_BY_USER":
                return false
            default:
                return false
        }

    }


    render() {

        return (
            <View showsVerticalScrollIndicator={false} style={{ zIndex: -1, width: "95%", height: window.innerHeight, backgroundColor: "#f5f5f5" }}>
                {this.context.haveReceipts && this.context.establishmentInfo && this.context.establishmentInfo.accept_orders &&
                    <FlatList
                        showsVerticalScrollIndicator={true}
                        refreshing={this.state.refreshingReceiptsList}
                        onRefresh={() => this.refreshData()}
                        style={{ width: "100%", }}
                        data={this.context.activeReceipts[this.state.activeStatus] ? this.context.activeReceipts[this.state.activeStatus][this.state.activeStatusSubTab] : 0}
                        renderItem={(item, index) => this.renderFlatListReceipt(item)}
                        keyExtractor={item => item.id}
                        ListEmptyComponent={this.renderNoReceipts}
                        ItemSeparatorComponent={() => <View style={{ height: 5 }} />}
                        ListHeaderComponent={this.renderReceiptsHeader}
                        ListFooterComponent={this.renderFooter}
                    />
                }
                {
                    !this.context.haveReceipts && this.context.establishmentInfo && this.context.establishmentInfo.accept_orders &&
                    <View style={{ width: "95%", alignSelf: "center" }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", paddingTop: 20, }}>
                    <View style={{ width: "60%", }}>
                        <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
                            Pedidos
                        </Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", marginLeft: 20 }}>
                            Los pedidos se actualizan de forma automática cada 5 minutos.
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={() => this.refreshData()}
                        onLongPress={() => this.refreshData()}
                        style={{ width: "20%", borderRadius: 10, marginRight: 20, backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center", alignSelf: "center" }}>

                        <Text style={{ paddingVertical: 15, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                            Actualizar
                        </Text>

                    </TouchableOpacity>
                </View>
                        <View style={{ height: 50 }} />

                        <View style={{ width: "90%", backgroundColor: "#fff", alignSelf: "center" }}>
                            <View style={{ height: 20 }} />
                            <Text style={{ fontSize: 16, fontWeight: "700", paddingHorizontal: 20 }}>
                                Aún no has recibido ningún pedido
                            </Text>
                            <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "400", paddingHorizontal: 20 }}>
                                Cúando un cliente realice un pedido, aparecerá a aquí. Desde esta pantalla podrás actualizar el estado de tus pedidos y informar a los clientes en tiempo real de las actualizaciones de sus pedidos
                            </Text>
                            <View style={{ height: 20 }} />
                        </View>

                    </View>
                }


                {this.context.establishmentInfo && !this.context.establishmentInfo.accept_orders &&
                    <View>

                        <View style={{ width: "95%" }}>

                            <View style={{ height: 20 }} />
                            <Text style={{ alignSelf: "flex-start", textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
                                Pedidos
                            </Text>
                            <View style={{ height: 50 }} />

                            <View style={{ height: 50 }} />
                            <View style={{ width: "90%", backgroundColor: "#fff", alignSelf: "center" }}>
                                <View style={{ height: 20 }} />
                                <Text style={{ fontSize: 16, fontWeight: "700", paddingHorizontal: 20 }}>
                                    No tienes la opción de pedidos activada
                                </Text>
                                <View style={{ height: 10 }} />
                                <Text style={{ fontWeight: "400", paddingHorizontal: 20 }}>
                                    Para poder empezar a aceptar pedidos, activa la opción de "Aceptar pedidos por la aplicación" dentro de la pestaña de "Servicios" de la sección "Perfil"
                                </Text>
                                <View style={{ height: 20 }} />
                            </View>
                        </View>
                    </View>

                }

                {
                    this.state.activeOrder &&
                    <TouchableOpacity onPress={() => this.setState({ activeOrder: null })} style={{ overflowY: "scroll", position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
                        <ScrollView showsVerticalScrollIndicator contentContainerStyle={{ paddingVertical: 50 }}>
                            <TouchableOpacity activeOpacity={1} style={{ backgroundColor: "#fff", width: "95%", alignSelf: "center", borderRadius: 10, }}>
                                <View style={{ flexDirection: "row", width: "100%" }}>
                                    <View style={{ width: "70%" }}>
                                        <View style={{ height: 20 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 32, textAlign: "left", paddingHorizontal: 20 }}>
                                            Pedido: {this.state.activeOrder.display_id}
                                        </Text>
                                        <View style={{ height: 20 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                            Creado: {this.generatePlacedDateTextList(this.state.activeOrder)}
                                        </Text>
                                        <View style={{ height: 10 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                            Para: {this.generatePickUpDateTextList(this.state.activeOrder)}
                                        </Text>

                                        <View>
                                            <View style={{ height: 20 }} />
                                            <View style={{ height: 1, width: "95%", backgroundColor: "#EDEDED", alignSelf: "center" }} />
                                            <View style={{ height: 20 }} />
                                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 26, textAlign: "left", paddingHorizontal: 20 }}>
                                                Tipo de pedido
                                            </Text>
                                            <View style={{ height: 20 }} />
                                            <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                                                {
                                                    this.state.activeOrder.order_type == "DELIVERY" ?

                                                        <MdDeliveryDining style={{ marginLeft: 20 }} size="2em" />
                                                        :
                                                        <MdStoreMallDirectory style={{ marginLeft: 20 }} size="2em" />
                                                }
                                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 10 }}>
                                                    {this.state.activeOrder.order_type == "DELIVERY" ? `Entrega a domicilio (Enviar a: ${this.state.activeOrder.client_info.direction}) ` : "Recogida en tienda"}
                                                </Text>
                                            </View>
                                        </View>

                                        {!!this.state.activeOrder.picked_at &&
                                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                                Entregado: {this.generatePickUpDateTextList(this.state.activeOrder)}
                                            </Text>
                                        }
                                        <View style={{ height: 20 }} />
                                        <View style={{ height: 1, width: "95%", backgroundColor: "#EDEDED", alignSelf: "center" }} />
                                        <View style={{ height: 20 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 26, textAlign: "left", paddingHorizontal: 20 }}>
                                            Detalles
                                        </Text>

                                        {this.generateOrderArticles()}
                                        <View style={{ height: 1, width: "95%", backgroundColor: "#EDEDED", alignSelf: "center" }} />
                                        <View style={{ height: 20 }} />
                                        <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                                            <Text style={{ textDecorationLine: "none", color: this.state.activeOrder.payment_info.final_amount > 0 ? "gray" : "#000", fontWeight: "500", fontSize: 20, textAlign: "left", paddingHorizontal: 20 }}>
                                                Precio total : {this.state.activeOrder.payment_info.initial_amount.toFixed(2)} €
                                            </Text>
                                            {!this.state.activeEditingPrice && this.checkIfCanChangePrice() &&
                                                <TouchableOpacity
                                                    onPress={() => this.setState({ activeEditingPrice: true })}
                                                    onLongPress={() => this.setState({ activeEditingPrice: true })}
                                                    style={{ borderRadius: 10, marginLeft: 20, backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>

                                                    <Text style={{ paddingVertical: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                                        Aplicar cambio de precio
                                                    </Text>

                                                </TouchableOpacity>
                                            }

                                            {this.state.activeEditingPrice &&
                                                <TouchableOpacity
                                                    onPress={() => this.cancelPriceChangeActiveOrder()}
                                                    onLongPress={() => this.cancelPriceChangeActiveOrder()}
                                                    style={{ borderRadius: 10, marginLeft: 20, backgroundColor: "red", justifyContent: "center", alignItems: "center" }}>

                                                    <Text style={{ paddingVertical: 10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                                        Cancelar cambio de precio
                                                    </Text>

                                                </TouchableOpacity>
                                            }
                                        </View>
                                        {this.state.activeEditingPrice &&
                                            <View>
                                                <View style={{ height: 20 }} />
                                                <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                                                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 20, textAlign: "left", paddingHorizontal: 20 }}>
                                                        Precio final :
                                                    </Text>
                                                    <TextInput
                                                        value={this.state.activeOrder.payment_info.final_amount}
                                                        onChangeText={t => this.updateActiveOrderFinalAmountPriceTemporal(t)}
                                                        onBlur={t => this.updateActiveOrderFinalAmountPrice(t)}
                                                        style={{ fontSize: "1rem", width: "20%", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 10 }} />
                                                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 20, textAlign: "left", paddingHorizontal: 20 }}>
                                                        €
                                                    </Text>
                                                </View>
                                                <View style={{ height: 20 }} />
                                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 20, textAlign: "left", paddingHorizontal: 20 }}>
                                                    Motivo del cambio de precio (Obligatorio)
                                                </Text>
                                                <TextInput
                                                    value={this.state.activeOrder.payment_info.reason_changes}
                                                    onChangeText={t => this.updateActiveOrderFinalAmountDescription(t)}
                                                    multiline
                                                    numberOfLines={3} placeholder={"Ej. Se nos han acabado las baguettes, te descuento el importe."} style={{ marginVertical: 10, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 10, height: 120, textAlignVertical: "top" }} />
                                                <View style={{ height: 20 }} />
                                                <Text style={{ textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                                    Se le comunicará al usuario el cambio de precio. Si vas a realizar un cambio de precio te recomendamos comunicarte primero con el cliente. Puedes cambiar el precio hasta que empieces a preparar el pedido.
                                                </Text>
                                            </View>
                                        }

                                    </View>
                                    <View style={{ width: "30%", borderLeftWidth: 2, borderLeftColor: "#EDEDED" }}>
                                        <View style={{ height: 20 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 26, textAlign: "left", paddingHorizontal: 20 }}>
                                            Información del cliente
                                        </Text>
                                        <View style={{ height: 20 }} />
                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                            Nombre: {this.state.activeOrder.client_info.name}
                                        </Text>
                                        {!!this.state.activeOrder.client_info.phone &&
                                            <View>
                                                <View style={{ height: 10 }} />
                                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 16, textAlign: "left", paddingHorizontal: 20 }}>
                                                    Teléfono: {this.state.activeOrder.client_info.phone}
                                                </Text>
                                            </View>
                                        }
                                        <View style={{ height: 20 }} />
                                        <View style={{ height: 1, width: "90%", backgroundColor: "#EDEDED", alignSelf: "center" }} />
                                        <View style={{ height: 20 }} />

                                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 26, textAlign: "left", paddingHorizontal: 20 }}>
                                            Estado del pedido
                                        </Text>
                                        <View style={{ height: 10 }} />
                                        {this.state.activeOrderInitial.current_state != "CANCELED_BY_USER" && this.state.activeOrderInitial.current_state != "CANCELED_BY_ESTABLISHMENT" &&
                                            this.generateOrdersStates().map((state, index_state) =>
                                                <TouchableOpacity
                                                    disabled={this.state.activeOrder.current_state == "CANCELED_BY_USER"}
                                                    onPress={() => this.updateActiveOrderStatus(state)}
                                                    onLongPress={() => this.updateActiveOrderStatus(state)}
                                                    style={{ flexDirection: "row", width: "100%", marginTop: 10 }}>
                                                    <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                                                        <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "500", fontSize: 16 }}>
                                                            {this.manageOrderStatusTextDetails(state)}
                                                        </Text>
                                                    </View>
                                                    <View style={{ width: "20%", justifyContent: "center", alignItems: "center" }}>
                                                        <View style={{ width: 30, height: 30, borderRadius: 100, borderWidth: this.state.activeOrder.current_state == state ? 0 : 1, backgroundColor: this.state.activeOrder.current_state == state ? "#68dc91" : "#fff", justifyContent: "center", alignItems: "center" }}>
                                                            {this.state.activeOrder.current_state == state &&
                                                                <IoIosCheckmark size={30} />
                                                            }
                                                        </View>
                                                    </View>
                                                </TouchableOpacity>)
                                        }
                                        {
                                            this.state.activeOrderInitial.current_state == "CANCELED_BY_USER" &&
                                            <View>
                                                <View style={{ height: 20 }} />
                                                <View style={{ width: "90%", alignSelf: "center", backgroundColor: "#EDEDED", borderRadius: 5, paddingVertical: 10 }}>
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "600", fontSize: 18 }}>
                                                        El usuario ha cancelado el pedido
                                                    </Text>
                                                    <View style={{ height: 10 }} />
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "400", fontSize: 16 }}>
                                                        Por este motivo, no puedes realizar más cambios en este pedido.
                                                    </Text>

                                                </View>
                                                <View style={{ height: 20 }} />
                                            </View>
                                        }
                                        {
                                            this.state.activeOrderInitial.current_state == "CANCELED_BY_ESTABLISHMENT" &&
                                            <View>
                                                <View style={{ height: 20 }} />
                                                <View style={{ width: "90%", alignSelf: "center", backgroundColor: "#EDEDED", borderRadius: 5, paddingVertical: 10 }}>
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "600", fontSize: 18 }}>
                                                        Has cancelado este pedido.
                                                    </Text>
                                                    <View style={{ height: 10 }} />
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "400", fontSize: 16 }}>
                                                        Por este motivo, no puedes realizar más cambios en este pedido.
                                                    </Text>
                                                    <View style={{ height: 10 }} />
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "400", fontSize: 14 }}>
                                                        Motivo de la cancelación: {this.state.activeOrderInitial.payment_info.reason_changes}
                                                    </Text>
                                                </View>
                                                <View style={{ height: 20 }} />
                                            </View>
                                        }


                                        {this.state.activeOrder.current_state == "CANCELED_BY_USER" &&
                                            <View

                                                style={{ flexDirection: "row", width: "100%", marginTop: 10 }}>
                                                <View style={{ width: "80%", justifyContent: "center", alignItems: "center" }}>
                                                    <Text style={{ paddingHorizontal: 20, textAlign: "left", width: "100%", fontWeight: "500", fontSize: 16 }}>
                                                        {this.manageOrderStatusTextDetails(this.state.activeOrder.current_state)}
                                                    </Text>
                                                </View>
                                                <View style={{ width: "20%", justifyContent: "center", alignItems: "center" }}>
                                                    <View style={{ width: 30, height: 30, borderRadius: 100, backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>

                                                        <IoIosCheckmark size={30} />

                                                    </View>
                                                </View>
                                            </View>
                                        }
                                        <View style={{ height: 20 }} />
                                        {
                                            this.state.activeOrder.current_state == "CANCELED_BY_ESTABLISHMENT" && this.state.activeOrderInitial.current_state != "CANCELED_BY_ESTABLISHMENT" && this.state.activeOrderInitial.current_state != "CANCELED_BY_USER" &&
                                            <View>
                                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: 20, textAlign: "left", paddingHorizontal: 20 }}>
                                                    Motivo de la cancelación (Obligatorio)
                                                </Text>
                                                <View style={{ height: 10 }} />
                                                <TextInput
                                                    onChangeText={t => this.updateActiveOrderCancellationMessage(t)}
                                                    multiline
                                                    numberOfLines={3} placeholder={"Ej. El pedido se ha cancelado por que ese día es festivo."} style={{ marginVertical: 10, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 10, height: 120, textAlignVertical: "top" }} />
                                            </View>
                                        }
                                        <View style={{ height: 1, width: "90%", backgroundColor: "#EDEDED", alignSelf: "center" }} />
                                        <View style={{ height: 20 }} />

                                    </View>

                                </View>
                                <View style={{ height: 50 }} />
                                {this.checkActiveOrderHaveChanges() && this.state.activeOrderInitial.current_state != "CANCELED_BY_USER" && this.state.activeOrderInitial.current_state != "CANCELED_BY_ESTABLISHMENT" &&
                                    <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: 50, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                        <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>

                                            <TouchableOpacity
                                                disabled={this.checkSaveActiveOrderChanges()}
                                                onPress={() => this.updateOrder()}
                                                onLongPress={() => this.updateOrder()}
                                                style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.checkSaveActiveOrderChanges() ? "#EDEDED" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                                                    Guardar cambios
                                                </Text>

                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                }
                                <TouchableOpacity
                                    onPress={() => this.setState({ activeOrder: null })}
                                    onLongPress={() => this.setState({ activeOrder: null })}
                                    style={{ position: "absolute", top: -30, right: -30, width: 60, height: 60, backgroundColor: "#fff", borderRadius: 100, justifyContent: "center", alignItems: "center", borderWidth: 2, borderColor: "#EDEDED" }}>
                                    <IoIosClose size="2.5em" />
                                </TouchableOpacity>
                            </TouchableOpacity>
                        </ScrollView>
                    </TouchableOpacity>
                }

            </View>
        )
    }
}

export default withSnackbar(App, {
    position: 'top-center',
    style: {
        zIndex: 1100,
        backgroundColor: '#68dc91',
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
        color: '#000',
        borderRadius: 10,
        psddingHorizontal: 20,
        fontSize: '1rem',
        textAlign: 'left',
    },
    closeStyle: {
        color: '#000',

    },
})