import React, { useEffect, useState, useContext } from 'react';
import restaurantLogin from './images/login.jpg';
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { Text, View, TouchableOpacity, Image, TextInput } from 'react-native';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { auth } from './firebase';
import { Auth } from "./AuthContext";
import GeneralContext from './Provider';
import { useNavigate } from 'react-router-dom';

export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <Login
      translate={context.translate}
      {...props}
    />
  );
};

function Login(props) {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");

  const [signup, setsignup] = useState(false);
  const { usuario, updateUser, setAuthFunction } = useContext(Auth);

  if (usuario)
    navigate("/manage", { state: { try: "" } });

  async function login(email, password) {
    const auth = getAuth();


    //setAuthFunction(auth);
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        updateUser(user)
        //update provider
        setError(false)
        navigate("/manage", { state: { try: "" } });

        // ...
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(true)

      });
  }


  return (
    <View style={{ width: window.innerWidth, height: window.innerHeight, backgroundColor: "#fff", flexDirection: "row-reverse" }}>

      <View style={{ width: "35%", height: "100%", backgroundColor: "#000", }}>
      <View style={{height:30}}/>
      <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "left", fontSize: 40, fontStyle: "italic",paddingHorizontal:20 }}>
            {props.translate("Login.sign_in").toUpperCase()}
        </Text>
        <View style={{height:10}}/>
        <Text style={{ marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
          {props.translate("access.sign_in")}
        </Text>

        <Text style={{ marginTop: 30, color: "#fff", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
          {props.translate("email.sign_in")}
        </Text>
        <TextInput
        autoFocus
          onChangeText={(t) => setEmail(t)}
          numberOfLines={1} placeholder={props.translate("example_email.sign_in")} style={{color:"#fff", marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "rgba(255,255,255,0.2)", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

        <Text style={{ marginTop: window.innerHeight * 0.025, color: "#fff", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
          {props.translate("password.sign_in")}
        </Text>
        <TextInput
          selectTextOnFocus
          secureTextEntry={true}
          textContentType="password"
          onSubmitEditing={() => login(email, password)}
          onChangeText={(t) => setPassword(t)}
          numberOfLines={1} placeholder={"Contraseña"} style={{ color:"#fff", marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "rgba(255,255,255,0.2)", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
        {error &&
          <Text style={{ marginTop: 10, color: "red", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 30, paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
            {props.translate("wrong.sign_in")}
          </Text>
        }

        {false &&
          <TouchableOpacity onPress={() => props.openSnackbar('Tu plato se ha creado con éxito. Puedes editar cúando quieras el plato y los cambios se reflejarán a tiempo real en la carta.')} style={{ alignSelf: "center", marginBottom: window.innerHeight * 0.03, alignItems: "center", backgroundColor: "#fff", width: "90%" }}>
            <TouchableOpacity to="/forgot" style={{ height: "100%", width: "100%", textDecoration: "none", color: "#000" }} >

              <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

                <Text style={{ textDecorationLine: "underline", color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
                  {props.translate("forgot.sign_in")}
                </Text>

              </View> </TouchableOpacity>
          </TouchableOpacity>
        }



        <TouchableOpacity
        disabled={!email || !password}
          onPress={() => login(email, password)}


          style={{ marginTop: 50, borderRadius: 0, alignSelf: "center", marginBottom: window.innerHeight * 0.03, alignItems: "center", backgroundColor:(!password || !email)?"rgba(255,255,255,0.3)":"#68DC91", width: "90%" }}>
          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

            <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: 20, paddingVertical: window.innerHeight * 0.025, textAlign: "center", width: "100%" }}>
              {props.translate("sign_in.sign_in")}
            </Text>
          </View>


        </TouchableOpacity>



      </View>
      <View style={{ width: "65%", height: "100%", backgroundColor: "#f5f5f5" }}>
        <Image source={restaurantLogin} style={{ width: window.innerWidth * 0.65, height: window.innerHeight, zIndex: 0, opacity: 1 }} resizeMode="cover" />
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0)", justifyContent: "center", alignItems: "center" }}>

        </div>
        <Link to="/" style={{ textDecoration: "none", color: "#000", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>

          <View style={{ position: "absolute", top: 10, left: 10, borderRadius: 5, alignSelf: "center", alignItems: "center", paddingHorizontal: 20 }}>

            <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "2rem", paddingVertical: window.innerHeight * 0.025, textAlign: "center", width: "100%" }}>
              {props.translate("Buyness.sign_in")}
            </Text>
          </View>
        </Link>


      </View>



    </View>
  )

}