import * as React from 'react';
import MenuBrowser from './MenuBrowser';
import MenuPhone from './MenuPhone';
const basicMenu = [
  {
    title: "La carta",
    index:0,
    data: [{
      title: "Glaç bowl",
      description: "Salmó marinat, alvocat, edamame, llavors, mezclum, bulgur, tomàquets xerris i salsa de mango i llima",
      price: 11.50,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true,
      vegetarian: true,
      vegan: true,
      no_gluten: true,
      unity: true,
      img:"as"

    },
    {
      title: "Teriyaki bowl",
      description: "Pollastre teriyaki, mezclum, edamame, ceba crispy, llavors, cacauets, shitakes i salsa teriyaki",
      price: 8.50,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Amanida de quinoa",
      description: "Mezclum, algues, tomàquets xerris, mango, ceba crispy, formatge de cabra i xia",
      price: 8.50,
      recommended: false,
      offer: true,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Healthy toast",
      description: "Pa d’espelta, salmó, alvocat, ou poche i tomàquets xerris",
      price: 11,
      recommended: false,
      offer: true,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Carpaccio de vedella",
      description: "amb salsa de soja, sèsam i tocs de wasabi i gingebre, maionesa de Kimchi i torrades de focaccia casolana",
      price: 12,
      recommended: false,
      offer: true,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Patates amb tòfona",
      description: "i parmesà",
      price: 5.20,
      recommended: false,
      offer: true,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Trumfus menorquins",
      description: "Patates amb sofregit de sobrassada , formatge gouda i un toc de mel i mostassa",
      price: 4.70,
      recommended: false,
      offer: true,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Croquetes casolanes",
      description: "de pernil ibèric (1 unitat)",
      price: 1.50,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Foie mi cuit",
      description: "amb timbal de poma, ceba negra i romaní",
      price: 12.50,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Taula de pernil de gla 'Guijuelo'",
      description: "amb pa torrat amb tomàquet",
      price: 9.00,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Selecció de formatges",
      description: "amb melmelades casolanes",
      price: 13.70,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Calamarsets a l’andalusa",
      description: "amb escuma de maionesa i llima",
      price: 9.50,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Gyozas de verdures",
      description: "i pollastre",
      price: 9.40,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Finguers de pollastre tandoori",
      description: "amb salsa de curry",
      price: 8.20,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }
    ]
  },
  {
    title: "Burgers",
    index:1,
    data: [{
      title: "Glaç",
      description: "Hamburguesa de pollastre, tomàquet, ceba, formatge de cabra i maionesa de mel i mostassa",
      price: 10.80,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Veggie",
      description: "Hamburguesa de saïta, enciam, tomàquet, albergínia fumada, salsa de iogurt i mostassa i ceba crispi",
      price: 11.80,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Ibèrica",
      description: "Hamburguesa de porc ibèrica, ruca, pernil ibèric,ceba i piquillos confitats",
      price: 11.80,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Eco",
      description: "Hamburguesa de vedella ecològica, tomàquet, enciam, formatge gouda, maionesa perrins i ceba crispy",
      price: 11.50,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Serrateix",
      description: "Hamburguesa de peus de porc, formatge de cabra, enciam, tomàquet, mostassa antiga i ceba negra",
      price: 12.50,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }
    ]
  },
  {
    title: "Sugerencias",
    index:2,
    data: [{
      title: "Yakisoba",
      description: "amb sípia i verduretes",
      price: 9.80,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true,
      people: 3,

    },
    {
      title: "Tomahawk",
      description: "de 750 gr. a la brasa",
      price: 16.50,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Pop a la gallega",
      description: "enreixat de patata, pop marinat amb kimchi, la seva maionesa i cotna de pimentó de la Vera",
      price: 16.80,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Ploma ibèrica a la brasa",
      description: "amb chips de remolatxa i crema de iogurt i mostassa",
      price: 14.90,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }
    ]
  },
  {
    title: "Postres",
    index:3,
    data: [{
      title: "Cheesecake",
      description: "cremós de formatge, fruits del bosc, Mòdena i galetes speculoos",
      price: 5.00,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: false

    },
    {
      title: "Coulant",
      description: "amb gelat de vainilla",
      price: 5.50,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Gelats Sandro Desi",
      description: "Consulta els gustos disponibles",
      price: 6.20,
      options: [
        {
          title: "Gustos dels gelats",
          content:
            [{
              title: "Festucs",
              avaliable: false
            },
            {
              title: "Trufa amb avellanes garapinyades",
              avaliable: true
            },
            {
              title: "Cítrics i verdures",
              avaliable: true
            },
              ,
            {
              title: "Te verd matxa",
              avaliable: true
            },
              ,
            {
              title: "Aove amb cacauets torrats",
              avaliable: true
            }]
        }],
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: false

    },
    {
      title: "Pecat de xocolata",
      description: "textures de xocolates amb pa, oli i sal",
      price: 5.00,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Pastís casolà de pastanaga",
      description: "amb caramel de gingebre i cremós de taronja",
      price: 5.00,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    ]
  },

  {
    title: "Cava",
    index:4,
    data: [{
      title: "Miquel Pons Brut Nature (D.O. CAVA)",
      description: "Macabeu, Xarel·lo i Parellada",
      price: 14.60,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Núria Brut (D.O. CAVA)",
      description: "Xarel·lo i Macabeu",
      price: 15.90,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Juve y Camps Reserva de la familia (D.O. CAVA)",
      description: "Macabeu, Xarel·lo, Parellada i Chardonnay",
      price: 21.40,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Montargull xarel·lo barrica brut nature (D.O. CAVA)",
      description: "Xarel·lo, Macabeu, Xarel·lo en barrica i Parellada",
      price: 17.90,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    ]
  },
  {
    title: "Cocktails clásicos",
    index:5,
    data: [{
      title: "Dry Martini",
      description: "Gin i vermut sec",
      price: 8,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Daiquiri",
      description: "Rom, suc de llimona i sucre",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Margarita",
      description: "Tequila, triple sec i suc de llimona",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Manhattan",
      description: "Bourbon, vermut negre i angostura",
      price: 9,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Cosmopolitan",
      description: "Vodka, triple sec i suc de nabius",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Sidecar",
      description: "Brandy, triple sec i suc de llimona",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Gin fizz",
      description: " Gin, sucre, suc de llimona i soda",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Mojito",
      description: "Rom, menta, suc de llimona i sucre",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Caipirinha",
      description: "Cachaça, llima i sucre",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Caipiroska",
      description: "Vodka, lima i sucre",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Piña colada",
      description: "Rom, llet de coco, llet i suc de pinya",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Bloody Mary",
      description: "Vodka, suc de tomàquet, Tabasco i salsa perrins",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Tequila sunrise",
      description: "Tequila, suc de tronja i grosella",
      price: 7,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Sex on the beach",
      description: "Vodka, licor de préssec, suc de navius i taronja",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "San francisco",
      description: "Vodka, suc de taronja, pinya i préssec",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Gimlet",
      description: "Gin i llima rose’s",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Whisky sour",
      description: "Bourbon, suc de llimona i sucre",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Pisco sour",
      description: "Pisco, suc de llimona, sucre i clara d’ou",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Alexander",
      description: "Brandy, licor cacau i crema de llet",
      price: 7,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Grasshopper",
      description: "Licor de menta, licor cacau i crema de llet",
      price: 7,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Mary Pickford",
      description: "Rom blanc, suc de pinya, Maraschino i granadina",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Old fashioned",
      description: "Bourbon, sucre i angostura",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Sazerac",
      description: "Conyac / Rye, sucre i Peychaud",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    }, {
      title: "Negroni",
      description: "Gin, Campari i vermut negr",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    ]
  },
  {
    title: "Mocktails",
    index:6,
    data: [{
      title: "San francisco",
      description: "Suc de taronja, pinya i préssec",
      price: 6,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true,
      alcoholFree: true

    },
    {
      title: "Virgin Colada",
      description: "Llet, llet de coco i suc de pinya",
      price: 6,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Caipirinha sense alcohol",
      description: "Llima, sucre i Ginger Ale",
      price: 6,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Ginger Mango",
      description: "Suc de mango, xarop de canyella i Ginger beer",
      price: 6.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Benzi virgin",
      description: "Blue tropic, xarop de vainilla i suc de llimona",
      price: 6.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Little candy virgin",
      description: "Granadina, xarop de vainilla i suc de llimona",
      price: 6.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    ]
  },

  {
    title: "Vodka",
    index:7,
    data: [{
      title: "Absolut",
      description: "",
      price: 7,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Axelvar",
      description: "",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Beluga",
      description: "",
      price: 9,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    ]
  },
  {
    title: "Rom",
    index:8,
    data: [{
      title: "Bacardi",
      description: "",
      price: 7.5,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Capitan Morgan",
      description: "",
      price: 7,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Don Papa",
      description: "",
      price: 10,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Havana Club 3",
      description: "",
      price: 6.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Habana Club 7",
      description: "",
      price: 7.5,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Rum Bar Silver",
      description: "",
      price: 10,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Santísima Trinidad 7",
      description: "",
      price: 8,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Santísima Trinidad 15",
      description: "",
      price: 12,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Zacapa 23",
      description: "",
      price: 9,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    ]
  },
  {
    title: "Whisky",
    index:9,
    data: [{
      title: "Auchentoshan",
      description: "",
      price: 9.99,
      recommended: false,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Blended Ballantine’s",
      description: "",
      price: 13.00,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Blended Gold Label Reserve",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Blended Red Label",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    {
      title: "Bourbon Bulleit",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    {
      title: "Bourbon Four Roses",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },

    {
      title: "Buffalo trace",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Bourbon Maker’s Mark",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Crown Royal",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Glenfiddich 12",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "JB",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "S.M. Ardbeg 10",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "S.M. Cardhu 12",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "S.M. Macallan Amber",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "S.M. Scapa 2001",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Tenesse Jack Daniel’s",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Roe Coe",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10,
      avaliable: true

    },
    {
      title: "Rye Bulleit",
      description: "",
      price: 11,
      recommended: true,
      offer: false,
      offerPercentage: 10

    },

    ]
  }
]

const hours=[
  {name:"Lunes",hours:[{init:"08:00",final:"14:00"},{init:"08:00",final:"14:00"}]},
  {name:"Martes",hours:[{init:"08:00",final:"14:00"}]},
  {name:"Miércoles",hours:[{init:"08:00",final:"14:00"}]},
  {name:"Jueves",hours:[{init:"08:00",final:"14:00"}]},
  {name:"Viernes",hours:[{init:"08:00",final:"14:00"}]},
  {name:"Sábado",hours:[{init:"08:00",final:"14:00"}]},
  {name:"Domingo",hours:[{init:"08:00",final:"14:00"}]},
]

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { screenWidth: window.innerWidth };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions, false);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
}

updateWindowDimensions() {
   this.setState({ screenWidth: window.innerWidth });
}

  render() {

    return (

      <div style={{ width: "100%", height: "100%" }}>
      {
        this.state.screenWidth>760 &&
        <MenuBrowser/>
      }
      {
        this.state.screenWidth<=760 &&
        <MenuPhone/>
      }
      </div>


    )
  }
}
/** 
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%",height:"100%" }}>
        <Stick node={} position="bottom center" align="top center">
  <p>The anchor node</p>
</Stick>
          
          </View>

          
            <View style={{overflow: "scroll", boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", marginTop: 10, paddingVertical: 10, width: "65%", alignSelf: "center", borderRadius: 10 }}>
            {false &&
        <View style={{ flexDirection: "row",width:"100%",justifyContent:"flex-start",alignItems:"flex-start" }}>
        
          <View style={{ width: "70%", alignSelf: "center" }}>
            
          </View>
        </View>
          }
           
          </View>
          {false &&
            
          }
  
        </View>
*/