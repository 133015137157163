export const ca={
  "Buyness.home": "Buyness",
  "log_in.home": "Iniciar sessió",
  "digitizes.home": "Digitalitza el teu negoci",
  "searching.home": "Estem buscant connectar, de manera única i nova, els establiments amb els seus clients habituals.",
  "start_with.home": "Acabem de començar i estem buscant negocis innovadors que vulguin provar la nostra tecnologia.",
  "contact_us.home": "Contacta amb nosaltres",
  "future.home":"Descobreix el futur!",
  "believe.home": "Creiem en la importància del negoci local",
  "want.home": "Volem ajudar a digitalitzar els petits comerços i que gaudeixin de tots els avantatges i possibilitats que ofereix la tecnologia.",
  "formation.home": "Sense necessitat de formació",
  "manage.home": "Comença a gestionar la teva botiga en línia des de qualsevol dispositiu amb connexió a Internet en qüestió de minuts. A més, tenim uns vídeos explicatius per si et sorgeixin dubtes durant el procés.",
  "designed.home": "Dissenyat per al segle XXI",
  "new.home": "Una nova forma de gestionar la teva botiga en línia a una velocitat i amb una senzillesa mai vista.",
  "amazing.home": "Una experiència increïble pels teus clients",
  "improve.home": "Millora l'experiència que tenen els teus clients a l'hora de descobrir quins productes poden trobar al teu establiment. Actualitza a temps real la disponibilitat dels teus productes.",
  "adapt.home": "Dissenyat per adaptar-se al teu negoci",
  "created.home": "Hem creat una plataforma d'última tecnologia que et permetrà gestionar la teva botiga en línia amb extrema facilitat. Descobreix totes les possibilitats que ofereix Buyness unint-te a la beta.",
  "how_does_it_work.home": "Com funciona Buyness?",
  "sign_up.home": "Registra't i afegeix els teus productes a la botiga.",
  "automatically.home": "Automàticament la teva botiga apareixerà cada vegada que algú proper al teu establiment obri l'aplicació. Així de fàcil! ",
  "optional.home": "Podràs descarregar uns QR que et portaran a la teva botiga online cada cop que algun client els escanegi. Ideal per a restaurants i bars ja que poden allotjar la nostra carta a la nostra plataforma sense cap mena de complicació.",
  "why.home": "Per què nosaltres?",
  "real_time.home": "Temps real",
  "publish.home": "Publica els canvis a l'instant. Acabes de rebre un producte nou? Afegeix-ho en 1 minut. Voleu modificar el preu d'un producte? Edita-ho en 30 segons. Vols marcar un producte com a no disponible? Fes-ho a la velocitat de la llum.",
  "flexibility.home": "Flexibilitat",
  "identity.home": "Volem que cada botiga expressi la seva identitat dins de la plataforma com vulgui. Organitza els teus productes al teu gust, sense restriccions.",
  "start.home": "Comença ara",
  "price.home": "Estem començant i volem que formis part de la nostra família. Comença sense sorpreses, costos ocults ni despeses addicionals per només 19,95 €.",
  "discover.home": "Descobreix com quedarà la botiga",
  "qr.home": "Escaneja aquest QR per veure un exemple de com els teus clients veuran la teva botiga en línia. També podeu prémer el botó per accedir directament.",
  "discover_button.home": "Descobreix com quedarà la botiga",
  "doubts.home": "Tens dubtes?",
  "solve_doubts.home": "Estem aquí per resoldre tots els dubtes que et puguin sorgir sobre la nostra plataforma.",
  "name.home": "El teu nom",
  "email.home": "Correu electrònic o núm. de telèfon",
  "query.home": "Detalls de la consulta",
  "contact.home": "Ens posarem en contacte tan aviat com ens sigui possible. Si us plau revisa que totes les dades siguin correctes.",
  "send.home": "Enviar consulta",
  "Buyness_2021.home": "Buyness © 2021. Fet amb ❤ (Fase beta)︎",
  "Login.sign_in": "Login",
  "Buyness.sign_in": "Buyness",
  "access.sign_in": "Accedeix al panell d'administració",
  "email.sign_in": "Correu electrònic",
  "example_email.sign_in": "negoci@negoci.com",
  "password.sign_in": "Contrasenya",
  "forgot.sign_in":"¿Has oblidat la contrasenya?",
  "wrong.sign_in":"Nom d'usuari i/o contrasenya incorrectes. Si tens problemes per accedir-hi escriu-nos a help@urbisapp.com",
  "example_password.sign_in": "Contrasenya",
  "sign_in.sign_in": "Iniciar sessió",  
  "products.add_home": "Productes",
  "categories.add_home": "Categories",
  "menu.add_home": "Menús",
  "schedule.add_home": "Horaris",
  "see_shop.add_home": "Veure botiga",
  "add_products.add_home": "+ Afegir producte",
  "add_menu.add_home": "+ Afegir menu",
  "add_category.add_home": "+ Afegir categoría",
  "name_product.add_home": "Nom del producte",
  "price.add_home": "Preu",
  "availability.add_home": "Disponibilitat",
  "available.add_home": "Disponible",
  "buyness.add_home": "Buyness",
  "admin.add_home": "Administra el teu establiment",
  "my_establishment.add_home": "El meu establiment",
  "qrs.add_home": "QRs",
  "profile.add_home": "Perfil",
  "drag.add_home": "Podeu arrossegar per canviar l'ordre de les categories.",
  "name_categories.add_home": "Nom de la categoria",
  "numbers_items.add_home": "Núm. de artículos",
  "visibility.add_home": "visibilitat",
  "visible.add_home": "visible",
  "drag_menu.add_home": "Podeu arrossegar per canviar l'ordre dels menús.",
  "name_menu.add_home": "Nom del menú",
  "no_menu.add_home": "Sense menús",
  "no_products.add_home": "Sense productes",
  "no_categories.add_home": "Sense categories",
  "press_to_edit.add_home": "Fes clic per editar els horaris d'un dels dies",
  "day.add_home": "Dia",
  "close.add_home": "Tancat",
  "horario.add_home": "Horari",
  "Monday.add_home": "Dilluns",
  "tuesday.add_home": "Dimarts",
  "wednesday.add_home": "Dimecres",
  "thursday.add_home": "Dijous",
  "friday.add_home": "Divendres",
  "saturday.add_home": "Dissabte",
  "sunday.add_home": "Diumenge",
  "edit_product.edit_prodict": "Editar producte",
  "new_product.add_product": "Nou producte",
  "add_image.add_product": "Arrossega una imatge o polsa per pujar-ne una des del teu ordinador (opcional)",
  "name.add_product": "Nom (Obligatori)",
  "example_name.add_product": "Hamburguesa amb formatge",
  "description.add_product": "Descripció",
  "example_description.add_product": "Hamburguesa de 200g de vedella amb mozzarella",
  "price.add_product": "Preu en €",
  "example_price.add_product": "6,45",
  "available.add_product": "Aquest article està disponible?",
  "yes.add_product": "Sí",
  "no.add_product": "No",
  "options.add_product": "Opcions",
  "price_kg.add_product": "Preu per Kg",
  "Categories.add_product": "Categories",
  "create_product.add_product": "Crear producte",
  "write_title.add_product": "Escriu un títol per poder crear el producte",
  "edit.edit_categories": "Editar categoria",
  "new.add_categories": "Nova categoria",
  "title.add_categories": "Títol",
  "example_title.add_categories": "Hamburgueses",
  "other_info.add_categories": "Informació addicional",
  "example_other_info.add_categories": "Totes les hamburgueses van acompanyades de patates i amanida",
  "visible.add_categories": "Aquesta categoria ja estarà visible?",
  "yes.add_categories": "Sí",
  "no.add_categories": "No",
  "select_items.add_categories": "Articles seleccionats:",
  "create.add_categories": "Crear categoria",
  "write_title.add_categories": "Escriu un títol per poder crear la categoria",
  "edit_menu.edit_menu": "Editar menús",
  "add_menu.add_menu": "Nou menú",
  "title.add_menu": "Títol",
  "exapmle_title.add_menu": "Menú diari",
  "other_info.add_menu": "Informació addicional",
  "example_other_info.add_menu": "El preu inclou un refresc o ampolla d'aigua gran",
  "visible.add_menu": "Aquest menú estarà visible?",
  "yes.add_menu": "Sí",
  "no.add_menu": "no",
  "first.add_menu": "Primers",
  "second.add_menu": "Segons",
  "create_menu.add_menu": "Crear menú",
  "write_title.add_menu": "Escriu un títol per poder crear el menú",
  "price.add_menu": "Preu en €",
  "example_price.add_menu": "12,50€",
  "edit_hour.add_schedule": "Editar horari de",

  "how_complete.add_schedule": "Com complet aquesta informació?",
  "info_how_complete.add_schedule": "El teu establiment pot tenir fins a tres horaris diferents per a un mateix dia. Per exemple si el teu establiment només obre de 08:00 ha 16:00 h omple només el primer horari. Si el teu establiment fa un horari de 09:00 ha 14:00 hi de 15:00 ha 20:00 omple el primer horari amb 09:00 hi 14:00 hi el segon amb 15:00 hi 20:00 h. Si no omples cap, es mostrará com a tancat.",
  "schedule_1.add_schedule": "Horari 1",
  "schedule_2.add_schedule": "Horari 2",
  "schedule_3.add_schedule": "Horari 3",
  "introduce_hour.add_schedule": "Introduir una hora",
  "save.add_schedule": "Desar canvis",
  "complete_data_1.add_schedule": "Completa les dades de l´horari 1",
  "complete_data_2.add_schedule": "Completa les dades de l´horari 2",
  "complete_data_3.add_schedule": "Completa les dades de l´horari 3",
  "qr.download_qr": "QR",
  "message.download_qr": "Descarrega el model de QR que millor encaixi amb el teu negoci.",
  "tecnology.download_qr": "amb la tecnologia buyness",
  "download.download_qr": "Descarregar",
  "scan.download_qr": "ESCANEJA'M!",
  "explore.download_qr": "Explora els nostres productes!",
  "profile.profile": "Perfil",
  "photo.profile": "Foto de l'establiment",
  "add_photo.profile": "Arrossega una imatge o polsa per pujar-ne una des del teu ordinador (opcional)",
  "name.profile": "Nom de l'establiment",
  "example_name.profile": "Restaurant gourmet",
  "change_photo.profile":"Canviar imatge",
  "delete.profile":"Eliminar",
  "city.profile": "Ciutat",
  "example_city.profile": "Manresa",
  "adress.profile": "Direcció",
  "example_adress.profile": "Carre Bruc, 115",
  "email.profile": "Correu electrònic",
  "example_email.profile": "restaurantgourmet@mail.com",
  "tel.profile": "Número de telèfon",
  "example_tel.profile": "930000000",
  "save.profile": "Desar canvis",
  "schedule.shop": "Horaris",
  "more_info.shop": "Més informació",
  "loading.shop": "Carregant...",
  "monday.shop": "Dilluns:",
  "tuesday.shop": "Dimarts:",
  "wednesday.shop": "Dimecres:",
  "thursday.shop": "Dijous:",
  "friday.shop": "Divendres:",
  "saturday.shop": "Dissabte:",
  "sunday.shop": "Diumenge:",
  "close.shop": "Tancat",
  "contact.shop": "Contacte",
  "price.shop": "Preu",
  "available.shop": "No disponible",
  "error.shop": "No s'ha pogut carregar la informació d'aquest establiment. Si us plau revisa l'adreça.",
  "doubt.shop": "Tens cap dubte? Contacta amb l´establiment",
  "buyness_final.shop": "buyness © 2021.",
  }