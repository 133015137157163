import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput,Image } from 'react-native';
import Dropzone,{useDropzone} from "react-dropzone"
import GeneralContext from '../Provider.js';
import { withSnackbar } from 'react-simple-snackbar'
 class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
          establishmentInfo:{},
          activeImage:"",
          activeFile:null,
          initialImage:null
        }
        this.dropzoneRef = React.createRef();
        this.updateImageContent=this.updateImageContent.bind(this);
    }

componentDidMount(){
  this.setState({
    establishmentInfo:this.context.establishmentInfo,
    activeImage:this.context.establishmentInfo.img,
    initialImage:this.context.establishmentInfo.img
  })
}
updateEstablishmentField(key,value){
  var establishmentInfo=this.state.establishmentInfo
  establishmentInfo[key]=value
  this.setState({establishmentInfo:establishmentInfo})

}

showSaveMessage(){
  this.props.openSnackbar("Se han guardado los cambios y ya están disponibles para tus clientes.",5000)
}

updateImageContent(url){
  setTimeout(()=>this.setState({activeImage:url}),100)
}

saveChanges(){
  if(this.state.activeImage!=this.state.initialImage){
  if(!!this.state.activeImage){
this.context.saveEstablishmentImage(this.state.activeFile,this.updateImageContent)
} else
  this.context.removeEstablishmentImage()
  this.setState({initialImage:this.state.activeImage})
  }
  this.context.updateEstablishment(this.state.establishmentInfo)
  this.showSaveMessage()


}


saveTemporalImages(image){

  this.setState({activeImage:URL.createObjectURL(image[0]),activeFile:image[0]})

}

deleteImage(){
this.setState({activeImage:"",activeFile:null})
}

componentWillUnmount(){
  if(!!this.state.activeFile)
  URL.revokeObjectURL(this.state.activeFile)
}


    static contextType = GeneralContext;
    render() {

        return (
            <ScrollView style={{position:"fixed",top:0, zIndex: 0, width: window.innerWidth, height: window.innerHeight-30, backgroundColor: "#fff" }}>
                
         
                    
                    <View style={{ width: "100%" }}>
                    <Text style={{ paddingTop: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft:20}}>
                    Perfil
                  </Text>
                  <View style={{width:"100%",height:window.innerHeight*0.02}}/>
                        <Text style={{ paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                            Foto del establecimiento
                  </Text>
                  {!this.state.activeImage &&
                  <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
  {({getRootProps, getInputProps}) => (
    <View style={{width:"90%",justifyContent:"center",alignItems:"center", alignSelf:"flex-start",marginHorizontal:20,height:200,backgroundColor:"#f5f5f5",marginVertical:window.innerHeight*0.02,borderRadius:10}}>

      <div style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}} {...getRootProps()}>
        <input style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}} {...getInputProps()} />
        <Text style={{position:"absolute",top:"45%",alignSelf:"center",justifySelf:"center",textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width:"100%",paddingHorizontal:window.innerWidth*0.02 }}>
        Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)
                  </Text>
       
      </div>
    </View>
  )}
</Dropzone>
                  }
                  {this.state.activeImage &&
                  <View style={{overflow:"hidden", width:"90%",justifyContent:"center",alignItems:"center", alignSelf:"flex-start",marginHorizontal:20,height:200,backgroundColor:"#f5f5f5",marginVertical:window.innerHeight*0.02,borderRadius:10}}>
                  <Image source={this.state.activeImage} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />
        
                  <Dropzone  multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
  {({getRootProps, getInputProps}) => (
    <View style={{position:"absolute",top:0, width:"100%",justifyContent:"center",alignItems:"center", alignSelf:"flex-start",marginHorizontal:20,height:200,borderRadius:10}}>

    <View style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}} {...getRootProps()}>
        <input style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}} {...getInputProps()} />

                 
                  <View
  style={{borderRadius:10,paddingHorizontal:10,alignItems:"center",justifyContent:"center",backgroundColor:"#68dc91",alignSelf:"flex-start",alignSelf:"center"}}>
       <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left",paddingVertical:10 }}>
                                               Cambiar imagen
                         </Text>
                         
       
       
                       </View>
                       </View>

                       </View>
  )}
                       </Dropzone>
                       <TouchableOpacity
 onPress={()=>this.deleteImage()}
 onLongPress={()=>this.deleteImage()}
  style={{position:"absolute",top:10,right:10, borderRadius:10,paddingHorizontal:10,alignItems:"center",justifyContent:"center",backgroundColor:"red",alignSelf:"flex-start",alignSelf:"center"}}>
       <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left",paddingVertical:10 }}>
                                               Eliminar
                         </Text>
                         
       
       
                       </TouchableOpacity>
                    </View>
                  }
{/**
<Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                        Eliminar cuenta
                            
                  </Text>
                  <Text style={{width:window.innerWidth*0.3, paddingBottom: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", marginHorizontal:20 }}>
                  Si eliminas tu cuenta se perderán todos tus menús y la carta. Tendrás que volver a crear una nueva cuenta    
                  </Text>
                  <TouchableOpacity style={{borderRadius:10,width:window.innerWidth*0.3,alignItems:"center",justifyContent:"center",backgroundColor:"#68dc91",alignSelf:"flex-start",marginLeft:window.innerWidth*0.01}}>
<Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left",paddingVertical:window.innerHeight*0.03 }}>
                                        Eliminar
                  </Text>
                  

                </TouchableOpacity>
                */}
              
                 
                    </View>
                    <View style={{ width: "100%" }}>
                        <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.05, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                            Nombre del establecimiento
                  </Text>
                        <TextInput onChangeText={t=>this.updateEstablishmentField("name",t)} defaultValue={this.state.establishmentInfo.name} numberOfLines={1} placeholder={"Restaurante gourmet"} style={{marginTop:window.innerHeight*0.01, borderRadius:10,marginHorizontal:20, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                        <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                            Ciudad
                  </Text>
                        <TextInput onChangeText={t=>this.updateEstablishmentField("location",t)} defaultValue={this.state.establishmentInfo.location}  numberOfLines={1} placeholder={"Manresa"} style={{ marginTop:window.innerHeight*0.01,borderRadius:10,marginHorizontal:20, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                       
                        <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                            Dirección
                  </Text>
                        <TextInput onChangeText={t=>this.updateEstablishmentField("direction",t)} defaultValue={this.state.establishmentInfo.direction}  numberOfLines={1} placeholder={"C/ Bruc 26"} style={{ marginTop:window.innerHeight*0.01,borderRadius:10,marginHorizontal:20, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                        <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                            Correo electrónico
                           
                  </Text>
                  <TextInput onChangeText={t=>this.updateEstablishmentField("mail",t)} defaultValue={this.state.establishmentInfo.mail}  numberOfLines={1} placeholder={"restaurantegourmet@mail.com"} style={{ marginTop:window.innerHeight*0.01,borderRadius:10,marginHorizontal:20, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: window.innerHeight * 0.02, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left", marginHorizontal:20 }}>
                            Número de teléfono
                            
                  </Text>
                  <TextInput onChangeText={t=>this.updateEstablishmentField("phone",t)} defaultValue={this.state.establishmentInfo.phone}  numberOfLines={1} placeholder={"930000000"} style={{ marginTop:window.innerHeight*0.01,borderRadius:10,marginHorizontal:20, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
 <TouchableOpacity
 onPress={()=>this.saveChanges()}
 onLongPress={()=>this.saveChanges()}
  style={{borderRadius:10, width:"95%",alignSelf:"center",alignItems:"center",justifyContent:"center",backgroundColor:"#68dc91",alignSelf:"flex-start",marginHorizontal:20,marginTop:30}}>
       <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left",paddingVertical:window.innerHeight*0.03 }}>
                                               Guardar cambios
                         </Text>
                         
       
       
                       </TouchableOpacity>
                    </View>

        
                
                <View style={{width:"100%",height:60}}/>
            </ScrollView>
        )
    }
}
export default withSnackbar(App,{position: 'top-center',
style: {
  zIndex:1100,
  backgroundColor: '#68dc91',
  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
  color: '#000',
  borderRadius:10,
  psddingHorizontal:20,
  fontSize: '1rem',
  textAlign: 'left',
},
closeStyle: {
  color: '#000',

},
})