import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput, Touchable, Image, Picker } from 'react-native';
import { IoIosClose, IoIosCheckmark } from "react-icons/io";
import Dropzone from 'react-dropzone'

//new API
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withSnackbar } from 'react-simple-snackbar'
import GeneralContext from '../Provider.js';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  FishIcon, GlutenIcon, CrustaceanIcon,
  EggIcon, PeanutIcon, SoyaIcon,
  MilkIcon, NutsIcon, CeleryIcon,
  MustardIcon, SesameIcon, SulphiteIcon,
  LupinIcon, MolluscIcon
} from 'react-allergens';

const supplementOptions = ["+", "-"]
const daysNames = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
const daysNamesLowercase = ["lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "domingo"]
const hours = [
  "Introduce una hora",
  "00:00", "00:30",
  "01:00", "01:30",
  "02:00", "02:30",
  "03:00", "03:30",
  "04:00", "04:30",
  "05:00", "05:30",
  "06:00", "06:30",
  "07:00", "07:30",
  "08:00", "08:30",
  "09:00", "09:30",
  "10:00", "10:30",
  "11:00", "11:30",
  "12:00", "12:30",
  "13:00", "13:30",
  "14:00", "14:30",
  "15:00", "15:30",
  "16:00", "16:30",
  "17:00", "17:30",
  "18:00", "18:30",
  "19:00", "19:30",
  "20:00", "20:30",
  "21:00", "21:30",
  "22:00", "22:30",
  "23:00", "23:30",
  "24:00"

]
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const newProductTemplate = {
  //update id,
  img: "",
  title: "",
  description: "",
  price: 0,
  kg: false,
  available: true,
  offer: false,
  options_active: false,
  options_fixed_price_active:false,
  supplements_active: false,
  custom_made: false,
  custom_unity: false,
  allergens: [],
  options: [],
  options_fixed_price:[],
  supplements: [],
  labels: []
}

var newSupplementTemplate = {
  title: "",
  price: ""
}

var newMenuTemplate = {

  title: "",
  description: "",
  price: 0,
  data: [],
  visible: true
}

var hoursTemplate = [
  { init: "Introduce una hora", final: "Introduce una hora" },
  { init: "Introduce una hora", final: "Introduce una hora" },
  { init: "Introduce una hora", final: "Introduce una hora" },

]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

Array.prototype.swap = function (x, y) {
  var b = this[x];
  this[x] = this[y];
  this[y] = b;
  return this;
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  //padding: grid * 2,
  //margin: `0 0 ${grid}px 0`,
  width: "100%",
  // change background colour if dragging
  background: isDragging ? '#F2F2F2' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#F2F2F2' : '#fff',
  //padding: grid,
  width: "100%",

});


const newCategoryTemplate = {
  title: "",
  description: "",
  visible: true,
  index: "",
  data: []
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "PRODUCTS",
      showNewMenu: false,

      showNewCategory: false,
      showNewProduct: false,
      editActualMenu: false,
      editActualCategory: false,
      editActualProduct: false,
      activeProductIndex: -1,
      activeCategoryIndex: -1,
      activeMenuIndex: -1,
      activeSupplementIndex: -1,
      newSupplement: JSON.parse(JSON.stringify(newSupplementTemplate)),
      newProduct: newProductTemplate,
      activeCategories: [],
      activeNewCategoryProducts: [],
      editingCategoryProductsList: [],
      newCategoryData: newCategoryTemplate,
      editingCategory: {},

      editingHours: hoursTemplate,
      showEditingHours: false,
      activeHoursIndex: -1,
      activeFile: null,
      initialImage: "",
      selectedOption: null,
      newProductCategories: [],
      editingProductCategories: [],
      newMenuPickerIndex: 0,
      newMenuFirsts: [],
      newMenuSeconds: [],
      editMenuFirsts: [],
      editMenuSeconds: [],
      newMenuData: newMenuTemplate,
      editingMenuPickerIndex: 0,
      activeProductOriginalTitle: "",
      activeCategoryOriginalTitle: "",
      newProductNewLabel: { label: "", color: {} },
      activeProductNewLabel: { label: "", color: {} },
      newProductNewOption: { title: "", price: "", description: "" },
      newProductNewOptionFixedPrice: { title: "", description: "" },
      editProductNewOption: { title: "", price: "", description: "" },
      editProductNewOptionFixedPrice: { title: "", price: "", description: "" },
      newProductNewSupplement: { title: "", type: "+", price: "" },
      editProductNewSupplement: { title: "", type: "+", price: "" },


      selectedOptionNewProduct: "",
      selectedIndexOptionNewProduct: -1,
      newSelectedOptionNewProduct: "",
      selectedIndexOptionFixedPriceNewProduct: -1,
      newSelectedOptionFixedPriceNewProduct: "",


      selectedOptionEditProduct: "",
      selectedIndexOptionEditProduct: -1,
      newSelectedOptionEditProduct: "",
      activeEditBarTerraceSupplement: false


    }

    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.openModalEditCategory = this.openModalEditCategory.bind(this);
    this.openModalEditMenu = this.openModalEditMenu.bind(this);
    this.updateImageContentNew = this.updateImageContentNew.bind(this);
    this.checkSaveProductChanges = this.checkSaveProductChanges.bind(this);
    this.checkSaveCategoryChanges = this.checkSaveCategoryChanges.bind(this);
    this.checkSaveMenuChanges = this.checkSaveMenuChanges.bind(this);
    this.updateEditingMenuField = this.updateEditingMenuField.bind(this);
    this.updateAllergenNewProduct = this.updateAllergenNewProduct.bind(this);
    this.updateAllergenEditProduct = this.updateAllergenEditProduct.bind(this);
    this.editDish = this.editDish.bind(this);
  }



  updateNewProductNewLabel(key, value) {
    var newProductNewLabel = this.state.newProductNewLabel
    if (key == "color" && value.color == newProductNewLabel.color.color)
      newProductNewLabel["color"] = { color: "", contrast: "" }
    else
      newProductNewLabel[key] = value
    this.setState({ newProductNewLabel: newProductNewLabel })
  }

  addLabelToNewProduct() {
    var newProduct = this.state.newProduct
    newProduct.labels.push( JSON.parse(JSON.stringify(this.state.newProductNewLabel)))
    this.setState({ newProduct: newProduct,newProductNewLabel:{label:"",color:{ color: "", contrast: "" }} })
  }

  deleteLabelNewProduct(index) {
    var newProduct = this.state.newProduct
    newProduct.labels.splice(index, 1)
    this.setState({ newProduct: newProduct, newProductNewLabel: { label: "", color: {} } })
  }


  updateActiveProductNewLabel(key, value) {
    var activeProductNewLabel = this.state.activeProductNewLabel
    if (key == "color" && value.color == activeProductNewLabel.color.color)
      activeProductNewLabel["color"] = { color: "", contrast: "" }
    else
      activeProductNewLabel[key] = value
    this.setState({ activeProductNewLabel: activeProductNewLabel })
  }

  addLabelToActiveProduct() {
    var activeProduct = this.state.activeProduct
    if(activeProduct.labels)
    activeProduct.labels.push( JSON.parse(JSON.stringify(this.state.activeProductNewLabel)))
    else
    activeProduct.labels=[ JSON.parse(JSON.stringify(this.state.activeProductNewLabel))]
    this.setState({ activeProduct: activeProduct,activeProductNewLabel:{label:"",color:{ color: "", contrast: "" }} })
  }

  deleteLabelActiveProduct(index) {
    var activeProduct = this.state.activeProduct
    activeProduct.labels.splice(index, 1)
    this.setState({ activeProduct: activeProduct, activeProductNewLabel: { label: "", color: {} } })
  }


  createSectionOptionsNewProduct() {
    var newProduct = this.state.newProduct
    newProduct.options.push({
      title: this.state.newSelectedOptionNewProduct,
      data: []
    })

    this.setState({ newProduct: newProduct, selectedIndexOptionNewProduct: newProduct.options.length - 1, selectedOptionNewProduct: newProduct.options[newProduct.options.length - 1].title, newSelectedOptionNewProduct: "" })

  }

  createSectionOptionsFixedPriceNewProduct() {
    var newProduct = this.state.newProduct
    newProduct.options_fixed_price.push({
      title: this.state.newSelectedOptionFixedPriceNewProduct,
      data: []
    })

    this.setState({ newProduct: newProduct, selectedIndexOptionFixedPriceNewProduct: newProduct.options_fixed_price.length - 1, selectedOptionFixedPriceNewProduct: newProduct.options_fixed_price[newProduct.options_fixed_price.length - 1].title, newSelectedOptionFixedPriceNewProduct: "" })

  }

  createSectionOptionsFixedPriceEditProduct() {
    var activeProduct = this.state.activeProduct
    if (activeProduct.options_fixed_price)
      activeProduct.options_fixed_price.push({
        title: this.state.newSelectedOptionFixedPriceEditProduct,
        data: []
      })
    else
      activeProduct.options_fixed_price = [{ title: this.state.newSelectedOptionFixedPriceEditProduct, data: [] }]

    this.setState({ activeProduct: activeProduct, selectedIndexOptionFixedPriceEditProduct: activeProduct.options_fixed_price.length - 1, selectedOptionFixedPriceEditProduct: activeProduct.options_fixed_price[activeProduct.options_fixed_price.length - 1].title, newSelectedOptionFixedPriceEditProduct: "" })

  }


  createSectionOptionsEditProduct() {
    var activeProduct = this.state.activeProduct
    if (activeProduct.options)
      activeProduct.options.push({
        title: this.state.newSelectedOptionEditProduct,
        data: []
      })
    else
      activeProduct.options = [{ title: this.state.newSelectedOptionEditProduct, data: [] }]

    this.setState({ activeProduct: activeProduct, selectedIndexOptionEditProduct: activeProduct.options.length - 1, selectedOptionEditProduct: activeProduct.options[activeProduct.options.length - 1].title, newSelectedOptionEditProduct: "" })

  }



  deleteSectionOptionsFixedPriceNewProduct(index) {
    var newProduct = this.state.newProduct
    newProduct.options_fixed_price.splice(index, 1)
    this.setState({ newProduct: newProduct, selectedIndexOptionFixedPriceNewProduct: -1 })
  }

  deleteSectionOptionsNewProduct(index) {
    var newProduct = this.state.newProduct
    newProduct.options.splice(index, 1)
    this.setState({ newProduct: newProduct, selectedIndexOptionNewProduct: -1 })
  }

  deleteSectionOptionsFixedPriceEditProduct(index) {
    var activeProduct = this.state.activeProduct
    activeProduct.options_fixed_price.splice(index, 1)
    this.setState({ activeProduct: activeProduct, selectedIndexOptionFixedPriceEditProduct: -1 })
  }


  deleteSectionOptionsEditProduct(index) {
    var activeProduct = this.state.activeProduct
    activeProduct.options.splice(index, 1)
    this.setState({ activeProduct: activeProduct, selectedIndexOptionEditProduct: -1 })
  }


  createSubSectionOptionsNewProduct() {
    var newProduct = this.state.newProduct
    newProduct.options[this.state.selectedIndexOptionNewProduct].data.push(this.state.newProductNewOption)



    this.setState({ newProduct: newProduct, newProductNewOption: { title: "", price: "",description:"" } })
  }

  createSubSectionOptionsFixedPriceNewProduct() {
    var newProduct = this.state.newProduct
    newProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceNewProduct].data.push(this.state.newProductNewOptionFixedPrice)



    this.setState({ newProduct: newProduct, newProductNewOptionFixedPrice: { title: "", description: "" } })
  }


  createSubSectionOptionsFixedPriceEditProduct() {
    var activeProduct = this.state.activeProduct
    activeProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceEditProduct].data.push(this.state.editProductNewOptionFixedPrice)



    this.setState({ activeProduct: activeProduct, editProductNewOptionFixedPrice: { title: "", description: "" } })
  }

  createSubSectionOptionsEditProduct() {
    var activeProduct = this.state.activeProduct
    activeProduct.options[this.state.selectedIndexOptionEditProduct].data.push(this.state.editProductNewOption)



    this.setState({ activeProduct: activeProduct, editProductNewOption: { title: "", price: "" } })
  }


  deleteSubSectionOptionsFixedPriceNewProduct(item) {
    var newProduct = this.state.newProduct
    for (var i = 0; i < newProduct.options_fixed_price.length; i++) {
      if (newProduct.options_fixed_price[i].title == this.state.selectedOptionFixedPriceNewProduct) {
        for (var j = 0; j < newProduct.options_fixed_price[i].data.length; j++) {
          if (newProduct.options_fixed_price[i].data[j].title == item) {
            newProduct.options_fixed_price[i].data.splice(j, 1)
            break
          }

        }
        break
      }

    }
    this.setState({ newProduct: newProduct })
  }

  deleteSubSectionOptionsNewProduct(item) {
    var newProduct = this.state.newProduct
    for (var i = 0; i < newProduct.options.length; i++) {
      if (newProduct.options[i].title == this.state.selectedOptionNewProduct) {
        for (var j = 0; j < newProduct.options[i].data.length; j++) {
          if (newProduct.options[i].data[j].title == item) {
            newProduct.options[i].data.splice(j, 1)
            break
          }

        }
        break
      }

    }
    this.setState({ newProduct: newProduct })
  }

  deleteSubSectionOptionsFixedPriceEditProduct(item) {
    var activeProduct = this.state.activeProduct
    for (var i = 0; i < activeProduct.options_fixed_price.length; i++) {
      if (activeProduct.options_fixed_price[i].title == this.state.selectedOptionFixedPriceEditProduct) {
        for (var j = 0; j < activeProduct.options_fixed_price[i].data.length; j++) {
          if (activeProduct.options_fixed_price[i].data[j].title == item) {
            activeProduct.options_fixed_price[i].data.splice(j, 1)
            break
          }

        }
        break
      }

    }
    this.setState({ activeProduct: activeProduct })
  }


  deleteSubSectionOptionsEditProduct(item) {
    var activeProduct = this.state.activeProduct
    for (var i = 0; i < activeProduct.options.length; i++) {
      if (activeProduct.options[i].title == this.state.selectedOptionEditProduct) {
        for (var j = 0; j < activeProduct.options[i].data.length; j++) {
          if (activeProduct.options[i].data[j].title == item) {
            activeProduct.options[i].data.splice(j, 1)
            break
          }

        }
        break
      }

    }
    this.setState({ activeProduct: activeProduct })
  }






  editProductAddOptions() {
    var activeProduct = this.state.activeProduct
    activeProduct.options.push(this.state.editProductNewOption)
    this.setState({ activeProduct: activeProduct, editProductNewOption: { title: "", price: "" } })
  }


  updateNewProductOptionTemplate(type, value) {
    var newProductNewOption = this.state.newProductNewOption

    newProductNewOption[type] = value

    this.setState({ newProductNewOption: newProductNewOption })
  }

  updateNewProductOptionFixedPriceTemplate(type, value) {
    var newProductNewOptionFixedPrice = this.state.newProductNewOptionFixedPrice

    newProductNewOptionFixedPrice[type] = value

    this.setState({ newProductNewOptionFixedPrice: newProductNewOptionFixedPrice })
  }


  updateEditProductOptionFixedPriceTemplate(type, value) {
    var editProductNewOptionFixedPrice = this.state.editProductNewOptionFixedPrice
    editProductNewOptionFixedPrice[type] = value

    this.setState({ editProductNewOptionFixedPrice: editProductNewOptionFixedPrice })
  }


  updateEditProductOptionTemplate(type, value) {
    var editProductNewOption = this.state.editProductNewOption

    editProductNewOption[type] = value

    this.setState({ editProductNewOption: editProductNewOption })
  }


 

  newProductAddSupplements(supplement) {
    var newProduct = this.state.newProduct
    if (newProduct.supplements.indexOf(supplement) == -1)
      newProduct.supplements.push(supplement)
    else
      newProduct.supplements.splice(newProduct.supplements.indexOf(supplement), 1)
    this.setState({ newProduct: newProduct, newProductNewSupplement: { title: "", type: "+", price: "" } })
  }


  editProductAddSupplements(supplement) {
    var activeProduct = this.state.activeProduct
    if (activeProduct.supplements) {
      if (activeProduct.supplements.indexOf(supplement) == -1) {
        activeProduct.supplements.push(supplement)
      }
      else
        activeProduct.supplements.splice(activeProduct.supplements.indexOf(supplement), 1)
    }
    else
      activeProduct.supplements = [supplement]
    this.setState({ activeProduct: activeProduct, newProductEditSupplement: { title: "", type: "+", price: "" } })
  }


  updateNewProductSupplementTemplate(type, value) {
    var newProductNewSupplement = this.state.newProductNewSupplement

    newProductNewSupplement[type] = value

    this.setState({ newProductNewSupplement: newProductNewSupplement })
  }

  updateEditProductSupplementTemplate(type, value) {
    var editProductNewSupplement = this.state.editProductNewSupplement
    editProductNewSupplement[type] = value
    this.setState({ editProductNewSupplement: editProductNewSupplement })
  }


  newProductDeleteSupplements(index) {
    var newProduct = this.state.newProduct
    newProduct.supplements.splice(index, 1)
    this.setState({ newProduct: newProduct })
  }

  editProductDeleteSupplements(index) {
    var activeProduct = this.state.activeProduct
    activeProduct.supplements.splice(index, 1)
    this.setState({ activeProduct: activeProduct })
  }


  updateAllergenNewProduct(allergen) {
    var newProduct = this.state.newProduct
    if (newProduct.allergens.indexOf(allergen) >= 0)
      newProduct.allergens.splice(newProduct.allergens.indexOf(allergen), 1)
    else
      newProduct.allergens.push(allergen)

    this.setState({ newProduct: newProduct })

  }

  updateAllergenEditProduct(allergen) {
    var activeProduct = this.state.activeProduct
    if (activeProduct.allergens && activeProduct.allergens.indexOf(allergen) >= 0)
      activeProduct.allergens.splice(activeProduct.allergens.indexOf(allergen), 1)
    else {
      if (activeProduct.allergens)
        activeProduct.allergens.push(allergen)
      else
        activeProduct.allergens = [allergen]
    }


    this.setState({ activeProduct: activeProduct })

  }

  toggleCategoryNewProduct(category) {

    var newProductCategories = this.state.newProductCategories
    if (newProductCategories.indexOf(category) >= 0)
      newProductCategories.splice(newProductCategories.indexOf(category), 1)
    else
      newProductCategories.push(category)
    this.setState({ newProductCategories: newProductCategories })
  }


  checkSaveProductChanges() {
    if (!!this.state.activeProduct.title)
      return false
    return true
  }


  checkSaveCategoryChanges() {
    if (!!this.state.newCategoryData.title)
      return true
    return false
  }

  checkSaveMenuChanges() {

    if (!this.state.editingMenu.title)
      return true
    return false
  }



  updateNewCategoryField(key, value) {
    var newCategoryData = this.state.newCategoryData
    newCategoryData[key] = value

    this.setState({ newCategoryData: newCategoryData })
  }

  updateEditingCategoryField(key, value) {
    var editingCategory = this.state.editingCategory
    editingCategory[key] = value

    this.setState({ editingCategory: editingCategory })
  }

  updateEditingMenuField(key, value) {
    var editingMenu = this.state.editingMenu
    editingMenu[key] = value

    this.setState({ editingMenu: editingMenu })
  }

  editActualSupplementFunction(key, value) {
    var activeSupplement = this.state.activeSupplement;
    activeSupplement[key] = value

    this.setState({ activeSupplement: activeSupplement })
  }


  updateNewCategoryProducts(item) {
    var activeNewCategoryProducts = this.state.activeNewCategoryProducts
    if (activeNewCategoryProducts.indexOf(item) >= 0)
      activeNewCategoryProducts.splice(activeNewCategoryProducts.indexOf(item), 1)
    else
      activeNewCategoryProducts.push(item)
    this.setState({ activeNewCategoryProducts: activeNewCategoryProducts })
  }


  createSupplement() {

    this.context.createSupplement(this.state.newSupplement)
    this.setState({ showNewSupplement: false, newSupplement: JSON.parse(JSON.stringify(newSupplementTemplate)) })
  }

  createCategory() {
    var newCategoryData = this.state.newCategoryData;
    newCategoryData.index = Object.keys(this.context.categoriesData).length
    newCategoryData.data = this.state.activeNewCategoryProducts

    this.context.createCategory(newCategoryData)
    setTimeout(() => this.setState({ activeNewCategoryProducts: [], showNewCategory: false, activeCategories: this.context.categoriesData, newCategoryData: newCategoryTemplate }, () => this.showSaveMessage()), 100)
  }

  updateImageContentNew(url) {
    var newProduct = this.state.newProduct
    newProduct.img = url

  }

  updateEditingProductCategories(item) {

    var editingProductCategories = this.state.editingProductCategories
    if (editingProductCategories.indexOf(item) == -1)
      editingProductCategories.push(item)
    else
      editingProductCategories.splice(editingProductCategories.indexOf(item), 1)


    this.setState({ editingProductCategories: editingProductCategories })

  }

  saveProductChanges() {
    var activeProduct = this.state.activeProduct

    if (activeProduct.options && activeProduct.options.length == 0)
      activeProduct.options_active = false

    if (activeProduct.supplements && activeProduct.supplements.length == 0)
      activeProduct.supplements_active = false

    if (activeProduct.options_active) {
      activeProduct.price = ""
      activeProduct.price_offer = ""
    }



    if (activeProduct.img != this.state.initialImage) {
      if (!!activeProduct.img) {
        this.context.saveProductImage(this.state.activeProductIndex, this.state.activeFile, this.updateImageContent)
      } else
        this.context.removeProductImage(this.state.activeProductIndex, this.updateImageContent)
    }

    this.context.updateProduct(this.state.activeProductIndex, activeProduct, this.state.editingProductCategories, this.state.activeFile)
    this.setState({
      selectedOptionEditProduct: "",
      selectedIndexOptionEditProduct: -1,
      newSelectedOptionEditProduct: "",
      editActualProduct: false
    }, () => this.showSaveMessage())
  }


  saveTemporalImagesEdit(image) {
    var activeProduct = this.state.activeProduct
    activeProduct.img = URL.createObjectURL(image[0])
    this.setState({ activeProduct: activeProduct, activeFile: image[0] })

  }

  saveTemporalImagesNew(image) {
    var newProduct = this.state.newProduct
    newProduct.img = URL.createObjectURL(image[0])
    this.setState({ newProduct: newProduct, activeFile: image[0] })

  }

  deleteImage() {
    var activeProduct = this.state.activeProduct
    activeProduct.img = ""
    this.setState({ activeProduct: activeProduct, activeFile: null })
  }

  deleteImageNew() {
    var newProduct = this.state.newProduct
    newProduct.img = ""
    this.setState({ newProduct: newProduct, activeFile: null })
  }

  componentWillUnmount() {
    if (!!this.state.activeFile)
      URL.revokeObjectURL(this.state.activeFile)
  }

  deleteProduct() {

    this.context.deleteProduct(this.state.activeProductIndex)
    setTimeout(() => this.setState({ editActualProduct: false }, () => this.showSaveMessage()), 100)
  }



  createProduct() {
    var newProduct = this.state.newProduct

    if (newProduct.options.length == 0)
      newProduct.options_active = false

    if (newProduct.supplements.length == 0)
      newProduct.supplements_active = false

    if (newProduct.options_active) {
      newProduct.price = ""
      newProduct.price_offer = ""
    }

    this.context.createProduct(newProduct, this.state.newProductCategories, this.state.activeFile)
    this.setState({
      selectedOptionNewProduct: "",
      selectedIndexOptionNewProduct: -1,
      newSelectedOptionNewProduct: "",
      showNewProduct: false, newProduct: JSON.parse(JSON.stringify(newProductTemplate)), newProductCategories: []
    }, () => this.showSaveMessage())
  }
  componentWillMount() {

    this.context.getInfoFromDB()
  }

  showSaveMessage() {
    this.props.openSnackbar("Se han guardado los cambios y ya están disponibles para tus clientes.", 5000)
  }


  toggleAvaliabilityProduct(i) {
    this.context.updateProductAvailability(i)
    this.showSaveMessage()
  }

  updateVisibilityActualDish(available) {
    var activeProduct = this.state.activeProduct;
    activeProduct.available = available
    this.setState({ activeProduct: activeProduct })
  }


  generateCategoriesList(categories) {

    var cleanCategories = []
    for (var i = 0; i < Object.keys(this.context.activeCategories).length; i++) {
      if (categories.indexOf(this.context.activeCategories[Object.keys(this.context.activeCategories)[i]].id) >= 0)
        cleanCategories.push(Object.keys(this.context.activeCategories)[i])
    }

    return cleanCategories
  }

  assignActualDish(item) {


    var activeProduct = this.context.establishmentInfo.allProducts[this.context.activeProducts[item].id]
    this.setState({ selectedIndexOptionEditProduct: -1, editingProductCategories: this.generateCategoriesList(this.context.activeProducts[item].categories), initialImage: activeProduct.img, activeProduct: activeProduct, activeProductOriginalTitle: activeProduct.title })
  }


  assignActualSupplement(item) {


    var activeSupplement = this.context.establishmentInfo.supplements[this.context.activeSupplements[item].id]
    this.setState({ activeSupplement: activeSupplement, activeSupplementOriginalTitle: activeSupplement.title })
  }

  editActualProductFunction(key, value) {
    var activeProduct = this.state.activeProduct;
    activeProduct[key] = value
    if (key == "options_active") {
      activeProduct["offer"] = false
      activeProduct["options_fixed_price_active"] = false
    }
    if (key == "options_fixed_price_active") {
      activeProduct["offer"] = false
      activeProduct["options_active"] = false
    }
    if (key == "custom_unity" && value) {
      activeProduct["kg"] = false
    }

    if (key == "kg" && value) {
      activeProduct["custom_unity"] = false
      activeProduct["custom_unity_text"] = ""
    }

    if (key == "offer") {
      activeProduct["options_active"] = false
      activeProduct["options"] = []
      this.setState({ selectedIndexOptionEditProduct: -1 })
    }
    this.setState({ activeProduct: activeProduct })
  }

  newSupplementField(key, value) {
    var newSupplement = this.state.newSupplement;
    newSupplement[key] = value
    this.setState({ newSupplement: newSupplement })
  }

  newProductField(key, value) {
    var newProduct = this.state.newProduct;
    newProduct[key] = value
    if (key == "options_active") {
      newProduct["offer"] = false
      newProduct["options_fixed_price_active"] = false
    }
    if (key == "options_fixed_price_active") {
      newProduct["offer"] = false
      newProduct["options_active"] = false
    }

    if (key == "custom_unity") {
      newProduct["kg"] = false
    }

    if (key == "kg") {
      newProduct["custom_unity"] = false
      newProduct["custom_unity_text"] = ""
    }


    if (key == "offer") {
      newProduct["options_active"] = false
      newProduct["options"] = []
      this.setState({ selectedIndexOptionNewProduct: -1 })
    }

    this.setState({ newProduct: newProduct })
  }

  updateNewMenuField(key, value) {
    var newMenuData = this.state.newMenuData;
    if (key != "price")
      newMenuData[key] = value
    else
      newMenuData[key] = parseFloat(value, "es-ES")
    this.setState({ newMenuData: newMenuData })
  }


  editDish(item, index) {

    this.assignActualDish(item)
    this.setState({ editActualProduct: true, activeProductIndex: this.context.activeProducts[item].id })
  }


  editSupplement(item) {

    this.assignActualSupplement(item)
    this.setState({ editActualSupplement: true, activeSupplementIndex: this.context.activeSupplements[item].id })
  }

  editSupplementSave() {
    this.context.updateSupplement(this.state.activeSupplement)
    this.setState({ editActualSupplement: false }, () => setTimeout(() => this.showSaveMessage(), 100))

  }


  editCategorySave() {
    var editingCategory = this.state.editingCategory
    editingCategory.data = this.state.editingCategoryProductsList

    this.context.updateCategory(this.state.activeCategoryIndex, this.state.editingCategory)
    this.setState({ editActualCategory: false, editingCategoryProductsList: [] })
  }




  editMenuSave() {
    var editingMenu = this.state.editingMenu
    // editingMenu.data = this.state.editingMenuProductsList
    var temporalData = []
    if (this.state.editMenuFirsts.length > 0)
      temporalData.push({ title: "Primeros", data: this.state.editMenuFirsts })

    if (this.state.editMenuSeconds.length > 0)
      temporalData.push({ title: "Segundos", data: this.state.editMenuSeconds })
    editingMenu.id = editingMenu.id ? editingMenu.id : 0
    editingMenu.data = temporalData
    this.context.updateMenu(this.state.activeMenuIndex, this.state.editingMenu)
    this.setState({ editActualMenu: false, editMenuFirsts: [], editMenuSeconds: [] })
  }



  updateEditingProductList(item) {
    var editingCategoryProductsList = this.state.editingCategoryProductsList
    if (editingCategoryProductsList.indexOf(this.context.activeProducts[item].id) >= 0)
      editingCategoryProductsList.splice(editingCategoryProductsList.indexOf(this.context.activeProducts[item].id), 1)
    else
      editingCategoryProductsList.push(this.context.activeProducts[item].id)
    this.setState({ editingCategoryProductsList: editingCategoryProductsList })
  }

  updateNewMenuProducts(item) {
    if (this.state.newMenuPickerIndex == 0) {
      var newMenuFirsts = this.state.newMenuFirsts
      if (newMenuFirsts.indexOf(item) >= 0)
        newMenuFirsts.splice(newMenuFirsts.indexOf(item), 1)
      else
        newMenuFirsts.push(item)

      this.setState({ newMenuFirsts: newMenuFirsts })
    }
    else {
      var newMenuSeconds = this.state.newMenuSeconds
      if (newMenuSeconds.indexOf(item) >= 0)
        newMenuSeconds.splice(newMenuSeconds.indexOf(item), 1)
      else
        newMenuSeconds.push(item)

      this.setState({ newMenuSeconds: newMenuSeconds })
    }
  }

  updateEditMenuProducts(item) {

    if (this.state.editingMenuPickerIndex == 0) {
      var editMenuFirsts = this.state.editMenuFirsts
      if (editMenuFirsts.indexOf(item) >= 0)
        editMenuFirsts.splice(editMenuFirsts.indexOf(item), 1)
      else
        editMenuFirsts.push(item)

      this.setState({ editMenuFirsts: editMenuFirsts })
    }
    else {
      var editMenuSeconds = this.state.editMenuSeconds
      if (editMenuSeconds.indexOf(item) >= 0)
        editMenuSeconds.splice(editMenuSeconds.indexOf(item), 1)
      else
        editMenuSeconds.push(item)

      this.setState({ editMenuSeconds: editMenuSeconds })
    }
  }

  checkIfProductNewExists() {
    if (this.state.newProduct.options_fixed_price_active &&
      (!this.state.newProduct.price || ((this.state.newProduct.options_fixed_price==null) || this.state.newProduct.options_fixed_price.length==0)))
   return true 
    if (this.state.newProduct.offer && !this.state.newProduct.price_offer)
      return true
    return this.context.activeProducts[this.state.newProduct.title] && !!this.state.newProduct.title
  }

  checkIfProductEditExists() {
    //REVISAR FRESCO
    if (this.state.activeProduct.options_fixed_price_active &&
       (!this.state.activeProduct.price || ((this.state.activeProduct.options_fixed_price==null) || this.state.activeProduct.options_fixed_price.length==0)))
    return true 

    if (this.state.activeProduct.offer && !this.state.activeProduct.price_offer)
      return true

    if (this.state.activeProduct.title == this.state.activeProductOriginalTitle) {
      return false

    }
    else
      if (!this.state.activeProduct.title) {

        return true
      }

    return (
      //caso same name
      Object.keys(this.context.activeProducts).indexOf(this.state.activeProduct.title) != -1
      &&
      (
        Object.keys(this.context.activeProducts).indexOf(this.state.activeProduct.title)
        !=
        Object.keys(this.context.activeProducts).indexOf(this.state.activeProductOriginalTitle)
      )
    )




  }

  checkIfMenuNewExists() {
    return this.context.activeMenus[this.state.newMenuData.title] && !!this.state.newMenuData.title
  }

  checkIfCategoryNewExists() {
    return this.context.activeCategories[this.state.newCategoryData.title] && !!this.state.newCategoryData.title
  }

  checkIfCategoryEditExists() {

    if (this.state.editingCategory.title == this.state.activeCategoryOriginalTitle) {
      return false

    }
    else
      if (!this.state.editingCategory.title) {

        return true
      }

    return (
      //caso same name
      Object.keys(this.context.activeCategories).indexOf(this.state.editingCategory.title) != -1
      &&
      (
        Object.keys(this.context.activeCategories).indexOf(this.state.editingCategory.title)
        !=
        Object.keys(this.context.activeCategories).indexOf(this.state.activeCategoryOriginalTitle)
      )
    )

  }

  checkIfMenuEditExists() {

    if (this.state.editingMenu.title == this.state.activeMenuOriginalTitle) {
      return false

    }
    else
      if (!this.state.editingMenu.title) {

        return true
      }

    return (
      //caso same name
      Object.keys(this.context.activeMenus).indexOf(this.state.editingMenu.title) != -1
      &&
      (
        Object.keys(this.context.activeMenus).indexOf(this.state.editingMenu.title)
        !=
        Object.keys(this.context.activeMenus).indexOf(this.state.activeMenuOriginalTitle)
      )
    )

  }





  updateBarTerraceDescription() {
    this.context.updateEstablishmentBarTerrace(this.state.editBarTerraceSupplement)
    this.setState({
      activeEditBarTerraceSupplement: false
    })
  }


  onDragEnd(result) {
    if (result.source.index != result.destination.index) {

      var activeCategories = this.context.activeCategories


      var arrayClean = []
      for (var i = 0; i < Object.keys(activeCategories).length; i++) {
        arrayClean.push(
          {
            title: Object.keys(activeCategories)[i],
            ...activeCategories[Object.keys(activeCategories)[i]]
          }
        )
        if (result.source.index < result.destination.index) {


          if (arrayClean[i].index <= result.destination.index && arrayClean[i].index != result.source.index && arrayClean[i].index > 0) {
            arrayClean[i].index -= 1
          }


          if (arrayClean[i].index == result.source.index && result.draggableId == arrayClean[i].title) {
            arrayClean[i].index = result.destination.index
          }
        }
        else {
          if (arrayClean[i].index >= result.destination.index && arrayClean[i].index < result.source.index && arrayClean[i].index + 1 < Object.keys(activeCategories).length) {
            arrayClean[i].index += 1

          }
          if (arrayClean[i].index == result.source.index && result.draggableId == arrayClean[i].title) {
            arrayClean[i].index = result.destination.index
          }
        }
      }
      var sortedArray = new Array(arrayClean.length).fill(null)
      for (var i = 0; i < arrayClean.length; i++) {
        sortedArray[arrayClean[i].index] = arrayClean[i]
      }



      this.context.updateCategoriesOrder(sortedArray)


    }
  }


  deleteCategory() {
    this.context.deleteCategory(this.context.activeCategories[Object.keys(this.context.activeCategories)[this.state.activeCategoryIndex]].index)
    setTimeout(() => this.setState({ editActualCategory: false, activeCategories: this.context.categoriesData }, () => this.showSaveMessage()), 100)
  }

  deleteSupplement() {

    this.context.deleteSupplement(this.state.activeSupplement.id)
    setTimeout(() => this.setState({ editActualSupplement: false }, () => this.showSaveMessage()), 100)
  }

  deleteMenu() {
    this.context.deleteMenu(this.context.activeMenus[Object.keys(this.context.activeMenus)[this.state.activeMenuIndex]].index)
    setTimeout(() => this.setState({ editActualMenu: false }, () => this.showSaveMessage()), 100)
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };


  openModalEditCategory(item, index) {

    var editingCategory = {
      title: item,
      index: this.context.activeCategories[item].index,
      description: this.context.activeCategories[item].description,
      visible: this.context.activeCategories[item].visible,
      data: this.context.activeCategories[item].data
    }

    this.setState({ activeCategoryOriginalTitle: item, editingCategoryProductsList: this.context.activeCategories[item].data, editingCategory: editingCategory, editActualCategory: true, activeCategoryIndex: index })
  }

  openModalEditMenu(item, index) {

    var editingMenu = {
      title: item,
      price: this.context.activeMenus[item].price,
      id: this.context.activeMenus[item].index,
      description: this.context.activeMenus[item].description,
      visible: this.context.activeMenus[item].visible,
      data: this.context.activeMenus[item].data
    }

    this.setState({
      editMenuSeconds: this.context.activeMenus[item].data[1] ? this.context.activeMenus[item].data[1].data : [],
      editMenuFirsts: this.context.activeMenus[item].data[0] ? this.context.activeMenus[item].data[0].data : [],
      editingMenu: editingMenu,
      activeMenuOriginalTitle: item,
      editActualMenu: true,
      activeMenuIndex: index
    })
  }

  generateCategories(item) {
    if (this.context.activeProducts[item].id == 108)
    var cleanArray = []
    for (var i = 0; i < Object.keys(this.context.activeCategories).length; i++) {
      if (this.context.activeCategories[Object.keys(this.context.activeCategories)[i]].data.indexOf(this.context.activeProducts[item].id) >= 0)
        cleanArray.push(Object.keys(this.context.activeCategories)[i])
    }
    var cleanString = cleanArray.length > 0 ? cleanArray.join(", ") : "Sin categoría"
    /* var cleanArray = []
 
     for (var i = 0; i < categoriesIndex.length; i++) {
 
       if (this.context.establishmentInfo.products && this.context.establishmentInfo.products.length>0)
         cleanArray.push(Object.keys(this.state.activeCategories)[categoriesIndex[i]])
     }

   
 */

    return cleanString
  }

  saveHoursChanges() {
    this.context.saveHoursChanges(this.state.activeHoursIndex, this.state.editingHours)
    this.setState({ showEditingHours: false, activeHoursIndex: -1 }, () => this.showSaveMessage())
  }

  manageOpenEditingModal(index) {

    var editingHours = JSON.parse(JSON.stringify(hoursTemplate))

    for (var i = 0; i < this.context.establishmentInfo.hours[index].hours.length; i++) {
      editingHours[i] = this.context.establishmentInfo.hours[index].hours[i]
    }
    this.setState({ showEditingHours: true, editingHours: editingHours, activeHoursIndex: index })
  }


  updateEditingHour(index, type, value) {

    var editingHours = this.state.editingHours
    editingHours[index][type] = value
    this.setState({ editingHours: editingHours })
  }

  possibleSaveHours() {
    var firstHour = (this.state.editingHours[0].init == "Introduce una hora" && this.state.editingHours[0].final == "Introduce una hora") || (this.state.editingHours[0].init != "Introduce una hora" && this.state.editingHours[0].final != "Introduce una hora")
    var secondHour = (this.state.editingHours[1].init == "Introduce una hora" && this.state.editingHours[1].final == "Introduce una hora") || (this.state.editingHours[1].init != "Introduce una hora" && this.state.editingHours[1].final != "Introduce una hora")
    var thirdHour = (this.state.editingHours[2].init == "Introduce una hora" && this.state.editingHours[2].final == "Introduce una hora") || (this.state.editingHours[2].init != "Introduce una hora" && this.state.editingHours[2].final != "Introduce una hora")
    return firstHour && secondHour && thirdHour
  }


  checkNewMenuProductActive(item) {
    if (this.state.newMenuPickerIndex == 0) {
      return this.state.newMenuFirsts.indexOf(item) >= 0
    } else {
      return this.state.newMenuSeconds.indexOf(item) >= 0
    }
  }


  checkEditMenuProductActive(item) {
    if (this.state.editingMenuPickerIndex == 0) {
      return this.state.editMenuFirsts.indexOf(item) >= 0
    } else {
      return this.state.editMenuSeconds.indexOf(item) >= 0
    }
  }

  createMenu() {
    var newMenuData = this.state.newMenuData
    var temporalData = []
    if (this.state.newMenuFirsts.length > 0)
      temporalData.push({ title: "Primeros", data: this.state.newMenuFirsts })

    if (this.state.newMenuSeconds.length > 0)
      temporalData.push({ title: "Segundos", data: this.state.newMenuSeconds })
    newMenuData.id = this.context.establishmentInfo.menus.length

    newMenuData.data = temporalData
    this.context.createMenu(newMenuData)
    this.setState({ showNewMenu: false, newMenuData: newMenuTemplate }, () => this.showSaveMessage())
  }

  static contextType = GeneralContext;

  generateProductPriceInfo(item) {
    if (this.context.activeProducts[item].options_active)
      return "Precio con opciones"
    if (this.context.activeProducts[item].price)
      return parseFloat(this.context.activeProducts[item].price).toFixed(2) + " € " + (this.context.activeProducts[item].kg ? "/ kg" : this.context.activeProducts[item].custom_unity ? "/ " + this.context.activeProducts[item].custom_unity_text : "")
    return "Sin precio"
  }


  render() {

    return (
      <View showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator style={{overflowY: "scroll", zIndex: -1, width: "95%", height: window.innerHeight, backgroundColor: "#f5f5f5" }}>
        <View style={{ width: "100%", height: 60, backgroundColor: "#fff", flexDirection: "row", boxShadow: "-10px -10px 10px rgba(0,0,0,0.1)", borderBottomWidth: 2, borderColor: "#f5f5f5" }}>
          <View style={{ flexDirection: "row", width: "70%", paddingLeft: 20 }}>
            <TouchableOpacity onLongPress={() => {
              this.context.filterProducts("")
              this.setState({ status: "PRODUCTS" })
            }}
              onPress={() => {
                this.context.filterProducts("")
                this.setState({ status: "PRODUCTS" })
              }}

              style={{ width: "15%", alignItems: "center", justifyContent: "center", backgroundColor: this.state.status == "PRODUCTS" ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 10 }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "PRODUCTS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                {this.context.translate("products.add_home")}
              </Text>

            </TouchableOpacity>
            <TouchableOpacity
              onLongPress={() => {

                this.setState({ status: "SUPPLEMENTS" })
              }}

              onPress={() => {

                this.setState({ status: "SUPPLEMENTS" })
              }}

              style={{ width: "15%", alignItems: "center", justifyContent: "center", backgroundColor: this.state.status == "SUPPLEMENTS" ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 10 }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "SUPPLEMENTS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                Suplementos
              </Text>

            </TouchableOpacity>
            <TouchableOpacity
              onLongPress={() => {
                this.context.filterCategories("")
                this.setState({ status: "CATEGORIES" })
              }}

              onPress={() => {
                this.context.filterCategories("")
                this.setState({ status: "CATEGORIES" })
              }}

              style={{ width: "15%", alignItems: "center", justifyContent: "center", backgroundColor: this.state.status == "CATEGORIES" ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 10 }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "CATEGORIES" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                {this.context.translate("categories.add_home")}
              </Text>

            </TouchableOpacity>

            <TouchableOpacity
              onLongPress={() => {

                this.setState({ status: "MENUS" })
              }}

              onPress={() => {

                this.setState({ status: "MENUS" })
              }}

              style={{ width: "15%", alignItems: "center", justifyContent: "center", backgroundColor: this.state.status == "MENUS" ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 10 }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "MENUS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                {this.context.translate("menu.add_home")}
              </Text>

            </TouchableOpacity>

            <TouchableOpacity
              onLongPress={() => {

                this.setState({ status: "HOURS" })
              }}

              onPress={() => {

                this.setState({ status: "HOURS" })
              }}

              style={{ width: "15%", alignItems: "center", justifyContent: "center", backgroundColor: this.state.status == "HOURS" ? "#68dc91" : "#fff", borderTopLeftRadius: 10, borderTopRightRadius: 10, marginTop: 10 }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "HOURS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                {this.context.translate("schedule.add_home")}
              </Text>

            </TouchableOpacity>

          </View>
          <View style={{ width: "30%", justifyContent: "center", alignItems: "center", marginRight: 20 }}>
            <TouchableOpacity

              onLongPress={() => { window.open("https://buynessapp.com/establishment/" + this.context.establishmentInfo.id, '_blank') }}
              onPress={() => { window.open("https://buynessapp.com/establishment/" + this.context.establishmentInfo.id, '_blank') }}
              style={{ alignSelf: "flex-end", marginRight: 20, backgroundColor: "#68dc91", borderRadius: 10 }}>
              <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: 20, paddingVertical: 10 }}>
                {this.context.translate("see_shop.add_home")}
              </Text>



            </TouchableOpacity>
          </View>


        </View>




        {
          this.state.status == "SUPPLEMENTS" &&
          <View>
            <TouchableOpacity
              onPress={() => this.setState({ activeEditBarTerraceSupplement: false, editBarTerraceSupplement: "" })}
              onLongPress={() => this.setState({ activeEditBarTerraceSupplement: false, editBarTerraceSupplement: "" })}
              activeOpacity={1} style={{ overflow: "visible", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: window.innerHeight * 0.03, backgroundColor: "#fff", paddingBottom: 30 }}>
              <View style={{ marginLeft: 20 }}>

                <TouchableOpacity
                  onLongPress={() => this.setState({ activeEditBarTerraceSupplement: true, editBarTerraceSupplement: this.context.establishmentInfo.bar_terrace })}
                  onPress={() => this.setState({ activeEditBarTerraceSupplement: true, editBarTerraceSupplement: this.context.establishmentInfo.bar_terrace })}
                  style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "#fff", borderWidth: 1, borderColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingLeft: 10, paddingVertical: window.innerHeight * 0.02 }}>
                    Suplemento terraza
                  </Text>

                  <Text style={{ textDecorationLine: "none", color: "#68dc91", fontWeight: "500", fontSize: "1rem", textAlign: "left", paddingHorizontal: 10, paddingVertical: window.innerHeight * 0.02 }}>
                    |
                  </Text>
                  {this.state.activeEditBarTerraceSupplement ?
                    <View style={{ flexDirection: "row" }}>
                      <TextInput value={this.state.editBarTerraceSupplement} onChangeText={t => this.setState({ editBarTerraceSupplement: t })} numberOfLines={1} placeholder={"+0.10 € por producto"} style={{ borderRadius: 5, fontSize: "1rem", width: 300, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                      <TouchableOpacity
                        onLongPress={() => this.updateBarTerraceDescription()}
                        onPress={() => this.updateBarTerraceDescription()}
                        style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginHorizontal: 5, borderRadius: 10 }}>
                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: 10, paddingVertical: 5 }}>
                          Guardar
                        </Text>



                      </TouchableOpacity>

                    </View>
                    :
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingLeft: 5, paddingRight: 10, paddingVertical: 5 }}>
                      {!!this.context.establishmentInfo.bar_terrace ? this.context.establishmentInfo.bar_terrace : "No"}
                    </Text>
                  }

                </TouchableOpacity>

              </View>
              <TouchableOpacity
                onLongPress={() => this.setState({ newProductCategories: [], showNewMenu: false, showNewCategory: false, showNewProduct: false, showNewSupplement: true })}
                onPress={() => this.setState({ newProductCategories: [], showNewMenu: false, showNewCategory: false, showNewProduct: false, showNewSupplement: true })}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: 15 }}>
                  + Añadir suplemento
                </Text>



              </TouchableOpacity>
            </TouchableOpacity>

            <View style={{ width: "100%%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row" }}>
              <View style={{ width: "80%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  Nombre del suplemento
                </Text>

              </View>



              <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                  Importe
                </Text>

              </View>




            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#f5f5f5" }} />
            {((this.context.activeSupplements &&
              Object.keys(this.context.activeSupplements).length == 0) || this.context.activeSupplements == null) &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: 30, width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  Aún no has creado ningún suplemento
                </Text>
              </View>
            }

            {
              Object.keys(this.context.activeSupplements).map((item, index) => (
                <TouchableOpacity
                  onPress={() => this.editSupplement(item, index)}
                  onLongPress={() => this.editSupplement(item, index)}

                  style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                  <View style={{ width: "80%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                      {this.context.activeSupplements[item].title}
                    </Text>


                  </View>

                  <View style={{ width: "20%", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                    <Text style={{ color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center" }}>
                      {this.context.activeSupplements[item].price ? this.context.activeSupplements[item].price : "Sin coste"}
                    </Text>

                  </View>

                </TouchableOpacity>
              ))
            }




          </View>
        }





        {this.state.editActualSupplement &&
          <View style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  Editar suplemento

                </Text>
                <View style={{ height: 30 }} />
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View style={{ width: "50%" }}>
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          1
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Título
                      </Text>
                    </View>
                    <TextInput defaultValue={this.state.activeSupplement.title} onChangeText={t => this.editActualSupplementFunction("title", t)} numberOfLines={1} placeholder={"Queso"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          2
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Importe
                      </Text>
                    </View>
                    <TextInput defaultValue={this.state.activeSupplement.price} onChangeText={t => this.editActualSupplementFunction("price", t)} numberOfLines={1} placeholder={"0.20"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  </View>
                </View>
                <View style={{ height: 30 }} />
                <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
                  <TouchableOpacity
                    onPress={() => this.deleteSupplement()}
                    onLongPress={() => this.deleteSupplement()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                      Eliminar
                    </Text>



                  </TouchableOpacity>
                  <TouchableOpacity
                    disabled={!this.state.activeSupplement.title || isNaN(this.state.activeSupplement.price)}
                    onPress={() => this.editSupplementSave()}
                    onLongPress={() => this.editSupplementSave()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.activeSupplement.title || isNaN(this.state.activeSupplement.price)) ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                      Guardar cambios
                    </Text>



                  </TouchableOpacity>



                </View>
                {(!this.state.activeSupplement.title || isNaN(this.state.activeSupplement.price)) &&
                  <View style={{ alignSelf: "center" }}>
                    <View style={{ height: 20 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Introduce un título y un precio correctos para editar el suplemento
                    </Text>
                  </View>
                }
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ editActualSupplement: false })} onPress={() => this.setState({ editActualSupplement: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
          </View>
        }

        {this.state.showNewSupplement &&
          <View style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  Nuevo suplemento

                </Text>
                <View style={{ height: 30 }} />
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View style={{ width: "50%" }}>
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          1
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Título
                      </Text>
                    </View>
                    <TextInput onChangeText={t => this.newSupplementField("title", t)} numberOfLines={1} placeholder={"Queso"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          2
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Importe
                      </Text>
                    </View>
                    <TextInput onChangeText={t => this.newSupplementField("price", t)} numberOfLines={1} placeholder={"0.20"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  </View>
                </View>
                <View style={{ height: 30 }} />
                <TouchableOpacity
                  disabled={!this.state.newSupplement.title || isNaN(this.state.newSupplement.price)}
                  onPress={() => this.createSupplement()}
                  onLongPress={() => this.createSupplement()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newSupplement.title || isNaN(this.state.newSupplement.price) ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                    Crear suplemento
                  </Text>



                </TouchableOpacity>

                {(!this.state.newSupplement.title || isNaN(this.state.newSupplement.price)) &&
                  <View style={{ alignSelf: "center" }}>
                    <View style={{ height: 20 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Escribe un título y un precio correctos para poder crear el suplemento
                    </Text>
                  </View>
                }

              </View>

              <TouchableOpacity onLongPress={() => this.setState({ showNewSupplement: false })} onPress={() => this.setState({ showNewSupplement: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
          </View>
        }

        {
          this.state.status == "MENUS" &&
          <View>
            <View style={{ overflow: "visible", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: window.innerHeight * 0.03, backgroundColor: "#fff", }}>
              <View style={{ marginLeft: 20 }}>

                {false &&
                  <View>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02 }}>
                      Mostrar
                    </Text>
                    <Picker>
                      {["Todos", ...Object.keys(this.context.categoriesData)].map((item, index) => (
                        <Picker.Item label={item} />
                      ))}

                    </Picker>
                  </View>
                }
              </View>
              <TouchableOpacity
                onLongPress={() => this.setState({ newProductCategories: [], showNewMenu: true, showNewCategory: false, showNewProduct: false })}
                onPress={() => this.setState({ newProductCategories: [], showNewMenu: true, showNewCategory: false, showNewProduct: false })}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02 }}>
                  {this.context.translate("add_menu.add_home")}
                </Text>



              </TouchableOpacity>
            </View>

            <View style={{ width: "100%", height: window.innerHeight * 0.03, backgroundColor: "#fff" }} />
            <View style={{ width: "100%%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row" }}>
              <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  {this.context.translate("name_menu.add_home")}
                </Text>

              </View>



              <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                  {this.context.translate("price.add_home")}
                </Text>

              </View>
              <View style={{ width: "30%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                  {this.context.translate("visibility.add_home")}
                </Text>

              </View>


            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />
            {
              Object.keys(this.context.activeMenus).map((item, index) => (
                <TouchableOpacity
                  onPress={() => this.openModalEditMenu(item, index)}
                  onLongPress={() => this.openModalEditMenu(item, index)}

                  style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                  <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                      {item}
                    </Text>

                  </View>

                  <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      {this.context.activeMenus[item].price ? parseFloat(this.context.activeMenus[item].price).toFixed(2) + " € " : "Sin precio"}{this.context.activeMenus[item].kg ? "/ kg" : ""}
                    </Text>

                  </View>
                  <TouchableOpacity
                    onPress={() => { this.showSaveMessage(); this.context.updateMenuAvailability(index) }}
                    onLongPress={() => { this.showSaveMessage(); this.context.updateMenuAvailability(index) }}
                    style={{ width: "20%", marginLeft: "5%", alignItems: "center", justifyContent: "center", backgroundColor: this.context.activeMenus[item].visible ? "#68dc91" : "#e8e8e8", paddingVertical: 15, alignSelf: "center", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left" }}>
                      {this.context.activeMenus[item].visible ? "Visible" : "No visible"}
                    </Text>

                  </TouchableOpacity>
                </TouchableOpacity>
              ))
            }
            {
              Object.keys(this.context.activeMenus).length == 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: 30, width: "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  {this.context.translate("no_menu.add_home")}
                </Text>
              </View>
            }
          </View>
        }


        {
          this.state.status == "HOURS" &&
          <View style={{ height: "100%", paddingBottom: 100 }}>
            <View style={{ flexDirection: "row", width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", justifyContent: "space-between", paddingTop: window.innerHeight * 0.03, backgroundColor: "#fff", }}>
              <Text style={{ height: "100%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", paddingHorizontal: 20, paddingVertical: window.innerHeight * 0.02 }}>
                {this.context.translate("press_to_edit.add_home")}
              </Text>
            </View>
            <View style={{ width: "100%", height: window.innerHeight * 0.03, backgroundColor: "#fff" }} />
            <View style={{ width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ width: "80%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  {this.context.translate("day.add_home")}
                </Text>

              </View>




              <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                  {this.context.translate("horario.add_home")}
                </Text>

              </View>
            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />
            {
              this.context.establishmentInfo.hours.map((item, index) => (
                <TouchableOpacity
                  onPress={() => this.manageOpenEditingModal(index)}
                  onLongPress={() => this.manageOpenEditingModal(index)}

                  style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                  <View style={{ width: "80%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                      {daysNames[index]}
                    </Text>

                  </View>
                  <View style={{ width: "20%", justifyContent: "center", alignItems: "center" }}>
                    {item.hours.length == 0 &&
                      <Text>

                        Cerrado
                      </Text>

                    }
                    {
                      item.hours.map((item_i, index_i) => (
                        <Text>

                          de {item_i.init} a {item_i.final}
                        </Text>

                      ))
                    }

                  </View>
                </TouchableOpacity>
              ))}
          </View>
        }

        {
          this.state.status == "PRODUCTS" &&

          <View style={{ height: "100%", paddingBottom: 100 }}>
            <View style={{ overflow: "visible", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: window.innerHeight * 0.03, backgroundColor: "#fff", }}>
              <View style={{ paddingLeft: 20 }}>


                {false &&
                  <View>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02 }}>
                      Mostrar
                    </Text>
                    <Picker>
                      {["Todos", ...Object.keys(this.context.categoriesData)].map((item, index) => (
                        <Picker.Item label={item} />
                      ))}

                    </Picker>
                  </View>
                }
              </View>
              <TouchableOpacity
                onLongPress={() => this.setState({ selectedIndexOptionNewProduct: -1, newProductCategories: [], showNewMenu: false, showNewCategory: false, showNewProduct: true, newProduct: JSON.parse(JSON.stringify(newProductTemplate)) })}
                onPress={() => this.setState({ selectedIndexOptionNewProduct: -1, newProductCategories: [], showNewMenu: false, showNewCategory: false, showNewProduct: true, newProduct: JSON.parse(JSON.stringify(newProductTemplate)) })}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02 }}>
                  {this.context.translate("add_products.add_home")}
                </Text>



              </TouchableOpacity>
            </View>

            <View style={{ width: "100%", height: 50, backgroundColor: "#fff" }} />


            <Accordion allowMultipleExpanded allowZeroExpanded style={{ width: "97%" }}>
              {
                Object.keys(this.context.activeNoCategoriesProducts).length > 0 &&

                <AccordionItem style={{ width: "100%" }}>
                  <AccordionItemHeading style={{ backgroundColor: "#fff" }}>

                    <AccordionItemButton style={{ backgroundColor: "#fff", borderColor: "#f5f5f5" }}>

                      <Text>
                        Sin categoría ({Object.keys(this.context.activeNoCategoriesProducts).length})
                      </Text>


                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
                    <View style={{ height: 10 }} />
                    <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Estos productos no aparecerán a tus clientes ya que todavía no pertenecen a ninguna categoría
                    </Text>
                    <View style={{ height: 20 }} />
                    <View style={{ width: "100%", borderWidth: 1, borderColor: "#f5f5f5" }}>
                      {
                        Object.keys(this.context.activeNoCategoriesProducts).length == 0 &&
                        <View style={{ justifyContent: "center", alignItems: "center", marginTop: window.innerHeight * 0.0025, width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                          <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                            Sin productos para esta categoría. Cúando crees un nuevo producto aparecerá aquí.
                          </Text>
                        </View>
                      }
                    </View>
                    {Object.keys(this.context.activeNoCategoriesProducts).map((item_internal, index_internal) => (
                      <View>
                        {this.context.activeProducts[item_internal] &&
                          <TouchableOpacity
                            onPress={() => this.editDish(item_internal, index_internal)}
                            onLongPress={() => this.editDish(item_internal, index_internal)}

                            style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                            <View style={{ width: "80%", alignItems: "flex-start", justifyContent: "center" }}>
                              <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                                {item_internal}
                              </Text>


                            </View>

                            <View style={{ width: "20%", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                              <Text style={{ textDecorationLine: this.context.activeProducts[item_internal].offer ? "line-through" : "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center" }}>
                                {this.generateProductPriceInfo(item_internal)
                                }
                              </Text>
                              {
                                this.context.activeProducts[item_internal].price_offer &&
                                <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "300", fontSize: "1rem", textAlign: "center", paddingLeft: 10 }}>
                                  {this.context.activeProducts[item_internal].price_offer ?
                                    parseFloat(this.context.activeProducts[item_internal].price_offer).toFixed(2) + " € " :
                                    "Sin precio"}
                                  {this.context.activeProducts[item_internal].kg ?
                                    "/ kg" : ""}
                                  {!this.context.activeProducts[item_internal].custom_unity ?
                                    "/ " + this.context.activeProducts[item_internal].custom_unity_text : ""}
                                </Text>
                              }

                            </View>

                          </TouchableOpacity>
                        }
                      </View>
                    ))}
                  </AccordionItemPanel>


                </AccordionItem>
              }

              {Object.keys(this.context.activeCategories).map((item, index) => (
                <AccordionItem style={{ width: "100%" }}>
                  <AccordionItemHeading style={{ backgroundColor: "#fff" }}>

                    <AccordionItemButton style={{ backgroundColor: "#fff", borderColor: "#f5f5f5" }}>

                      <Text>
                        {item} ({this.context.activeCategories[item].data.length})
                      </Text>


                    </AccordionItemButton>


                  </AccordionItemHeading>
                  <AccordionItemPanel style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
                    <View style={{ width: "100%", borderWidth: 1, borderColor: "#f5f5f5" }}>
                      {
                        this.context.activeCategories[item].data.length == 0 &&
                        <View style={{ justifyContent: "center", alignItems: "center", marginTop: window.innerHeight * 0.0025, width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                          <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                            Sin productos para esta categoría. Añade nuevos productos desde la pestaña de categorías.
                          </Text>
                        </View>
                      }
                      {this.context.activeCategories[item].data.map((item_internal, index_internal) => (
                        <View >
                          {this.context.activeProducts[this.context.activeProductsIDs[item_internal]] &&
                            <TouchableOpacity
                              onPress={() => this.editDish(this.context.activeProductsIDs[item_internal], index_internal)}
                              onLongPress={() => this.editDish(this.context.activeProductsIDs[item_internal], index_internal)}

                              style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                              <View style={{ width: "60%", alignItems: "flex-start", justifyContent: "center" }}>
                                <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                                  {this.context.activeProductsIDs[item_internal]}
                                </Text>


                              </View>

                              <View style={{ width: "20%", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                                <Text style={{ textDecorationLine: this.context.activeProducts[this.context.activeProductsIDs[item_internal]].offer ? "line-through" : "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center" }}>
                                  {this.generateProductPriceInfo(this.context.activeProductsIDs[item_internal])}
                                </Text>
                                {
                                  this.context.activeProducts[this.context.activeProductsIDs[item_internal]].price_offer &&
                                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "300", fontSize: "1rem", textAlign: "center", paddingLeft: 10 }}>
                                    {this.context.activeProducts[this.context.activeProductsIDs[item_internal]].price_offer ? parseFloat(this.context.activeProducts[this.context.activeProductsIDs[item_internal]].price_offer).toFixed(2) + " € " : "Sin precio"}{this.context.activeProducts[item_internal].kg ? "/ kg" : this.context.activeProducts[this.context.activeProductsIDs[item_internal]].custom_unity ? this.context.activeProducts[this.context.activeProductsIDs[item_internal]].custom_unity_text : ""}
                                  </Text>
                                }

                              </View>
                              <TouchableOpacity

                                onPress={() => this.toggleAvaliabilityProduct(item_internal)}
                                onLongPress={() => this.toggleAvaliabilityProduct(item_internal)}
                                style={{ width: "15%", marginLeft: "2.5%", alignSelf: "center", alignItems: "center", justifyContent: "center", paddingVertical: 15, backgroundColor: this.context.activeProducts[this.context.activeProductsIDs[item_internal]].available ? "#68dc91" : "#F5F5F5", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                                  {this.context.activeProducts[this.context.activeProductsIDs[item_internal]].available ? "Disponible" : "No disponible"}
                                </Text>

                              </TouchableOpacity>
                            </TouchableOpacity>
                          }
                        </View>
                      ))}
                    </View>
                  </AccordionItemPanel>

                </AccordionItem>
              ))}
            </Accordion>

            {
              Object.keys(this.context.productsData).length == 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: 30, width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  {this.context.translate("no_products.add_home")}
                </Text>
              </View>
            }


          </View>
        }

        {
          this.state.status == "CATEGORIES" &&

          <View style={{ height: "100%", paddingBottom: 100 }}>

            <View style={{ backgroundColor: "#fff", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: window.innerHeight * 0.03, }}>
              <Text style={{ height: "100%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", paddingHorizontal: 20, paddingVertical: window.innerHeight * 0.02 }}>
                {this.context.translate("drag.add_home")}
              </Text>
              {false &&
                <TextInput
                  onChangeText={(text) => this.context.filterCategories(text)}
                  numberOfLines={1} placeholder={"Buscar una categoría"} style={{ marginLeft: window.innerWidth * 0.01, borderRadius: 10, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "30%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              }
              <TouchableOpacity
                onLongPress={() => this.setState({ showNewMenu: false, showNewCategory: true, showNewProduct: false })}
                onPress={() => this.setState({ showNewMenu: false, showNewCategory: true, showNewProduct: false })}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02 }}>
                  {this.context.translate("add_category.add_home")}
                </Text>



              </TouchableOpacity>
            </View>

            <View style={{ width: "100%", backgroundColor: "#fff", height: window.innerHeight * 0.03 }} />

            <View style={{ width: "100%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row" }}>
              <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  {this.context.translate("name_categories.add_home")}
                </Text>

              </View>

              <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  {this.context.translate("number_items.add_home")}
                </Text>

              </View>
              <View style={{ width: "30%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  {this.context.translate("visibility.add_home")}
                </Text>

              </View>

            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />



            <DragDropContext
              //onBeforeCapture={onBeforeCapture}
              //onBeforeDragStart={onBeforeDragStart}
              //onDragStart={onDragStart}
              //onDragUpdate={onDragUpdate}
              onDragEnd={this.onDragEnd}

            >
              <Droppable droppableId="droppable">
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                  >
                    {Object.keys(this.context.categoriesData).map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style,
                            )}>

                            <TouchableOpacity

                              onPress={() => this.openModalEditCategory(item, index)}
                              onLongPress={() => this.openModalEditCategory(item, index)}
                              style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                              <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                                  {item}
                                </Text>
                                <View style={{ height: 10 }} />
                                <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "gray", fontWeight: "300", fontSize: "0.7rem", textAlign: "left", width: "100%" }}>
                                  {this.context.activeCategories[item].description}
                                </Text>
                              </View>


                              <View style={{ width: "20%", alignItems: "center", justifyContent: "center" }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left" }}>
                                  {this.context.activeCategories[item].data.length}
                                </Text>

                              </View>

                              <TouchableOpacity
                                onPress={() => { this.showSaveMessage(); this.context.updateCategoryAvailability(index) }}
                                onLongPress={() => { this.showSaveMessage(); this.context.updateCategoryAvailability(index) }}
                                style={{ width: "20%", marginLeft: "5%", alignItems: "center", justifyContent: "center", backgroundColor: this.context.activeCategories[item].visible ? "#68dc91" : "#e8e8e8", paddingVertical: 15, alignSelf: "center", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left" }}>
                                  {this.context.activeCategories[item].visible ? "Visible" : "No visible"}
                                </Text>

                              </TouchableOpacity>


                            </TouchableOpacity>
                          </div>
                        )}
                      </Draggable>
                    ))

                    }
                  </div>
                )}

              </Droppable>

            </DragDropContext>

            {
              Object.keys(this.context.categoriesData).length == 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: 30, width: this.props.menuOpen ? window.innerWidth * 0.7 : "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: window.innerWidth * 0.01, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  {this.context.translate("no_categories.add_home")}
                </Text>
              </View>
            }

          </View>
        }



        {this.state.showNewMenu &&
          <View style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02, flexDirection: "row" }}>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("add_menu.add_menu")}


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    {this.context.translate("title.add_menu")}
                  </Text>
                  <TextInput onChangeText={t => this.updateNewMenuField("title", t)} numberOfLines={1} placeholder={"Menú diario"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    {this.context.translate("other_info.add_menu")}
                  </Text>
                  <TextInput onChangeText={t => this.updateNewMenuField("description", t)} numberOfLines={3} placeholder={"El precio incluye un refresco o una botella de agua grande"} style={{ marginVertical: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <View style={{ flexDirection: "row", width: "100%" }}>



                    <View style={{ paddingLeft: 20, width: "50%" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                        {this.context.translate("price.add_menu")}
                      </Text>
                      <View style={{ marginTop: 10, flexDirection: "row", alignItems: "center" }}>
                        <TextInput onChangeText={t => this.updateNewMenuField("price", t)} numberOfLines={1} placeholder={"12.00"} style={{ fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", backgroundColor: "#fff" }}>
                          €
                        </Text>
                      </View>
                    </View>
                  </View>

                </View>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01, height: "100%" }}>
                  <ScrollView horizontal style={{ width: "100%", backgroundColor: "#fff" }}>
                    <TouchableOpacity
                      onPress={() => this.setState({ newMenuPickerIndex: 0 })}
                      onLongPress={() => this.setState({ newMenuPickerIndex: 0 })}
                      style={{ paddingVertical: 10, backgroundColor: this.state.newMenuPickerIndex == 0 ? "#68dc91" : "#fff", borderRadius: 5, paddingHorizontal: 5 }}>
                      <Text>
                        {this.context.translate("first.add_menu")}
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => this.setState({ newMenuPickerIndex: 1 })}
                      onLongPress={() => this.setState({ newMenuPickerIndex: 1 })}
                      style={{ paddingVertical: 10, backgroundColor: this.state.newMenuPickerIndex == 1 ? "#68dc91" : "#fff", borderRadius: 5, paddingHorizontal: 5 }}>
                      <Text>
                        {this.context.translate("second.add_menu")}
                      </Text>
                    </TouchableOpacity>

                  </ScrollView>
                  <View style={{ width: "100%", height: 10 }} />
                  {Object.keys(this.context.productsData).length > 0 &&
                    <ScrollView style={{ backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", maxHeight: window.innerHeight * 0.6, height: "100%", paddingTop: 20 }}>
                      {
                        Object.keys(this.context.productsData).map((item, index) => (
                          <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                            <TouchableOpacity
                              onPress={() => this.updateNewMenuProducts(this.context.productsData[item].id)}
                              onLongPress={() => this.updateNewMenuProducts(this.context.productsData[item].id)}
                              style={{ width: "100%", flexDirection: "row", height: 30 }}>
                              <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start" }}>
                                <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: this.checkNewMenuProductActive(this.context.productsData[item].id) ? "500" : "400", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>

                                  {item}
                                </Text>
                              </View>
                              <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                                <View style={{ width: 18, height: 18, backgroundColor: this.checkNewMenuProductActive(this.context.productsData[item].id) ? "#68dc91" : "#F2F2F2" }}>

                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        ))
                      }

                      <View style={{ height: 10 }} />
                    </ScrollView>
                  }
                  {Object.keys(this.context.productsData).length == 0 &&
                    <View style={{ marginLeft: 20, marginTop: 10, width: "90%", backgroundColor: "#F5F5F5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, paddingVertical: 15 }}>
                        Aún no has creado ningún producto
                      </Text>
                    </View>
                  }
                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ showNewMenu: false })} onPress={() => this.setState({ showNewMenu: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View>
              <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>

                <TouchableOpacity
                  disabled={this.checkIfMenuNewExists() || !this.state.newMenuData.title}
                  onPress={() => this.createMenu()}
                  onLongPress={() => this.createMenu()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: (this.checkIfMenuNewExists() || !this.state.newMenuData.title) ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                    {this.context.translate("create_menu.add_menu")}
                  </Text>



                </TouchableOpacity>


                {!this.state.newMenuData.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      {this.context.translate("write_title.add_menu")}
                    </Text>
                  </View>
                }
                {this.checkIfMenuNewExists() &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      Ya existe un menú con ese nombre
                    </Text>
                  </View>
                }
              </View>
            </View>
          </View>
        }


        {this.state.showNewCategory &&
          <View style={{ position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02, flexDirection: "row" }}>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("new.add_categories")}


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    {this.context.translate("title.add_categories")}
                  </Text>
                  <TextInput onChangeText={t => this.updateNewCategoryField("title", t)} numberOfLines={1} placeholder={"Hamburguesas"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    {this.context.translate("other_info.add_categories")}
                  </Text>
                  <TextInput onChangeText={t => this.updateNewCategoryField("description", t)} numberOfLines={3} placeholder={"Todas las hamburguesas van acompañadas de patatas y ensalada"} style={{ marginVertical: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />




                </View>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01, height: "100%" }}>
                  <View style={{ width: "100%", backgroundColor: "#fff" }}>
                    <View style={{ flexDirection: "row", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, justifyContent: "space-between", width: "90%" }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", paddingVertical: window.innerHeight * 0.01, paddingHorizontal: window.innerWidth * 0.01 }}>
                        {this.context.translate("select_items.add_categories")} {this.state.activeNewCategoryProducts}
                      </Text>

                    </View>
                  </View>
                  {Object.keys(this.context.productsData).length > 0 &&
                    <ScrollView style={{ backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", maxHeight: window.innerHeight * 0.6, height: "100%", paddingTop: 20 }}>
                      {
                        Object.keys(this.context.productsData).map((item, index) => (
                          <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                            <TouchableOpacity
                              onPress={() => this.updateNewCategoryProducts(index)}
                              onLongPress={() => this.updateNewCategoryProducts(index)}
                              style={{ width: "100%", flexDirection: "row", height: 30 }}>
                              <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start" }}>
                                <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: this.state.activeNewCategoryProducts.indexOf(item) >= 0 ? "500" : "400", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>

                                  {item}
                                </Text>
                              </View>
                              <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                                <View style={{ width: 18, height: 18, backgroundColor: this.state.activeNewCategoryProducts.indexOf(index) >= 0 ? "#68dc91" : "#F2F2F2" }}>

                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        ))
                      }

                      <View style={{ height: 10 }} />
                    </ScrollView>
                  }
                  {Object.keys(this.context.productsData).length == 0 &&
                    <View style={{ marginLeft: 20, marginTop: 10, width: "90%", backgroundColor: "#F5F5F5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, paddingVertical: 15 }}>
                        Aún no has creado ningún producto
                      </Text>
                    </View>
                  }
                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ showNewCategory: false })} onPress={() => this.setState({ showNewCategory: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View>
              <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>

                <TouchableOpacity
                  disabled={this.checkIfCategoryNewExists() || !this.state.newCategoryData.title}
                  onPress={() => this.createCategory()}
                  onLongPress={() => this.createCategory()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: (this.checkIfCategoryNewExists() || !this.state.newCategoryData.title) ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                    {this.context.translate("create.add_categories")}
                  </Text>



                </TouchableOpacity>


                {!this.state.newCategoryData.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      {this.context.translate("write_title.add_categories")}
                    </Text>
                  </View>
                }

                {this.checkIfCategoryNewExists() &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      Ya existe una categoría con este nombre
                    </Text>
                  </View>
                }
              </View>
            </View>
          </View>
        }
        {this.state.editActualCategory &&
          <ScrollView style={{ position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02, flexDirection: "row" }}>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("edit.edit_categories")}


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    {this.context.translate("title.add_categories")}
                  </Text>
                  <TextInput
                    onChangeText={t => this.updateEditingCategoryField("title", t)}
                    defaultValue={this.state.editingCategory.title}
                    numberOfLines={1} placeholder={"Ej. Hamburguesas"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    Información adicional
                  </Text>
                  <TextInput
                    onChangeText={t => this.updateEditingCategoryField("description", t)}
                    defaultValue={this.state.editingCategory.description}
                    numberOfLines={3} placeholder={"Ej. Todas las hamburguesas van acompañadas de patatas y ensalada"} style={{ marginVertical: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />



                </View>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01, height: "100%" }}>
                  <View style={{ width: "100%", backgroundColor: "#fff" }}>
                    <View style={{ flexDirection: "row", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, justifyContent: "space-between", width: "90%" }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", paddingVertical: window.innerHeight * 0.01, paddingHorizontal: window.innerWidth * 0.01 }}>
                        {this.context.translate("select_items.add_categories")}
                      </Text>

                    </View>
                  </View>
                  <ScrollView style={{ backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", maxHeight: window.innerHeight * 0.6, height: "100%", paddingTop: 10, paddingBottom: 20 }}>
                    {
                      Object.keys(this.context.productsData).map((item, index) => (
                        <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                          <TouchableOpacity
                            onPress={() => this.updateEditingProductList(item)}
                            onLongPress={() => this.updateEditingProductList(item)}
                            style={{ width: "100%", flexDirection: "row" }}>
                            <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start", height: 30 }}>
                              <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>

                                {item}
                              </Text>
                            </View>
                            <View style={{ width: "10%", justifyContent: "center", alignItems: "center", height: 30 }}>
                              <View style={{ width: 18, height: 18, backgroundColor: this.state.editingCategoryProductsList.indexOf(this.context.activeProducts[item].id) >= 0 ? "#68dc91" : "#F2F2F2" }}>

                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ))
                    }


                  </ScrollView>
                </View>

              </View>
              <TouchableOpacity onPress={() => this.setState({ editActualCategory: false })} onLongPress={() => this.setState({ editActualCategory: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
              <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
                <TouchableOpacity
                  onPress={() => this.deleteCategory()}
                  onLongPress={() => this.deleteCategory()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                    Eliminar
                  </Text>



                </TouchableOpacity>
                <TouchableOpacity
                  disabled={this.checkIfCategoryEditExists()}
                  onPress={() => this.editCategorySave()}
                  onLongPress={() => this.editCategorySave()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.checkIfCategoryEditExists() ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                    Guardar cambios
                  </Text>



                </TouchableOpacity>

              </View>
              {!this.state.editingCategory.title &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    Escribe un título para poder guardar los cambios
                  </Text>
                </View>
              }
              {//caso same name
                Object.keys(this.context.activeCategories).indexOf(this.state.editingCategory.title) != -1
                &&
                (
                  Object.keys(this.context.activeCategories).indexOf(this.state.editingCategory.title)
                  !=
                  Object.keys(this.context.activeCategories).indexOf(this.state.activeCategoryOriginalTitle)
                ) &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                    Este nombre ya existe para otra categoría
                  </Text>
                </View>
              }

            </View>
          </ScrollView>
        }

        {
          this.state.editActualMenu &&
          <View style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02, flexDirection: "row" }}>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("edit_menu.edit_menu")}


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    Título
                  </Text>
                  <TextInput onChangeText={t => this.updateEditingMenuField("title", t)} defaultValue={this.state.editingMenu.title} numberOfLines={1} placeholder={"Menú diario"} style={{ marginTop: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: window.innerWidth * 0.01, marginTop: window.innerHeight * 0.02 }}>
                    Información adicional
                  </Text>
                  <TextInput onChangeText={t => this.updateEditingMenuField("description", t)} defaultValue={this.state.editingMenu.description} numberOfLines={3} placeholder={"El precio incluye un refresco o una botella de agua grande"} style={{ marginVertical: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <View style={{ flexDirection: "row", width: "100%" }}>



                    <View style={{ width: "50%" }}>
                      <Text numberOfLines={1} style={{ paddingLeft: 20, color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", backgroundColor: "#fff" }}>
                        Precio
                      </Text>
                      <View style={{ paddingLeft: 20, flexDirection: "row", alignItems: "center" }}>
                        <TextInput onChangeText={t => this.updateEditingMenuField("price", t)} defaultValue={this.state.editingMenu.price} numberOfLines={1} placeholder={"12.00"} style={{ paddingLeft: 20, marginVertical: window.innerHeight * 0.02, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", backgroundColor: "#fff" }}>
                          €
                        </Text>
                      </View>
                    </View>
                  </View>

                </View>
                <View style={{ width: "50%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.01, height: "100%" }}>
                  <ScrollView horizontal style={{ width: "100%", backgroundColor: "#fff" }}>
                    <TouchableOpacity
                      onPress={() => this.setState({ editingMenuPickerIndex: 0 })}
                      onLongPress={() => this.setState({ editingMenuPickerIndex: 0 })}
                      style={{ paddingVertical: 10, backgroundColor: this.state.editingMenuPickerIndex == 0 ? "#68dc91" : "#fff", borderRadius: 5, paddingHorizontal: 5 }}>
                      <Text>
                        Primeros
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => this.setState({ editingMenuPickerIndex: 1 })}
                      onLongPress={() => this.setState({ editingMenuPickerIndex: 1 })}
                      style={{ paddingVertical: 10, backgroundColor: this.state.editingMenuPickerIndex == 1 ? "#68dc91" : "#fff", borderRadius: 5, paddingHorizontal: 5 }}>
                      <Text>
                        Segundos
                      </Text>
                    </TouchableOpacity>

                  </ScrollView>
                  <View style={{ width: "100%", height: 10 }} />
                  {Object.keys(this.context.productsData).length > 0 &&
                    <ScrollView style={{ backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", maxHeight: window.innerHeight * 0.6, height: "100%", paddingTop: 20 }}>
                      {
                        Object.keys(this.context.productsData).map((item, index) => (
                          <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                            <TouchableOpacity
                              onPress={() => this.updateEditMenuProducts(this.context.productsData[item].id)}
                              onLongPress={() => this.updateEditMenuProducts(this.context.productsData[item].id)}
                              style={{ width: "100%", flexDirection: "row", height: 30 }}>
                              <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start" }}>
                                <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: this.checkEditMenuProductActive(this.context.productsData[item].id) ? "500" : "400", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>

                                  {item}
                                </Text>
                              </View>
                              <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                                <View style={{ width: 18, height: 18, backgroundColor: this.checkEditMenuProductActive(this.context.productsData[item].id) ? "#68dc91" : "#F2F2F2" }}>

                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        ))
                      }

                      <View style={{ height: 50 }} />
                    </ScrollView>
                  }
                  {Object.keys(this.context.productsData).length == 0 &&
                    <View style={{ marginLeft: 20, marginTop: 10, width: "90%", backgroundColor: "#F5F5F5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 10, paddingVertical: 15 }}>
                        Aún no has creado ningún producto
                      </Text>
                    </View>
                  }
                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ editActualMenu: false })} onPress={() => this.setState({ editActualMenu: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View>
              <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>

                <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
                  <TouchableOpacity
                    onPress={() => this.deleteMenu()}
                    onLongPress={() => this.deleteMenu()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                      Eliminar
                    </Text>



                  </TouchableOpacity>
                  <TouchableOpacity
                    disabled={this.checkIfMenuEditExists()}
                    onPress={() => this.editMenuSave()}
                    onLongPress={() => this.editMenuSave()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.checkIfMenuEditExists() ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                      Guardar cambios
                    </Text>

                  </TouchableOpacity>
                </View>



                {!this.state.editingMenu.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      Escribe un título para poder crear el menú
                    </Text>
                  </View>
                }


                {//caso same name
                  Object.keys(this.context.activeMenus).indexOf(this.state.editingMenu.title) != -1
                  &&
                  (
                    Object.keys(this.context.activeMenus).indexOf(this.state.editingMenu.title)
                    !=
                    Object.keys(this.context.activeMenus).indexOf(this.state.activeMenuOriginalTitle)
                  ) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Ya existe un menú con ese nombre
                    </Text>
                  </View>
                }


              </View>
            </View>
          </View>
        }



        {this.state.showNewProduct &&
          <View style={{ overflowY: "scroll", position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <ScrollView showsVerticalScrollIndicator>
              <View style={{ backgroundColor: "#fff", width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
                <View style={{ height: 20 }} />
                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  {this.context.translate("new_product.add_product")}


                </Text>
                <View style={{ width: "100%" }}>
                  <View style={{ height: 30 }} />
                  <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                    <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                      <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        1
                      </Text>
                    </View>
                    <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                      Información básica
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ width: "50%" }}>
                      {!this.state.newProduct.img &&
                        <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImagesNew(acceptedFiles)}>
                          {({ getRootProps, getInputProps }) => (
                            <View style={{ width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, backgroundColor: "#f5f5f5", marginVertical: window.innerHeight * 0.02, borderRadius: 10 }}>

                              <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                                <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />
                                <Text style={{ position: "absolute", top: "45%", alignSelf: "center", justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: window.innerWidth * 0.02 }}>
                                  Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)
                                </Text>

                              </div>
                            </View>
                          )}
                        </Dropzone>
                      }
                      {this.state.newProduct.img &&
                        <View style={{ overflow: "hidden", width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, backgroundColor: "#f5f5f5", marginVertical: window.innerHeight * 0.02, borderRadius: 10 }}>
                          <Image source={this.state.newProduct.img} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                          <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImagesNew(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                              <View style={{ position: "absolute", top: 0, width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, borderRadius: 10 }}>

                                <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                                  <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />


                                  <View
                                    style={{ borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "flex-start", alignSelf: "center" }}>
                                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                                      Cambiar imagen
                                    </Text>



                                  </View>
                                </View>

                              </View>
                            )}
                          </Dropzone>
                          <TouchableOpacity
                            onPress={() => this.deleteImageNew()}
                            onLongPress={() => this.deleteImageNew()}
                            style={{ position: "absolute", top: 10, right: 10, borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "red", alignSelf: "flex-start", alignSelf: "center" }}>
                            <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                              Eliminar
                            </Text>



                          </TouchableOpacity>
                        </View>
                      }
                    </View>
                    <View style={{ width: "50%" }}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.2rem", paddingLeft: 10, backgroundColor: "#fff" }}>
                          Nombre
                        </Text>
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingLeft: 5, backgroundColor: "#fff" }}>
                          (Obligatorio)
                        </Text>
                      </View>
                      <View style={{ height: 10 }} />
                      <TextInput
                        onChangeText={(text) => this.newProductField("title", text)}
                        numberOfLines={1} placeholder={"Hamburguesa con queso"} style={{ borderRadius: 5, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                      <View style={{ height: 20 }} />
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.2rem", paddingLeft: 10, backgroundColor: "#fff" }}>
                        Descripción
                      </Text>
                      <View style={{ height: 10 }} />
                      <TextInput
                        multiline
                        blurOnSubmit={false}
                        numberOfLines={4}
                        onChangeText={(text) => this.newProductField("description", text)}
                        placeholder={"Hamburguesa de 200g de ternera con mozarella"} style={{ justifyContent: "flex-start", marginLeft: 20, width: "90%", marginTop: window.innerHeight * 0.01, borderRadius: 10, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015, height: 150 }} />

                    </View>
                  </View>
                </View>

                <View style={{ width: "100%", flexDirection: "row" }}>
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          2
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Ajustes del precio
                      </Text>
                    </View>


                    <View style={{ height: 20 }} />
                    <View style={{ width: "90%", marginLeft: 20 }}>
                      <View style={{ marginVertical: 2, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio por kg
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("kg", this.state.newProduct.kg != null ? !this.state.newProduct.kg : false)}
                            onLongPress={() => this.newProductField("kg", this.state.newProduct.kg != null ? !this.state.newProduct.kg : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.kg ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.kg ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Oferta
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("offer", this.state.newProduct.offer != null ? !this.state.newProduct.offer : false)}
                            onLongPress={() => this.newProductField("offer", this.state.newProduct.offer != null ? !this.state.newProduct.offer : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.offer ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.offer ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>



                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio por unidad especial
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("custom_unity", this.state.newProduct.custom_unity != null ? !this.state.newProduct.custom_unity : false)}
                            onLongPress={() => this.newProductField("custom_unity", this.state.newProduct.custom_unity != null ? !this.state.newProduct.custom_unity : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.custom_unity ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.custom_unity ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>

                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio variable con opciones
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("options_active", this.state.newProduct.options_active != null ? !this.state.newProduct.options_active : false)}
                            onLongPress={() => this.newProductField("options_active", this.state.newProduct.options_active != null ? !this.state.newProduct.options_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.options_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.options_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio fijo con opciones
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("options_fixed_price_active", this.state.newProduct.options_fixed_price_active != null ? !this.state.newProduct.options_fixed_price_active : false)}
                            onLongPress={() => this.newProductField("options_fixed_price_active", this.state.newProduct.options_fixed_price_active != null ? !this.state.newProduct.options_fixed_price_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.options_fixed_price_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.options_fixed_price_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Suplementos
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.newProductField("supplements_active", this.state.newProduct.supplements_active != null ? !this.state.newProduct.supplements_active : false)}
                            onLongPress={() => this.newProductField("supplements_active", this.state.newProduct.supplements_active != null ? !this.state.newProduct.supplements_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.newProduct.supplements_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.newProduct.supplements_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                    </View>

                    {!this.state.newProduct.options_active &&
                      <View>

                        <View style={{ flexDirection: "row", width: "100%", flexWrap: "wrap" }}>
                          <View style={{ paddingLeft: 20, width: "50%", }}>
                            <View style={{ height: 20 }} />
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", paddingBottom: window.innerHeight * 0.02, backgroundColor: "#fff" }}>
                              Precio
                            </Text>

                            <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                              <TextInput
                                onChangeText={(text) => this.newProductField("price", text)}
                                numberOfLines={1} placeholder={"6.45"} style={{ marginTop: window.innerHeight * 0.01, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                €
                              </Text>
                              {this.state.newProduct.kg &&
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  / kg
                                </Text>
                              }

                              {this.state.newProduct.custom_unity && !!this.state.newProduct.custom_unity_text &&
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  / {this.state.newProduct.custom_unity_text}
                                </Text>
                              }

                            </View>
                          </View>
                          {this.state.newProduct.offer &&
                            <View style={{ paddingLeft: 20, width: "50%", }}>
                              <View style={{ height: 20 }} />
                              <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", paddingBottom: window.innerHeight * 0.02, backgroundColor: "#fff" }}>
                                Precio con oferta
                              </Text>

                              <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                                <TextInput
                                  onChangeText={(text) => this.newProductField("price_offer", text)}
                                  numberOfLines={1} placeholder={"6.45"} style={{ marginTop: window.innerHeight * 0.01, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  €
                                </Text>
                                {this.state.newProduct.kg &&
                                  <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                    / kg
                                  </Text>
                                }


                                {this.state.newProduct.custom_unity && !!this.state.newProduct.custom_unity_text &&
                                  <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                    / {this.state.newProduct.custom_unity_text}
                                  </Text>
                                }

                              </View>
                            </View>
                          }
                          {
                            this.state.newProduct.custom_unity &&
                            <View style={{ paddingLeft: 20, width: "50%", }}>
                              <View style={{ height: 20 }} />
                              <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", backgroundColor: "#fff" }}>
                                Unidad especial
                              </Text>
                              <View style={{ height: 10 }} />
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: "2.5%", paddingBottom: 10, backgroundColor: "#fff" }}>
                                Si quieres vender algo por metros introduce "metro" debajo y tus clientes verán ej. 3.53 € / metro
                              </Text>
                              <View style={{ height: 10 }} />
                              <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                                <TextInput
                                  onChangeText={(text) => this.newProductField("custom_unity_text", text)}
                                  numberOfLines={1} placeholder={"m2"} style={{ fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />


                              </View>
                            </View>
                          }


                        </View>
                      </View>
                    }
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          3
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Alérgenos
                      </Text>
                    </View>
                    <View style={{ height: 20 }} />
                    <View style={{ marginLeft: 20, backgroundColor: "#f5f5f5", width: "90%", borderRadius: 5 }}>
                      <View style={{ width: "100%", height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%" }}>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("fish")}
                          onLongPress={() => this.updateAllergenNewProduct("fish")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <FishIcon innerColor={this.state.newProduct.allergens.indexOf("fish") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("fish") >= 0 ? "#403B8A" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("fish") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Pescado
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("gluten")}
                          onLongPress={() => this.updateAllergenNewProduct("gluten")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <GlutenIcon innerColor={this.state.newProduct.allergens.indexOf("gluten") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("gluten") >= 0 ? "#EE7440" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("gluten") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Gluten
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("crustacean")}
                          onLongPress={() => this.updateAllergenNewProduct("crustacean")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <CrustaceanIcon innerColor={this.state.newProduct.allergens.indexOf("crustacean") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("crustacean") >= 0 ? "#00A1DB" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("crustacean") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Crustáceos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("egg")}
                          onLongPress={() => this.updateAllergenNewProduct("egg")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <EggIcon innerColor={this.state.newProduct.allergens.indexOf("egg") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("egg") >= 0 ? "#F39339" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("egg") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Huevos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("peanut")}
                          onLongPress={() => this.updateAllergenNewProduct("peanut")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <PeanutIcon innerColor={this.state.newProduct.allergens.indexOf("peanut") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("peanut") >= 0 ? "#C47B4F" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("peanut") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Cacahuetes
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("soya")}
                          onLongPress={() => this.updateAllergenNewProduct("soya")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SoyaIcon innerColor={this.state.newProduct.allergens.indexOf("soya") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("soya") >= 0 ? "#009A4C" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("soya") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Soja
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("milk")}
                          onLongPress={() => this.updateAllergenNewProduct("milk")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MilkIcon innerColor={this.state.newProduct.allergens.indexOf("milk") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("milk") >= 0 ? "#804330" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("milk") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Lácteos
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "100%", height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%" }}>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("nuts")}
                          onLongPress={() => this.updateAllergenNewProduct("nuts")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <NutsIcon innerColor={this.state.newProduct.allergens.indexOf("nuts") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("nuts") >= 0 ? "#CF4D51" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("nuts") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Nueces
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("celery")}
                          onLongPress={() => this.updateAllergenNewProduct("celery")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <CeleryIcon innerColor={this.state.newProduct.allergens.indexOf("celery") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("celery") >= 0 ? "#4CAE3B" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("celery") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Apio
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("mustard")}
                          onLongPress={() => this.updateAllergenNewProduct("mustard")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MustardIcon innerColor={this.state.newProduct.allergens.indexOf("mustard") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("mustard") >= 0 ? "#C69838" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("mustard") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Mostaza
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("sesame")}
                          onLongPress={() => this.updateAllergenNewProduct("sesame")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SesameIcon innerColor={this.state.newProduct.allergens.indexOf("sesame") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("sesame") >= 0 ? "#A89A7B" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("sesame") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Sésamo
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("sulphite")}
                          onLongPress={() => this.updateAllergenNewProduct("sulphite")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SulphiteIcon innerColor={this.state.newProduct.allergens.indexOf("sulphite") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("sulphite") >= 0 ? "#8D2F51" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("sulphite") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Sulfitos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("lupin")}
                          onLongPress={() => this.updateAllergenNewProduct("lupin")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <LupinIcon innerColor={this.state.newProduct.allergens.indexOf("lupin") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("lupin") >= 0 ? "#F6D24E" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("lupin") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Altramuces
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenNewProduct("mollusc")}
                          onLongPress={() => this.updateAllergenNewProduct("mollusc")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MolluscIcon innerColor={this.state.newProduct.allergens.indexOf("mollusc") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.newProduct.allergens.indexOf("mollusc") >= 0 ? "#03B2C6" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.newProduct.allergens.indexOf("mollusc") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Moluscos
                          </Text>
                        </TouchableOpacity>

                      </View>
                      <View style={{ width: "100%", height: 20 }} />
                    </View>
                  </View>
                </View>

                <View style={{ width: "50%" }}>
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          4
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Etiquetas
                      </Text>
                    </View>
                  </View>
                  <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>
                    {
                      this.state.newProduct.labels.length==0 &&
                      <View style={{width:"100%",paddingVertical:30}}>
                      <Text style={{paddingHorizontal:10, justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                        Aún no has creado ninguna etiqueta
                      </Text>
                      </View>
                    }
                    {
                      this.state.newProduct.labels.map((label, index_label) => (
                        <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>

                          <View style={{ width: "85%", marginHorizontal: 10, }}>
                            <View style={{borderRadius:100,paddingVertical:5,paddingHorizontal:10,backgroundColor:label.color.color,alignSelf:"flex-start"}}>
                          <Text style={{textDecorationLine: "none", color: label.color.contrast, fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                    
                              {label.label}
                            </Text>
                            </View>
                          </View>

                          <TouchableOpacity
                            onPress={() => this.deleteLabelNewProduct(index_label)}
                            onLongPress={() => this.deleteLabelNewProduct(index_label)}
                            style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                            <IoIosClose size="2em" />
                          </TouchableOpacity>


                        </View>
                      ))
                    }
                  </ScrollView>
                  <View style={{ height: 20 }} />
                  <View style={{ marginHorizontal: 10, borderRadius: 5, width: "100%", borderColor: "#f5f5f5", borderWidth: 1, paddingVertical: 15 }}>
                    <Text style={{ marginHorizontal: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left" }}>
                      Título
                    </Text>
                    <View style={{ height: 10 }} />
                    <TextInput
                    value={this.state.newProductNewLabel.label}
                      onChangeText={(text) => this.updateNewProductNewLabel("label", text)}
                      numberOfLines={1} placeholder={"Picante, Contiene alcohol, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                    <View style={{ height: 10 }} />
                    <Text style={{ marginHorizontal: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left" }}>
                      Color
                    </Text>
                    <View style={{ height: 10 }} />
                    <View style={{ marginHorizontal: 10, flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        [{ color: "#1C5253", contrast: "#fff" },
                        { color: "#C3EB78", contrast: "#000" },
                        { color: "#a01a7d", contrast: "#fff" },
                        { color: "#311847", contrast: "#fff" },
                        { color: "#F9B5AC", contrast: "#000" },
                        { color: "#CCE6F4", contrast: "#000" },
                        { color: "#B27C66", contrast: "#fff" },
                        { color: "#000", contrast: "#fff" },
                        { color: "#f0eff4", contrast: "#000" },
                        { color: "#74776B", contrast: "#fff" },
                        { color: "#645853", contrast: "#fff" },
                        { color: "#5a9367", contrast: "#fff" },
                        { color: "#5cab7d", contrast: "#fff" },
                        { color: "#D62839", contrast: "#fff" },
                        { color: "#eac4d5", contrast: "#000" },
                        { color: "#b8e0d2", contrast: "#000" },
                        { color: "#E2F89C", contrast: "#000" },
                        { color: "#3F7CAC", contrast: "#fff" },
                        { color: "#007991", contrast: "#fff" },
                        { color: "#FFFD77", contrast: "#000" },
                        { color: "#FA8334", contrast: "#fff" },
                        { color: "#271033", contrast: "#fff" },
                        { color: "#9E2B25", contrast: "#fff" },
                        { color: "#F8F32B", contrast: "#000" },
                        { color: "#8D99AE", contrast: "#fff" },
                        { color: "#FE9000", contrast: "#000" },
                        { color: "#CCDBDC", contrast: "#000" },
                        { color: "#68dc91", contrast: "#000" },
                        ].map((color, color_index) => (
                          <View style={{ width: 40, height: 40, justifyContent: "center", alignItems: "center" }} >
                            <TouchableOpacity
                              onPress={() => this.updateNewProductNewLabel("color", color)}
                              onLongPress={() => this.updateNewProductNewLabel("color", color)}
                              style={{ width: 30, height: 30, borderRadius: 100, backgroundColor: color.color, justifyContent: "center", alignItems: "center" }}>
                              {this.state.newProductNewLabel.color.color == color.color &&
                                <IoIosCheckmark size={30} color={color.contrast} />
                              }
                            </TouchableOpacity>
                          </View>
                        ))
                      }

                    </View>
                    <View style={{ height: 20 }} />
                    <TouchableOpacity
                      disabled={!this.state.newProductNewLabel.label || !this.state.newProductNewLabel.color.color}
                      onPress={() => this.addLabelToNewProduct()}
                      onLongPress={() => this.addLabelToNewProduct()}
                      style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.newProductNewLabel.label || !this.state.newProductNewLabel.color.color) ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                        Crear etiqueta
                      </Text>



                    </TouchableOpacity>
                  </View>
                </View>



                {this.state.newProduct.options_active &&
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          5
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Opciones con precio variable
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row", width: "100%" }}>

                      <View style={{ width: "50%", alignItems: "center" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí los grupos de opciones (Tamaño del bocadillo, tamaño de la bebida, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>

                          {this.state.newProduct.options &&
                            this.state.newProduct.options.map((item, index) => (
                              this.state.selectedIndexOptionNewProduct != index ?

                                <TouchableOpacity
                                  onPress={() => this.setState({ selectedOptionNewProduct: this.state.newProduct.options[index].title, selectedIndexOptionNewProduct: index })}
                                  onLongPress={() => this.setState({ selectedOptionNewProduct: this.state.newProduct.options[index].title, selectedIndexOptionNewProduct: index })}
                                  style={{ width: "100%", alignItems: "center" }}>
                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <Text style={{ paddingRight: 15, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.data.length} opciones
                                    </Text>
                                  </View>

                                </TouchableOpacity>
                                :
                                <View style={{ width: "100%", alignItems: "center" }}>


                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10, alignItems: "center" }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>


                                    <TouchableOpacity
                                      onPress={() => this.deleteSectionOptionsNewProduct(index)}
                                      onLongPress={() => this.deleteSectionOptionsNewProduct(index)}
                                      style={{ alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10 }}>

                                      <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "500", fontSize: "1rem", textAlign: "right", width: "100%" }}>
                                        Eliminar
                                      </Text>
                                    </TouchableOpacity>
                                  </View>

                                </View>
                            ))}
                        </ScrollView>
                        <View style={{ height: 10 }} />
                        <View style={{ width: "90%", alignSelf: "center", borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                          <TextInput
                            value={this.state.newSelectedOptionNewProduct}
                            onChangeText={(text) => this.setState({ newSelectedOptionNewProduct: text })}
                            numberOfLines={3} placeholder={"Tamaño del bocadillo"} style={{ borderRadius: 5, fontSize: "1rem", width: "65%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: 10, marginLeft: 10 }} />
                          <TouchableOpacity
                            disabled={!this.state.newSelectedOptionNewProduct}
                            onPress={() => this.createSectionOptionsNewProduct()}
                            onLongPress={() => this.createSectionOptionsNewProduct()}
                            style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newSelectedOptionNewProduct ? "#F5F5F5" : "#68dc91", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, marginRight: 10 }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Crear
                            </Text>



                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ width: "50%" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí las opciones (Pequeño, mediano, grande, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                          {this.state.newProduct.options && this.state.selectedIndexOptionNewProduct >= 0 && this.state.newProduct.options[this.state.selectedIndexOptionNewProduct] &&
                            this.state.newProduct.options[this.state.selectedIndexOptionNewProduct].data.map((item, index) => (


                              <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                                <View style={{ width: "60%", justifyContent: "center" }}>
                                  <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingTop: 15 }}>
                                    {item.title}
                                  </Text>
                                  {!!item.description &&
                                    <View>
                                      <View style={{ height: 10 }} />
                                      <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "left" }}>
                                        {item.description}
                                      </Text>
                                      <View style={{ height: 10 }} />
                                    </View>
                                  }
                                  {
                                    !item.description &&
                                    <View style={{ height: 15 }} />
                                  }
                                </View>
                                <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
                                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                                    {
                                      !!item.price ?
                                        `${parseFloat(item.price).toFixed(2)}€${this.state.newProduct.kg ? "/kg" : ""}`
                                        :
                                        "Sin precio"
                                    }
                                  </Text>
                                </View>
                                <TouchableOpacity
                                  onPress={() => this.deleteSubSectionOptionsNewProduct(item.title)}
                                  onLongPress={() => this.deleteSubSectionOptionsNewProduct(item.title)}
                                  style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                                  <IoIosClose size="2em" />
                                </TouchableOpacity>
                              </View>
                            ))}

                        </ScrollView>


                        {this.state.selectedIndexOptionNewProduct < 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Selecciona una grupo de opciones para poder añadir nuevas opciones
                            </Text>
                          </View>
                        }

                        {this.state.selectedIndexOptionNewProduct >= 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <View style={{ borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, width: "90%", marginLeft: 10 }}>
                              <View style={{ height: 5, }} />
                              <View style={{ width: "100%", paddingVertical: 10, flexDirection: "row", marginLeft: 10 }}>
                                <TextInput
                                  value={this.state.newProductNewOption.title}
                                  onChangeText={(text) => this.updateNewProductOptionTemplate("title", text)}
                                  numberOfLines={3} placeholder={"Tamaño grande"} style={{ borderRadius: 5, fontSize: "1rem", width: "62.5%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                                <TextInput
                                  value={this.state.newProductNewOption.price}
                                  onChangeText={(text) => this.updateNewProductOptionTemplate("price", text)}
                                  numberOfLines={3} placeholder={"3.20 €"} style={{ marginLeft: "2.5%", borderRadius: 5, fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                              </View>
                              <TextInput
                                value={this.state.newProductNewOption.description}
                                onChangeText={(text) => this.updateNewProductOptionTemplate("description", text)}
                                numberOfLines={3} placeholder={"No disponible, últimas unidades, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "62.5%", alignSelf: "flex-start", marginLeft: 10, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <View style={{ height: 10 }} />


                              <TouchableOpacity
                                disabled={!this.state.newProductNewOption.title || isNaN(this.state.newProductNewOption.price)}
                                onPress={() => this.createSubSectionOptionsNewProduct()}
                                onLongPress={() => this.createSubSectionOptionsNewProduct()}
                                style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.newProductNewOption.title || isNaN(this.state.newProductNewOption.price)) ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Añadir
                                </Text>



                              </TouchableOpacity>
                              <View style={{ height: 10, }} />
                            </View>
                            {(!this.state.newProductNewOption.title || isNaN(this.state.newProductNewOption.price)) &&
                              <View style={{ width: "90%", marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                                <View style={{ height: 10 }} />
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Introduce un título y un precio correctos para crear la opción
                                </Text>
                              </View>
                            }
                          </View>
                        }



                      </View>





                    </View>


                  </View>
                }


                {this.state.newProduct.options_fixed_price_active &&
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          5
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Opciones con precio fijo
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row", width: "100%" }}>

                      <View style={{ width: "50%", alignItems: "center" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí los grupos de opciones (Tamaño del bocadillo, tamaño de la bebida, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>

                          {this.state.newProduct.options_fixed_price &&
                            this.state.newProduct.options_fixed_price.map((item, index) => (
                              this.state.selectedIndexOptionFixedPriceNewProduct != index ?

                                <TouchableOpacity
                                  onPress={() => this.setState({ selectedOptionFixedPriceNewProduct: this.state.newProduct.options_fixed_price[index].title, selectedIndexOptionFixedPriceNewProduct: index })}
                                  onLongPress={() => this.setState({ selectedOptionFixedPriceNewProduct: this.state.newProduct.options_fixed_price[index].title, selectedIndexOptionFixedPriceNewProduct: index })}
                                  style={{ width: "100%", alignItems: "center" }}>
                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <Text style={{ paddingRight: 15, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.data.length} opciones
                                    </Text>
                                  </View>

                                </TouchableOpacity>
                                :
                                <View style={{ width: "100%", alignItems: "center" }}>


                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10, alignItems: "center" }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>


                                    <TouchableOpacity
                                      onPress={() => this.deleteSectionOptionsFixedPriceNewProduct(index)}
                                      onLongPress={() => this.deleteSectionOptionsFixedPriceNewProduct(index)}
                                      style={{ alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10 }}>

                                      <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "500", fontSize: "1rem", textAlign: "right", width: "100%" }}>
                                        Eliminar
                                      </Text>
                                    </TouchableOpacity>
                                  </View>

                                </View>
                            ))}
                        </ScrollView>
                        <View style={{ height: 10 }} />
                        <View style={{ width: "90%", alignSelf: "center", borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                          <TextInput
                            value={this.state.newSelectedOptionFixedPriceNewProduct}
                            onChangeText={(text) => this.setState({ newSelectedOptionFixedPriceNewProduct: text })}
                            numberOfLines={3} placeholder={"Tamaño del bocadillo"} style={{ borderRadius: 5, fontSize: "1rem", width: "65%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: 10, marginLeft: 10 }} />
                          <TouchableOpacity
                            disabled={!this.state.newSelectedOptionFixedPriceNewProduct}
                            onPress={() => this.createSectionOptionsFixedPriceNewProduct()}
                            onLongPress={() => this.createSectionOptionsFixedPriceNewProduct()}
                            style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newSelectedOptionFixedPriceNewProduct ? "#F5F5F5" : "#68dc91", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, marginRight: 10 }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Crear
                            </Text>



                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ width: "50%" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí las opciones (Pequeño, mediano, grande, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                          {this.state.newProduct.options_fixed_price && this.state.selectedIndexOptionFixedPriceNewProduct >= 0 && this.state.newProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceNewProduct] &&
                            this.state.newProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceNewProduct].data.map((item, index) => (


                              <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                                <View style={{ width: "90%", justifyContent: "center" }}>
                                  <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingTop: 15 }}>
                                    {item.title}
                                  </Text>
                                  {!!item.description &&
                                    <View>
                                      <View style={{ height: 10 }} />
                                      <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "left" }}>
                                        {item.description}
                                      </Text>
                                      <View style={{ height: 10 }} />
                                    </View>
                                  }
                                  {
                                    !item.description &&
                                    <View style={{ height: 15 }} />
                                  }
                                </View>
                               
                                <TouchableOpacity
                                  onPress={() => this.deleteSubSectionOptionsFixedPriceNewProduct(item.title)}
                                  onLongPress={() => this.deleteSubSectionOptionsFixedPriceNewProduct(item.title)}
                                  style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                                  <IoIosClose size="2em" />
                                </TouchableOpacity>
                              </View>
                            ))}

                        </ScrollView>


                        {this.state.selectedIndexOptionFixedPriceNewProduct < 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Selecciona una grupo de opciones para poder añadir nuevas opciones
                            </Text>
                          </View>
                        }

                        {this.state.selectedIndexOptionFixedPriceNewProduct >= 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <View style={{ borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, width: "90%", marginLeft: 10 }}>
                              <View style={{ height: 5, }} />
                              <View style={{ width: "100%", paddingVertical: 10, flexDirection: "row", marginLeft: 10 }}>
                                <TextInput
                                  value={this.state.newProductNewOptionFixedPrice.title}
                                  onChangeText={(text) => this.updateNewProductOptionFixedPriceTemplate("title", text)}
                                  numberOfLines={3} placeholder={"Tamaño grande"} style={{ borderRadius: 5, fontSize: "1rem", width: "100%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
  
                              </View>
                              <TextInput
                                value={this.state.newProductNewOptionFixedPrice.description}
                                onChangeText={(text) => this.updateNewProductOptionFixedPriceTemplate("description", text)}
                                numberOfLines={3} placeholder={"No disponible, últimas unidades, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "100%", alignSelf: "flex-start", marginLeft: 10, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <View style={{ height: 10 }} />


                              <TouchableOpacity
                                disabled={!this.state.newProductNewOptionFixedPrice.title}
                                onPress={() => this.createSubSectionOptionsFixedPriceNewProduct()}
                                onLongPress={() => this.createSubSectionOptionsFixedPriceNewProduct()}
                                style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newProductNewOptionFixedPrice.title ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Añadir
                                </Text>



                              </TouchableOpacity>
                              <View style={{ height: 10, }} />
                            </View>
                            {!this.state.newProductNewOptionFixedPrice.title &&
                              <View style={{ width: "90%", marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                                <View style={{ height: 10 }} />
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Introduce un título y un precio correctos para crear la opción
                                </Text>
                              </View>
                            }
                          </View>
                        }



                      </View>





                    </View>


                  </View>
                }

                {this.state.newProduct.supplements_active &&
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          {this.state.newProduct.options_active ? "6" : "5"}
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Suplementos
                      </Text>
                    </View>
                    <View style={{ height: 20 }} />
                    <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                      {Object.keys(this.context.activeSupplements).map((item, index) => (


                        <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                          <View style={{ width: "60%", justifyContent: "center" }}>
                            <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                              {item}
                            </Text>
                          </View>
                          <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                              {parseFloat(this.context.activeSupplements[item].price).toFixed(2)} €
                            </Text>
                          </View>
                          <TouchableOpacity
                            onPress={() => this.newProductAddSupplements(this.context.activeSupplements[item].id)}
                            onLongPress={() => this.newProductAddSupplements(this.context.activeSupplements[item].id)}
                            style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                            <View style={{ width: 18, height: 18, backgroundColor: this.state.newProduct.supplements.indexOf(this.context.activeSupplements[item].id) >= 0 ? "#68dc91" : "#F2F2F2" }}>


                            </View>
                          </TouchableOpacity>
                        </View>
                      ))}

                    </ScrollView>



                  </View>
                }
                <TouchableOpacity onLongPress={() => this.setState({ showNewProduct: false })} onPress={() => this.setState({ showNewProduct: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                  <IoIosClose size="2.5em" />
                </TouchableOpacity>

                <View style={{ height: 50, }} />
                <View style={{ width: "95%", alignSelf: "center", justifyContent: "center", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff" }}>

                  <TouchableOpacity
                    disabled={this.checkIfProductNewExists() || !this.state.newProduct.title}
                    onPress={() => this.createProduct()}
                    onLongPress={() => this.createProduct()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.newProduct.title || this.checkIfProductNewExists()) ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                      Crear producto
                    </Text>



                  </TouchableOpacity>
                </View>
                {(this.state.newProduct.offer && !this.state.newProduct.price_offer) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Introduce el precio de la oferta para poder crear el producto
                    </Text>
                  </View>
                }
                {(this.state.newProduct.options_fixed_price_active && this.state.newProduct.options_fixed_price.length==0) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Crea al menos una opción para poder crear el producto
                    </Text>
                  </View>
                }
                {this.state.newProduct.options_fixed_price_active && !this.state.newProduct.price &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Necesitas añadir el precio si estás creando un producto de precio fijo con opciones
                    </Text>
                  </View>
                }

                {!this.state.newProduct.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Escribe un título para poder crear el producto
                    </Text>
                  </View>
                }

                {this.context.activeProducts[this.state.newProduct.title] && !!this.state.newProduct.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Ya existe un producto con este nombre
                    </Text>
                  </View>
                }

                <View style={{ height: 10 }} />
              </View>
              <View style={{ width: "100%", height: window.innerHeight * 0.05 }} />
            </ScrollView>
          </View>
        }

        {this.state.editActualProduct &&
          <View style={{ overflowY: "scroll", position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <ScrollView showsVerticalScrollIndicator>
              <View style={{ backgroundColor: "#fff", width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
                <View style={{ height: 20 }} />
                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  {this.context.translate("edit_product.add_product")}
                  Editar producto

                </Text>
                <View style={{ width: "100%" }}>
                  <View style={{ height: 30 }} />
                  <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                    <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                      <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        1
                      </Text>
                    </View>
                    <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                      Información básica
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ width: "50%" }}>
                      {!this.state.activeProduct.img &&
                        <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImagesEdit(acceptedFiles)}>
                          {({ getRootProps, getInputProps }) => (
                            <View style={{ width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, backgroundColor: "#f5f5f5", marginVertical: window.innerHeight * 0.02, borderRadius: 10 }}>

                              <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                                <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />
                                <Text style={{ position: "absolute", top: "45%", alignSelf: "center", justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: window.innerWidth * 0.02 }}>
                                  Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)
                                </Text>

                              </div>
                            </View>
                          )}
                        </Dropzone>
                      }
                      {this.state.activeProduct.img &&
                        <View style={{ overflow: "hidden", width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, backgroundColor: "#f5f5f5", marginVertical: window.innerHeight * 0.02, borderRadius: 10 }}>
                          <Image source={this.state.activeProduct.img} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                          <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImagesEdit(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                              <View style={{ position: "absolute", top: 0, width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, height: 265, borderRadius: 10 }}>

                                <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                                  <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />


                                  <View
                                    style={{ borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "flex-start", alignSelf: "center" }}>
                                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                                      Cambiar imagen
                                    </Text>



                                  </View>
                                </View>

                              </View>
                            )}
                          </Dropzone>
                          <TouchableOpacity
                            onPress={() => this.deleteImage()}
                            onLongPress={() => this.deleteImage()}
                            style={{ position: "absolute", top: 10, right: 10, borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "red", alignSelf: "flex-start", alignSelf: "center" }}>
                            <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                              Eliminar
                            </Text>



                          </TouchableOpacity>
                        </View>
                      }
                    </View>
                    <View style={{ width: "50%" }}>
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.2rem", paddingLeft: 10, backgroundColor: "#fff" }}>
                          Nombre
                        </Text>
                        <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingLeft: 5, backgroundColor: "#fff" }}>
                          (Obligatorio)
                        </Text>
                      </View>
                      <View style={{ height: 10 }} />
                      <TextInput
                        value={this.state.activeProduct.title}
                        onChangeText={(text) => this.editActualProductFunction("title", text)}
                        numberOfLines={1} placeholder={"Hamburguesa con queso"} style={{ borderRadius: 5, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                      <View style={{ height: 20 }} />
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.2rem", paddingLeft: 10, backgroundColor: "#fff" }}>
                        Descripción
                      </Text>
                      <View style={{ height: 10 }} />
                      <TextInput
                        multiline
                        value={this.state.activeProduct.description}
                        onChangeText={(text) => this.editActualProductFunction("description", text)}
                        numberOfLines={4} placeholder={"Hamburguesa de 200g de ternera con mozarella"}
                        style={{ justifyContent: "flex-start", marginLeft: 20, width: "90%", marginTop: window.innerHeight * 0.01, borderRadius: 10, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015, height: 150 }} />

                    </View>
                  </View>
                </View>

                <View style={{ width: "100%", flexDirection: "row" }}>
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          2
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Ajustes del precio
                      </Text>
                    </View>


                    <View style={{ height: 20 }} />
                    <View style={{ width: "90%", marginLeft: 20 }}>
                      <View style={{ marginVertical: 2, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio por kg
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("kg", this.state.activeProduct.kg != null ? !this.state.activeProduct.kg : false)}
                            onLongPress={() => this.editActualProductFunction("kg", this.state.activeProduct.kg != null ? !this.state.activeProduct.kg : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.kg ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.kg ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Oferta
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("offer", this.state.activeProduct.offer != null ? !this.state.activeProduct.offer : false)}
                            onLongPress={() => this.editActualProductFunction("offer", this.state.activeProduct.offer != null ? !this.state.activeProduct.offer : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.offer ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.offer ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>



                     
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio por unidad especial
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("custom_unity", this.state.activeProduct.custom_unity != null ? !this.state.activeProduct.custom_unity : false)}
                            onLongPress={() => this.editActualProductFunction("custom_unity", this.state.activeProduct.custom_unity != null ? !this.state.activeProduct.custom_unity : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.custom_unity ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.custom_unity ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>

                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio variable con opciones
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("options_active", this.state.activeProduct.options_active != null ? !this.state.activeProduct.options_active : false)}
                            onLongPress={() => this.editActualProductFunction("options_active", this.state.activeProduct.options_active != null ? !this.state.activeProduct.options_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.options_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.options_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio fijo con opciones
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("options_fixed_price_active", this.state.activeProduct.options_fixed_price_active != null ? !this.state.activeProduct.options_fixed_price_active : false)}
                            onLongPress={() => this.editActualProductFunction("options_fixed_price_active", this.state.activeProduct.options_fixed_price_active != null ? !this.state.activeProduct.options_fixed_price_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.options_fixed_price_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.options_fixed_price_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                      <View style={{ marginTop: 5, marginHorizontal: 2, flexDirection: "row", backgroundColor: "#fff", borderWidth: 2, borderRadius: 5, borderColor: "#F5F5F5" }}>
                        <View style={{ width: "80%", justifyContent: "center", height: "100%" }}>
                          <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 10, paddingVertical: 10, backgroundColor: "#fff" }}>
                            Precio con suplementos
                          </Text>
                        </View>
                        <View style={{ width: "20%", flexDirection: "row", alignItems: "center" }}>

                          <TouchableOpacity
                            onPress={() => this.editActualProductFunction("supplements_active", this.state.activeProduct.supplements_active != null ? !this.state.activeProduct.supplements_active : false)}
                            onLongPress={() => this.editActualProductFunction("supplements_active", this.state.activeProduct.supplements_active != null ? !this.state.activeProduct.supplements_active : false)}
                            style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 5, backgroundColor: this.state.activeProduct.supplements_active ? "#68dc91" : "#f5f5f5" }}>
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                              {this.state.activeProduct.supplements_active ? "Sí" : "No"}
                            </Text>
                          </TouchableOpacity>

                        </View>
                      </View>
                    </View>

                    {!this.state.activeProduct.options_active &&
                      <View>

                        <View style={{ flexDirection: "row", width: "100%", flexWrap: "wrap" }}>
                          <View style={{ paddingLeft: 20, width: "50%", }}>
                            <View style={{ height: 20 }} />
                            <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", paddingBottom: window.innerHeight * 0.02, backgroundColor: "#fff" }}>
                              Precio
                            </Text>

                            <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                              <TextInput
                                value={this.state.activeProduct.price}
                                onChangeText={(text) => this.editActualProductFunction("price", text)}
                                numberOfLines={1} placeholder={"6.45"} style={{ marginTop: window.innerHeight * 0.01, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                €
                              </Text>
                              {this.state.activeProduct.kg &&
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  / kg
                                </Text>
                              }
                              {this.state.activeProduct.custom_unity && !!this.state.activeProduct.custom_unity_text &&
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  / {this.state.newProduct.custom_unity_text}
                                </Text>
                              }

                            </View>
                          </View>
                          {this.state.activeProduct.offer &&
                            <View style={{ paddingLeft: 20, width: "50%", }}>
                              <View style={{ height: 20 }} />
                              <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", paddingBottom: window.innerHeight * 0.02, backgroundColor: "#fff" }}>
                                Precio con oferta
                              </Text>

                              <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                                <TextInput
                                  value={this.state.activeProduct.price_offer}
                                  onChangeText={(text) => this.editActualProductFunction("price_offer", text)}
                                  numberOfLines={1} placeholder={"6.45"} style={{ marginTop: window.innerHeight * 0.01, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                                <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                  €
                                </Text>
                                {this.state.activeProduct.kg &&
                                  <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                    / kg
                                  </Text>
                                }
                                {this.state.activeProduct.custom_unity && !!this.state.activeProduct.custom_unity_text &&
                                  <Text numberOfLines={1} style={{ paddingLeft: 20, textAlign: "left", color: "#000", fontWeight: "400", fontSize: "1.2rem", backgroundColor: "#fff" }}>
                                    / {this.state.activeProduct.custom_unity_text}
                                  </Text>
                                }

                              </View>
                            </View>
                          }
                          {
                            this.state.activeProduct.custom_unity &&
                            <View style={{ paddingLeft: 20, width: "50%", }}>
                              <View style={{ height: 20 }} />
                              <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "2.5%", backgroundColor: "#fff" }}>
                                Unidad especial
                              </Text>
                              <View style={{ height: 10 }} />
                              <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: "2.5%", paddingBottom: 10, backgroundColor: "#fff" }}>
                                Si quieres vender algo por metros introduce "metro" debajo y tus clientes verán ej. 3.53 € / metro
                              </Text>
                              <View style={{ height: 10 }} />
                              <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center", alignItems: "center" }}>
                                <TextInput
                                  onChangeText={(text) => this.editActualProductFunction("custom_unity_text", text)}
                                  numberOfLines={1} placeholder={"m2"} style={{ fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />


                              </View>
                            </View>
                          }

                        </View>
                      </View>
                    }
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          3
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Alérgenos
                      </Text>
                    </View>
                    <View style={{ height: 20 }} />
                    <View style={{ marginLeft: 20, backgroundColor: "#f5f5f5", width: "90%", borderRadius: 5 }}>
                      <View style={{ width: "100%", height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%" }}>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("fish")}
                          onLongPress={() => this.updateAllergenEditProduct("fish")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <FishIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("fish") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("fish") >= 0 ? "#403B8A" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("fish") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Pescado
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("gluten")}
                          onLongPress={() => this.updateAllergenEditProduct("gluten")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <GlutenIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("gluten") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("gluten") >= 0 ? "#EE7440" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("gluten") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Gluten
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("crustacean")}
                          onLongPress={() => this.updateAllergenEditProduct("crustacean")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <CrustaceanIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("crustacean") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("crustacean") >= 0 ? "#00A1DB" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("crustacean") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Crustáceos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("egg")}
                          onLongPress={() => this.updateAllergenEditProduct("egg")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <EggIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("egg") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("egg") >= 0 ? "#F39339" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("egg") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Huevos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("peanut")}
                          onLongPress={() => this.updateAllergenEditProduct("peanut")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <PeanutIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("peanut") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("peanut") >= 0 ? "#C47B4F" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("peanut") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Cacahuetes
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("soya")}
                          onLongPress={() => this.updateAllergenEditProduct("soya")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SoyaIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("soya") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("soya") >= 0 ? "#009A4C" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("soya") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Soja
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("milk")}
                          onLongPress={() => this.updateAllergenEditProduct("milk")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MilkIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("milk") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("milk") >= 0 ? "#804330" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("milk") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Lácteos
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "100%", height: 20 }} />
                      <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%" }}>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("nuts")}
                          onLongPress={() => this.updateAllergenEditProduct("nuts")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <NutsIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("nuts") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("nuts") >= 0 ? "#CF4D51" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("nuts") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Nueces
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("celery")}
                          onLongPress={() => this.updateAllergenEditProduct("celery")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <CeleryIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("celery") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("celery") >= 0 ? "#4CAE3B" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("celery") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Apio
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("mustard")}
                          onLongPress={() => this.updateAllergenEditProduct("mustard")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MustardIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mustard") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mustard") >= 0 ? "#C69838" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mustard") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Mostaza
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("sesame")}
                          onLongPress={() => this.updateAllergenEditProduct("sesame")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SesameIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sesame") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sesame") >= 0 ? "#A89A7B" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sesame") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Sésamo
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("sulphite")}
                          onLongPress={() => this.updateAllergenEditProduct("sulphite")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <SulphiteIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sulphite") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sulphite") >= 0 ? "#8D2F51" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("sulphite") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Sulfitos
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("lupin")}
                          onLongPress={() => this.updateAllergenEditProduct("lupin")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <LupinIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("lupin") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("lupin") >= 0 ? "#F6D24E" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("lupin") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Altramuces
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => this.updateAllergenEditProduct("mollusc")}
                          onLongPress={() => this.updateAllergenEditProduct("mollusc")}
                          style={{ justifyContent: "center", alignItems: "center" }}>
                          <MolluscIcon innerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mollusc") >= 0 ? "#fff" : "#F5F5F5"} outerColor={this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mollusc") >= 0 ? "#03B2C6" : "#B5B5B5"} width={40} height={40} />
                          <View style={{ width: "100%", height: 5 }} />
                          <Text style={{ color: this.state.activeProduct.allergens && this.state.activeProduct.allergens.indexOf("mollusc") >= 0 ? "#000" : "gray", fontSize: "0.6rem", fontWeight: "600" }}>
                            Moluscos
                          </Text>
                        </TouchableOpacity>

                      </View>
                      <View style={{ width: "100%", height: 20 }} />
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          4
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Etiquetas
                      </Text>
                    </View>
                  </View>
                  <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>
                    {this.state.activeProduct.labels &&
                      this.state.activeProduct.labels.length==0 &&
                      <View style={{width:"100%",paddingVertical:30}}>
                      <Text style={{paddingHorizontal:10, justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                        Aún no has creado ninguna etiqueta
                      </Text>
                      </View>
                    }
                    {this.state.activeProduct.labels &&
                      this.state.activeProduct.labels.map((label, index_label) => (
                        <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>

                          <View style={{ width: "85%", marginHorizontal: 10, }}>
                            <View style={{borderRadius:100,paddingVertical:5,paddingHorizontal:10,backgroundColor:label.color.color,alignSelf:"flex-start"}}>
                          <Text style={{textDecorationLine: "none", color: label.color.contrast, fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                    
                              {label.label}
                            </Text>
                            </View>
                          </View>

                          <TouchableOpacity
                            onPress={() => this.deleteLabelActiveProduct(index_label)}
                            onLongPress={() => this.deleteLabelActiveProduct(index_label)}
                            style={{ width: "15%", justifyContent: "center", alignItems: "center" }}>
                            <IoIosClose size="2em" />
                          </TouchableOpacity>


                        </View>
                      ))
                    }
                  </ScrollView>
                  <View style={{ height: 20 }} />
                  <View style={{ marginHorizontal: 10, borderRadius: 5, width: "100%", borderColor: "#f5f5f5", borderWidth: 1, paddingVertical: 15 }}>
                    <Text style={{ marginHorizontal: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left" }}>
                      Título
                    </Text>
                    <View style={{ height: 10 }} />
                    <TextInput
                    value={this.state.activeProductNewLabel.label}
                      onChangeText={(text) => this.updateActiveProductNewLabel("label", text)}
                      numberOfLines={1} placeholder={"Picante, Contiene alcohol, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                    <View style={{ height: 10 }} />
                    <Text style={{ marginHorizontal: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left" }}>
                      Color
                    </Text>
                    <View style={{ height: 10 }} />
                    <View style={{ marginHorizontal: 10, flexDirection: "row", flexWrap: "wrap" }}>
                      {
                        [{ color: "#1C5253", contrast: "#fff" },
                        { color: "#C3EB78", contrast: "#000" },
                        { color: "#a01a7d", contrast: "#fff" },
                        { color: "#311847", contrast: "#fff" },
                        { color: "#F9B5AC", contrast: "#000" },
                        { color: "#CCE6F4", contrast: "#000" },
                        { color: "#B27C66", contrast: "#fff" },
                        { color: "#000", contrast: "#fff" },
                        { color: "#f0eff4", contrast: "#000" },
                        { color: "#74776B", contrast: "#fff" },
                        { color: "#645853", contrast: "#fff" },
                        { color: "#5a9367", contrast: "#fff" },
                        { color: "#5cab7d", contrast: "#fff" },
                        { color: "#D62839", contrast: "#fff" },
                        { color: "#eac4d5", contrast: "#000" },
                        { color: "#b8e0d2", contrast: "#000" },
                        { color: "#E2F89C", contrast: "#000" },
                        { color: "#3F7CAC", contrast: "#fff" },
                        { color: "#007991", contrast: "#fff" },
                        { color: "#FFFD77", contrast: "#000" },
                        { color: "#FA8334", contrast: "#fff" },
                        { color: "#271033", contrast: "#fff" },
                        { color: "#9E2B25", contrast: "#fff" },
                        { color: "#F8F32B", contrast: "#000" },
                        { color: "#8D99AE", contrast: "#fff" },
                        { color: "#FE9000", contrast: "#000" },
                        { color: "#CCDBDC", contrast: "#000" },
                        { color: "#68dc91", contrast: "#000" },
                        ].map((color, color_index) => (
                          <View style={{ width: 40, height: 40, justifyContent: "center", alignItems: "center" }} >
                            <TouchableOpacity
                              onPress={() => this.updateActiveProductNewLabel("color", color)}
                              onLongPress={() => this.updateActiveProductNewLabel("color", color)}
                              style={{ width: 30, height: 30, borderRadius: 100, backgroundColor: color.color, justifyContent: "center", alignItems: "center" }}>
                              {this.state.activeProductNewLabel.color.color == color.color &&
                                <IoIosCheckmark size={30} color={color.contrast} />
                              }
                            </TouchableOpacity>
                          </View>
                        ))
                      }

                    </View>
                    <View style={{ height: 20 }} />
                    <TouchableOpacity
                      disabled={!this.state.activeProductNewLabel.label || !this.state.activeProductNewLabel.color.color}
                      onPress={() => this.addLabelToActiveProduct()}
                      onLongPress={() => this.addLabelToActiveProduct()}
                      style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.activeProductNewLabel.label || !this.state.activeProductNewLabel.color.color) ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                        Crear etiqueta
                      </Text>



                    </TouchableOpacity>
                  </View>
                </View>
        {/** xxx */}
                {this.state.activeProduct.options_active &&
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          5
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Opciones con precio variable
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row", width: "100%" }}>

                      <View style={{ width: "50%", alignItems: "center" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí los grupos de opciones (Tamaño del bocadillo, tamaño de la bebida, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>

                          {this.state.activeProduct.options &&
                            this.state.activeProduct.options.map((item, index) => (
                              this.state.selectedIndexOptionEditProduct != index ?

                                <TouchableOpacity
                                  onPress={() => this.setState({ selectedOptionEditProduct: this.state.activeProduct.options[index].title, selectedIndexOptionEditProduct: index })}
                                  onLongPress={() => this.setState({ selectedOptionEditProduct: this.state.activeProduct.options[index].title, selectedIndexOptionEditProduct: index })}
                                  style={{ width: "100%", alignItems: "center" }}>
                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <Text style={{ paddingRight: 15, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.data.length} opciones
                                    </Text>
                                  </View>

                                </TouchableOpacity>
                                :
                                <View style={{ width: "100%", alignItems: "center" }}>


                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#68dc91", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10, alignItems: "center" }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <TouchableOpacity
                                      onPress={() => this.deleteSectionOptionsEditProduct(index)}
                                      onLongPress={() => this.deleteSectionOptionsEditProduct(index)}
                                      style={{ alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10 }}>

                                      <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "500", fontSize: "1rem", textAlign: "right", width: "100%" }}>
                                        Eliminar
                                      </Text>
                                    </TouchableOpacity>
                                  </View>

                                </View>
                            ))}
                        </ScrollView>
                        <View style={{ height: 10 }} />
                        <View style={{ width: "90%", alignSelf: "center", borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                          <TextInput
                            value={this.state.newSelectedOptionEditProduct}
                            onChangeText={(text) => this.setState({ newSelectedOptionEditProduct: text })}
                            numberOfLines={3} placeholder={"Tamaño del bocadillo"} style={{ borderRadius: 5, fontSize: "1rem", width: "65%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: 10, marginLeft: 10 }} />
                          <TouchableOpacity
                            disabled={!this.state.newSelectedOptionEditProduct}
                            onPress={() => this.createSectionOptionsEditProduct()}
                            onLongPress={() => this.createSectionOptionsEditProduct()}
                            style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newSelectedOptionEditProduct ? "#F5F5F5" : "#68dc91", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, marginRight: 10 }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Crear
                            </Text>



                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ width: "50%" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí las opciones (Pequeño, mediano, grande, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                          {this.state.activeProduct.options && this.state.selectedIndexOptionEditProduct >= 0 &&
                            this.state.activeProduct.options[this.state.selectedIndexOptionEditProduct].data.map((item, index) => (


                              <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                                <View style={{ width: "60%", justifyContent: "center" }}>
                                  <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingTop: 15 }}>
                                    {item.title}
                                  </Text>
                                  {!!item.description &&
                                    <View>
                                      <View style={{ height: 10 }} />
                                      <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "left" }}>
                                        {item.description}
                                      </Text>
                                      <View style={{ height: 10 }} />
                                    </View>
                                  }
                                  {
                                    !item.description &&
                                    <View style={{ height: 15 }} />
                                  }
                                </View>
                                <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
                                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                                    {
                                      !!item.price ?
                                        `${parseFloat(item.price).toFixed(2)}€${this.state.activeProduct.kg ? "/kg" : ""}`
                                        :
                                        "Sin precio"
                                    }
                                  </Text>
                                </View>
                                <TouchableOpacity
                                  onPress={() => this.deleteSubSectionOptionsEditProduct(item.title)}
                                  onLongPress={() => this.deleteSubSectionOptionsEditProduct(item.title)}
                                  style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                                  <IoIosClose size="2em" />
                                </TouchableOpacity>
                              </View>
                            ))}

                        </ScrollView>
                        {this.state.selectedIndexOptionEditProduct < 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Selecciona una grupo de opciones para poder añadir nuevas opciones
                            </Text>
                          </View>
                        }


                        {this.state.selectedIndexOptionEditProduct >= 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <View style={{ borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, width: "90%", marginLeft: 10 }}>
                              <View style={{ height: 5, }} />
                              <View style={{ width: "100%", paddingVertical: 10, flexDirection: "row", marginLeft: 10 }}>
                                <TextInput

                                  value={this.state.editProductNewOption.title}
                                  onChangeText={(text) => this.updateEditProductOptionTemplate("title", text)}
                                  numberOfLines={3} placeholder={"Tamaño grande"} style={{ borderRadius: 5, fontSize: "1rem", width: "62.5%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                                <TextInput
                                  value={this.state.editProductNewOption.price}
                                  onChangeText={(text) => this.updateEditProductOptionTemplate("price", text)}
                                  numberOfLines={3} placeholder={"3.20"} style={{ marginLeft: "2.5%", borderRadius: 5, fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                              </View>

                              <TextInput
                                value={this.state.editProductNewOption.description}
                                onChangeText={(text) => this.updateEditProductOptionTemplate("description", text)}
                                numberOfLines={3} placeholder={"No disponible, últimas unidades, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "62.5%", alignSelf: "flex-start", marginLeft: 10, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <View style={{ height: 10 }} />
                              <TouchableOpacity
                                disabled={!this.state.editProductNewOption.title || isNaN(this.state.editProductNewOption.price)}
                                onPress={() => this.createSubSectionOptionsEditProduct()}
                                onLongPress={() => this.createSubSectionOptionsEditProduct()}
                                style={{ alignItems: "center", justifyContent: "center", backgroundColor: (!this.state.editProductNewOption.title || isNaN(this.state.editProductNewOption.price)) ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Añadir
                                </Text>



                              </TouchableOpacity>
                              <View style={{ height: 10, }} />
                            </View>

                            {(!this.state.editProductNewOption.title || isNaN(this.state.editProductNewOption.price)) &&
                              <View style={{ width: "90%", marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                                <View style={{ height: 10 }} />
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Introduce un título y un precio correctos para crear la opción
                                </Text>
                              </View>
                            }
                          </View>
                        }
                      </View>





                    </View>


                  </View>
                }

                {this.state.activeProduct.options_fixed_price_active &&
                  <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>

                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          5
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Opciones con precio fijo
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row", width: "100%" }}>

                      <View style={{ width: "50%", alignItems: "center" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí los grupos de opciones (Tamaño del bocadillo, tamaño de la bebida, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ alignSelf: "center", maxHeight: 300, height: 300, width: "90%", backgroundColor: "#F5F5F5", borderRadius: 5, overflow: "hidden" }}>

                          {this.state.activeProduct.options_fixed_price &&
                            this.state.activeProduct.options_fixed_price.map((item, index) => (
                              this.state.selectedIndexOptionFixedPriceEditProduct != index ?

                                <TouchableOpacity
                                  onPress={() => this.setState({ selectedOptionFixedPriceEditProduct: this.state.activeProduct.options_fixed_price[index].title, selectedIndexOptionFixedPriceEditProduct: index })}
                                  onLongPress={() => this.setState({ selectedOptionFixedPriceEditProduct: this.state.activeProduct.options_fixed_price[index].title, selectedIndexOptionFixedPriceEditProduct: index })}
                                  style={{ width: "100%", alignItems: "center" }}>
                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#F5F5F5", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <Text style={{ paddingRight: 15, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.data.length} opciones
                                    </Text>
                                  </View>

                                </TouchableOpacity>
                                :
                                <View style={{ width: "100%", alignItems: "center" }}>


                                  <View style={{ width: "100%", backgroundColor: "#fff", borderWidth: 1, borderColor: "#68dc91", justifyContent: "space-between", flexDirection: "row", paddingVertical: 10, alignItems: "center" }}>
                                    <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                                      {item.title}
                                    </Text>
                                    <TouchableOpacity
                                      onPress={() => this.deleteSectionOptionsFixedPriceEditProduct(index)}
                                      onLongPress={() => this.deleteSectionOptionsFixedPriceEditProduct(index)}
                                      style={{ alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10 }}>

                                      <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "500", fontSize: "1rem", textAlign: "right", width: "100%" }}>
                                        Eliminar
                                      </Text>
                                    </TouchableOpacity>
                                  </View>

                                </View>
                            ))}
                        </ScrollView>
                        <View style={{ height: 10 }} />
                        <View style={{ width: "90%", alignSelf: "center", borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, justifyContent: "space-between", flexDirection: "row", paddingVertical: 10 }}>
                          <TextInput
                            value={this.state.newSelectedOptionFixedPriceEditProduct}
                            onChangeText={(text) => this.setState({ newSelectedOptionFixedPriceEditProduct: text })}
                            numberOfLines={3} placeholder={"Tamaño del bocadillo"} style={{ borderRadius: 5, fontSize: "1rem", width: "65%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: 10, marginLeft: 10 }} />
                          <TouchableOpacity
                            disabled={!this.state.newSelectedOptionFixedPriceEditProduct}
                            onPress={() => this.createSectionOptionsFixedPriceEditProduct()}
                            onLongPress={() => this.createSectionOptionsFixedPriceEditProduct()}
                            style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newSelectedOptionFixedPriceEditProduct ? "#F5F5F5" : "#68dc91", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, marginRight: 10 }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Crear
                            </Text>



                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ width: "50%" }}>
                        <View style={{ height: 20 }} />
                        <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                          Introduce aquí las opciones (Pequeño, mediano, grande, ...)
                        </Text>
                        <View style={{ height: 20 }} />
                        <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                          {this.state.activeProduct.options_fixed_price && this.state.selectedIndexOptionFixedPriceEditProduct >= 0 && this.state.activeProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceEditProduct] &&
                            this.state.activeProduct.options_fixed_price[this.state.selectedIndexOptionFixedPriceEditProduct].data.map((item, index) => (


                              <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                                <View style={{ width: "60%", justifyContent: "center" }}>
                                  <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingTop: 15 }}>
                                    {item.title}
                                  </Text>
                                  {!!item.description &&
                                    <View>
                                      <View style={{ height: 10 }} />
                                      <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "left" }}>
                                        {item.description}
                                      </Text>
                                      <View style={{ height: 10 }} />
                                    </View>
                                  }
                                  {
                                    !item.description &&
                                    <View style={{ height: 15 }} />
                                  }
                                </View>
                                <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
                                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                                    {
                                      !!item.price ?
                                        `${parseFloat(item.price).toFixed(2)}€${this.state.activeProduct.kg ? "/kg" : ""}`
                                        :
                                        "Sin precio"
                                    }
                                  </Text>
                                </View>
                                <TouchableOpacity
                                  onPress={() => this.deleteSubSectionOptionsFixedPriceEditProduct(item.title)}
                                  onLongPress={() => this.deleteSubSectionOptionsFixedPriceEditProduct(item.title)}
                                  style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                                  <IoIosClose size="2em" />
                                </TouchableOpacity>
                              </View>
                            ))}

                        </ScrollView>
                        {this.state.selectedIndexOptionFixedPriceEditProduct < 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <Text style={{ paddingHorizontal: 10, width: "90%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 5 }}>
                              Selecciona una grupo de opciones para poder añadir nuevas opciones
                            </Text>
                          </View>
                        }


                        {this.state.selectedIndexOptionFixedPriceEditProduct >= 0 &&
                          <View>
                            <View style={{ height: 10 }} />
                            <View style={{ borderWidth: 1, borderColor: "#F5F5F5", borderRadius: 5, width: "90%", marginLeft: 10 }}>
                              <View style={{ height: 5, }} />
                              <View style={{ width: "100%", paddingVertical: 10, flexDirection: "row", marginLeft: 10 }}>
                                <TextInput

                                  value={this.state.editProductNewOptionFixedPrice.title}
                                  onChangeText={(text) => this.updateEditProductOptionFixedPriceTemplate("title", text)}
                                  numberOfLines={3} placeholder={"Tamaño grande"} style={{ borderRadius: 5, fontSize: "1rem", width: "100%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              
                              </View>

                              <TextInput
                                value={this.state.editProductNewOptionFixedPrice.description}
                                onChangeText={(text) => this.updateEditProductOptionFixedPriceTemplate("description", text)}
                                numberOfLines={3} placeholder={"No disponible, últimas unidades, ..."} style={{ borderRadius: 5, fontSize: "1rem", width: "100%", alignSelf: "flex-start", marginLeft: 10, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                              <View style={{ height: 10 }} />
                              <TouchableOpacity
                                disabled={!this.state.editProductNewOptionFixedPrice.title}
                                onPress={() => this.createSubSectionOptionsFixedPriceEditProduct()}
                                onLongPress={() => this.createSubSectionOptionsFixedPriceEditProduct()}
                                style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.editProductNewOptionFixedPrice.title ? "#F5F5F5" : "#68dc91", alignSelf: "center", marginHorizontal: 10, width: "95%", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Añadir
                                </Text>



                              </TouchableOpacity>
                              <View style={{ height: 10, }} />
                            </View>

                            {!this.state.editProductNewOptionFixedPrice.title &&
                              <View style={{ width: "90%", marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                                <View style={{ height: 10 }} />
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                                  Introduce un título y un precio correctos para crear la opción
                                </Text>
                              </View>
                            }
                          </View>
                        }
                      </View>





                    </View>


                  </View>
                }

                {this.state.activeProduct.supplements_active &&
                  <View style={{ width: "50%" }}>
                    <View style={{ height: 30 }} />
                    <View style={{ paddingLeft: 10, flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 30, height: 30, backgroundColor: "#68dc91", borderRadius: 100, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                          {this.state.activeProduct.options_active ? "5" : "4"}
                        </Text>
                      </View>
                      <Text style={{ marginLeft: 10, justifySelf: "center", textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center" }}>
                        Suplementos
                      </Text>
                    </View>
                    <View style={{ height: 20 }} />
                    <ScrollView style={{ height: 300, width: "90%", backgroundColor: "#F5F5F5", marginLeft: 10, borderRadius: 5, borderWidth: 1, borderColor: "#F5F5F5" }}>
                      {Object.keys(this.context.activeSupplements).map((item, index) => (


                        <View style={{ width: "100%", flexDirection: "row", backgroundColor: "#fff", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                          <View style={{ width: "60%", justifyContent: "center" }}>
                            <Text style={{ paddingLeft: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 15 }}>
                              {item}
                            </Text>
                          </View>
                          <View style={{ width: "30%", justifyContent: "center", alignItems: "center" }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                              {parseFloat(this.context.activeSupplements[item].price).toFixed(2)} €
                            </Text>
                          </View>
                          <TouchableOpacity
                            onPress={() => this.editProductAddSupplements(this.context.activeSupplements[item].id)}
                            onLongPress={() => this.editProductAddSupplements(this.context.activeSupplements[item].id)}
                            style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>

                            <View style={{ width: 18, height: 18, backgroundColor: (this.state.activeProduct.supplements && this.state.activeProduct.supplements.indexOf(this.context.activeSupplements[item].id) >= 0) ? "#68dc91" : "#F2F2F2" }}>


                            </View>
                          </TouchableOpacity>
                        </View>
                      ))}

                    </ScrollView>



                  </View>
                }
                <TouchableOpacity onLongPress={() => this.setState({ editActualProduct: false, activeProduct: {} })} onPress={() => this.setState({ editActualProduct: false, activeProduct: {} })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                  <IoIosClose size="2.5em" />
                </TouchableOpacity>

                <View style={{ height: 50, }} />

                <View>
                  <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: window.innerHeight * 0.03, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
                      <TouchableOpacity
                        onPress={() => this.deleteProduct()}
                        onLongPress={() => this.deleteProduct()}
                        style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                          Eliminar
                        </Text>



                      </TouchableOpacity>
                      <TouchableOpacity
                        disabled={this.checkIfProductEditExists()}
                        onPress={() => this.saveProductChanges()}
                        onLongPress={() => this.saveProductChanges()}
                        style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.checkIfProductEditExists() ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                        <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                          Guardar cambios
                        </Text>

                      </TouchableOpacity>
                    </View>
                  </View>
                </View>



                {(this.state.activeProduct.offer && !this.state.activeProduct.price_offer) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Introduce el precio de la oferta para poder guardar los cambios
                    </Text>
                  </View>
                }


                {!this.state.activeProduct.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Escribe un título para poder guardar los cambios
                    </Text>
                  </View>
                }
                {(this.state.activeProduct.options_fixed_price_active && (this.state.activeProduct.options_fixed_price==null || (this.state.activeProduct.options_fixed_price && this.state.activeProduct.options_fixed_price.length==0))) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Crea al menos una opción para poder guardar los cambios del producto
                    </Text>
                  </View>
                }

                {this.state.activeProduct.options_fixed_price_active && !this.state.activeProduct.price &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Necesitas añadir el precio si estás actualizando un producto de precio fijo con opciones
                    </Text>
                  </View>
                }

                {//caso same name
                  Object.keys(this.context.activeProducts).indexOf(this.state.activeProduct.title) != -1
                  &&
                  (
                    Object.keys(this.context.activeProducts).indexOf(this.state.activeProduct.title)
                    !=
                    Object.keys(this.context.activeProducts).indexOf(this.state.activeProductOriginalTitle)
                  ) &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      Este nombre ya existe para otro producto
                    </Text>
                  </View>
                }

                <View style={{ height: 10 }} />
              </View>
              <View style={{ width: "100%", height: window.innerHeight * 0.05 }} />

            </ScrollView>
          </View>
        }
        {
          this.state.showEditingHours &&
          <ScrollView style={{ position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: window.innerHeight * 0.03, width: "95%", marginTop: window.innerHeight * 0.05, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: window.innerHeight * 0.02 }}>

                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                  {this.context.translate("edit_hour.add_schedule")} {daysNamesLowercase[this.state.activeHoursIndex]}


                </Text>
                <View style={{ height: 30 }} />
                <View style={{ width: "95%", alignSelf: "center", backgroundColor: "#F5F5F5", paddingVertical: 15, borderRadius: 10 }}>
                  <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("how_complete.add_schedule")}
                  </Text>
                  <Text style={{ paddingVertical: 10, color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", marginLeft: window.innerWidth * 0.01 }}>
                    {this.context.translate("info_how_complete.add_schedule")}
                  </Text>
                </View>


              </View>
              <View style={{ height: 30 }} />
              <View style={{ flexDirection: "row", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <View style={{ width: "33.33%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    {this.context.translate("schedule_1.add_schedule")}
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <Picker selectedValue={this.state.editingHours[0] ? this.state.editingHours[0].init : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(0, "init", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <Picker selectedValue={this.state.editingHours[0] ? this.state.editingHours[0].final : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(0, "final", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                  </View>
                </View>
                <View style={{ width: "33.33%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    {this.context.translate("schedule_2.add_schedule")}
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <Picker selectedValue={this.state.editingHours[1] ? this.state.editingHours[1].init : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(1, "init", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <Picker selectedValue={this.state.editingHours[1] ? this.state.editingHours[1].final : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(1, "final", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                  </View>
                </View>
                <View style={{ width: "33.33%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    {this.context.translate("schedule_3.add_schedule")}
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <Picker selectedValue={this.state.editingHours[2] ? this.state.editingHours[2].init : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(2, "init", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <Picker selectedValue={this.state.editingHours[2] ? this.state.editingHours[2].final : "Introduce una hora"} onValueChange={(value, index) => this.updateEditingHour(2, "final", value)}>
                      {hours.map((item, index) => (
                        <Picker.Item value={item} label={item} />
                      ))}

                    </Picker>
                  </View>
                </View>
              </View>
              <View style={{ height: 30 }} />
              <TouchableOpacity
                disabled={!this.possibleSaveHours()}
                onPress={() => this.saveHoursChanges()}
                onLongPress={() => this.saveHoursChanges()}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.possibleSaveHours() ? "#68dc91" : "#F5F5F5", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                  {this.context.translate("save.add_schedule")}
                </Text>



              </TouchableOpacity>
              {
                ((this.state.editingHours[0].init == "Introduce una hora" &&
                  this.state.editingHours[0].final != "Introduce una hora") ||
                  (this.state.editingHours[0].init != "Introduce una hora" &&
                    this.state.editingHours[0].final == "Introduce una hora"))
                &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    {this.context.translate("complete_data_1.add_schedule")}
                  </Text>
                </View>
              }
              {
                ((this.state.editingHours[1].init == "Introduce una hora" &&
                  this.state.editingHours[1].final != "Introduce una hora") ||
                  (this.state.editingHours[1].init != "Introduce una hora" &&
                    this.state.editingHours[1].final == "Introduce una hora"))
                &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    {this.context.translate("complete_data_2.add_schedule")}
                  </Text>
                </View>
              }
              {
                ((this.state.editingHours[2].init == "Introduce una hora" &&
                  this.state.editingHours[2].final != "Introduce una hora") ||
                  (this.state.editingHours[2].init != "Introduce una hora" &&
                    this.state.editingHours[2].final == "Introduce una hora"))
                &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ paddingLeft: 20, textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    {this.context.translate("complete_data_3.add_schedule")}
                  </Text>
                </View>
              }
              <View style={{ height: 10 }} />

              <TouchableOpacity onLongPress={() => this.setState({ showEditingHours: false })} onPress={() => this.setState({ showEditingHours: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
          </ScrollView>
        }
      </View>
    )
  }
}
export default withSnackbar(App, {
  position: 'top-center',
  style: {
    zIndex: 1100,
    backgroundColor: '#68dc91',
    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
    color: '#000',
    borderRadius: 10,
    psddingHorizontal: 20,
    fontSize: '1rem',
    textAlign: 'left',
  },
  closeStyle: {
    color: '#000',

  },
})