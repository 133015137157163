import * as React from 'react';
import all from './images/all.jpg';

//new images 
import meat from './images/meat.jpg'
import fish from './images/fish.jpg'
import fruit from './images/fruit.jpg'
import bread from './images/bread.jpg'
import supermarket from './images/supermarket.jpg'
import clothes from './images/clothes.jpg'
//
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { GrYoga } from 'react-icons/gr'
import { BiRun } from 'react-icons/bi';
import {IoIosMail} from 'react-icons/io'
import { MdChevronRight,MdMoneyOffCsred, MdLocalPharmacy, MdCake, MdStoreMallDirectory,MdCategory } from 'react-icons/md'
import adapt from './images/adapt.jpg';
import contact from './images/contact.jpg';
import qr_example from './images/qr_example.png';
import { Text, View, ScrollView, TouchableOpacity, Image, TextInput } from 'react-native';
import { Link } from 'react-router-dom';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';
import { AuthContext } from './AuthContext';
import GeneralContext from './Provider';
var QRCode = require('qrcode.react');

export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <App
      translate={context.translate}
      {...props}
    />
  );
};
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      contact: "",
      description: "",
      sendHelpDisabled: true,
      infoRequested: false,
      windowsWidth:window.innerWidth

    }
  }

  static contextType = AuthContext;

  componentDidMount() {
    //
  }


  updateField(key, value) {
    this.setState({ [key]: value }, () => this.checkIfSendHelpDisabled())
  }

  async sendToFirebase() {
    const db = getFirestore(this.context.app);
    await addDoc(collection(db, "help"), {
      name: this.state.name,
      contact: this.state.contact,
      description: this.state.description
    }).then(this.setState({ infoRequested: true }));
  }

  checkIfSendHelpDisabled() {
    if (!!this.state.name && !!this.state.contact && !!this.state.description)
      this.setState({ sendHelpDisabled: false })
    else
      this.setState({ sendHelpDisabled: true })
  }




  render() {

    return (
      <View style={{ width: "100%", height: "100%", backgroundColor: "#000", overflowX: "hidden" }}>

        <View style={{ width: "100%", height: "100%", }}>
          <View style={{}}>
          <View style={{ width: "100%",backgroundColor:"#000", }}>

<View style={{ height: 150 }} />
  <View style={{ height: 500 }}>
    <Image source={require('./images/business1.jpg')} style={{ width: "80%", alignSelf: "center", height: 500 }} resizeMode='cover' >

    </Image>
    </View>

  <View style={{ height: 70 }} />
  <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 32, paddingHorizontal: 20, fontStyle: "italic" }}>
    REVOLUCIONA TU NEGOCIO
  </Text>
  <View style={{ height: 30 }} />
  <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 18, paddingHorizontal: 20 }}>
  {this.props.translate("searching.home")}
  </Text>
  <View style={{ height: 20 }} />
  <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 18, paddingHorizontal: 20 }}>
  {this.props.translate("start_with.home")}
  </Text>
  <View style={{ height: 50 }} />
          <TouchableOpacity
            onPress={() => window.scrollTo({ top: 10000, behavior: 'smooth' })}
            onLongPress={() => window.scrollTo({ top: 10000, behavior: 'smooth' })}
            style={{ flexDirection: "row", alignSelf: "center", backgroundColor: "#68DC91", justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 20 }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 20, fontStyle: "italic" }}>
              QUIERO UNIRME
            </Text>
          </TouchableOpacity>
          <View style={{ height: 100 }} />
</View>

</View>

<View style={{width:"100%",flexDirection:"row" }}>
  <View style={{width:"40%",justifyContent:"center",alignItems:"center"}}>
              <Image source={meat} style={{ borderRadius: 0,  width: "100%", height: 400, zIndex: 0 }} resizeMode="cover" />
              </View>
              <View style={{width:"60%",justifyContent:"center",alignItems:"center"}}>
              <Text style={{paddingHorizontal:20, color: "#fff", fontWeight: "800", width: "100%", textAlign: "left", fontSize: 26, fontStyle: "italic" }}>
               {this.props.translate("believe.home")}
              </Text>
              <View style={{ height: 20 }} />
              <Text style={{ marginBottom: 20, color: "#fff", fontWeight: "500", fontSize: 18, paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                {this.props.translate("want.home")}
              </Text>
              </View>


            </View>
           

            <View style={{ backgroundColor: "#000", width: "100%" }}>
          <View style={{ height: 50 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 22, fontStyle: "italic" }}>
            {this.props.translate("formation.home").toUpperCase()}
          </Text>
          <View style={{ height: 5 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 22, fontStyle: "italic", opacity: 0.8 }}>
            {this.props.translate("formation.home").toUpperCase()}
          </Text>
          <View style={{ height: 5 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 22, fontStyle: "italic", opacity: 0.6 }}>
            {this.props.translate("formation.home").toUpperCase()}
          </Text>
          <View style={{ height: 5 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 22, fontStyle: "italic", opacity: 0.4 }}>
            {this.props.translate("formation.home").toUpperCase()}
          </Text>
          <View style={{ height: 5 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 22, fontStyle: "italic", opacity: 0.2 }}>
            {this.props.translate("formation.home").toUpperCase()}
          </Text>
          <View style={{ height: 50 }} />
        </View>
          {/**BLOQUE 2 */}
          <View style={{ borderWidth: 0, borderRadius: 0, overflow: "hidden", width:"100%", overflow: "hidden",alignSelf:"center",flexDirection:"row" }}>
          <View style={{width:"50%",justifyContent:"center",alignItems:"center"}}>


          <Text style={{paddingHorizontal:20, color: "#fff", fontWeight: "800", width: "100%", textAlign: "left", fontSize: 26, fontStyle: "italic" }}>
               {this.props.translate("designed.home")}
</Text>
<View style={{ height: 20 }} />
<Text style={{ marginBottom: 20, color: "#fff", fontWeight: "500", fontSize: 18, paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
               {this.props.translate("new.home")}
</Text>
</View>
              <View style={{width:"50%"}}>
              <Image source={all} style={{ borderRadius: 0, alignSelf: "flex-end", width: "100%", height: 400, zIndex: 0 }} resizeMode="cover" />
</View>

            </View>
           <View style={{ height: 100 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic",paddingHorizontal:20 }}>
                   EMPIEZA EN MINUTOS
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#68DC91", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic",paddingHorizontal:20 }}>
                   por sólo 19.95€/mes
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 18, fontStyle: "italic",paddingHorizontal:20 }}>
                   sin gastos adicionales ni costes ocultos
          </Text>
          <View style={{ height: 50 }} />
          {/**BLOQUE 3 */}
          <View style={{ width: "100%", backgroundColor: "#000", marginTop: 50, alignSelf: "center" }}>


            <View style={{ width: "100%", justifyContent: "space-between"}}>
              <View style={{ alignSelf: "center", borderWidth: 0, borderColor: "#000", width: "100%",alignItems: "center", backgroundColor: "#000" }}>
                <Image source={bread} objectFit={"center"} style={{ zIndex: 0,width: "80%",height:400, zIndex: 0,objectFit:"center" }} resizeMode="cover" />

                <div style={{ flexDirection: "column", zIndex: 1, width: "100%", background: "linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.65)", justifyContent: "center", alignItems: "center" }}>
                  <View style={{ width: "100%"}}>
           <View style={{ height: 30 }} />
                  <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 26, fontStyle: "italic",paddingHorizontal:20 }}>
             {this.props.translate("amazing.home").toUpperCase()}

                    </Text>
                    <View style={{ height: 20 }} />
                    <Text style={{ zIndex: 1, marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "center", }}>
                      {this.props.translate("improve.home")}
                    </Text>
                    <View style={{ height: 50 }} />
                  </View>
                </div>
              </View> 
    

              <View style={{ alignSelf: "center", borderWidth: 0, borderColor: "#000", width: "100%",alignItems: "center", backgroundColor: "#000" }}>
                <Image source={adapt} objectFit={"center"} style={{ zIndex: 0,width: "80%",height:400, zIndex: 0,objectFit:"center" }} resizeMode="cover" />

                <div style={{ flexDirection: "column", zIndex: 1, width: "100%", background: "linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.65)", justifyContent: "center", alignItems: "center" }}>
                  <View style={{ width: "100%"}}>
           <View style={{ height: 30 }} />
                  <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 26, fontStyle: "italic",paddingHorizontal:20 }}>
             {this.props.translate("adapt.home").toUpperCase()}

                    </Text>
                    <View style={{ height: 20 }} />
                    <Text style={{ zIndex: 1, marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 20, textAlign: "center", }}>
                      {this.props.translate("created.home")}
                    </Text>
                    <View style={{ height: 50 }} />
                  </View>
                </div>
              </View>

              
            </View>
            <View style={{ height: 20 }} />
          </View>
          {/**BLOQUE 4 */}
          <View style={{ width: "100%", backgroundColor: "#000", marginTop: 50 }}>
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 32, paddingHorizontal: 20, fontStyle: "italic" }}>
     ¿CÓMO EMPIEZO A USAR BUYNESS?
            </Text>
          
            <View style={{ height: 80 }} />
          <View style={{ alignSelf: "center", width: 60, height: 60, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
              1
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
            REGÍSTRATE
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "center"}}>
                  {this.props.translate("sign_up.home")}
                </Text>
                <View style={{ height: 80 }} />
                <View style={{ alignSelf: "center", width: 60, height: 60, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
              2
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
            DISFRUTA DE LA VISIBILIDAD
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "center"}}>
                  {this.props.translate("automatically.home")}
                </Text>
                <View style={{ height: 80 }} />

                <View style={{ alignSelf: "center", width: 60, height: 60, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
              3
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 24, fontStyle: "italic" }}>
            (OPCIONAL)
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "center"}}>
          {this.props.translate("optional.home")}
                </Text>
                <View style={{ height: 80 }} />


          </View>


          <View style={{ backgroundColor: "#000", width: "100%" }}>

<Link
  to="/login"
  style={{ textDecorationLine: "none", height: "100%", zIndex: 0, alignSelf: "flex-start", width: "100%" }}>
  <View style={{ backgroundColor: "#fff", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 20, width: "100%" }}>

    <Text style={{ fontStyle: "italic", paddingHorizontal: 20, color: "#000", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 18 }}>
      PANEL DE ADMINISTRACIÓN PARA ESTABLECIMIENTOS
    </Text>
    <MdChevronRight style={{ marginRight: 20 }} size="60" color={"#000"} />
  </View>
</Link>
</View>

          <View style={{ width: "100%", backgroundColor: "#000", marginTop: 100 }}>
       
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, paddingHorizontal: 20, fontStyle: "italic" }}>
       {this.props.translate("why.home").toUpperCase()}
            </Text>
            <View style={{ height: 50 }} />
            <View style={{ marginLeft: 20, borderRadius: 14, height: "100%", boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",flexDirection:"row" }}>
            <View style={{width:"30%",justifyContent:"center",alignItems:"center"}}>
                <View style={{borderRadius: 100, width: 50, height: 50, backgroundColor: "#000", justifyContent: "center", alignItems: "center" }}>
                  <BsFillLightningChargeFill size={1000} color={"#fff"} />
                </View> 
          </View>
          <View style={{width:"70%"}}>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "1.8rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                  {this.props.translate("real_time.home")}
                </Text>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                {this.props.translate("publish.home")}
                </Text>
                <View style={{ height: 10 }} />
           </View>
              </View>
              <View style={{ height: 50 }} />
            <View style={{ marginLeft: 20, borderRadius: 14, height: "100%", boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",flexDirection:"row" }}>
            <View style={{width:"30%",justifyContent:"center",alignItems:"center"}}>
                <View style={{borderRadius: 100, width: 50, height: 50, backgroundColor: "#000", justifyContent: "center", alignItems: "center" }}>
              <MdCategory size={1000} color={"#fff"} />
                </View> 
          </View>
          <View style={{width:"70%"}}>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "1.8rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                {this.props.translate("flexibility.home")}
                </Text>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                {this.props.translate("identity.home")}
                </Text>
                <View style={{ height: 10 }} />
           </View>
              </View>
              <View style={{ height: 50 }} />
            <View style={{ marginLeft: 20, borderRadius: 14, height: "100%", boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",flexDirection:"row" }}>
            <View style={{width:"30%",justifyContent:"center",alignItems:"center"}}>
                <View style={{borderRadius: 100, width: 50, height: 50, backgroundColor: "#000", justifyContent: "center", alignItems: "center" }}>
                <BiRun size={1000}  color={"#fff"} />
                </View> 
          </View>
          <View style={{width:"70%"}}>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "1.8rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                {this.props.translate("start.home")}
                </Text>
                <View style={{ height: 10 }} />
                <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1rem", paddingHorizontal: 20, textAlign: "left", maxWidth: 400 }}>
                {this.props.translate("price.home")} 
                </Text>
                <View style={{ height: 10 }} />
           </View>
              </View>

            <View style={{ height: 30 }} />
          </View>

          <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "90%", backgroundColor: "#000", marginTop: window.innerHeight * 0.05, alignSelf: "center" }}>

            <View style={{ paddingVertical: window.innerHeight * 0.05, width: "100%" }}>

            <Text style={{ color: "#68DC91", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 32, fontStyle: "italic" }}>
            {this.props.translate("discover.home").toUpperCase()}

              </Text>
              <View style={{ height: 80 }} />
              <View style={{ width: "100%", alignItems: "center" }}>
                <QRCode value="https://buynessapp.com/establishment/2" size={this.state.windowsWidth*0.7} />
              </View>


              <Text style={{ marginTop: 50, color: "#fff", fontWeight: "500", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "center",alignSelf:"center", width: "100%" }}>
              {this.props.translate("qr.home")}
              </Text>

            </View>

          </View>
        
          <Text style={{fontStyle:"italic", marginTop: 20, color: "#fff", fontWeight: "bold", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "center", width: "100%",alignSelf:"center" }}>
              O PULSA AQUÍ
              </Text>
              <View style={{ height: 30 }} />
          <View style={{ backgroundColor: "#000", width: "100%" }}>

<Link
  to="/establishment/2"
  style={{ textDecorationLine: "none", height: "100%", zIndex: 0, alignSelf: "flex-start", width: "100%" }}>
  <View style={{ backgroundColor: "#fff", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 10, width: "100%" }}>

    <Text style={{ fontStyle: "italic", paddingHorizontal: 20, color: "#000", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 18 }}>
    DESCUBRE UN EJEMPLO
    </Text>
    <MdChevronRight style={{ marginRight: 20 }} size="40" color={"#000"} />
  </View>
</Link>
</View>

<View style={{ width: "100%", backgroundColor: "#000" }}>
          <View style={{ height: 100 }} />
          <Text style={{ color: "#68DC91", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic" }}>
          {this.props.translate("doubts.home").toUpperCase()}
          </Text>
          <View style={{ height: 20 }} />
          <Text style={{ color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: 20, textAlign: "center", width: "100%" }}>
                {this.props.translate("solve_doubts.home")}
                    
                  </Text>
          <View style={{ height: 50 }} />
          <TouchableOpacity
            onPress={() => window.open('mailto:help@buynessapp.com')}
            onLongPress={() => window.open('mailto:help@buynessapp.com')}
            style={{ flexDirection: "row", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 10 }}>
<IoIosMail size={30} color={"#000"}/>
            <Text style={{marginLeft:10, color: "#000", fontWeight: "bold", width: "100%", textAlign: "center", fontSize: 18, fontStyle: "italic" }}>
              help@buynessapp.com
            </Text>
          </TouchableOpacity>
         
          <View style={{ height: 50 }} />
        </View>



        <View style={{ width: "100%", paddingVertical: 20, backgroundColor: "#68DC91" }}>
          <Text style={{ color: "#000", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 16 }}>
            Buyness © 2022
          </Text>
        </View>
        </View>



        <View style={{position:"fixed",top:0, flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center",backgroundColor:"#68DC91",paddingVertical:15 }}>

<Text style={{ color: "#000", fontWeight: "700", textAlign: "left", fontSize: 28, paddingHorizontal: 20 }}>
  Buyness
</Text>

<Link
to="/login"

style={{ textDecorationLine: "none",width:"60%",alignSelf:"flex-end"}}>
<View style={{ textDecorationLine: "none", flexDirection: "row", alignSelf: "flex-end", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", paddingHorizontal: 10, paddingVertical: 10, marginRight: 20 }}>

<Text style={{ color: "#000", fontWeight: "800", textAlign: "left", fontSize: 12, fontStyle: "italic" }}>
  LOGIN ESTABLECIMIENTOS
</Text>
<MdChevronRight style={{marginLeft:20}} size="30" color={"#000"} />
</View>
</Link>
</View>

      </View>
    )
  }
}