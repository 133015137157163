import * as React from 'react';
import { Text, View,TouchableOpacity,Picker } from 'react-native';
import GeneralContext from '../Provider.js';
import {AiOutlineCloudDownload} from 'react-icons/ai'
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import ca from '../translations/ca'
import es from '../translations/es'
var QRCode = require('qrcode.react');
var htmlToImage = require('html-to-image');
const languages=[{label:"Català",value:"ca"},{label:"Español",value:"es"}]


export default function Wrapper(props) {

       const context = React.useContext(GeneralContext);
       return (
         <App
           translate={context.translate}
           {...props}
         />
       );
     };

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageStatus:"",
            qrLanguage:"es"
        }
        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
        this.ref3 = React.createRef();
        this.ref4 = React.createRef();
        this.ref5 = React.createRef();
        this.ref6 = React.createRef();
        this.ref7 = React.createRef();
        this.ref8 = React.createRef();
    }



    static contextType = GeneralContext;
    render() {

        return (
            <View style={{zIndex: 0,width: "95%", height: window.innerHeight, backgroundColor: "#fff",overflowY:"scroll" }}>
         <View style={{flexDirection:"row",width:"100%"}}>
                <View style={{width:"50%"}}>
            <Text style={{ paddingTop: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
            {this.props.translate("qr.download_qr")}
                  </Text>
                  <Text style={{ paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>
                  {this.props.translate("message.download_qr")}
                  </Text>
                  </View>
               
                  </View>
                  <View style={{height:30}}/>
<View style={{flexDirection:"row",paddingLeft:20}}>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref1,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref1}>
                 <View style={{width:250,height:250,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref2,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref2}>
                 <View style={{width:250,height:250,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View>

                 

                 <View style={{height:30}}/>
<View style={{flexDirection:"row",paddingLeft:20}}>
<TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref3,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref3}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
           ¡ESCANEAME!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref4,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref4}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
            ESCANEJA'M
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                  
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View>
                 


                 <View style={{height:30}}/>
<View style={{flexDirection:"row",paddingLeft:20}}>
<TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref5,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref5}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
           EXPLORA LA CARTA
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref6,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref6}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
            EXPLORA LA CARTA
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                  
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View>



                 <View style={{height:30}}/>
                 <View style={{flexDirection:"row",paddingLeft:20}}>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref7,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref7}>
                 <View style={{width:290,height:290,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center"}}>
           ¡Explora nuestros productos!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref8,{fileName:"buyness_qr_code"})} >
                        <div ref={this.ref8}>
                 <View style={{width:290,height:290,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center"}}>
           ¡Descobreix els nostres productes!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View> 
                 <View style={{height:30}}/>
            </View>
        )
    }
}