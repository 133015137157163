export const es={
    "Buyness.home": "Buyness",
    "log_in.home": "Iniciar sesión",
    "digitizes.home": "Digitaliza tu negocio",
    "searching.home": "Estamos buscando conectar de una forma única y novedosa los establecimientos con sus clientes habituales.",
    "start_with.home": "Acabamos de emepezar y estamos buscando negocios innovadores que quieran probar nuestra tecnología.",
    "contact_us.home": "Contacta con nosotros",
    "future.home":"¡Descubre el futuro!",
    "believe.home": "Creemos en la importancia del negocio local",
    "want.home": "Queremos ayudar a digitalizar a los pequeños comercios y que disfruten de todas las ventajas y posibilidades que ofrece la tecnología.",
    "formation.home": "Sin necesidad de formación",
    "manage.home": "Empieza a gestionar tu tienda online desde cualquier dispositivo con conexión a Internet en cuestión de minutos. Además tenemos unos vídeos explicativos por si te surjen dudas durante el proceso.",
    "designed.home": "Diseñado para el siglo XXI",
    "new.home": "Una nueva forma de gestionar tu tienda online a una velocidad y con una sencillez nunca vista.",
    "amazing.home": "Una experiencia increible para tus clientes",
    "improve.home": "Mejora la experiencia que tienen tus clientes a la hora de descubrir que productos pueden encontrar en tu establecimiento. Actualiza en tiempo real la disponibilidad de tus productos.",
    "adapt.home": "Diseñado para adaptarse a tu negocio",
    "created.home": "Hemos creado una plataforma de última tecnología que te va a permitir gestionar tu tienda online con extrema facilidad. Descubre todas las posibilidades que ofrece Buyness uniendote a la beta.",
    "how_does_it_work.home": "¿Cómo funciona Buyness?",
    "sign_up.home": "Regístrate y añade tus productos a la tienda.",
    "automatically.home": "Automáticamente tu tienda aparecerá cada vez que alguien cercano a tu establecimiento abra la aplicación.¡Así de fácil! ",
    "optional.home": "Podrás descargar unos QR que te llevarán a tu tienda online cada vez que algún cliente los escanee. Ideal para restaurantes y bares ya que pueden alojar nuestra carta en nuestra plataforma sin ningún tipo de complicación.",
    "why.home": "¿Por qué nosotros?",
    "real_time.home": "Tiempo real",
    "publish.home": "Publica los cambios al instante. ¿Acabas de recibir un nuevo producto? Añádelo en 1 minuto. ¿Quieres modificar el precio de un producto? Edítalo en 30 segundos. ¿Quieres marcar un producto como no disponible? Házlo a la velocidad de la luz.",
    "flexibility.home": "Flexibilidad",
    "identity.home": "Queremos que cada tienda exprese su identidad dentro de la plataforma como quiera. Organiza tus productos a tu gusto, sin restricciones.",
    "start.home": "Empieza ahora",
    "price.home": "Estamos empezando y queremos que formes parte de nuestra família. Empieza sin sorpresas, costes ocultos ni gastos adicionales por solo 19,95€.",
    "discover.home": "Descubre cómo quedará tu tienda",
    "qr.home": "Escanea este QR para ver un ejemplo de como tus clientes verán tu tienda online. También puedes pulsar en el botón para acceder directamente.",
    "discover_button.home": "Descubre cómo quedará tu tienda",
    "doubts.home": "¿Tienes dudas?",
    "solve_doubts.home": "Estamos aquí para resolver todas las dudas que te puedan surgin sobre nuestra plataforma.",
    "name.home": "Tu nombre",
    "email.home": "Correo electrónico o nº de teléfono",
    "query.home": "Detalles de la consulta",
    "contact.home": "Nos pondremos en contacto tan pronto como nos sea posible. Por favor revisa que todos los datos sean correctos.",
    "send.home": "Enviar consulta",
    "Buyness_2021.home": "Buyness © 2021. Hecho con ❤ (Fase beta)︎",
    "Login.sign_in": "Login",
    "Buyness.sign_in": "Buyness",
    "access.sign_in": "Accede al panel de administración",
    "email.sign_in": "Correo electrónico",
    "example_email.sign_in": "negocio@negocio.com",
    "password.sign_in": "Contraseña",
    "example_password.sign_in": "Contraseña",
    "forgot.sign_in":"¿Has olvidado la contraseña?",
    "wrong.sign_in":"Nombre de usuario y/o contraseña incorrectos. Si tienes problemas para acceder escríbenos a help@urbisapp.com",
    "sign_in.sign_in": "Iniciar sesión",  
    "products.add_home": "Productos",
    "categories.add_home": "Categorías",
    "menu.add_home": "Menús",
    "schedule.add_home": "Horarios",
    "see_shop.add_home": "Ver tienda",
    "add_products.add_home": "+ Añadir producto",
    "add_menu.add_home": "+ Añadir menu",
    "add_category.add_home": "+ Añadir categoría",
    "name_product.add_home": "Nombre del producto",
    "price.add_home": "Precio",
    "availability.add_home": "Disponibilidad",
    "available.add_home": "Disponible",
    "buyness.add_home": "Buyness",
    "admin.add_home": "Administra tu establecimiento",
    "my_establishment.add_home": "Mi establecimiento",
    "qrs.add_home": "QRs",
    "profile.add_home": "Perfil",
    "drag.add_home": "Puedes arrastrar para cambiar el orden de las categorías.",
    "name_categories.add_home": "Nombre de la categoría",
    "numbers_items.add_home": "Núm. de artículos",
    "visibility.add_home": "Visibilidad",
    "visible.add_home": "visible",
    "drag_menu.add_home": "Puedes arrastrar para cambiar el orden de los menús.",
    "name_menu.add_home": "Nombre del menú",
    "no_menu.add_home": "Sin menús",
    "no_products.add_home": "Sin productos",
    "no_categories.add_home": "Sin categorías",
    "press_to_edit.add_home": "Pulsa para editar los horarios de uno de los días",
    "day.add_home": "Día",
    "close.add_home": "Cerrado",
    "horario.add_home": "Horario",
    "Monday.add_home": "Lunes",
    "tuesday.add_home": "Martes",
    "wednesday.add_home": "Miércoles",
    "thursday.add_home": "Jueves",
    "friday.add_home": "Viernes",
    "saturday.add_home": "Sábado",
    "sunday.add_home": "Domingo",
    "edit_product.edit_prodict": "Editar producto",
    "new_product.add_product": "Nuevo producto",
    "add_image.add_product": "Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)",
    "name.add_product": "Nombre (Obligatorio)",
    "example_name.add_product": "Hamburguesa con queso",
    "description.add_product": "Descripción",
    "example_description.add_product": "Hamburguesa de 200g de ternera con mozarella",
    "price.add_product": "Precio en €",
    "example_price.add_product": "6,45",
    "available.add_product": "¿Este artículo está disponible?",
    "yes.add_product": "Sí",
    "no.add_product": "No",
    "options.add_product": "Opciones",
    "price_kg.add_product": "Prcio por Kg",
    "Categories.add_product": "Categorías",
    "create_product.add_product": "Crear producto",
    "write_title.add_product": "Escribe un título para poder crear el producto",
    "edit.edit_categories": "Editar categoría",
    "new.add_categories": "Nueva categoría",
    "title.add_categories": "Título",
    "example_title.add_categories": "Hamburguesas",
    "other_info.add_categories": "Información adicional",
    "example_other_info.add_categories": "Todas las hamburguesas van acompañadas de patatas y ensalada",
    "visible.add_categories": "¿Esta categoría estará ya visible?",
    "yes.add_categories": "Sí",
    "no.add_categories": "No",
    "select_items.add_categories": "Artículos seleccionados:",
    "create.add_categories": "Crear categoría",
    "write_title.add_categories": "Escribe un título para poder crear la categoría",
    "edit_menu.edit_menu": "Editar menús",
    "add_menu.add_menu": "Nuevo menú",
    "title.add_menu": "Título",
    "exapmle_title.add_menu": "Menú diario",
    "other_info.add_menu": "Información adicional",
    "example_other_info.add_menu": "El precio incluye un refresco o una botella de agua grande",
    "visible.add_menu": "¿Este menú estará visible?",
    "yes.add_menu": "Sí",
    "no.add_menu": "No",
    "first.add_menu": "Primeros",
    "second.add_menu": "Segundos",
    "create_menu.add_menu": "Crear menú",
    "write_title.add_menu": "Escribe un titulo para poder crear el menu",
    "price.add_menu": "Precio en €",
    "example_price.add_menu": "12,50€",
    "edit_hour.add_schedule": "Editar horario del",
    "how_complete.add_schedule": "¿Cómo completo esta información?",
    "info_how_complete.add_schedule": "Tu establecimiento puede tener hasta tres horarios diferentes para un mismo día. Por ejemplo si tu establecimiento solo abre de 08:00h a 16:00h rellena solo el primer horario. Si tu establecimiento hace un horario de 09:00h a 14:00h y de 15:00h a 20:00h rellena el primer horario con 09:00h y 14:00h y el segundo con 15:00h y 20:00h. En el caso de no rellenar ningún horario, se mostrará cómo cerrado a tus clientes.",
    "schedule_1.add_schedule": "Horario 1",
    "schedule_2.add_schedule": "Horario 2",
    "schedule_3.add_schedule": "Horario 3",
    "introduce_hour.add_schedule": "Introducir una hora",
    "save.add_schedule": "Guardar cambios",
    "complete_data_1.add_schedule": "Completa los datos del horario 1",
    "complete_data_2.add_schedule": "Completa los datos del horario 2",
    "complete_data_3.add_schedule": "Completa los datos del horario 3",
    "qr.download_qr": "QR",
    "message.download_qr": "Descarga el modelo de QR que mejor encaje con tu negocio.",
    "tecnology.download_qr": "con la tecnologia buyness",
    "download.download_qr": "Descargar",
    "scan.download_qr": "¡ESCANÉAME!",
    "explore.download_qr": "¡Explora nuestros productos!",
    "profile.profile": "Perfil",
    "photo.profile": "Foto del establecimiento",
    "add_photo.profile": "Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)",
    "change_photo.profile":"Cambiar imagen",
    "delete.profile":"Eliminar",
    "name.profile": "Nombre del establecimiento",
    "example_name.profile": "Restaurante gourmet",
    "city.profile": "Ciudad",
    "example_city.profile": "Manresa",
    "adress.profile": "Dirección",
    "example_adress.profile": "Calle Bruc, 115",
    "email.profile": "Correo electrónico",
    "example_email.profile": "restaurantegourmet@mail.com",
    "tel.profile": "Número de teléfono",
    "example_tel.profile": "930000000",
    "save.profile": "Guardar cambios",
    "schedule.shop": "Horarios",
    "more_info.shop": "Más información",
    "loading.shop": "Cargando...",
    "monday.shop": "Lunes:",
    "tuesday.shop": "Martes:",
    "wednesday.shop": "Miércoles:",
    "thursday.shop": "Jueves:",
    "friday.shop": "Viernes:",
    "saturday.shop": "Sábado:",
    "sunday.shop": "Domingo:",
    "close.shop": "Cerrado",
    "contact.shop": "Contacto",
    "price.shop": "Precio",
    "available.shop": "No disponible",
    "error.shop": "No se ha podido cargar la información de este establecimiento. Por favor revisa la dirección.",
    "doubt.shop": "¿Tienes alguna duda? Contacta con el establecimiento",
    "buyness_final.shop": "buyness © 2021.",
  
  }
