import * as React from 'react';
import all from './images/all.jpg';

//new images 
import meat from './images/meat.jpg'
import fish from './images/fish.jpg'
import fruit from './images/fruit.jpg'
import bread from './images/bread.jpg'
import supermarket from './images/supermarket.jpg'
import clothes from './images/clothes.jpg'
//
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { GrYoga } from 'react-icons/gr'
import { MdMoneyOffCsred } from 'react-icons/md';
import Typewriter from 'typewriter-effect';
import adapt from './images/adapt.jpg';
import contact from './images/contact.jpg';
import qr_example from './images/qr_example.png';
import { Text, View, ScrollView, TouchableOpacity, Image, TextInput, Animated } from 'react-native';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { AuthContext } from './AuthContext';
import GeneralContext from './Provider';
import { types } from './categoriesScroll'
import { IoStorefront, IoFish, IoIceCream, IoCamera, IoLogoInstagram } from 'react-icons/io5'
import { IoMdRestaurant, IoIosClose, IoIosPin, IoIosBeer, IoIosCart, IoIosHammer, IoIosSearch } from 'react-icons/io'
import { GiFruitBowl, GiDelicatePerfume, GiConverseShoe, GiPorcelainVase, GiFlowerPot, GiFrozenOrb, GiClothes, GiBread, GiMeat } from 'react-icons/gi'
import { FaCoffee, FaHandsHelping, FaStore } from 'react-icons/fa'
import { MdLocalPharmacy, MdCake, MdStoreMallDirectory } from 'react-icons/md'
import { BiRun } from 'react-icons/bi'
import { geolocated } from "react-geolocated";
import Sheet from 'react-modal-sheet';
import Ticker from 'react-ticker'
import { MdChevronRight, MdChevronLeft } from "react-icons/md"
var QRCode = require('qrcode.react');





export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <App
      translate={context.translate}
      {...props}
    />
  );
};
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      contact: "",
      description: "",
      sendHelpDisabled: true,
      infoRequested: false,
      activeType: 0,
      activeTypeText: "all",
      showTypeText: true,
      showCities: false,
      activeCity: "",
      establishments: [],
      searchResults: [],
      numSearchResults: 0,
      query: "",
      userLocation: null,
      activeEstablishments: [],
      activeEstablishmentsTypes: [],
      activeTypes: [],
      nextToYouEstablishments: [],
      visibleHowWorks: true,
      visibleHowWorksActive: false

    }
  }

  static contextType = AuthContext;



  render() {

    return (
      <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#000", overflowX: "hidden" }}>
        <View style={{ width: "100%", backgroundColor: "#000" }}>
          <View style={{ height: 30 }} />
         
          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <View style={{width:"50%"}}> 
              <Text style={{ color: "#fff", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 40, paddingHorizontal: 50 }}>
              Buyness
            </Text>
</View>
            <Link
              to="/login"

              style={{ textDecorationLine: "none",width:"40%"}}>
<View style={{ textDecorationLine: "none", flexDirection: "row", alignSelf: "flex-end", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", paddingHorizontal: 20, paddingVertical: 10, marginRight: 50 }}>

              <Text style={{ color: "#000", fontWeight: "800", textAlign: "center", fontSize: 18, fontStyle: "italic" }}>
                LOGIN PARA ESTABLECIMIENTOS
              </Text>
              <MdChevronRight style={{marginLeft:20}} size="40" color={"#000"} />
              </View>
            </Link>
          </View>
        </View>
        <View style={{ backgroundColor: "#000", flexDirection: "row" }}>
          <View style={{ width: "50%" }}>


            <View style={{ width: "100%" }}>



              <View style={{ height: 100 }} />
              <Text style={{ color: "#fff", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 90, paddingHorizontal: 50, fontStyle: "italic" }}>
                EXPLORA
              </Text>
              <View style={{ height: 30 }} />
              {
                ["Carnicerías", "Pescaderías", "Fruterías", "Panaderías", "Zapaterías", "Cafeterías", "Heladerías", "Floristerías", "Y mucho más..."].map((item, item_index) => (
                  <Text style={{ marginTop: 10, paddingHorizontal: 50, whiteSpace: "nowrap", color: item == "Y mucho más..." ? "#68DC91" : "rgba(255,255,255,0.7)", fontWeight: "700", fontSize: 50, fontStyle: "italic" }}>
                    {item.toLocaleUpperCase()}
                  </Text>
                ))
              }
              <View style={{ height: 100 }} />
            </View>

          </View>
          <View style={{ width: "50%", justifyContent: "center", alignItems: "center" }}>
            <View style={{ height: 30 }} />
            <Image source={require('./images/newHome1.jpg')} style={{ width: "80%", alignSelf: "center", height: 700 }} resizeMode='cover' >

            </Image>

          </View>

        </View>
        <View style={{ height: 100 }} />
        <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 70, fontStyle: "italic" }}>
          ¿CÓMO FUNCIONA?
        </Text>
      
        <View style={{ backgroundColor: "#000" }}>



          <View style={{ height: 150 }} />
          <View style={{ alignSelf: "center", width: 80, height: 80, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic" }}>
              1
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
            DESCARGA LA APP
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 26, paddingHorizontal: 100 }}>
            Descarga nuestra app des de la App Store o Google Play
          </Text>
          <View style={{ height: 20 }} />
       <View style={{flexDirection:"row",width:"100%",justifyContent:"center",alignItems:"center"}}>
       <TouchableOpacity
          onPress={()=>window.open('https://apps.apple.com/es/app/buyness/id1616714361')}
          onLongPress={()=>window.open('https://apps.apple.com/es/app/buyness/id1616714361')}
           style={{alignSelf:"center",width:270,alignSelf:"center",justifyContent:"center",alignItems:"center"}}>
          <Image source={require('./images/apple-store-badge.png')} style={{ width: "100%", alignSelf: "center", height: 150 }} resizeMode='contain' >

</Image>
          
          </TouchableOpacity>
          <View style={{ width: 50 }} />
          <TouchableOpacity
          onPress={()=>window.open('https://play.google.com/store/apps/details?id=com.close2youapp')}
          onLongPress={()=>window.open('https://play.google.com/store/apps/details?id=com.close2youapp')}
           style={{alignSelf:"center",width:350,alignSelf:"center",justifyContent:"center",alignItems:"center"}}>
          <Image source={require('./images/google-play-badge.png')} style={{ width: "100%", alignSelf: "center", height: 200 }} resizeMode='contain' >

</Image>
          
          </TouchableOpacity>
       </View>
          <View style={{ height: 100 }} />
          <View style={{ alignSelf: "center", width: 80, height: 80, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic" }}>
              2
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
            EXPLORA LAS TIENDAS LOCALES
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 26, paddingHorizontal: 100 }}>
            Navega por la aplicación y descubre los productos de las tiendas que te rodean cómo nunca antes.
          </Text>
          <View style={{ height: 100 }} />
          <View style={{ alignSelf: "center", width: 80, height: 80, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic" }}>
              3
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
            REALIZA UN PEDIDO POR WHATSAPP
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 26, paddingHorizontal: 100 }}>
            Selecciona los productos que quieras mediante la app y comuníca el pedido al establecimiento por WhatsApp de manera cómoda.
          </Text>
          <View style={{ height: 100 }} />
          <View style={{ alignSelf: "center", width: 80, height: 80, borderRadius: 100, backgroundColor: "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 40, fontStyle: "italic" }}>
              4
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
            PASA A RECOGERLO O RECÍBELO EN CASA
          </Text>
          <View style={{ height: 10 }} />
          <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 26, paddingHorizontal: 100 }}>
            Pasa a recoger tu pedido cúando el establecimiento lo haya preparado o recíbelo en casa si el establecimiento dispone de la opción de envío a domicilio.
          </Text>
          <View style={{ height: 50 }} />

        </View>

        <View style={{ backgroundColor: "#fff" }}>
          <View style={{ width: "100%", paddingVertical: 100, backgroundColor: "#000" }}>
            <Text style={{ color: "#68DC91", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 60, fontStyle: "italic" }}>
              DISPONIBLE EN EL BAGES
            </Text>
          </View>

        </View>
        <View style={{ height: 50 }} />
        <View style={{ backgroundColor: "#000", flexDirection: "row" }}>
          <View style={{ width: "40%", justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "80%" }}>
              <Image source={require('./images/newHome3.jpg')} style={{ width: "100%", alignSelf: "center", height: 600 }} resizeMode='cover' />

              <View style={{ position: "absolute", bottom: 0, backgroundColor: "#68DC91" }}>
                <View style={{ height: 20 }} />
                <Text style={{ paddingHorizontal: 20, color: "#000", fontWeight: "600", width: "100%", textAlign: "left", fontSize: 22 }}>
                  ¿Tienes un negocio?
                </Text>
                <View style={{ height: 10 }} />
                <Text style={{ paddingHorizontal: 20, color: "#000", fontWeight: "400", width: "100%", textAlign: "left", fontSize: 18 }}>
                  Estamos buscando negocios que compartan nuestra visión del comercio local para que se unan a la plataforma.
                </Text>
                <View style={{ height: 30 }} />
                <Link
                  to="/business"
                  style={{ textDecorationLine: "none", zIndex: 0, alignSelf: "flex-start" }}>
                  <View style={{ paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "#000", alignSelf: "flex-start", marginLeft: 20 }}>
                    <Text style={{ textDecorationLine: "none", fontWeight: "600", color: "#fff" }}>
                      Descubre cómo
                    </Text>
                  </View>
                </Link>
                <View style={{ height: 20 }} />
              </View>

            </View>

          </View>
          <View style={{ width: "60%", justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "100%", backgroundColor: "#000" }}>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.85 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.7 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.55 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.4 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.25 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.1 }}>
                AUMENTA TU VISIBILIDAD
              </Text>
            </View>
          </View>
        </View>
        <View style={{ height: 120 }} />
        <Link
          to="/login"
          style={{ textDecorationLine: "none", height: "100%", zIndex: 0, alignSelf: "flex-start", width: "100%" }}>
          <View style={{ backgroundColor: "#fff", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 30, width: "100%" }}>

            <Text style={{ fontStyle: "italic", paddingHorizontal: 20, color: "#000", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 32 }}>
              PANEL DE ADMINISTRACIÓN PARA ESTABLECIMIENTOS
            </Text>


            <MdChevronRight style={{ marginRight: 20 }} size="60" color={"#000"} />



          </View>
        </Link>
        <View style={{ height: 120 }} />
        <View style={{ backgroundColor: "#000", flexDirection: "row" }}>
          <View style={{ width: "50%", paddingVertical: 50 }}>

            <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "left", fontSize: 70, fontStyle: "italic", paddingHorizontal: 50 }}>
              NUESTRA VISIÓN
            </Text>

            <View style={{ height: 40 }} />
            <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "left", fontSize: 22, paddingHorizontal: 50 }}>
              Creemos que los comercio local son pilares fundamentales de las comunidades. Ayudandoles a digitalizarse buscamos mejorar esta conexión dentro de estas mismas las comunidades y ayudar a mejorar la comunicación entre las personas y negocios que se encuentran en ellas.
            </Text>
            <View style={{ height: 20 }} />
            <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "left", fontSize: 22, paddingHorizontal: 50 }}>
              Estamos realmente comprometidos en crear las mejores herramientas para ayudar a cualquier tipo de negocio en su transición a la venta online.
            </Text>
            <View style={{ height: 20 }} />
            <Text style={{ color: "#fff", fontWeight: "500", width: "100%", textAlign: "left", fontSize: 22, paddingHorizontal: 50 }}>
              Si tienes un negocio y crees que encajamos con tus necesidades, estaremos encantados de darte la bienvenida.
            </Text>

            <View style={{ height: 50 }} />
          </View>
          <View style={{ width: "50%" }}>
            <Image source={require('./images/newHome4.jpg')} style={{ width: "60%", alignSelf: "center", height: "100%" }} resizeMode='cover' />
          </View>

        </View>
        <View style={{ height: 120 }} />
        <Text style={{ color: "#68DC91", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 60, fontStyle: "italic" }}>
          SÍGUENOS EN
        </Text>
        <View style={{ height: 50 }} />
        <TouchableOpacity
          onPress={() => window.open('https://www.instagram.com/buyness_app/')}
          onLongPress={() => window.open('https://www.instagram.com/buyness_app/')}
          style={{ flexDirection: "row", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", paddingHorizontal: 50, paddingVertical: 20 }}>
          <IoLogoInstagram style={{ marginRight: 10 }} size="50" color={"#000"} />
          <Text style={{ color: "#000", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 20, fontStyle: "italic" }}>
            INSTAGRAM
          </Text>
        </TouchableOpacity>
        <View style={{ height: 120 }} />
        <View style={{ width: "100%", paddingVertical: 20, backgroundColor: "#68DC91" }}>
          <Text style={{ color: "#000", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 16 }}>
            Buyness © 2022
          </Text>
        </View>
      </View>
    )
  }
}