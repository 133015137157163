export var types = [
  {"name":"Tipo de negocio","key":"no"},
{"name":"Restaurante","key":"restaurant"},
{"name":"Cafetería","key":"coffee_shop"},
{"name":"Bar","key":"bar"},
{"name":"Carnicería","key":"meat_shop"},
{"name":"Pescadería","key":"fish_shop"},
{"name":"Farmacias","key":"pharmacy"},
{"name":"Tienda de conveniencia","key":"convenience_store"},
{"name":"Supermercado","key":"supermarket"},
{"name":"Tienda especializada","key":"specialized_store"},
{"name":"Frutería","key":"fruit_shop"},
{"name":"Panadería","key":"bread_shop"},
{"name":"Pastelería","key":"cake_shop"},
{"name":"Tienda de ropa","key":"clothes_shop"},
{"name":"Tienda de congelados","key":"frozen_store"},
{"name":"Heladería","key":"ice_cream_shop"},
{"name":"Floristeria","key":"flower_shop"},
{"name":"Tienda de deportes","key":"sports_shop"},
{"name":"Tienda de antiguedades","key":"antique_shop"},
{"name":"Tienda de segunda mano","key":"second_hand_shop"},
{"name":"Tienda de fotografía","key":"photo_shop"},
{"name":"Zapatería","key":"shoe_shop"},
{"name":"Permuferías","key":"perfume_shop"},
{"name":"Otros","key":"other"},
]