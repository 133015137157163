import * as React from 'react';
import { Text, View,TouchableOpacity } from 'react-native';
import GeneralContext from '../Provider.js';
import {AiOutlineCloudDownload} from 'react-icons/ai'
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
var QRCode = require('qrcode.react');
var htmlToImage = require('html-to-image');
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageStatus:""
        }
        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
        this.ref3 = React.createRef();
        this.ref4 = React.createRef();
        this.ref5 = React.createRef();
        this.ref6 = React.createRef();
    }

    download(index){
       var node = document.getElementById('qr_'+index);
       htmlToImage.toPng(node)
  .then(function (dataUrl) {
    var img = new Image();
    img.src = dataUrl;
    document.body.appendChild(img);
  })
  .catch(function (error) {
    console.log('oops, something went wrong!', error);
  });
    }
    

    static contextType = GeneralContext;
    render() {

        return (
            <View style={{zIndex: 0,width: window.innerWidth, height: window.innerHeight, backgroundColor: "#fff",overflowY:"scroll" }}>
            <Text style={{ paddingTop: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
            QRs
                  </Text>
                  <Text style={{ paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>
           Descarga el modelo de QR que mejor encaje con tu negocio.
                  </Text>
                  <View style={{height:30}}/>
<View style={{flexDirection:"row",paddingLeft:20}}>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref1)} >
                        <div ref={this.ref1}>
                 <View style={{width:250,height:250,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref2)} >
                        <div ref={this.ref2}>
                 <View style={{width:250,height:250,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View>

                 

                 <View style={{height:30}}/>
<View style={{flexDirection:"row",paddingLeft:20}}>
<TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref3)} >
                        <div ref={this.ref3}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
           ¡ESCANEAME!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref4)} >
                        <div ref={this.ref4}>
                 <View style={{width:280,height:280,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1.2rem", textAlign: "center"}}>
            ESCANEJA'M
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                  
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View>
                 <View style={{height:30}}/>
                 <View style={{flexDirection:"row",paddingLeft:20}}>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref5)} >
                        <div ref={this.ref5}>
                 <View style={{width:290,height:290,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center"}}>
           ¡Explora nuestros productos!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           con la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descargar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 <View style={{width:50}}/>
                 <TouchableOpacity onPress={()=>exportComponentAsPNG(this.ref6)} >
                        <div ref={this.ref6}>
                 <View style={{width:290,height:290,backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
                 <Text style={{ paddingBottom: 20, textDecorationLine: "none", color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center"}}>
           ¡Descobreix els nostres productes!
                  </Text>
<View style={{height:180,width:180,backgroundColor:"#F5F5F5"}}>
<QRCode value={"https://www.buynessapp.com/establishment/"+this.context.establishmentInfo.id} size={180} />
</View>
<Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.6rem", textAlign: "center"}}>
           amb la tecnología Buyness
                  </Text>
                 </View>
                 </div>
                 <View style={{flexDirection:"row", justifyContent:"center",alignItems:"center", backgroundColor:"#000",width:"100%",height:40}}>
                 <AiOutlineCloudDownload size={"1.5em"} color={"#fff"}/>
                 <Text style={{paddingLeft:10, textDecorationLine: "none", color: "#fff", fontWeight: "500", fontSize: "0.9rem", textAlign: "center"}}>
           Descarregar
                  </Text>
                 </View>
                 </TouchableOpacity>
                 </View> 
                 <View style={{height:60}}/>
            </View>
        )
    }
}