import * as React from 'react';
import { Animated, Text, View, TouchableOpacity } from 'react-native';
import { IoMdMenu, IoIosCard, IoIosHelpCircle } from "react-icons/io";
import { RiArrowLeftLine, RiQrCodeFill } from "react-icons/ri";
import { ImSpoonKnife,ImUser } from "react-icons/im";
import { AiFillShop } from "react-icons/ai"
import { BiPowerOff } from "react-icons/bi"
import Carta from './AdminManageCarta'
import Dishes from './AdminManageDishes'
import Help from './AdminManageHelp'
import Home from './AdminManageHome'
import Menus from './AdminManageMenus'
import Payments from './AdminManagePayments'
import Profile from './AdminManageProfile'
import {HiOutlineEmojiSad} from 'react-icons/hi'
import {
    Link,
    Navigate,
    useNavigate
} from "react-router-dom";
import { Auth } from '../AuthContext'
import { getAuth, signInWithEmailAndPassword ,signOut} from "firebase/auth";
export default function Wrapper(props) {
    const navigate = useNavigate();

    return (
        <App
            navigate={navigate}
            {...props}
        />
    );
};



class App extends React.Component {
    constructor(props) {
        super(props);
        this.updateManagerStatus = this.updateManagerStatus.bind(this);
        this.state = {
            manageStatus: "PRODUCTS",
            lateralMenu: new Animated.Value(window.innerWidth * 0.05),
            menuOpen: false
        }
    }

    static contextType = Auth

    updateManagerStatus(newStatus) {
        this.setState({ manageStatus: newStatus })
    }

    componentWillMount() {

        if (!this.context.usuario)
            setTimeout(() => this.props.navigate('/login'), 100)
       

    }


    showMenu() {
        Animated.timing(this.state.lateralMenu, {
            toValue: window.innerWidth * 0.3,
            duration: 300
        }).start(() =>
            this.setState({
                menuOpen: true
            }))
    }
    closeMenu() {
        this.setState({
            menuOpen: false
        }, () => Animated.timing(this.state.lateralMenu, {
            toValue: window.innerWidth * 0.05,
            duration: 300,
        }).start())
    }


    closeSession() {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            setTimeout(() => this.props.navigate('/login', { test: "test" }), 100)
        }).catch((error) => {
            // An error happened.
        });
    }

    render() {

        return (
            <View style={{ flexDirection: "row", zIndex: 0, position: "fixed", top: 0, width:"100%", height:window.innerHeight, backgroundColor: "#fff", alignItems: "center", overflow: "hidden", flexDirection: "row" }}>
                

                    

                <View style={{ width: window.innerWidth }}>
                    {
                        this.state.manageStatus == "HOME" &&
                        <Home lateralMenuOffset={this.state.lateralMenu} updateManagerStatus={this.updateManagerStatus} />
                        //Home
                    }
                    {
                        this.state.manageStatus == "PROFILE" &&
                        <Profile menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Profile
                    }
                    {this.state.manageStatus == "QR" &&
                        <Carta menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Carta
                    }
                    {this.state.manageStatus == "MENUS" &&
                        <Menus menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Menús
                    }
                    {this.state.manageStatus == "PRODUCTS" && 
                        <Dishes menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Platos
                    }
                    {this.state.manageStatus == "HELP" &&
                        <Help menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Help
                    }
                </View>

                <View style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.2)", height: 60,position:"fixed",bottom:0,width:"100%",flexDirection:"row",justifyContent:"space-between",backgroundColor:"#68dc91",alignItems:"center" }}>

                            <TouchableOpacity onPress={() => this.updateManagerStatus("PRODUCTS")} style={{marginLeft:20,justifyContent:"center",alignItems:"center"}}>
                                <AiFillShop size="1.5em" color={this.state.manageStatus=="PRODUCTS"?"#000":"rgba(0,0,0,0.4)"} />
                                {this.state.manageStatus=="PRODUCTS" &&
                                <Text style={{fontWeight:"600",fontSize:"0.8rem"}}>
                                    Tienda
                                    </Text>
                                }
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.updateManagerStatus("QR")} style={{justifyContent:"center",alignItems:"center"}}>
                                <RiQrCodeFill size="1.5em" color={this.state.manageStatus=="QR"?"#000":"rgba(0,0,0,0.4)"}/>
                                {this.state.manageStatus=="QR" &&
                                <Text style={{fontWeight:"600",fontSize:"0.8rem"}}>
                                    QRs
                                    </Text>
                                }
                            </TouchableOpacity>


                            <TouchableOpacity onPress={() => this.updateManagerStatus("PROFILE")} style={{justifyContent:"center",alignItems:"center"}}>
                                <ImUser size="1.5em" color={this.state.manageStatus=="PROFILE"?"#000":"rgba(0,0,0,0.4)"}/>
                                {this.state.manageStatus=="PROFILE" &&
                                <Text style={{fontWeight:"600",fontSize:"0.8rem"}}>
                                    Perfil
                                    </Text>
                                }
                            </TouchableOpacity>
                            
                            <TouchableOpacity
                            onPress={()=>this.closeSession()}
                            onLongPress={()=>this.closeSession()}
                            style={{marginRight:20,alignItems:"center",justifyContent:"center"}}
                          >
                                <View to="/" style={{ textDecoration: "none", color: "#000",alignItems:"center",justifyContent:"center" }} >
                                    <BiPowerOff size="1.5em" />
                                    <Text style={{fontWeight:"600",fontSize:"0.8rem"}}>
                                    Salir
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={{position:"absolute",top:0,width:"100%",height:"100%",backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
  <HiOutlineEmojiSad size="5em" color="#000" />
  <View style={{height:30}}/>
  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, width: "100%",textAlign:"center" }}>
                    Panel de administración para móviles en construcción. Accede a través de tu ordenador para cambiar los detalles de tu establecimiento. Disponible muy pronto.
                  </Text>
  <View style={{height:100}}/>
</View>
            </View>
        )
    }
}
