import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput, Touchable, Image } from 'react-native';
import { IoIosClose } from "react-icons/io";
import Dropzone from 'react-dropzone'

//new API
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withSnackbar } from 'react-simple-snackbar'
import GeneralContext from '../Provider.js';
import {HiOutlineEmojiSad} from 'react-icons/hi'

const optionsProduct = ["Especialidad", "Vegetariano", "Vegano", "Sin gluten", "Sin lactosa", "Para compartir", "Por unidad", "Alcohol", "Sin alcohol", "Halal"]
const allergensOptions = ["Pescado", "Frutos secos", "Lácteos", "Moluscos", "Cereales con gluten", "Crustáceos", "Huevos", "Cacahuetes", "Soja", "Apio", "Mostaza", "Sésamo", "Altramuz", "Sulfitos"]
const daysNames = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
const daysNamesLowercase = ["lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "domingo"]


var newProductTemplate = {
  //update id,
  image: "",
  title: "",
  description: "",
  price: 0,
  kg:false,
  available:true
}

var hoursTemplate = [
  { init: "", final: "" },
  { init: "", final: "" },
  { init: "", final: "" },

]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

Array.prototype.swap = function (x,y) {
  var b = this[x];
  this[x] = this[y];
  this[y] = b;
  return this;
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  //padding: grid * 2,
  //margin: `0 0 ${grid}px 0`,
  width: "100%",
  // change background colour if dragging
  background: isDragging ? '#F2F2F2' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#F2F2F2' : '#fff',
  //padding: grid,
  width: "100%",

});

const newCategoryTemplate = {
  title: "",
  description: "",
  visible: true,
  index: "",
  data: []
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      status: "PRODUCTS",
      showNewMenu: false,
      showNewCategory: false,
      showNewProduct: false,
      editActualMenu: false,
      editActualCategory: false,
      editActualProduct: false,
      activeProductIndex: -1,
      activeCategoryIndex: -1,
      activeMenuIndex: -1,
      dishesCorrespondence: {},
      filteredDishes: [],
      filteredCategories: {},
      filteredMenus: {},
      newProduct: newProductTemplate,
      activeCategories: [],
      activeNewCategoryProducts: [],
      editingCategoryProductsList: [],
      newCategoryData: newCategoryTemplate,
      editingCategory: {},
      listOfAllProducts: [],
      listOfAllCategories: [],
      activeListOfAllProducts: [],
      activeListOfAllCategories: [],
      activeEditingProduct: {},
      activeEditingCategory: {},
      editingHours: hoursTemplate,
      showEditingHours: false,
      activeHoursIndex: -1,
      activeFile: null,
      initialImage: ""


    }

    this.onDragEnd = this.onDragEnd.bind(this);
    this.openModalEditCategory = this.openModalEditCategory.bind(this);
    this.updateImageContent = this.updateImageContent.bind(this);
    this.checkSaveProductChanges = this.checkSaveProductChanges.bind(this);
    this.checkSaveCategoryChanges = this.checkSaveCategoryChanges.bind(this);
  }

  checkSaveProductChanges() {
    if (!!this.state.activeProduct.title)
      return false
    return true
  }


  checkSaveCategoryChanges() {
    if (!!this.state.newCategoryData.title)
      return true
    return false
  }


  updateNewCategoryField(key, value) {
    var newCategoryData = this.state.newCategoryData
    newCategoryData[key] = value

    this.setState({ newCategoryData: newCategoryData })
  }

  updateEditingCategoryField(key, value) {
    var editingCategory = this.state.editingCategory
    editingCategory[key] = value

    this.setState({ editingCategory: editingCategory })
  }


  updateNewCategoryProducts(item) {
    var activeNewCategoryProducts = this.state.activeNewCategoryProducts
    if (activeNewCategoryProducts.indexOf(item) >= 0)
      activeNewCategoryProducts.splice(activeNewCategoryProducts.indexOf(item), 1)
    else
      activeNewCategoryProducts.push(item)
    this.setState({ activeNewCategoryProducts: activeNewCategoryProducts })
  }


  createCategory() {
    var newCategoryData = this.state.newCategoryData;
    newCategoryData.index = Object.keys(this.context.categoriesData).length
    newCategoryData.data = this.state.activeNewCategoryProducts

    this.context.createCategory(newCategoryData)
    setTimeout(() => this.setState({ activeNewCategoryProducts: [], showNewCategory: false, activeCategories: this.context.categoriesData, newCategoryData: newCategoryTemplate }, () => this.showSaveMessage()), 100)
  }

  updateImageContent(url) {
    var activeProduct = this.state.activeProduct
    activeProduct.img = url
    setTimeout(() => this.setState({ activeProduct: activeProduct }, () => this.context.updateProduct(this.state.activeProductIndex, this.state.activeProduct)), 100)
  }



  saveProductChanges() {
    if (this.state.activeProduct.img != this.state.initialImage) {
      if (!!this.state.activeProduct.img) {
        this.context.saveProductImage(this.state.activeProductIndex, this.state.activeFile, this.updateImageContent)
      } else
        this.context.removeProductImage(this.state.activeProductIndex, this.updateImageContent)
    }
    this.context.updateProduct(this.state.activeProductIndex, this.state.activeProduct)
    this.setState({ editActualProduct: false }, () => this.showSaveMessage())
  }


  saveTemporalImages(image) {
    var activeProduct = this.state.activeProduct
    activeProduct.img = URL.createObjectURL(image[0])
    this.setState({ activeProduct: activeProduct, activeFile: image[0] })

  }

  deleteImage() {
    var activeProduct = this.state.activeProduct
    activeProduct.img = ""
    this.setState({ activeProduct: activeProduct, activeFile: null })
  }

  componentWillUnmount() {
    if (!!this.state.activeFile)
      URL.revokeObjectURL(this.state.activeFile)
  }

  deleteProduct() {
    this.context.deleteProduct(this.state.activeProductIndex)
    setTimeout(() => this.setState({ editActualProduct: false, activeCategories: this.context.categoriesData }, () => this.showSaveMessage()), 100)
  }

  createProduct() {

    this.context.createProduct(this.state.newProduct)

    this.setState({ showNewProduct: false, newProduct: newProductTemplate }, () => this.showSaveMessage())
  }
  componentWillMount() {

    //this.context.getInfoFromDB()
  }

  showSaveMessage() {
    this.props.openSnackbar("Se han guardado los cambios y ya están disponibles para tus clientes.", 5000)
  }

  componentDidMount() {
    //this.updateAllInfo();
    this.setState({ listOfAllProducts: this.context.productsData, listOfAllCategories: this.context.categoriesData, activeCategories: this.context.categoriesData })

  }

  toggleAvaliabilityProduct(i) {
    this.context.updateProductAvailability(i)
    this.showSaveMessage()
  }

  updateVisibilityActualDish(available) {
    var activeProduct = this.state.activeProduct;
    activeProduct.available = available
    this.setState({ activeProduct: activeProduct })
  }


  assignActualDish(item) {
    var activeProduct =  this.context.establishmentInfo.allProducts[this.context.activeProducts[item].index]
    this.setState({ initialImage: activeProduct.img, activeProduct: activeProduct })
  }

  editActualProductFunction(key, value) {
    var activeProduct = this.state.activeProduct;
    if (key != "price")
      activeProduct[key] = value
    else
      activeProduct[key] = parseFloat(value)
    this.setState({ activeProduct: activeProduct })
  }

  newProductField(key, value) {
    var newProduct = this.state.newProduct;
    if (key != "price")
      newProduct[key] = value
    else
      newProduct[key] = parseFloat(value, "es-ES")
    this.setState({ newProduct: newProduct })
  }


  editDish(item,index) {
    this.assignActualDish(item)
    this.setState({ editActualProduct: true, activeProductIndex: this.context.activeProducts[item].index })
  }
  editDishSave() {
    var activeProduct = this.state.activeProduct;
    var dishes = this.state.dishes;
    dishes[this.state.activeProductIndex] = activeProduct;
    this.setState({ dishes: dishes, editActualProduct: false, activeProductIndex: -1, activeProduct: {} }, (() => this.context.filterProducts("")))
  }

  editCategorySave() {
    var editingCategory = this.state.editingCategory
    editingCategory.data = this.state.editingCategoryProductsList

    this.context.updateCategory(this.state.activeCategoryIndex, this.state.editingCategory)
    this.setState({ editActualCategory: false, editingCategoryProductsList: [] })
  }


  updateEditingProductList(item) {
    var editingCategoryProductsList = this.state.editingCategoryProductsList
    if (editingCategoryProductsList.indexOf(item) >= 0)
      editingCategoryProductsList.splice(editingCategoryProductsList.indexOf(item), 1)
    else
      editingCategoryProductsList.push(item)
   
    this.setState({ editingCategoryProductsList: editingCategoryProductsList })
  }



  onDragEnd(result) {
if(result.source.index!=result.destination.index){


    var activeCategories = this.context.activeCategories


    var arrayClean=[]
    for(var i=0;i<Object.keys(activeCategories).length;i++){
      arrayClean.push(
        {title:Object.keys(activeCategories)[i],
          ...activeCategories[Object.keys(activeCategories)[i]]
        }
        )
        if(result.source.index<result.destination.index){

     
        if(arrayClean[i].index<=result.destination.index && arrayClean[i].index!=result.source.index && arrayClean[i].index>0){
          arrayClean[i].index-=1
        }

      
        if(arrayClean[i].index==result.source.index && result.draggableId==arrayClean[i].title){
          arrayClean[i].index=result.destination.index
        }
      }
      else{
        if(arrayClean[i].index<=result.source.index && arrayClean[i].index!=result.source.index && arrayClean[i].index>0){
          arrayClean[i].index+=1
        }
      }
    }
    var sortedArray=new Array(arrayClean.length).fill(null)
    for (var i=0;i<arrayClean.length;i++){
      sortedArray[arrayClean[i].index]=arrayClean[i]
    }
  
    

   this.context.updateCategoriesOrder(sortedArray)


  }
}


  deleteCategory() {
    this.context.deleteCategory( this.context.activeCategories[Object.keys(this.context.activeCategories)[this.state.activeCategoryIndex]].index)
    setTimeout(() => this.setState({ editActualCategory: false, activeCategories: this.context.categoriesData }, () => this.showSaveMessage()), 100)
  }


  openModalEditCategory(item, index) {

    var editingCategory = {
      title: item,
      index: this.context.activeCategories[item].index,
      description: this.context.activeCategories[item].description,
      visible: this.context.activeCategories[item].visible,
      data: this.context.activeCategories[item].data
    }
  

    this.setState({ editingCategoryProductsList: this.context.activeCategories[item].data, editingCategory: editingCategory, editActualCategory: true, activeCategoryIndex: index })
  }

  generateCategories(categoriesIndex) {
    var cleanArray = []

    for (var i = 0; i < categoriesIndex.length; i++) {


      cleanArray.push(this.context.establishmentInfo.products[categoriesIndex[i]].title)
    }
    var cleanString = cleanArray.length > 0 ? cleanArray.join(", ") : "Sin categoría"

    return cleanString
  }

  saveHoursChanges() {
    this.context.saveHoursChanges(this.state.activeHoursIndex, this.state.editingHours)
    this.setState({ showEditingHours: false, activeHoursIndex: -1 }, () => this.showSaveMessage())
  }

  manageOpenEditingModal(index) {
    var editingHours = this.state.editingHours
    editingHours = hoursTemplate
    console.warn(this.context.establishmentInfo.hours[index].hours)
    editingHours[0] = this.context.establishmentInfo.hours[index].hours[0] ? this.context.establishmentInfo.hours[index].hours[0] : { "init": "", "final": "" }
    editingHours[1] = this.context.establishmentInfo.hours[index].hours[1] ? this.context.establishmentInfo.hours[index].hours[1] : { "init": "", "final": "" }
    editingHours[2] = this.context.establishmentInfo.hours[index].hours[2] ? this.context.establishmentInfo.hours[index].hours[2] : { "init": "", "final": "" }
    this.setState({ showEditingHours: true, editingHours: editingHours, activeHoursIndex: index })
  }


  updateEditingHour(index, type, value) {
    var editingHours = this.state.editingHours
    editingHours[index][type] = value
    this.setState({ editingHours: editingHours })
  }

  static contextType = GeneralContext;
  render() {
/** 
    return (
      <View style={{ zIndex: -1, width: "100%", height: window.innerHeight, backgroundColor: "#f5f5f5" }}>
        <View style={{ width: "100%", height: 60, backgroundColor: "#fff", flexDirection: "row", boxShadow: "-10px -10px 10px rgba(0,0,0,0.1)", borderBottomWidth: 2, borderColor: "#f5f5f5" }}>
          <ScrollView horizontal>
            <TouchableOpacity onLongPress={() => {
              this.context.filterProducts("")
              this.setState({ status: "PRODUCTS" })
            }}
              onPress={() => {
                this.context.filterProducts("")
                this.setState({ status: "PRODUCTS" })
              }}

              style={{marginLeft:20, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "PRODUCTS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                Productos
              </Text>

            </TouchableOpacity>
            <TouchableOpacity
              onLongPress={() => {
                this.context.filterCategories("")
                this.setState({ status: "CATEGORIES" })
              }}

              onPress={() => {
                this.context.filterCategories("")
                this.setState({ status: "CATEGORIES" })
              }}

              style={{marginLeft:20, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "CATEGORIES" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                Categorías
              </Text>

            </TouchableOpacity>
            <TouchableOpacity
              onLongPress={() => {

                this.setState({ status: "HOURS" })
              }}

              onPress={() => {

                this.setState({ status: "HOURS" })
              }}

              style={{marginLeft:20, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ textDecorationLine: "none", color: this.state.status == "HOURS" ? "#000" : "#BDBDBD", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                Horarios
              </Text>

            </TouchableOpacity>
       
       
            <TouchableOpacity
              onLongPress={() => { window.open("https://buyness.netlify.app/establishment/" + this.context.establishmentInfo.id) }}
              onPress={() => { window.open("https://buyness.netlify.app/establishment/" + this.context.establishmentInfo.id) }}
              style={{ marginHorizontal:20,backgroundColor: "#68dc91", borderRadius: 10, paddingHorizontal: 20,justifyContent:"center",alignItems:"center", paddingVertical: 10, marginVertical: 10 }}>
              <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                Ver tienda
              </Text>
</TouchableOpacity>


      
        

          </ScrollView>
        </View>
        {
          this.state.status == "HOURS" &&
          <View style={{width:"100%", height: "100%", overflowY: "scroll", paddingBottom: 100,backgroundColor:"#fff" }}>
            <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: 20, backgroundColor: "#fff", }}>
              <Text style={{ height: "100%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", paddingHorizontal: 20, paddingVertical: 20 }}>
                Pulsa para editar los horarios de uno de los días
              </Text>
            </View>
            
            <View style={{ width: "100%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row", justifyContent: "space-between" }}>
              <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  Día
                </Text>

              </View>




              <View style={{ width: "50%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center",width:"100%" }}>
                  Horario
                </Text>

              </View>
            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />
            {
              this.context.establishmentInfo.hours.map((item, index) => (
                <TouchableOpacity
                  onPress={() => this.manageOpenEditingModal(index)}
                  onLongPress={() => this.manageOpenEditingModal(index)}

                  style={{ width: "100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                  <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                      {daysNames[index]}
                    </Text>

                  </View>
                  <View style={{ width: "50%", justifyContent: "center", alignItems: "center" }}>
                    {item.hours.length == 0 &&
                      <Text>

                        Cerrado
                      </Text>

                    }
                    {
                      item.hours.map((item_i, index_i) => (
                        <Text style={{width:"100%",textAlign:"center"}}>

                          de {item_i.init} a {item_i.final}
                        </Text>

                      ))
                    }

                  </View>
                </TouchableOpacity>
              ))}
          </View>
        }

        {
          this.state.status == "PRODUCTS" &&

          <View style={{ height: "100%", overflowY: "scroll", paddingBottom: 100,backgroundColor:"#fff" }}>
            <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: 20, backgroundColor: "#fff", }}>
              <View style={{ width: 10 }} />
              {false &&
                <TextInput
                  onChangeText={(text) => this.context.filterProducts(text)}
                  numberOfLines={1} placeholder={"Buscar un producto"} style={{ marginLeft: 20, marginBottom: 20, borderRadius: 10, fontSize: "1rem", width: "30%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
              }
              <TouchableOpacity
                onLongPress={() => this.setState({ showNewMenu: false, showNewCategory: false, showNewProduct: true })}
                onPress={() => this.setState({ showNewMenu: false, showNewCategory: false, showNewProduct: true })}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: 10 }}>
                  + Añadir producto
                </Text>



              </TouchableOpacity>
            </View>

            <View style={{ width: 30 }} />
            <View style={{ width:"100%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row" }}>
              <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  Nombre
                </Text>

              </View>



              <View style={{ width: "20%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                  Precio
                </Text>

              </View>
              <View style={{ width: "28%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{marginRight:10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center" }}>
                  Disponibilidad
                </Text>

              </View>
            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />
            {
              Object.keys(this.context.activeProducts).length == 0 && Object.keys(this.context.productsData).length > 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: window.innerHeight * 0.0025, width:"100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  Sin resultados para esta búsqueda
                </Text>
              </View>
            }
            {
              Object.keys(this.context.productsData).length == 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: window.innerHeight * 0.0025, width: "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  Sin productos
                </Text>
              </View>
            }
            {
              Object.keys(this.context.activeProducts).map((item, index) => (
                <TouchableOpacity
                  onPress={() => this.editDish(item,index)}
                  onLongPress={() => this.editDish(item,index)}

                  style={{ width:"100%", paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                  <View style={{ width: "50%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", width: "100%" }}>
                      {item}
                    </Text>
                    <View style={{ height: 10 }} />
                    <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                      {this.generateCategories(this.context.activeProducts[item].categories)}
                    </Text>

                  </View>

                  <View style={{ width: "20%", alignItems: "flex-start", justifyContent: "center" }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.9rem", textAlign: "left", width: "100%" }}>
                      {this.context.activeProducts[item].price?this.context.activeProducts[item].price.toFixed(2)+" € ":"Sin precio"}{this.context.activeProducts[item].kg?"/ kg":""}
                    </Text>

                  </View>
                  <TouchableOpacity

                    onPress={() => this.toggleAvaliabilityProduct(index)}
                    onLongPress={() => this.toggleAvaliabilityProduct(index)}
                    style={{ width: "28%", alignSelf: "center",marginRight:20, alignItems: "center", justifyContent: "center", paddingVertical: 12, backgroundColor: this.context.activeProducts[item].available ? "#68dc91" : "#F5F5F5", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.9rem", textAlign: "center", width: "100%" }}>
                      {this.context.activeProducts[item].available ? "Disponible" : "No disponible"}
                    </Text>

                  </TouchableOpacity>
                </TouchableOpacity>
              ))
            }

          </View>
        }

        {
          this.state.status == "CATEGORIES" &&

          <View style={{ height: "100%", overflowY: "scroll", paddingBottom: 100,backgroundColor:"#fff" }}>

            <View style={{ backgroundColor: "#fff", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingTop: 20, }}>
              <Text style={{ height: "100%", textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", paddingHorizontal: 20, paddingVertical: 20 }}>
                Puedes arrastrar para cambiar el orden de las categorías.
              </Text>
              {false &&
                <TextInput
                  onChangeText={(text) => this.context.filterCategories(text)}
                  numberOfLines={1} placeholder={"Buscar una categoría"} style={{ marginLeft: 20, borderRadius: 10, marginBottom: 20, fontSize: "1rem", width: "30%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
              }
              <TouchableOpacity
                onLongPress={() => this.setState({ showNewMenu: false, showNewCategory: true, showNewProduct: false })}
                onPress={() => this.setState({ showNewMenu: false, showNewCategory: true, showNewProduct: false })}
                style={{alignSelf:"center", alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", marginRight: window.innerWidth * 0.02, borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingHorizontal: window.innerWidth * 0.02, paddingVertical:10 }}>
                  + Añadir categoría
                </Text>



              </TouchableOpacity>
            </View>

            <View style={{ width: "100%", backgroundColor: "#fff", height: 20 }} />

            <View style={{ width: "100%", height: window.innerHeight * 0.08, backgroundColor: "#fff", flexDirection: "row" }}>
              <View style={{ width: "40%", alignItems: "flex-start", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Nombre
                </Text>

              </View>

              <View style={{ width: "30%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center",  }}>
                  Núm. artículos
                </Text>

              </View>
              <View style={{ width: "30%", alignItems: "center", justifyContent: "center" }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "center", }}>
                  Visibilidad
                </Text>

              </View>

            </View>
            <View style={{ width: "100%", height: 1, backgroundColor: "#F5F5F5" }} />



            <DragDropContext
              //onBeforeCapture={onBeforeCapture}
              //onBeforeDragStart={onBeforeDragStart}
              //onDragStart={onDragStart}
              //onDragUpdate={onDragUpdate}
              onDragEnd={this.onDragEnd}

            >
              <Droppable droppableId="droppable">
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                  >
                    {Object.keys(this.context.categoriesData).map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style,
                            )}>

                            <TouchableOpacity

                              onPress={() => this.openModalEditCategory(item, index)}
                              onLongPress={() => this.openModalEditCategory(item, index)}
                              style={{ width: window.innerWidth * 0.95, paddingVertical: 15, backgroundColor: "#fff", flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#F5F5F5" }}>
                              <View style={{ width: "40%", alignItems: "flex-start", justifyContent: "center" }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>
                                  {item}
                                </Text>
                                <View style={{ height: 10 }} />
                                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", width: "100%" }}>
                                  {this.context.activeCategories[item].description}
                                </Text>
                              </View>


                              <View style={{ width: "30%", alignItems: "center", justifyContent: "center" }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                                  {this.context.activeCategories[item].data.length}
                                </Text>

                              </View>

                              <TouchableOpacity
                                onPress={() =>{this.showSaveMessage(); this.context.updateCategoryAvailability(index)}}
                                onLongPress={() =>{this.showSaveMessage(); this.context.updateCategoryAvailability(index)}}
                                style={{ width: "30%",marginRight:20, alignItems: "center", justifyContent: "center", backgroundColor: this.context.activeCategories[item].visible ? "#68dc91" : "#e8e8e8", paddingVertical: 10, alignSelf: "center", borderRadius: 10 }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "0.9rem", textAlign: "left" }}>
                                  {this.context.activeCategories[item].visible ? "Visible" : "No visible"}
                                </Text>

                              </TouchableOpacity>


                            </TouchableOpacity>
                          </div>
                        )}
                      </Draggable>
                    ))

                    }
                  </div>
                )}

              </Droppable>

            </DragDropContext>

            {
              Object.keys(this.context.categoriesData).length == 0 &&
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: window.innerHeight * 0.0025, width: "100%", height: window.innerHeight * 0.08, flexDirection: "row" }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                  Sin categorías
                </Text>
              </View>
            }
<View style={{height:100}}/>
          </View>
        }



        {this.state.showNewCategory &&
          <ScrollView style={{ position: "fixed", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: 20, width: "95%", marginTop: 10, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 20}}>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: 20 }}>
                    Nueva categoría


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: 20, marginTop: 20 }}>
                    Título
                  </Text>
                  <TextInput onChangeText={t => this.updateNewCategoryField("title", t)} numberOfLines={1} placeholder={"Hamburguesas"} style={{ marginTop: 20, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: 20, marginTop: 20 }}>
                    Información adicional
                  </Text>
                  <TextInput onChangeText={t => this.updateNewCategoryField("description", t)} numberOfLines={3} placeholder={"Todas las hamburguesas van acompañadas de patatas y ensalada"} style={{ marginVertical: 20, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop:10, backgroundColor: "#fff" }}>
                    ¿Este categoría estará ya visible?
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                      onPress={() => this.updateNewCategoryField("visible", true)}
                      onLongPress={() => this.updateNewCategoryField("visible", true)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: this.state.newCategoryData.visible ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        Sí
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.updateNewCategoryField("visible", false)}
                      onLongPress={() => this.updateNewCategoryField("visible", false)}
                      style={{ justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: !this.state.newCategoryData.visible ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        No
                      </Text>
                    </TouchableOpacity>
                  </View>





                </View>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10, height:400 }}>
                  <View style={{ width: "100%", backgroundColor: "#fff" }}>
                    <View style={{ flexDirection: "row", alignSelf: "flex-start", marginLeft: 20, justifyContent: "space-between", width: "90%" }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", paddingVertical: 10 }}>
                        Artículos seleccionados: {this.state.activeNewCategoryProducts}
                      </Text>

                    </View>
                  </View>
<View style={{height:10}}/>
                  <ScrollView style={{ overflowY: "scroll", backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", maxHeight: window.innerHeight * 0.6, height: "100%", paddingTop: 10 }}>
                    {
                      Object.keys(this.context.productsData).map((item, index) => (
                        <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                          <TouchableOpacity
                            onPress={() => this.updateNewCategoryProducts(index)}
                            onLongPress={() => this.updateNewCategoryProducts(index)}
                            style={{ width: "100%", flexDirection: "row", height: 30 }}>
                            <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start" }}>
                              <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: this.state.activeNewCategoryProducts.indexOf(item) >= 0 ? "500" : "400", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>

                                {item}
                              </Text>
                            </View>
                            <View style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                              <View style={{ width: 18, height: 18, backgroundColor: this.state.activeNewCategoryProducts.indexOf(index) >= 0 ? "#68dc91" : "#F2F2F2" }}>

                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ))
                    }

                    <View style={{ height: 10 }} />
                  </ScrollView>
                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ showNewCategory: false })} onPress={() => this.setState({ showNewCategory: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View>
              <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: 20, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>

                <TouchableOpacity
                  disabled={!this.state.newCategoryData.title}
                  onPress={() => this.createCategory()}
                  onLongPress={() => this.createCategory()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.newCategoryData.title ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                    Crear categoría
                  </Text>



                </TouchableOpacity>


                {!this.state.newCategoryData.title &&
                  <View>
                    <View style={{ height: 10 }} />
                    <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                      Escribe un título para poder crear la categoría
                    </Text>
                  </View>
                }
              </View>
            </View>
            <View style={{height:90}}/>
          </ScrollView>
        }
        {this.state.editActualCategory &&
          <ScrollView style={{ position: "fixed", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : window.innerWidth * 0.95, height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: 20, width: "95%", marginTop: 10, alignSelf: "center", borderTopLeftRadius: 10, borderTopRightRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 20}}>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: 20 }}>
                    Editar categoría


                  </Text>
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: 20, marginTop: 20 }}>
                    Título
                  </Text>
                  <TextInput
                    onChangeText={t => this.updateEditingCategoryField("title", t)}
                    defaultValue={this.state.editingCategory.title}
                    numberOfLines={1} placeholder={"Ej. Hamburguesas"} style={{ marginTop: 20, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", marginLeft: 20, marginTop: 20 }}>
                    Información adicional
                  </Text>
                  <TextInput
                    onChangeText={t => this.updateEditingCategoryField("description", t)}
                    defaultValue={this.state.editingCategory.description}
                    numberOfLines={3} placeholder={"Ej. Todas las hamburguesas van acompañadas de patatas y ensalada"} style={{ marginVertical: 20, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop: 10, backgroundColor: "#fff" }}>
                    ¿Este categoría estará ya visible?
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                      onPress={() => this.updateEditingCategoryField("visible", true)}
                      onLongPress={() => this.updateEditingCategoryField("visible", true)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: this.state.editingCategory.visible ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        Sí
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.updateEditingCategoryField("visible", false)}
                      onLongPress={() => this.updateEditingCategoryField("visible", false)}
                      style={{ justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: !this.state.editingCategory.visible ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        No
                      </Text>
                    </TouchableOpacity>
                  </View>



                </View>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10, height: 400 }}>
                  <View style={{ width: "100%", backgroundColor: "#fff" }}>
                    <View style={{ flexDirection: "row", alignSelf: "flex-start", marginLeft: 20, justifyContent: "space-between", width: "90%" }}>
                      <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.2rem", textAlign: "left", paddingVertical: 10}}>
                        Artículos seleccionados
                      </Text>

                    </View>
                  </View>
                  <View style={{height:10}}/>
                  <ScrollView style={{ overflowY: "scroll", backgroundColor: "#f5f5f5", alignSelf: "center", width: "95%", height: "100%", paddingTop: 10 }}>
                    {
                      Object.keys(this.context.productsData).map((item, index) => (
                        <View style={{ width: "98%", alignSelf: "center", backgroundColor: "#fff", justifyContent: "center", alignItems: "center", marginTop: 2 }}>
                          <TouchableOpacity
                            onPress={() => this.updateEditingProductList(index)}
                            onLongPress={() => this.updateEditingProductList(index)}
                            style={{ width: "100%", flexDirection: "row" }}>
                            <View style={{ width: "90%", justifyContent: "center", alignItems: "flex-start", height: 30 }}>
                              <Text numberOfLines={1} style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>

                                {item}
                              </Text>
                            </View>
                            <View style={{ width: "10%", justifyContent: "center", alignItems: "center", height: 30 }}>
                              <View style={{ width: 18, height: 18, backgroundColor: this.state.editingCategoryProductsList.indexOf(index) >= 0 ? "#68dc91" : "#F2F2F2" }}>

                              </View>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ))
                    }

             
                  </ScrollView>
                </View>

              </View>
              <TouchableOpacity onPress={() => this.setState({ editActualCategory: false })} onLongPress={() => this.setState({ editActualCategory: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
            <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: 20, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
              <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
                <TouchableOpacity
                  onPress={() => this.deleteCategory()}
                  onLongPress={() => this.deleteCategory()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                    Eliminar
                  </Text>



                </TouchableOpacity>
                <TouchableOpacity
                  disabled={!this.state.editingCategory.title}
                  onPress={() => this.editCategorySave()}
                  onLongPress={() => this.editCategorySave()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor: !this.state.editingCategory.title ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                    Guardar cambios
                  </Text>



                </TouchableOpacity>

              </View>
              {!this.state.editingCategory.title &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    Escribe un título para poder guardar los cambios
                  </Text>
                </View>
              }
            </View>
            <View style={{height:90}}/>
          </ScrollView>
        }

        {this.state.showNewProduct &&
          <ScrollView style={{ position: "absolute", top: 0, width: "95%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", width: "95%", marginTop:20, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 20 }}>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: 20 }}>
                    Nuevo producto


                  </Text>
                  <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <View style={{ width: "95%", justifyContent: "center", alignItems: "center", alignSelf: "center", height: 200, backgroundColor: "#f5f5f5", marginTop: 20, borderRadius: 10 }}>

                        <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                          <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />
                          <Text style={{ position: "absolute", top: "45%", alignSelf: "center", justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: window.innerWidth * 0.02 }}>
                            Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)
                          </Text>

                        </div>
                      </View>
                    )}
                  </Dropzone>

                  <Text numberOfLines={1} style={{ marginTop: 20, color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingBottom: 20, backgroundColor: "#fff" }}>
                    Nombre
                  </Text>
                  <TextInput
                    onChangeText={(text) => this.newProductField("title", text)}
                    numberOfLines={1} placeholder={"Hamburguesa con queso"} style={{ fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ marginTop: 20, color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingBottom: 20, backgroundColor: "#fff" }}>
                    Descripción
                  </Text>
                  <TextInput
                    onChangeText={(text) => this.newProductField("description", text)}
                    numberOfLines={3} placeholder={"Hamburguesa de 200g de ternera con mozarella"} style={{ fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />







                </View>
                <View style={{ width: "100%", backgroundColor: "#fff", height: "100%" }}>
                  <View style={{ height: 30 }} />
                  <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                    <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingBottom: 20, backgroundColor: "#fff" }}>
                      Precio en €
                    </Text>

                  </View>


                  <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center" }}>
                    <TextInput
                      onChangeText={(text) => this.newProductField("price", text)}
                      numberOfLines={1} placeholder={"6.45"} style={{ marginTop: 10, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                      {this.state.newProduct.kg &&
                      <Text numberOfLines={1} style={{paddingLeft:20, textAlign:"left", color: "#000", fontWeight: "400", fontSize: "1.2rem",backgroundColor: "#fff" }}>
                    / kg
                  </Text>
}


                  </View>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop: 10, backgroundColor: "#fff" }}>
                    ¿Este artículo está disponible?
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                      onPress={() => this.newProductField("available", true)}
                      onLongPress={() => this.newProductField("available", true)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center",paddingHorizontal:20, height: 20, backgroundColor: this.state.newProduct.available ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        Sí
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.newProductField("available", false)}
                      onLongPress={() => this.newProductField("available", false)}
                      style={{ justifyContent: "center", alignItems: "center",paddingHorizontal:20, height: 20, backgroundColor: !this.state.newProduct.available ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        No
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop: 10, backgroundColor: "#fff" }}>
                    Precio por kg
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                      onPress={() => this.newProductField("kg", this.state.newProduct.kg!=null?!this.state.newProduct.kg:false)}
                      onLongPress={() => this.newProductField("kg", this.state.newProduct.kg!=null?!this.state.newProduct.kg:false)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: this.state.newProduct.kg ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        {this.state.newProduct.kg?"Sí":"No"}
                      </Text>
                    </TouchableOpacity>

                  </View>

                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ showNewProduct: false })} onPress={() => this.setState({ showNewProduct: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
              <View style={{ width: "95%", alignSelf: "center", justifyContent: "center", alignItems: "center", paddingBottom: 20, backgroundColor: "#fff" }}>

                <TouchableOpacity
                    disabled={!this.state.newProduct.title}
                  onPress={() => this.createProduct()}
                  onLongPress={() => this.createProduct()}
                  style={{ alignItems: "center", justifyContent: "center", backgroundColor:!this.state.newProduct.title || !this.state.newProduct.price?"#F5F5F5": "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                  <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                    Crear producto
                  </Text>



                </TouchableOpacity>
              </View>
              {!this.state.newProduct.title &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                    Escribe un título para poder crear el producto
                  </Text>
                </View>
              }

              <View style={{ height: 10 }} />
            </View>
            <View style={{ width: "100%", height: 90 }} />

          </ScrollView>
        }

        {this.state.editActualProduct &&
          <ScrollView style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : window.innerWidth * 0.95, height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: 20, width: "95%", marginTop: 10, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 20 }}>
                <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 10 }}>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: 20 }}>
                    Editar producto


                  </Text>
                  {!this.state.activeProduct.img &&
                    <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
                      {({ getRootProps, getInputProps }) => (
                        <View style={{ width: "95%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, backgroundColor: "#f5f5f5", marginVertical: 20, borderRadius: 10 }}>

                          <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                            <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />
                            <Text style={{ position: "absolute", top: "45%", alignSelf: "center", justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: window.innerWidth * 0.02 }}>
                              Arrastra una imagen o pulsa para subir una desde tu ordenador (opcional)
                            </Text>

                          </div>
                        </View>
                      )}
                    </Dropzone>
                  }
                  {this.state.activeProduct.img &&
                    <View style={{ overflow: "hidden", width: "95%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, backgroundColor: "#f5f5f5", marginVertical: 20, borderRadius: 10 }}>
                      <Image source={this.state.activeProduct.img} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                      <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <View style={{ position: "absolute", top: 0, width: "95%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, borderRadius: 10 }}>

                            <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                              <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />


                              <View
                                style={{ borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "flex-start", alignSelf: "center" }}>
                                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                                  Cambiar imagen
                                </Text>



                              </View>
                            </View>

                          </View>
                        )}
                      </Dropzone>
                      <TouchableOpacity
                        onPress={() => this.deleteImage()}
                        onLongPress={() => this.deleteImage()}
                        style={{ position: "absolute", top: 10, right: 10, borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "red", alignSelf: "flex-start", alignSelf: "center" }}>
                        <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                          Eliminar
                        </Text>



                      </TouchableOpacity>
                    </View>
                  }

                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingVertical: 10, backgroundColor: "#fff" }}>
                    Nombre
                  </Text>
                  <TextInput
                    onChangeText={(text) => this.editActualProductFunction("title", text)}
                    defaultValue={this.state.activeProduct.title} numberOfLines={1} placeholder={"Hamburguesa con queso"} style={{ marginTop: 10, fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
                  <Text numberOfLines={1} style={{ color: "#000", borderRadius: 10, fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingVertical: 10, backgroundColor: "#fff" }}>
                    Descripción
                  </Text>
                  <TextInput
                    onChangeText={(text) => this.editActualProductFunction("description", text)}
                    defaultValue={this.state.activeProduct.description} numberOfLines={3} placeholder={"Hamburguesa de 200g de ternera con mozarella"} style={{ marginVertical: 10, fontWeight: "400", fontSize: "1rem", width: "95%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />







                </View>
                <View style={{ width: "100%", backgroundColor: "#fff", height: "100%" }}>
             
                  <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                    <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, paddingBottom: 20, backgroundColor: "#fff" }}>
                      Precio en €
                    </Text>

                  </View>


                  <View style={{ width: "95%", flexDirection: "row", justifyContent: "flex-start", alignSelf: "center" }}>
                    <TextInput
                    placeholder={"6.45"}
                      onChangeText={(text) => this.editActualProductFunction("price", text)}
                      numberOfLines={1} placeholder={"6.45"} defaultValue={this.state.activeProduct.price?this.state.activeProduct.price.toFixed(2):""} style={{ marginTop: 10, fontSize: "1rem", width: "40%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />
{this.state.activeProduct.kg &&
                      <Text numberOfLines={1} style={{paddingLeft:20, textAlign:"left", color: "#000", fontWeight: "400", fontSize: "1.2rem",backgroundColor: "#fff" }}>
                    / kg
                  </Text>
}

                  </View>
                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop: 10, backgroundColor: "#fff" }}>
                    ¿Este artículo está disponible?
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                      onPress={() => this.editActualProductFunction("available", true)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: this.state.activeProduct.available ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        Sí
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.editActualProductFunction("available", false)}
                      style={{ justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: !this.state.activeProduct.available ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                        No
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ height: 10 }} />

                  <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal:20, marginTop: 10, backgroundColor: "#fff" }}>
                    Precio por kg
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>

                    <TouchableOpacity
                    onPress={() => this.editActualProductFunction("kg", this.state.activeProduct.kg!=null?!this.state.activeProduct.kg:false)}
                      onLongPress={() => this.editActualProductFunction("kg", this.state.activeProduct.kg!=null?!this.state.activeProduct.kg:false)}
                      style={{ marginLeft: 20, justifyContent: "center", alignItems: "center", paddingHorizontal:20, height: 20, backgroundColor: this.state.activeProduct.kg ? "#68dc91" : "#f5f5f5" }}>
                      <Text numberOfLines={1} style={{ color: "#000", fontWeight: "400", fontSize: "1.1rem" }}>
                      {this.state.activeProduct.kg?"Sí":"No"}
                      </Text>
                    </TouchableOpacity>

                  </View>
                </View>
              </View>
              <TouchableOpacity onLongPress={() => this.setState({ editActualProduct: false, activeProduct: {} })} onPress={() => this.setState({ editActualProduct: false, activeProduct: {} })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
              <View>
              <View style={{ alignSelf: "center", width: "95%", alignItems: "center", paddingBottom: 20, backgroundColor: "#fff", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
              <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", }}>
             <TouchableOpacity
                    onPress={() => this.deleteProduct()}
                    onLongPress={() => this.deleteProduct()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#fff", alignSelf: "center", width: "20%", }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                      Eliminar
                    </Text>



                  </TouchableOpacity>
                  <TouchableOpacity
                    disabled={this.checkSaveProductChanges()}
                    onPress={() => this.saveProductChanges()}
                    onLongPress={() => this.saveProductChanges()}
                    style={{ alignItems: "center", justifyContent: "center", backgroundColor: this.checkSaveProductChanges() ? "#F5F5F5" : "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                    <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                      Guardar cambios
                    </Text>

                  </TouchableOpacity>
                </View>
              </View>
            </View>
            {!this.state.activeProduct.title &&
                <View>
                  <View style={{ height: 10 }} />
                  <Text style={{ textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                    Escribe un título para poder guardar los cambios
                  </Text>
                </View>
              }
            
</View>
<View style={{ width: "100%", height: 90 }} />
          </ScrollView>
        }
        {
          this.state.showEditingHours &&
          <ScrollView style={{ position: "absolute", top: 0, width: this.state.menuOpen ? window.innerWidth * 0.7 : window.innerWidth * 0.95, height: "100%", backgroundColor: "rgba(0,0,0,0.4)" }}>
            <View style={{ backgroundColor: "#fff", paddingBottom: 20, width: "95%", marginTop: 10, alignSelf: "center", borderRadius: 10, overflow: "hidden" }}>
              <View style={{ width: "100%", backgroundColor: "#fff", paddingVertical: 20 }}>

                <Text numberOfLines={1} style={{ color: "#000", fontWeight: "500", fontSize: "1.5rem", textAlign: "left", marginLeft: 20 }}>
                  Editar horario del {daysNamesLowercase[this.state.activeHoursIndex]}


                </Text>
                <View style={{ height: 20 }} />
                <View style={{ width: "95%", alignSelf: "center", backgroundColor: "#F5F5F5", paddingVertical: 15, borderRadius: 10 }}>
                  <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "left", marginLeft: 20 }}>
                    ¿Cómo relleno esto?
                  </Text>
                  <Text style={{ paddingVertical: 10, color: "#000", fontWeight: "400", fontSize: "0.8rem", textAlign: "left", marginLeft: 20 }}>
                    Tu establecimiento puede tener hasta tres horarios diferentes para un mismo día. Por ejemplo si tu establecimiento solo abre de 08:00h a 16:00h rellena únicamente el primer horario. Si tu establecimiento hace un horario de 09:00h a 14:00 y de 15:00h a 20:00h rellena el primer horario con 09:00 y 14:00 y el segundo con 15:00h y 20:00h.
                  </Text>
                </View>


              </View>
              <View style={{ height: 30 }} />
              <View style={{ justifyContent: "center", width: "100%", alignItems: "center" }}>
                <View style={{ width: "100%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    Horario 1
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingRight: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[0].init}
                      onChangeText={(text) => this.updateEditingHour(0, "init", text)}
                      //onChangeText={(text) => this.editActualProductFunction("description", text)}
                      placeholder={"00:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[0].final}
                      onChangeText={(text) => this.updateEditingHour(0, "final", text)}
                      //onChangeText={(text) => this.editActualProductFunction("description", text)}
                      placeholder={"24:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                  </View>
                </View>
                <View style={{ width: "100%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    Horario 2
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingRight: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[1].init}
                      onChangeText={(text) => this.updateEditingHour(1, "init", text)}
                      //onChangeText={(text) => this.editActualProductFunction("description", text)}
                      placeholder={"00:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[1].final}
                      onChangeText={(text) => this.updateEditingHour(1, "final", text)}
                      //onChangeText={(text) => this.editActualProductFunction("description", text)}
                      placeholder={"24:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                  </View>
                </View>
                <View style={{ width: "100%" }}>
                  <Text numberOfLines={1} style={{ width: "100%", textAlign: "center", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                    Horario 3
                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={1} style={{ paddingRight: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      de
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[2].init}
                      onChangeText={(text) => this.updateEditingHour(2, "init", text)}
                      //onChangeText={(text) => this.editActualProductFunction("description", text)}
                      placeholder={"00:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                    <Text numberOfLines={1} style={{ paddingHorizontal: 10, textAlign: "left", color: "#000", fontWeight: "500", fontSize: "1rem" }}>
                      a
                    </Text>
                    <TextInput
                      defaultValue={this.state.editingHours[2].final}
                      onChangeText={(text) => this.updateEditingHour(2, "final", text)}
                      placeholder={"24:00h"} style={{ marginVertical: 10, fontWeight: "500", fontSize: "1rem", width: "30%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: 20, paddingVertical: 105 }} />

                  </View>
                </View>
              </View>
              <View style={{ height: 30 }} />
              <TouchableOpacity
                onPress={() => this.saveHoursChanges()}
                onLongPress={() => this.saveHoursChanges()}
                style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "center", width: "30%", borderRadius: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 20 }}>
                  Guardar cambios
                </Text>



              </TouchableOpacity>
              <View style={{ height: 10 }} />
              <TouchableOpacity onLongPress={() => this.setState({ showEditingHours: false })} onPress={() => this.setState({ showEditingHours: false })} style={{ alignSelf: "flex-end", position: "absolute", top: 0, right: 0 }}>
                <IoIosClose size="2.5em" />
              </TouchableOpacity>
            </View>
          </ScrollView>
        }
      </View>
    )
    */

    return (
       <div style={{flex:1,width:"100%",height:"100%"}}>
  <View style={{position:"absolute",top:0,width:"100%",height:"100%",backgroundColor:"#68dc91",justifyContent:"center",alignItems:"center"}}>
  <HiOutlineEmojiSad size="5em" />
  <View style={{height:30}}/>
  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, width: "100%",textAlign:"center" }}>
                    No se ha podido cargar la información de este establecimiento. Por favor revisa la dirección.
                  </Text>
  <View style={{height:100}}/>
</View>
</div>
    )
  }
}
export default withSnackbar(App, {
  position: 'top-center',
  style: {
    zIndex: 1100,
    backgroundColor: '#68dc91',
    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
    color: '#000',
    borderRadius: 10,
    psddingHorizontal: 20,
    fontSize: '1rem',
    textAlign: 'left',
  },
  closeStyle: {
    color: '#000',

  },
})