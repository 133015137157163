import * as React from 'react';
import { Animated, Text, View, TouchableOpacity } from 'react-native';
import { IoMdMenu, IoIosCard, IoIosHelpCircle} from "react-icons/io";
import {IoReceiptSharp} from 'react-icons/io5'
import { RiArrowLeftLine, RiQrCodeFill } from "react-icons/ri";
import { ImSpoonKnife,ImUser } from "react-icons/im";
import { AiFillShop } from "react-icons/ai"
import { BiPowerOff } from "react-icons/bi"
import Carta from './AdminManageCarta'
import Dishes from './AdminManageDishes'
import Help from './AdminManageHelp'
import Orders from './AdminManageOrders'
import Menus from './AdminManageMenus'
import Payments from './AdminManagePayments'
import Profile from './AdminManageProfile'
import {
    Link,
    Navigate,
    useNavigate
} from "react-router-dom";
import { Auth } from '../AuthContext'
import { getAuth, signInWithEmailAndPassword ,signOut} from "firebase/auth";
import SnackbarProvider from 'react-simple-snackbar'
export default function Wrapper(props) {
    const navigate = useNavigate();

    return (
        <App
            navigate={navigate}
            {...props}
        />
    );
};



class App extends React.Component {
    static contextType = Auth
    constructor(props,context) {
        super(props);
        this.updateManagerStatus = this.updateManagerStatus.bind(this);
        this.state = {
            manageStatus: "PRODUCTS",
            lateralMenu: new Animated.Value(window.innerWidth * 0.05),
            menuOpen: false
        }
    }

 

    updateManagerStatus(newStatus) {
        this.setState({ manageStatus: newStatus })
    }

    componentWillMount() {

        if (!this.context.usuario)
            setTimeout(() => this.props.navigate('/login'), 100)
       


    }
    


    showMenu() {
        Animated.timing(this.state.lateralMenu, {
            toValue: window.innerWidth * 0.3,
            duration: 300
        }).start(() =>
            this.setState({
                menuOpen: true
            }))
    }
    closeMenu() {
        this.setState({
            menuOpen: false
        }, () => Animated.timing(this.state.lateralMenu, {
            toValue: window.innerWidth * 0.05,
            duration: 300,
        }).start())
    }


    closeSession() {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            setTimeout(() => this.props.navigate('/login', { test: "test" }), 100)
        }).catch((error) => {
            // An error happened.
        });
    }

    render() {

        return (
            <View style={{ flexDirection: "row", zIndex: 0, position: "absolute", top: 0, width:"100%", height: "100%", backgroundColor: "#fff", alignItems: "center", overflow: "hidden", flexDirection: "row" }}>
                <Animated.View
                    style={{ boxShadow: "0px 30px 10px rgba(0,0,0,0.1)", height: window.innerHeight, backgroundColor: "#68dc91", zIndex: 1, width: this.state.lateralMenu, overflow: "hidden" }}>
                    {this.state.menuOpen &&
                        <View style={{height:"100%"}}>
                            <Text style={{ color: "#000", fontWeight: "500", fontSize: "2.5rem", paddingHorizontal: "5%", paddingTop: window.innerHeight * 0.02, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                Buyness
                            </Text>
                            <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.5rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                administra tu establecimiento
                            </Text>
                            <TouchableOpacity style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02 }} onLongPress={() => { this.updateManagerStatus("PRODUCTS"); this.closeMenu() }} onPress={() => { this.updateManagerStatus("PRODUCTS"); this.closeMenu() }}>
                                <AiFillShop size="1.5em" />
                                <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                    Mi establecimiento
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02 }} onLongPress={() => { this.updateManagerStatus("ORDERS"); this.closeMenu() }} onPress={() => { this.updateManagerStatus("ORDERS"); this.closeMenu() }}>
                                <IoReceiptSharp size="1.5em" />
                                <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                   Pedidos
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02, marginTop: window.innerHeight * 0.02 }} onLongPress={() => { this.updateManagerStatus("QR"); this.closeMenu() }} onPress={() => { this.updateManagerStatus("QR"); this.closeMenu() }}>
                                <RiQrCodeFill size="1.5em" />
                                <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                    QRs
                                </Text>
                            </TouchableOpacity>



                            <TouchableOpacity style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02 }} onLongPress={() => { this.updateManagerStatus("PROFILE"); this.closeMenu() }} onPress={() => { this.updateManagerStatus("PROFILE"); this.closeMenu() }}>
                                <ImUser size="1.5em" />
                                <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.02, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                    Perfil
                                </Text>
                            </TouchableOpacity>
                            {false &&
                                <TouchableOpacity style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02 }} onLongPress={() => { this.updateManagerStatus("HELP"); this.closeMenu() }} onPress={() => { this.updateManagerStatus("HELP"); this.closeMenu() }}>
                                    <IoIosHelpCircle size="1.5em" />
                                    <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.02, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                        Ayuda
                                    </Text>
                                </TouchableOpacity>
                            }
                            <TouchableOpacity
                            onPress={()=>this.closeSession()}
                            onLongPress={()=>this.closeSession()}

                             to="/" style={{position:"absolute",bottom:0, textDecoration: "none", color: "#000", marginTop: window.innerHeight * 0.25 }} >
                                <View style={{ paddingVertical: window.innerHeight * 0.02, flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: window.innerWidth * 0.02 }} onLongPress={() => this.updateManagerStatus("PRODUCTS")} onPress={() => this.updateManagerStatus("PRODUCTS")}>
                                    <BiPowerOff size="1.5em" />
                                    <Text style={{ color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingLeft: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.02, textAlign: "left", width: "100%", backgroundColor: "transparent" }}>

                                        Cerrar sesión
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity onLongPress={() => this.closeMenu()} onPress={() => this.closeMenu()} style={{ alignSelf: "center", position: "absolute", top: window.innerHeight * 0.01, right: window.innerWidth * 0.02 }}>
                                <RiArrowLeftLine size="2em" />
                            </TouchableOpacity>
                        </View>
                    }
                    {!this.state.menuOpen &&
                        <View style={{ height: "100%" }}>
                            <TouchableOpacity onLongPress={() => this.showMenu()} onPress={() => this.showMenu()} style={{ marginTop: window.innerHeight * 0.02, alignSelf: "center" }}>
                                <IoMdMenu size="2em" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.updateManagerStatus("PRODUCTS")} style={{ marginTop: window.innerHeight * 0.1, alignSelf: "center" }}>
                                <AiFillShop size="1.5em" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.updateManagerStatus("ORDERS")} style={{ marginTop: window.innerHeight * 0.1, alignSelf: "center" }}>
                                <IoReceiptSharp size="1.5em" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => this.updateManagerStatus("QR")} style={{ marginTop: window.innerHeight * 0.1, alignSelf: "center" }}>

                                <RiQrCodeFill size="1.5em" />
                            </TouchableOpacity>


                            <TouchableOpacity onPress={() => this.updateManagerStatus("PROFILE")} style={{ marginTop: window.innerHeight * 0.1, alignSelf: "center" }}>
                                <ImUser size="1.5em" />
                            </TouchableOpacity>
                            {false &&
                                <TouchableOpacity onPress={() => this.updateManagerStatus("HELP")} style={{ marginTop: window.innerHeight * 0.1, alignSelf: "center" }}>
                                    <IoIosHelpCircle size="1.5em" />
                                </TouchableOpacity>
                            }
                            <TouchableOpacity
                            onPress={()=>this.closeSession()}
                            onLongPress={()=>this.closeSession()}
                             style={{ marginTop: window.innerHeight * 0.1, position: "absolute", bottom: window.innerHeight * 0.02, alignSelf: "center" }}>
                                <View to="/" style={{ textDecoration: "none", color: "#000" }} >
                                    <BiPowerOff size="1.5em" />
                                </View>
                            </TouchableOpacity>
                        </View>
                    }
                </Animated.View>

                <View style={{ width:"100%",height:"100%" }}>
                    {
                        this.state.manageStatus == "ORDERS" &&
                        <Orders lateralMenuOffset={this.state.lateralMenu} updateManagerStatus={this.updateManagerStatus} />
                        //Orders
                    }
                    {
                        this.state.manageStatus == "PROFILE" &&
                        <Profile menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Profile
                    }
                    {this.state.manageStatus == "QR" &&
                        <Carta menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Carta
                    }
                    {this.state.manageStatus == "MENUS" &&
                        <Menus menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Menús
                    }
                    {this.state.manageStatus == "PRODUCTS" && 
                        <Dishes menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Platos
                    }
                    {this.state.manageStatus == "HELP" &&
                        <Help menuOpen={this.state.menuOpen} updateManagerStatus={this.updateManagerStatus} />
                        //Help
                    }
                </View>

            </View>
        )
    }
}
