import logo from './logo.svg';
import './App.css';
import Landing from './LandingManager'
import Login from './LoginManager'
import Contact from './ContactManager'
import Register from './RegisterBrowser'
import Menu from './MenuManager'
import MenuFav from './MenuFavManager'
import AdminPanel from './AdminManager'
import Client from './ClientManager'
import TermsAndConditions from './TermsAndConditions'
import RedirectToLanding from './RedirectToLanding'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from "react-router-dom";

import GeneralContext, { GeneralProvider } from './Provider.js';
import { AuthContext } from "./AuthContext";
import smoothscroll from 'smoothscroll-polyfill';
import SnackbarProvider from 'react-simple-snackbar'
// smooth scroll not supported on safari, this fixes
smoothscroll.polyfill();



function App() {
  return (
   <SnackbarProvider>
    <AuthContext>     
   
        <GeneralProvider>
     
        <Router>
          <Routes>
          <Route exact path="/" element={<Client />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/establishment/:id" element={<Menu />} />
            <Route exact path="/fav_establishment/:id" element={<MenuFav />} />
            <Route exact path="/manage" element={<AdminPanel />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/business" element={<Landing />} />
            <Route path='*' element={<RedirectToLanding />} />
          </Routes>
        </Router>
      
       </GeneralProvider> 
           
    </AuthContext>
    </SnackbarProvider>
   
  );
}

export default App;
