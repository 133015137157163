import * as React from 'react';
import ClientBrowser from './ClientBrowser';
import ClientPhone from './ClientPhone';




export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { screenWidth: window.innerWidth };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions, false);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
}

updateWindowDimensions() {
   this.setState({ screenWidth: window.innerWidth });
}

  render() {

    return (

      <div style={{ width: "100%", height: "100%" }}>
      {
        this.state.screenWidth>760 &&
        <ClientBrowser/>
      }
      {
        this.state.screenWidth<=760 &&
        <ClientPhone/>
      }
      </div>


    )
  }
}
/** 
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: "100%",height:"100%" }}>
        <Stick node={} position="bottom center" align="top center">
  <p>The anchor node</p>
</Stick>
          
          </View>

          
            <View style={{overflow: "scroll", boxShadow: "0px 0px 10px rgba(0,0,0,0.2)", marginTop: 10, paddingVertical: 10, width: "65%", alignSelf: "center", borderRadius: 10 }}>
            {false &&
        <View style={{ flexDirection: "row",width:"100%",justifyContent:"flex-start",alignItems:"flex-start" }}>
        
          <View style={{ width: "70%", alignSelf: "center" }}>
            
          </View>
        </View>
          }
           
          </View>
          {false &&
            
          }
  
        </View>
*/