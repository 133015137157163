import React, { useEffect, useState, useContext } from 'react';
import register from './images/register.jpg';
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { Text, View, TouchableOpacity, Image, TextInput, Picker } from 'react-native';
import { types } from './types'
import { cities } from './cities'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { auth } from './firebase';
import { Auth } from "./AuthContext";
import { useNavigate } from 'react-router-dom';

export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [password2, setPassword2] = useState("");

  const [sendCreate, setSendCreate] = useState(false);
  const [createdSuccess, setCreatedSuccess] = useState(false);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [type_key, setTypeKey] = useState("");
  const [direction, setDirection] = useState("");
  const [locality, setLocality] = useState("");


  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);

  const [signup, setsignup] = useState(false);
  const { usuario, updateUser, setAuthFunction } = useContext(Auth);



  function checkStep(step) {
    if (step == 1)
      return !!name && !!type && !!direction && !!locality

    if (step == 2)
      return validEmail && validPhone && validPassword && password == password2

  }

   function createAccount() {
    const data = {
      direction: direction,
      location: locality,
      email: email,
      name: name,
      phone: phone,
      type: type_key,
      password: password
    }

   fetch("https://us-central1-close2you-a7817.cloudfunctions.net/v1/create_store", {
      method: "POST",
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(res => {

      if (res.status != 201) {
        setSendCreate(true)
        setCreatedSuccess(false)
      }
      else {
        setSendCreate(true)
        setCreatedSuccess(true)
      }
    }).catch(e => {});

   
  }

  function checkPassword(password) {
    var i = 0;
    var containsNumber = false
    var containsUpperCase = false
    var containsLowerCase = false
    var character;
    while (i <= password.length) {
      character = password.charAt(i);
      if (!isNaN(character * 1)) {
        containsNumber = true
      } else {
        if (character == character.toUpperCase()) {
          containsUpperCase = true
        }
        if (character == character.toLowerCase()) {
          containsLowerCase = true
        }
      }
      i++;
    }

    setValidPassword(
      password.length > 6 &&
      containsNumber &&
      containsLowerCase &&
      containsUpperCase
    )
  }

  function checkPhone(phone) {
    setValidPhone(String(phone)
      .toLowerCase().match(/^\d{9}$/))
  }

  function checkEmail(email) {
    setValidEmail(String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ))
  }

  return (
    <View style={{ width: "100%", height: window.innerHeight, backgroundColor: "#fff", alignItems: "center" }}>

      <Image source={register} style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, opacity: 1 }} resizeMode="cover" />
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.8)", justifyContent: "center", alignItems: "center" }}>

      </div>
      <Link to="/" style={{ textDecoration: "none", color: "#000", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>

        <View style={{ position: "absolute", top: 10, left: 10, borderRadius: 5, alignSelf: "center", alignItems: "center", paddingHorizontal: 20 }}>

          <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "2rem", paddingVertical: window.innerHeight * 0.025, textAlign: "center", width: "100%" }}>
            Buyness
          </Text>
        </View>
      </Link>

      {!sendCreate &&
        <View style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.5)", width: "95%", backgroundColor: "#fff", borderRadius: 20 }}>
          <Text style={{ marginTop: 20, color: "#000", fontWeight: "600", fontSize: "2rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
            Registro
          </Text>
          {step == 0 &&
            <View>

              <View style={{ height: 30 }} />
              <View style={{ flexDirection: 'row', width: "100%" }}>
                <View style={{ width: "50%" }}>
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Nombre del establecimiento
                  </Text>
                  <TextInput
                    value={name}
                    onChangeText={(t) => setName(t)}
                    numberOfLines={1} placeholder={"El racó del vi"} style={{ fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  <Text style={{ marginTop: 30, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Tipo de establecimiento
                  </Text>
                  <View style={{ paddingHorizontal: 30, width: "100%", alignSelf: "center" }}>
                    <Picker
                      selectedValue={type}
                      onValueChange={(value, index) => {
                        if (value == "Tipo de negocio"){
                          setType("")
                          setTypeKey("")
                        }else{
                          setType(value)
                          setTypeKey(types[index].key)
                        }
                      }}>
                      {types.map((item, index) => (
                        <Picker.Item label={item.name} />
                      ))}

                    </Picker>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: 30, paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Dirección
                  </Text>
                  <View style={{ marginHorizontal: 30 }}>
                    <TextInput
                      value={direction}
                      onChangeText={(t) => setDirection(t)}
                      numberOfLines={1} placeholder={"C / Bruc 15"} style={{ marginHorizontal: 30, fontSize: "1rem", width: "100%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  </View>
                  <Text style={{ marginTop: 30, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Localidad
                  </Text>
                  <View style={{ paddingHorizontal: 30, width: "100%", alignSelf: "center" }}>
                    <Picker
                      selectedValue={locality}
                      onValueChange={(value, index) => {
         
                        if (value == "Selecciona una localidad")
                          setLocality("")
                        else
                          setLocality(value)
                      }}>
                      {cities.map((item, index) => (
                        <Picker.Item label={item.name} />
                      ))}

                    </Picker>
                  </View>
                </View>
              </View>
              <TouchableOpacity
                disabled={!checkStep(1)}
                onPress={() => setStep(1)}
                onLongPress={() => setStep(1)}

                style={{ marginTop: 50, borderRadius: 5, alignSelf: "center", marginBottom: 20, alignItems: "center", backgroundColor: checkStep(1) ? "#68dc91" : "#f5f5f5", paddingHorizontal: 30 }}>
                <View style={{ flexDirection: "row", width: "100%", paddingHorizontal: 20, paddingVertical: 20, justifyContent: "space-between", alignItems: "center" }}>

                  <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                    Siguiente
                  </Text>
                </View>


              </TouchableOpacity>
            </View>

          }
          {step == 1 &&
            <View >
              <View style={{ flexDirection: "row", width: "100%" }}>
                <View style={{ width: "50%" }}>
                  <Text style={{ marginTop: 10, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Teléfono de contacto
                  </Text>
                  <TextInput
                  value={phone}
                    onChangeText={(t) => { setPhone(t); checkPhone(t) }}
                    numberOfLines={1} placeholder={"600000000"} style={{ marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  {!!phone && !validPhone &&
                    <Text style={{ marginTop: 10, color: "red", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                      El formato del teléfono móvil es incorrecto.
                    </Text>
                  }
                  <Text style={{ marginTop: 10, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Correo electrónico
                  </Text>


                  <TextInput
                    value={email}
                    onChangeText={(t) => { setEmail(t); checkEmail(t) }}
                    numberOfLines={1} placeholder={"negoci@negoci.com"} style={{ marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  {!!email && !validEmail &&
                    <Text style={{ marginTop: 10, color: "red", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                      El formato del correo electrónico es incorrecto.
                    </Text>
                  }
                </View>
                <View style={{ width: "50%" }}>
                  <Text style={{ marginTop: 10, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Contraseña
                  </Text>
                  <TextInput
                    value={password}
                    selectTextOnFocus
                    secureTextEntry={true}
                    textContentType="password"
                    //onSubmitEditing={()=>login(email,password)}
                    onChangeText={(t) => { setPassword(t); checkPassword(t) }}
                    numberOfLines={1} placeholder={"Contraseña"} style={{ marginBottom: 20, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  {!!password && !validPassword &&
                    <Text style={{ marginTop: 10, color: "red", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                      El formato de la contraseña es incorrecto. Debe contener 7 carácteres (al menos una minúscula, una mayúscula y un dígito).
                    </Text>
                  }


                  <Text style={{ marginTop: 10, color: "#000", fontWeight: "600", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                    Repite la contraseña
                  </Text>
                  <TextInput
                    value={password2}
                    selectTextOnFocus
                    secureTextEntry={true}
                    textContentType="password"
                    //onSubmitEditing={()=>login(email,password)}
                    onChangeText={(t) => setPassword2(t)}
                    numberOfLines={1} placeholder={"Contraseña"} style={{ marginBottom: 20, fontSize: "1rem", width: "90%", alignSelf: "center", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                  {!!password && !!password2 && password != password2 &&
                    <Text style={{ marginTop: 10, color: "red", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.02, textAlign: "left", width: "100%" }}>
                      Las contraseñas no coinciden.
                    </Text>
                  }

                </View>
              </View>

              <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-evenly" }}>
                <TouchableOpacity
                  onPress={() => setStep(0)}
                  onLongPress={() => setStep(0)}

                  style={{ marginTop: 50, borderRadius: 5, alignSelf: "center", marginBottom: 20, alignItems: "center", backgroundColor: "#fff", paddingHorizontal: 30, borderWidth: 1, borderColor: "#EDEDED" }}>
                  <View style={{ flexDirection: "row", width: "100%", paddingHorizontal: 20, paddingVertical: 20, justifyContent: "space-between", alignItems: "center" }}>

                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 20, textAlign: "center", width: "100%" }}>
                      Atrás
                    </Text>
                  </View>


                </TouchableOpacity>
                <TouchableOpacity
                  disabled={!checkStep(2)}
                  onPress={() => createAccount()}
                  onLongPress={() => createAccount()}

                  style={{ marginTop: 50, borderRadius: 5, alignSelf: "center", marginBottom: 20, alignItems: "center", backgroundColor: checkStep(2) ? "#68dc91" : "#f5f5f5", paddingHorizontal: 30 }}>
                  <View style={{ flexDirection: "row", width: "100%", paddingHorizontal: 20, paddingVertical: 20, justifyContent: "space-between", alignItems: "center" }}>

                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                      Crear cuenta
                    </Text>
                  </View>


                </TouchableOpacity>
              </View>
            </View>
          }


        </View>
      }

      {sendCreate &&
        <View style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.5)", width: "95%", backgroundColor: "#fff", borderRadius: 20 }}>
          <Text style={{ marginTop: 20, color: "#000", fontWeight: "600", fontSize: "2rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
            {createdSuccess ? "¡Registro completado!" : "Se ha producido un error"}
          </Text>
          <Text style={{ marginTop: 20, color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
            {createdSuccess ? "Se revisarán los datos enviados y se te dará de alta en la plataforma." : "Esperate un poco y vuelve a intentarlo."}
          </Text>
          {
            createdSuccess &&
            <View>
              <View style={{height:50}}/>
            <Link to="/" style={{ textDecoration: "none", color: "#000", alignSelf:"center", height: "100%", justifyContent: "center", alignItems: "center" }}>

              <View style={{backgroundColor:"#68dc91", borderRadius: 5, alignSelf: "center", alignItems: "center", paddingHorizontal: 20,paddingVertical:10 }}>

                <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem",textAlign: "center", width: "100%" }}>
                  Volver a la página inicial
                </Text>
              </View>
            </Link>
            <View style={{height:50}}/>
            </View>
          }
          {
            !createdSuccess &&
            <View>
            <TouchableOpacity
                  onPress={() => {setStep(0);setSendCreate(false);setCreatedSuccess(false)}}
                  onLongPress={() => {setStep(0);setSendCreate(false);setCreatedSuccess(false)}}

                  style={{ marginTop: 50, borderRadius: 5, alignSelf: "center", marginBottom: 20, alignItems: "center", backgroundColor: "#fff", paddingHorizontal: 30, borderWidth: 1, borderColor: "#EDEDED" }}>
                  <View style={{ flexDirection: "row", width: "100%", paddingHorizontal: 20, paddingVertical: 20, justifyContent: "space-between", alignItems: "center" }}>

                    <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 20, textAlign: "center", width: "100%" }}>
                      Atrás
                    </Text>
                  </View>


                </TouchableOpacity>
                </View>
          }
        </View>}

      <View style={{ height: 150 }} />




    </View>
  )

}