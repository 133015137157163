import React, { useEffect, useState } from "react";
import {View} from 'react-native'
import {getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from 'firebase/app';
export const Auth = React.createContext();


const firebaseConfig = {
  apiKey: "AIzaSyAv_fIuS-epLY2brq47zge04zT3bmTuIuQ",
  authDomain: "close2you-a7817.firebaseapp.com",
  projectId: "close2you-a7817",
  storageBucket: "close2you-a7817.appspot.com",
  messagingSenderId: "446673714165",
  appId: "1:446673714165:web:4680f5ccfae5ba11e7bebc"
};

const app = initializeApp(firebaseConfig);

export const AuthContext = ({ children }) => {
    const [usuario, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth,(user)=> {

            setUsuario(user);
            setShowChild(true);
        });
    }, []);

    function updateUser(user){
  
      setUsuario(user)
    }

    if (!showChild) {
        return <View/>;
    } else {
        return (
            <Auth.Provider
                value={{
                  app,
                    usuario,
                    auth,
                    updateUser
                }}
            >
                {React.cloneElement(children, {app:app, usuario:usuario?usuario.uid:-1})}
            </Auth.Provider>
        );
    }
};