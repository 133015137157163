import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput, Image, Picker, Switch } from 'react-native';
import Dropzone, { useDropzone } from "react-dropzone"
import GeneralContext from '../Provider.js';
import { types } from '../types'
import { cities } from '../cities'
import { withSnackbar } from 'react-simple-snackbar'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishmentInfo: {},
      activeImage: "",
      activeFile: null,
      initialImage: null,
      activeSection: "info"
    }
    this.dropzoneRef = React.createRef();
    this.updateImageContent = this.updateImageContent.bind(this);
  }

  componentDidMount() {
    this.setState({
      establishmentInfo: JSON.parse(JSON.stringify(this.context.establishmentInfo)),
      activeImage: this.context.establishmentInfo.img,
      initialImage: this.context.establishmentInfo.img
    })
  }
  updateEstablishmentField(key, value) {
    var establishmentInfo = this.state.establishmentInfo
    establishmentInfo[key] = value

    this.setState({ establishmentInfo: establishmentInfo })

  }





  showSaveMessage() {
    this.props.openSnackbar("Se han guardado los cambios y ya están disponibles para tus clientes.", 3000)
  }

  updateImageContent(url) {
    setTimeout(() => this.setState({ activeImage: url }), 100)
  }

  saveChanges() {
    if (this.state.activeImage != this.state.initialImage) {
      if (!!this.state.activeImage) {

        this.context.saveEstablishmentImage(this.state.activeFile, this.updateImageContent)
      } else
        this.context.removeEstablishmentImage()
      this.setState({ initialImage: this.state.activeImage })
    }
    this.context.updateEstablishment(this.state.establishmentInfo)
    this.showSaveMessage()


  }


  saveTemporalImages(image) {

    this.setState({ activeImage: URL.createObjectURL(image[0]), activeFile: image[0] })

  }

  deleteImage() {
    this.setState({ activeImage: "", activeFile: null })
  }

  componentWillUnmount() {
    if (!!this.state.activeFile)
      URL.revokeObjectURL(this.state.activeFile)
  }


  static contextType = GeneralContext;
  render() {

    return (
      <ScrollView showsVerticalScrollIndicator style={{ zIndex: 0, width: "95%", height: window.innerHeight, backgroundColor: "#fff" }}>
        <Text style={{ paddingTop: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "2rem", textAlign: "left", marginLeft: 20 }}>
          {this.context.translate("profile.profile")}
        </Text>
        <View style={{ width: "100%", height: 10 }} />
        <View style={{ paddingLeft: 20, width: "100%", flexDirection: "row" }}>
          <TouchableOpacity
            onPress={() => this.setState({ activeSection: "info", establishmentInfo: JSON.parse(JSON.stringify(this.context.establishmentInfo)) })}
            onLongPress={() => this.setState({ activeSection: "info", establishmentInfo: JSON.parse(JSON.stringify(this.context.establishmentInfo)) })}
            style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingVertical: 10, paddingHorizontal: 10, backgroundColor: this.state.activeSection == "info" ? "#68dc91" : "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text>
              Información básica
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.setState({ activeSection: "reserve", establishmentInfo: JSON.parse(JSON.stringify(this.context.establishmentInfo)) })}
            onLongPress={() => this.setState({ activeSection: "reserve", establishmentInfo: JSON.parse(JSON.stringify(this.context.establishmentInfo)) })}
            style={{ marginLeft: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingVertical: 10, paddingHorizontal: 10, backgroundColor: this.state.activeSection == "reserve" ? "#68dc91" : "#fff", justifyContent: "center", alignItems: "center" }}>
            <Text>
              Servicios
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: "100%", height: 2, backgroundColor: "#f5f5f5" }} />
        <View style={{ width: "100%", height: 10 }} />
        {this.state.activeSection == "info" &&
          <View style={{ width: "100%", flexDirection: "row" }}>

            <View style={{ width: "50%" }}>


              <Text style={{ paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("photo.profile")}
              </Text>
              {!this.state.activeImage &&
                <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <View style={{ width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, backgroundColor: "#f5f5f5", marginVertical: 10, borderRadius: 10 }}>

                      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                        <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />
                        <Text style={{ position: "absolute", top: "45%", alignSelf: "center", justifySelf: "center", textDecorationLine: "none", color: "gray", fontWeight: "400", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: window.innerWidth * 0.02 }}>
                          {this.context.translate("add_photo.profile")}
                        </Text>

                      </div>
                    </View>
                  )}
                </Dropzone>
              }
              {this.state.activeImage &&
                <View style={{ overflow: "hidden", width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, backgroundColor: "#f5f5f5", marginVertical: 10, borderRadius: 10 }}>
                  <Image source={this.state.activeImage} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0 }} resizeMode="cover" />

                  <Dropzone multiple={false} onDrop={acceptedFiles => this.saveTemporalImages(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <View style={{ position: "absolute", top: 0, width: "85%", justifyContent: "center", alignItems: "center", alignSelf: "flex-start", marginLeft: 20, height: 200, borderRadius: 10 }}>

                        <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getRootProps()}>
                          <input style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} {...getInputProps()} />


                          <View
                            style={{ borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "flex-start", alignSelf: "center" }}>
                            <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                              {this.context.translate("change_photo.profile")}
                            </Text>



                          </View>
                        </View>

                      </View>
                    )}
                  </Dropzone>
                  <TouchableOpacity
                    onPress={() => this.deleteImage()}
                    onLongPress={() => this.deleteImage()}
                    style={{ position: "absolute", top: 10, right: 10, borderRadius: 10, paddingHorizontal: 10, alignItems: "center", justifyContent: "center", backgroundColor: "red", alignSelf: "flex-start", alignSelf: "center" }}>
                    <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: 10 }}>
                      {this.context.translate("delete.profile")}
                    </Text>



                  </TouchableOpacity>
                </View>
              }
              {/**
<Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop:10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft:20 }}>
                        Eliminar cuenta
                            
                  </Text>
                  <Text style={{width:window.innerWidth*0.3, paddingBottom: window.innerHeight * 0.03, textDecorationLine: "none", color: "#000", fontWeight: "300", fontSize: "1rem", textAlign: "left", marginLeft:20 }}>
                  Si eliminas tu cuenta se perderán todos tus menús y la carta. Tendrás que volver a crear una nueva cuenta    
                  </Text>
                  <TouchableOpacity style={{borderRadius:10,width:window.innerWidth*0.3,alignItems:"center",justifyContent:"center",backgroundColor:"#68dc91",alignSelf:"flex-start",marginLeft:window.innerWidth*0.01}}>
<Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left",paddingVertical:window.innerHeight*0.03 }}>
                                        Eliminar
                  </Text>
                  

                </TouchableOpacity>
                */}

              <Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("name.profile")}
              </Text>
              <TextInput onChangeText={t => this.updateEstablishmentField("name", t)} value={this.state.establishmentInfo.name} numberOfLines={1} placeholder={"Restaurante gourmet"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginLeft: 20, marginBottom: 10, fontSize: "1rem", width: "90%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              <View style={{ width: "90%", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Descripción
                </Text>
                <Text style={{ paddingTop: 20, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  {300 - (this.state.establishmentInfo.description ? this.state.establishmentInfo.description.length : 0)}
                </Text>
              </View>
              <View style={{ height: 10 }} />
              <TextInput blurOnSubmit={true} multiline maxLength={300} numberOfLines={4} onChangeText={t => { if (t.length < 300) this.updateEstablishmentField("description", t) }} value={this.state.establishmentInfo.description} numberOfLines={1} placeholder={"Fundado en el año 1988 ..."} style={{ marginLeft: 20, width: "90%", marginTop: window.innerHeight * 0.01, borderRadius: 10, marginBottom: 10, fontSize: "1rem", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015, height: 200 }} />
            </View>
            <View style={{ width: "50%", paddingTop: 30 }}>
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                Tipo de negocio
              </Text>
              <View style={{ width: "60%", paddingLeft: 20 }}>
                <Picker onValueChange={(value, index) => this.updateEstablishmentField("type", value)} selectedValue={this.state.establishmentInfo.type}>
                  {types.map((item, index) => (
                    <Picker.Item label={item.name} value={item.key} />
                  ))}
                </Picker>
              </View>
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("city.profile")}
              </Text>
              <View style={{ width: "60%", paddingLeft: 20 }}>
                <Picker onValueChange={(value, index) => this.updateEstablishmentField("location", value)} selectedValue={this.state.establishmentInfo.location}>
                  {cities.map((item, index) => (
                    <Picker.Item label={item.name} value={item.name} />
                  ))}
                </Picker>
              </View>
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("adress.profile")}
              </Text>
              <TextInput onChangeText={t => this.updateEstablishmentField("direction", t)} value={this.state.establishmentInfo.direction} numberOfLines={1} placeholder={"C/ Bruc 26"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginLeft: 20, marginBottom: 10, fontSize: "1rem", width: "60%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("email.profile")}

              </Text>
              <TextInput onChangeText={t => this.updateEstablishmentField("mail", t)} value={this.state.establishmentInfo.mail} numberOfLines={1} placeholder={"restaurantegourmet@mail.com"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginLeft: 20, marginBottom: 10, fontSize: "1rem", width: "60%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                {this.context.translate("tel.profile")}
              </Text>
              <TextInput onChangeText={t => this.updateEstablishmentField("phone", t)} value={this.state.establishmentInfo.phone} numberOfLines={1} placeholder={"930000000"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginLeft: 20, marginBottom: 10, fontSize: "1rem", width: "60%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                WhatsApp

              </Text>
              <View style={{ height: 10 }} />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontWeight: "400", fontSize: "1.1rem", marginLeft: 20 }}>
                  +34
                </Text>
                <TextInput onChangeText={t => this.updateEstablishmentField("whatsapp", t)} value={this.state.establishmentInfo.whatsapp} numberOfLines={1} placeholder={"627761879"} style={{ borderRadius: 10, marginLeft: 10, fontSize: "1rem", width: "55%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
              </View>
              <TouchableOpacity
                onPress={() => this.saveChanges()}
                onLongPress={() => this.saveChanges()}
                style={{ borderRadius: 10, width: window.innerWidth * 0.3, alignItems: "center", justifyContent: "center", backgroundColor: "#68dc91", alignSelf: "flex-start", marginLeft: 20, marginTop: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                  Publicar cambios
                </Text>



              </TouchableOpacity>
            </View>

          </View>
        }
        {this.state.activeSection == "reserve" &&
          <View style={{ width: "95%", flexDirection: "row" }}>
            <View style={{ width: "30%" }}>
              {false &&
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: 20, }}>
                <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Entrega a domicilio
                </Text>
                <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.delivery} onValueChange={() => this.updateEstablishmentField("delivery", this.state.establishmentInfo.delivery != null ? !this.state.establishmentInfo.delivery : true)} />
              </View>
              }
              <View style={{ width: "100%", height: 10 }} />
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: 20 }}>
                <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Para recoger
                </Text>
                <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.take_away} onValueChange={() => this.updateEstablishmentField("take_away", this.state.establishmentInfo.take_away != null ? !this.state.establishmentInfo.take_away : true)} />
              </View>

              <View style={{ width: "100%", height: 10 }} />
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: 20 }}>
                <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Se admiten reservas
                </Text>
                <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.reserve} onValueChange={() => this.updateEstablishmentField("reserve", this.state.establishmentInfo.reserve != null ? !this.state.establishmentInfo.reserve : true)} />
              </View>

              <View style={{ width: "100%", height: 10 }} />
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginRight: 20 }}>
                <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left", marginLeft: 20 }}>
                  Reservas para grupos
                </Text>
                <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.groups} onValueChange={() => this.updateEstablishmentField("groups", this.state.establishmentInfo.groups != null ? !this.state.establishmentInfo.groups : true)} />
              </View>



            </View>

            <View style={{ width: "30%" }}>
              <View style={{ height: 10 }} />
              <View style={{ borderWidth: 1, borderColor: "#f5f5f5", borderRadius: 5 }}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "90%", alignSelf: "center" }}>
                  <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1.1rem", textAlign: "left" }}>
                    WIFI
                  </Text>
                  <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.wifi_active} onValueChange={() => this.updateEstablishmentField("wifi_active", this.state.establishmentInfo.wifi_active != null ? !this.state.establishmentInfo.wifi_active : true)} />
                </View>
                <View style={{ width: "100%", height: 10 }} />
                <View style={{ width: "90%", alignSelf: "center", height: 1, backgroundColor: "#f5f5f5" }} />
                <View style={{ width: "100%", height: 10 }} />
                <View style={{ width: "90%", alignSelf: "center" }}>
                  <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    Nombre de la red
                  </Text>
                  <TextInput onChangeText={t => this.updateEstablishmentField("wifi_net", t)} defaultValue={this.state.establishmentInfo.wifi_net} numberOfLines={1} placeholder={"RestauranteWifi"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginBottom: 10, fontSize: "1rem", width: "100%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                  <Text style={{ paddingBottom: window.innerHeight * 0.01, paddingTop: 10, textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                    Contraseña
                  </Text>
                  <TextInput onChangeText={t => this.updateEstablishmentField("wifi_password", t)} defaultValue={this.state.establishmentInfo.wifi_password} numberOfLines={1} placeholder={"123456"} style={{ marginTop: window.innerHeight * 0.01, borderRadius: 10, marginBottom: 10, fontSize: "1rem", width: "100%", alignSelf: "flex-start", backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />

                </View>
                <View style={{ width: "100%", height: 10 }} />
              </View>
            </View>
            <View style={{ width: "30%" }} >
              <View style={{ height: 10 }} />
              {true &&
                <View style={{ width: "100%", marginLeft: 10, borderWidth: 1, borderColor: "#f5f5f5", borderRadius: 5 }}>
                  <View style={{ height: 10 }} />
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ width: "80%" }}>
                      <Text style={{ paddingHorizontal: 10, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1.1rem", textAlign: "left" }}>
                       Aceptar pedidos por la aplicación
                      </Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Switch style={{ height: 25, width: 25 }} activeThumbColor={"#fff"} activeTrackColor={"#68dc91"} value={this.state.establishmentInfo.accept_orders} onValueChange={() => this.updateEstablishmentField("accept_orders", this.state.establishmentInfo.accept_orders != null ? !this.state.establishmentInfo.accept_orders : true)} />

                    </View>

                  </View>
                  <View style={{ height: 10 }} />
                  <Text style={{ paddingHorizontal: 10 }}>
                    Permite a tus clientes realizar pedidos a tu establecimiento mediante la aplicación de Buyness. Esta opción les permitirá añadir productos directamente desde la web/aplicación y realizar su pedido de forma cómoda a través de la plataforma. Una vez que un cliente cree un nuevo pedido aparecerá en la sección de "Pedidos".
                  </Text>
                  <View style={{ height: 20 }} />
                </View>
              }
              <View style={{ height: 30 }} />
              <TouchableOpacity
                disabled={(this.state.establishmentInfo.wifi_active &&
                  (!this.state.establishmentInfo.wifi_net || !this.state.establishmentInfo.wifi_password))}
                onPress={() => this.saveChanges()}
                onLongPress={() => this.saveChanges()}
                style={{ borderRadius: 10, width: window.innerWidth * 0.3, alignItems: "center", justifyContent: "center", backgroundColor: (this.state.establishmentInfo.wifi_active && (!this.state.establishmentInfo.wifi_net || !this.state.establishmentInfo.wifi_password)) ? "#f5f5f5" : "#68dc91", alignSelf: "flex-start", marginLeft: 20, marginTop: 10 }}>
                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                  Publicar cambios
                </Text>



              </TouchableOpacity>

              {
                (this.state.establishmentInfo.wifi_active &&
                  (!this.state.establishmentInfo.wifi_net || !this.state.establishmentInfo.wifi_password)) &&
                <View style={{ width: "100%", alignSelf: "center" }}>
                  <View style={{ height: 10 }} />
                  <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "red", fontWeight: "400", fontSize: "1rem", textAlign: "left", paddingVertical: window.innerHeight * 0.03 }}>
                    Si decides mostrar el wifi a tus clientes, tienes que proporcionar el nombre la red y su contraseña.
                  </Text>
                </View>
              }
            </View>

          </View>
        }

 

        <View style={{ width: "100%", height: window.innerHeight * 0.1 }} /> 
      </ScrollView>
    )
  }
}
export default withSnackbar(App, {
  position: 'top-center',
  style: {
    zIndex: 1100,
    backgroundColor: '#68dc91',
    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
    color: '#000',
    borderRadius: 10,
    psddingHorizontal: 20,
    fontSize: '1rem',
    textAlign: 'left',
  },
  closeStyle: {
    color: '#000',

  },
})