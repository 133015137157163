import React, { useEffect, useState,useContext }  from 'react';
import restaurantLogin from './images/login.jpg';
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import {Text, View, TouchableOpacity, Image, TextInput,ScrollView,Platform } from 'react-native';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import {auth} from './firebase';
import { Auth } from "./AuthContext";
import GeneralContext from './Provider';
import { useNavigate } from 'react-router-dom';

export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <Login
      translate={context.translate}
      {...props}
    />
  );
};


function Login(props){
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [password, setPassword] = useState("");

    const [signup, setsignup] = useState(false);
    const { usuario,updateUser, setAuthFunction } = useContext(Auth);

    if (usuario)
    navigate("/manage", { state: {try:""} });
    
async function login(email,password){
    const auth = getAuth();


//setAuthFunction(auth);
    await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      updateUser(user)
      //update provider
        setError(false)
      navigate("/manage", { state: {try:""} });
  
      // ...
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(true)

    });
}

  
      return (
          <View style={{width:"100%",height: Platform.OS === 'web' ? '100vh' : '100%',overflowX:"hidden"}}>
          
 <Image source={restaurantLogin} style={{zIndex:0,width:"100%", height:Platform.OS === 'web' ? '100vh' : '100%', zIndex: 0,opacity:1,position:"absolute",top:0 }} resizeMode="cover" />
 <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: Platform.OS === 'web' ? '100vh' : '100%', background: "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.6)", justifyContent: "center", alignItems: "center" }}>

    

             

 

 <View style={{width:"100%",position:"fixed",bottom:0,height:"100%",justifyContent:"flex-end"}}>
 <Link to="/" style={{textDecoration:"none",color:"#000",width:"100%",position:"absolute",top:20}}>
                  
                  <View   style={{borderRadius:5, alignSelf:"center",alignItems: "center",paddingHorizontal:20,justifyContent:"center",width:"100%"}}>
                                     
                                   <Text style={{ color: "#fff", fontWeight: "bold", fontSize: "2rem",paddingTop:20, textAlign: "center" }}>
                                   {props.translate("Buyness.sign_in")}
                                     </Text>
                                   
                                    
                                    
                            </View>
                             </Link>
                            <Text style={{position:"absolute",top:80,alignSelf:"center", color: "#fff", fontWeight: "500", fontSize: "1rem",paddingTop: 10, textAlign: "center",width:"100%" }}>
                            {props.translate("access.sign_in")}
                                     </Text>
                  
<Text style={{marginTop:30, color: "#fff", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: 10, textAlign: "left", width: "100%" }}>
{props.translate("email.sign_in")}
</Text>
<TextInput
onChangeText={(t)=>setEmail(t)}
numberOfLines={1} placeholder={props.translate("example_email.sign_in")} style={{borderRadius:5, marginBottom:10,fontSize:"1rem", width:"90%",alignSelf:"center",backgroundColor:"#f5f5f5",paddingHorizontal:20,paddingVertical:10}} />

<Text style={{marginTop:10, color: "#fff", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingBottom: 10, textAlign: "left", width: "100%" }}>
{props.translate("password.sign_in")}
</Text>
<TextInput
selectTextOnFocus
secureTextEntry={true}
textContentType="password"
onSubmitEditing={()=>login(email,password)}
onChangeText={(t)=>setPassword(t)}
numberOfLines={1} placeholder={"Contraseña"} style={{borderRadius:5,marginBottom:10,fontSize:"1rem", width:"90%",alignSelf:"center",backgroundColor:"#f5f5f5",paddingHorizontal:20,paddingVertical:10}} />
{error &&
<Text style={{marginTop:10, color: "red", fontWeight: "500", fontSize: "1rem", paddingHorizontal: 30, paddingBottom: 10, textAlign: "left", width: "100%" }}>
{props.translate("wrong.sign_in")}
</Text>
}

{false &&
<TouchableOpacity onPress={()=>this.props.openSnackbar('Tu plato se ha creado con éxito. Puedes editar cúando quieras el plato y los cambios se reflejarán a tiempo real en la carta.')}  style={{alignSelf:"center",marginBottom:20, alignItems: "center", backgroundColor: "#fff", width: "90%"}}>
<TouchableOpacity to="/forgot" style={{height:"100%", width:"100%",textDecoration:"none",color:"#000"}} >

<View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

<Text style={{textDecorationLine:"underline", color: "#000", fontWeight: "400", fontSize: "0.8rem", paddingHorizontal: 20, paddingVertical: 10, textAlign: "left", width: "100%" }}>
{props.translate("forgot.sign_in")}
</Text>

</View> </TouchableOpacity>
</TouchableOpacity>
}



<TouchableOpacity
onPress={()=>login(email,password)}


style={{marginTop:30, borderRadius:5, alignSelf:"center",marginBottom:40, alignItems: "center", backgroundColor: "#68dc91", width: "90%" }}>
<View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

<Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingVertical: 20, textAlign: "center", width: "100%" }}>
{props.translate("sign_in.sign_in")}
</Text>
</View>


</TouchableOpacity>

<View style={{height:20}}/>

</View>
 

</div>
 
          </View>
      )

}