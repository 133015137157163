import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyAv_fIuS-epLY2brq47zge04zT3bmTuIuQ",
  authDomain: "close2you-a7817.firebaseapp.com",
  projectId: "close2you-a7817",
  storageBucket: "close2you-a7817.appspot.com",
  messagingSenderId: "446673714165",
  appId: "1:446673714165:web:4680f5ccfae5ba11e7bebc"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);


const signIn = async (email, password) => {
 await signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    //update provider
    let navigate = useNavigate();
    navigate("/manage", { state: {try:""} });

    // ...
  }).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode,errorMessage)
  });
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await db.collection("users").add({
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
  
    alert(err.message);
  }
};
const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
  
    alert(err.message);
  }
};
const logout = () => {
  auth.signOut();
};
export {
  auth,
  db,
  signIn,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
};