import * as React from 'react';
import { Text, View, ScrollView, TouchableOpacity, Image, TextInput, Animated } from 'react-native';
import { MdChevronRight } from 'react-icons/md'
import { Link } from 'react-router-dom';
export default class App extends React.Component {

  constructor(props) {
    super(props)

  }


  render() {
    return (
      <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#000", overflowX: "hidden" }}>
        <View style={{ width: "100%", backgroundColor: "#000" }}>
          <View style={{ height: 30 }} />

          <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <View style={{ width: "50%" }}>
            <Link
                  to="/"
                  style={{ textDecorationLine: "none", zIndex: 0, alignSelf: "flex-start" }}>
                 
              <Text style={{ color: "#fff", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: 40, paddingHorizontal: 50 }}>
                Buyness
              </Text>
              </Link>
            </View>

          </View>
          <View style={{ height: 50 }} />
          <View style={{ flexDirection: "row", width: "100%" }}>
            <View style={{ width: "50%",justifyContent:"center",alignItems:"center" }}>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic" }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.85 }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.7 }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.55 }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.4 }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.25 }}>
                ¿NECESITAS AYUDA?
              </Text>
              <Text style={{ color: "#fff", fontWeight: "800", width: "100%", textAlign: "center", fontSize: 50, fontStyle: "italic", opacity: 0.1 }}>
                ¿NECESITAS AYUDA?
              </Text>
            </View>
            <View style={{ width: "50%",justifyContent:"center",alignItems:"center" }}>
              <View style={{ width: "70%", alignSelf: "center", backgroundColor: "#68DC91", }}>
                <View style={{ width: "100%" }}>
                <Image source={require('./images/help.jpg')} style={{ width: "100%", alignSelf: "center", height: 400 }} resizeMode='cover' />

                </View>
                <View style={{ width: "100%", paddingHorizontal: 20, paddingVertical: 35 }}>
                  <Text style={{ color: "#000", fontWeight: "500", width: "100%", textAlign: "left", fontSize: 16 }}>
                    Cualquier duda que pueda surgirte sobre cómo empezar a usar nuestra aplicación tanto si eres un establecimiento cómo un usuario, escribe un correo a help@buynessapp.com y estaremos encantados de resolverla.
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ height: 100 }} />
          <View style={{ width: "100%", paddingVertical: 20, backgroundColor: "#fff" }}>
          <Text style={{ color: "#000", fontWeight: "500", width: "100%", textAlign: "center", fontSize: 16 }}>
            Buyness © 2022
          </Text>
        </View>
        </View>
      </View>
    )
  }
}