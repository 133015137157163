import * as React from 'react';
import restaurantLogin from './images/login.jpg';
import { SectionList, Text, View, TouchableOpacity, img, TextInput, Modal, Image, Touchable, Animated, Linking, Alert } from 'react-native';
import restaurant from './images/restaurant.jpg'
import Stick from 'react-stick'
import { Platform, ScrollView } from 'react-native-web';
import { establishment } from './dataTest'
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { AuthContext } from './AuthContext';
import Loader from "react-loader-spinner";
import { IoTrash, IoLogoWhatsapp } from "react-icons/io5";
import { IoIosClose, } from "react-icons/io";
import { MdError } from "react-icons/md";
import GeneralContext from './Provider';
import {
  FishIcon, GlutenIcon, CrustaceanIcon,
  EggIcon, PeanutIcon, SoyaIcon,
  MilkIcon, NutsIcon, CeleryIcon,
  MustardIcon, SesameIcon, SulphiteIcon,
  LupinIcon, MolluscIcon
} from 'react-allergens';
import { Link } from 'react-router-dom'
import Sticky from 'react-sticky-el';
import { MdChevronRight, MdChevronLeft } from "react-icons/md"
import ShowMoreText from "react-show-more-text";
import './Menu.css'
import ReactWhatsapp from 'react-whatsapp';

import reserve from './images/reserve.png'
import group from './images/group.png'
import takeAway from './images/take-away.png'
import fastDelivery from './images/fast-delivery.png'
import { BottomSheet } from 'react-spring-bottom-sheet'
import Sheet from 'react-modal-sheet';
import Ticker from 'react-ticker'
import Calendar from 'react-calendar'
import './MenuPhone.css'
import 'react-spring-bottom-sheet/dist/style.css'
import { isIOS, isAndroid } from 'react-device-detect';


const daysNames = ["monday.shop", "tuesday.shop", "wednesday.shop", "thursday.shop", "friday.shop", "saturday.shop", "sunday.shop"]

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}



export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <App
      translate={context.translate}
      {...props}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: null,

    }
  }
  static contextType = AuthContext;
  async getFromDB() {
    const db = getFirestore(this.context.app);
    const q = query(collection(db, "establishments"), where("id", "==", window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {

      this.setState({ menu: doc.data() })


    });

    if (querySnapshot.docs.length == 0)
      this.setState({ emptyMenu: true })
  }


  getEstablishmentInfo() {
    this.getFromDB()
  }

  componentDidMount() {
    this.getEstablishmentInfo()
    if (false) {
      Alert.alert("Error abriendo la app", "Por favor, vuelva a intentarlo.")
      Linking.canOpenURL("buyness-app://fav_establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]).then((supported) => {

        if (supported && (isIOS || isAndroid)) {
          window.location = "buyness-app://fav_establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

        } else {
          this.getEstablishmentInfo()

        }
      }).catch(e => { })
    }


  }



  checkAndroidOrIOS() {
    return (isAndroid || isIOS)
  }

  openEstablishmnentProducts() {
    Linking.canOpenURL("buyness-app://establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]).then((supported) => {

      if (supported && (isIOS || isAndroid)) {
        window.location = "buyness-app://establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        window.location = "https://www.buynessapp.com/establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
      } else {
        window.location = "https://www.buynessapp.com/establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

      }
    }).catch(e => { return true })
  }

  openAppWithFavs() {

    var deeplinkUrl = "buyness-app://fav_establishment/" + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    //variable will check app installed or not
    var change = false;
    setTimeout(() => {
      if (!change) {
        var redirectUrl = isIOS ? "https://apps.apple.com/es/app/buyness/id1616714361" : "https://play.google.com/store/apps/details?id=com.close2youapp";
        window.location = redirectUrl;
      }
    }, 2000);
    window.location = deeplinkUrl;
    //handle event to check app installed or not
    window.onblur = function () {
      change = true;
    };
    window.onfocus = function () {
      change = false;
    }



  }

  render() {

    return (
      <View id="bodyMenu">
        {this.state.menu != null &&
          <View style={{ width: "100%", height: "100%", justifyContent: "center" }}>
            <View style={{ width: "100%", height: 250, backgroundColor: "#f5f5f5", justifyContent: "center", alignItems: "center" }}>
              {!!this.state.menu.img &&
                <img loading='lazy' src={this.state.menu.img} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: "100%", height: "100%", zIndex: 0, objectFit: "cover" }} resizeMode="cover" />
              }
              <View style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.5)", width: "100%", height: "100%" }} />

              <Text style={{ zIndex: 99, color: "#fff", fontWeight: "600", fontSize: "1.5rem", paddingHorizontal: 10, width: "100%", textAlign: "center" }}>
                ¡Añade {this.state.menu.name} a tus establecimientos favoritos!
              </Text>
            </View>
            <View style={{ height: 50 }} />
            {(isAndroid || isIOS) &&
              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: 20 }}>
                Añade con un solo clic {this.state.menu.name} a tus establecimientos favoritos de tu app Buyness y lo tendrás siempre a la vista.
              </Text>
            }
            {(!isAndroid && !isIOS) &&
              <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: 20 }}>
                Accede desde un dispositivo móvil para poder añadir a {this.state.menu.name} a tus establecimientos favoritos
              </Text>
            }
            <View style={{ height: 50 }} />
            {(isAndroid || isIOS) &&
              <View>
                <TouchableOpacity
                  onPress={() => this.openAppWithFavs()}
                  onLongPress={() => this.openAppWithFavs()}
                  style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: "#68dc91", borderRadius: 10, paddingVertical: 15 }}>
                  <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                    Añadir a favoritos
                  </Text>
                </TouchableOpacity>
                <View style={{ height: 50 }} />
              </View>
            }


            <View style={{ width: "90%", height: 2, backgroundColor: "#EDEDED", alignSelf: "center" }} />
            <View style={{ height: 30 }} />

            <Text style={{ color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center", width: "100%", paddingHorizontal: 20 }}>
              También puedes consultar los productos que vende este establecimiento
            </Text>
            <View style={{ height: 30 }} />
            <TouchableOpacity
              onPress={() => this.openEstablishmnentProducts()}
              onLongPress={() => this.openEstablishmnentProducts()}
              style={{ justifyContent: "center", alignItems: "center", width: "95%", alignSelf: "center", backgroundColor: (isAndroid || isIOS) ? "#fff" : "#68dc91", borderWidth: (isAndroid || isIOS) ? 2 : 0, borderColor: "#EDEDED", borderRadius: 10, paddingVertical: 15 }}>
              <Text style={{ color: "#000", fontWeight: "600", fontSize: "1rem", textAlign: "center", width: "100%" }}>
                Consultar productos
              </Text>
            </TouchableOpacity>
          </View>
        }
        {
          !this.state.menu &&
          <div style={{ width: "100%", height: window.innerHeight }}>
            <View style={{ position: "absolute", top: 0, width: "100%", height: "100%", backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>
              <Loader
                secondaryColor={"#000"}
                type="Watch"
                color="#000"
                height={200}
                width={200}

              />
              <View style={{ height: 30 }} />
              <Text style={{ color: "#000", fontWeight: "600", fontSize: "2rem", paddingHorizontal: 10, width: "100%", textAlign: "center" }}>
                {this.props.translate("loading.shop")}
              </Text>

              <View style={{ height: 100 }} />
            </View>
          </div>
        }





        {
          this.state.emptyMenu &&
          <div style={{ flex: 1, width: "100%", height: "100%" }}>

          </div>
        }
      </View>
    )
  }
}
