import * as React from 'react';
import all from './images/all.jpg';

//new images 
import meat from './images/meat.jpg'
import fish from './images/fish.jpg'
import fruit from './images/fruit.jpg'
import bread from './images/bread.jpg'
import supermarket from './images/supermarket.jpg'
import clothes from './images/clothes.jpg'
//

import adapt from './images/adapt.jpg';
import contact from './images/contact.jpg';
import qr_example from './images/qr_example.png';
import { Text, View, ScrollView, TouchableOpacity, Image, TextInput } from 'react-native';
import { Link } from 'react-router-dom';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';
import { AuthContext } from './AuthContext';

import GeneralContext from './Provider.js';
import { withTranslation } from 'react-i18next';
var QRCode = require('qrcode.react');


export default function Wrapper(props) {

  const context = React.useContext(GeneralContext);
  return (
    <App
      translate={context.translate}
      {...props}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      contact: "",
      description: "",
      sendHelpDisabled: true,
      infoRequested: false

    }

  }

  static contextType = AuthContext;

  componentDidMount() {
    //
  }


  updateField(key, value) {
    this.setState({ [key]: value }, () => this.checkIfSendHelpDisabled())
  }

  async sendToFirebase() {
    const db = getFirestore(this.context.app);
    await addDoc(collection(db, "help"), {
      name: this.state.name,
      contact: this.state.contact,
      description: this.state.description
    }).then(this.setState({ infoRequested: true }));
  }

  checkIfSendHelpDisabled() {
    if (!!this.state.name && !!this.state.contact && !!this.state.description)
      this.setState({ sendHelpDisabled: false })
    else
      this.setState({ sendHelpDisabled: true })
  }




  render() {

    return (
      <View style={{ width: "100%", height: "100%", backgroundColor: "#fff", overflowX: "hidden" }}>

        <View style={{ width: "100%", height: "100%", }}>
          <View style={{ backgroundColor: "#000", paddingBottom: 30 }}>
            <View style={{ marginTop: 90, backgroundColor: "#000", width: "90%", alignSelf: "center", borderRadius: 30, justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
              <Image source={fish} style={{ position: "absolute", top: 0, right: 0, alignSelf: "center", width: window.innerWidth * 1, height: "100%", zIndex: 0 }} resizeMode="cover" />


              <View style={{ width: "100%", alignSelf: "center", justifyContent: "space-between", flexDirection: "row", marginBottom: window.innerHeight * 0.05, marginTop: window.innerHeight * 0.05 }}>

                <View style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.5)", width: "30%", marginLeft: window.innerWidth * 0.03, alignItems: "center", justifyContent: "center", backgroundColor: "#fff", borderRadius: 10 }}>
                  <Text style={{ marginTop: 20, color: "#000", fontWeight: "500", fontSize: "1.5rem", paddingHorizontal: "5%", paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
                    {this.props.translate("digitizes.home")}
                  </Text>
                  <Text style={{ marginBottom: window.innerHeight * 0.02, color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                    {this.props.translate("searching.home")}
                  </Text>
                  <Text style={{ marginBottom: window.innerHeight * 0.02, color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                    {this.props.translate("start_with.home")}
                  </Text>




                  <TouchableOpacity
                    onPress={() => window.scrollTo({ top: 10000, behavior: 'smooth' })}
                    style={{ alignSelf: "center", marginBottom: window.innerHeight * 0.03, borderRadius: 10, alignItems: "center", backgroundColor: "#000", width: "90%", marginTop: window.innerHeight * 0.01 }}>

                    <View to="/success" style={{ width: "100%", alignSelf: "flex-start", textDecoration: "none", color: "#000" }} >

                      <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

                        <Text style={{ color: "#fff", fontWeight: "500", fontSize: "1rem", paddingHorizontal: "5%", paddingVertical: window.innerHeight * 0.025, textAlign: "center", width: "100%" }}>
                          {this.props.translate("contact_us.home")}
                        </Text>
                      </View>

                    </View>
                  </TouchableOpacity>

                </View>
                <Text style={{ alignSelf: "center", color: "rgba(255,255,255,0.8)", fontWeight: "600", fontSize: "5rem", paddingHorizontal: "5%", textAlign: "left" }}>
                  {this.props.translate("Buyness.home")}
                </Text>
              </View>








            </View>
          </View>
          {/**BLOQUE 2 */}

          <View style={{ alignSelf: "center", width: "100%", justifyContent: "space-evenly", marginTop: window.innerHeight * 0.05, height: window.innerHeight * 0.8, flexDirection: "row" }}>

            <View style={{ paddingBottom: 30, borderWidth: 0, borderRadius: 10, overflow: "hidden", backgroundColor: "#000", width: "30%", height: window.innerHeight * 0.8, backgroundColor: "#000", overflow: "hidden" }}>
              <Image source={fruit} style={{ borderRadius: 0, alignSelf: "flex-end", width: "100%", height: window.innerHeight * 0.55, zIndex: 0 }} resizeMode="cover" />
              <Text style={{ paddingTop: 20, color: "#fff", fontWeight: "500", fontSize: "1.5rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
                {this.props.translate("believe.home")}
              </Text>
              <Text style={{ marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "400", fontSize: "1.1rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                {this.props.translate("want.home")}
              </Text>


            </View>
            <View style={{ paddingBottom: 30, borderWidth: 0, borderRadius: 10, overflow: "hidden", backgroundColor: "#000", width: "30%", height: window.innerHeight * 0.8, backgroundColor: "#000", overflow: "hidden" }}>
              <Image source={meat} style={{ borderRadius: 0, alignSelf: "flex-end", width: "100%", height: window.innerHeight * 0.55, zIndex: 0 }} resizeMode="cover" />
              <Text style={{ paddingTop: 20, color: "#fff", fontWeight: "500", fontSize: "1.5rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
                {this.props.translate("formation.home")}
              </Text>
              <Text style={{ marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "400", fontSize: "1.1rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                {this.props.translate("manage.home")}
              </Text>

            </View>
            <View style={{ paddingBottom: 30, borderWidth: 0, borderRadius: 10, overflow: "hidden", backgroundColor: "#000", width: "30%", height: window.innerHeight * 0.8, backgroundColor: "#000", overflow: "hidden" }}>
              <Image source={all} style={{ borderRadius: 0, alignSelf: "flex-end", width: "100%", height: window.innerHeight * 0.55, zIndex: 0 }} resizeMode="cover" />

              <Text style={{ paddingTop: 20, color: "#fff", fontWeight: "500", fontSize: "1.5rem", paddingHorizontal: 20, paddingBottom: window.innerHeight * 0.01, textAlign: "left", width: "100%" }}>
                {this.props.translate("designed.home")}
              </Text>
              <Text style={{ marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "400", fontSize: "1.1rem", paddingHorizontal: 20, textAlign: "left", width: "100%" }}>
                {this.props.translate("new.home")}
              </Text>

            </View>

          </View>
          {/**BLOQUE 3 */}
          <View style={{ width: "95%", backgroundColor: "#fff", marginTop: window.innerHeight * 0.05, alignSelf: "center" }}>


            <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "48%", alignItems: "center", backgroundColor: "#000" }}>
                <Image source={bread} style={{ borderTopLeftRadius: 14, borderTopRightRadius: 14, width: "100%", height: window.innerHeight * 0.5, zIndex: 0 }} resizeMode="cover" />

                <Text style={{ color: "#fff", fontWeight: "500", padding: 0, margin: 0, fontSize: "1.5rem", paddingHorizontal: "5%", textAlign: "left", marginTop: window.innerHeight * 0.05, width: "100%" }}>
                  {this.props.translate("amazing.home")}

                </Text>

                <Text style={{ marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "left", }}>
                  {this.props.translate("improve.home")}
                </Text>

              </View>
              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "48%", alignItems: "center", height: window.innerHeight * 0.85, backgroundColor: "#000" }}>
                <Image source={adapt} style={{ borderTopLeftRadius: 14, borderTopRightRadius: 14, width: "100%", height: window.innerHeight * 0.5, zIndex: 0 }} resizeMode="cover" />

                <Text style={{ color: "#fff", fontWeight: "500", padding: 0, margin: 0, fontSize: "1.5rem", paddingHorizontal: "5%", textAlign: "left", marginTop: window.innerHeight * 0.05, width: "100%" }}>
                  {this.props.translate("adapt.home")}

                </Text>

                <Text style={{ marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "left", }}>
                  {this.props.translate("created.home")}
                </Text>

                <View style={{ height: 10 }} />
              </View>


            </View>
          </View>
          {/**BLOQUE 4 */}

          {/**BLOQUE 5 */}
          <View style={{ width: "95%", alignSelf: "center", backgroundColor: "#fff", marginTop: window.innerHeight * 0.05, alignSelf: "center", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <View style={{ width: "40%", flexDirection: "column" }}>
              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "100%", backgroundColor: "#000", paddingTop: window.innerHeight * 0.02 }}>
                <Text style={{ paddingTop: 10, color: "#fff", fontWeight: "500", padding: 0, fontSize: "1.5rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.props.translate("how_does_it_work.home")}
                </Text>

                <Text style={{ marginTop: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.props.translate("sign_up.home")}
                </Text>
                <Text style={{ marginTop: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.props.translate("automatically.home")}
                </Text>
                <Text style={{ marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.05, color: "#000", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: "5%", textAlign: "left", width: "100%" }}>
                  {this.props.translate("optional.home")}
                </Text>
                <Image source={supermarket} style={{ borderBottomLeftRadius: 14, borderBottomRightRadius: 14, alignSelf: "center", width: "100%", height: window.innerHeight * 0.6, zIndex: 0 }} resizeMode="cover" />

              </View>
              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "100%", backgroundColor: "#000", marginTop: window.innerHeight * 0.05, alignSelf: "center" }}>

                <View style={{ paddingVertical: window.innerHeight * 0.05, width: "100%" }}>

                  <Text style={{ color: "#fff", fontWeight: "500", fontSize: "1.5rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%", marginBottom: window.innerHeight * 0.02 }}>
                    {this.props.translate("discover.home")}

                  </Text>
                  <View style={{ height: 10 }} />
                  <View style={{ width: "100%", alignItems: "center" }}>
                    <QRCode value="https://buynessapp.com/establishment/2" size={250} />
                  </View>
                  <View style={{ height: 10 }} />

                  <Text style={{ marginVertical: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                    {this.props.translate("qr.home")}
                  </Text>

                </View>
                <Link to="/establishment/2" style={{ alignSelf: "flex-start", textDecoration: "none", color: "#000" }} >

                  <View style={{ borderRadius: 6, alignSelf: "flex-start", marginLeft: window.innerWidth * 0.02, justifyContent: "center", alignItems: "center", backgroundColor: "#fff", paddingHorizontal: window.innerWidth * 0.02, paddingVertical: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.03 }}>
                    <Text style={{ width: "100%", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "center" }}>
                      {this.props.translate("discover_button.home")}
                    </Text>
                  </View>
                </Link>

              </View>
            </View>
            <View style={{ width: "60%", height: "100%", justifyContent: "flex-end", alignItems: "flex-end" }}>

              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", width: "95%", backgroundColor: "#000" }}>
                <Text style={{ color: "#fff", fontWeight: "500", padding: 0, marginTop: window.innerHeight * 0.02, fontSize: "1.5rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                  {this.props.translate("why.home")}
                </Text>

                <Text style={{ marginTop: window.innerHeight * 0.03, color: "#fff", fontWeight: "500", fontSize: "1.3rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("real_time.home")}

                </Text>
                <Text style={{ marginTop: window.innerHeight * 0.01, marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("publish.home")}
                  </Text>
                <Text style={{ marginTop: window.innerHeight * 0.01, color: "#fff", fontWeight: "500", fontSize: "1.3rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("flexibility.home")}
                </Text>
                <Text style={{ marginTop: window.innerHeight * 0.01, marginBottom: window.innerHeight * 0.03, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("identity.home")}
                   </Text>
                <Text style={{ marginTop: window.innerHeight * 0.01, color: "#fff", fontWeight: "500", fontSize: "1.3rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("start.home")}

                </Text>
                <Text style={{ marginTop: window.innerHeight * 0.01, marginBottom: window.innerHeight * 0.05, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.02, textAlign: "left", width: "100%" }}>
                {this.props.translate("price.home")} </Text>
                <Image source={clothes} style={{ borderBottomLeftRadius: 14, borderBottomRightRadius: 14, alignSelf: "center", width: "100%", height: window.innerHeight * 0.62, zIndex: 0 }} resizeMode="cover" />

              </View>

              <View style={{ borderWidth: 0, borderRadius: 20, borderColor: "#000", flexDirection: "row", marginTop: window.innerHeight * 0.05, width: "95%", alignSelf: "center", backgroundColor: "#000", overflow: "hidden" }}>
                {!this.state.infoRequested &&
                  <View style={{ width: "50%" }}>
                    <Text style={{ marginTop: window.innerHeight * 0.02, color: "#fff", fontWeight: "500", padding: 0, fontSize: "1.5rem", paddingHorizontal: window.innerWidth * 0.01, textAlign: "left", width: "100%" }}>
                    {this.props.translate("doubts.home")}
                    </Text>
                    <Text style={{ marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.01, textAlign: "left", width: "70%" }}>
                    {this.props.translate("solve_doubts.home")}
                      </Text>
                    <TextInput onChangeText={(t => this.updateField("name", t))} numberOfLines={1} placeholder= {this.props.translate("name.home")} style={{ borderRadius: 6, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                    <TextInput onChangeText={(t => this.updateField("contact", t))} numberOfLines={1} placeholder= {this.props.translate("email.home")} style={{ borderRadius: 6, marginBottom: window.innerHeight * 0.02, fontSize: "1rem", width: "90%", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015 }} />
                    <TextInput onChangeText={(t => this.updateField("description", t))} multiline numberOfLines={5} placeholder= {this.props.translate("query.home")} style={{ borderRadius: 6, fontSize: "1rem", width: "90%", alignSelf: "flex-start", marginLeft: window.innerWidth * 0.01, backgroundColor: "#f5f5f5", paddingHorizontal: window.innerWidth * 0.01, paddingVertical: window.innerHeight * 0.015, height: 200 }} />
                    <Text style={{ color: "#000", fontWeight: "400", fontSize: "0.7rem", paddingHorizontal: 20, paddingVertical: 10, textAlign: "left", width: "100%" }}>
                    {this.props.translate("contact.home")}
                             </Text>
                    <TouchableOpacity
                      onPress={() => this.sendToFirebase()}
                      onLongPress={() => this.sendToFirebase()}
                      disabled={this.state.sendHelpDisabled} style={{ borderRadius: 6, alignSelf: "flex-start", marginBottom: window.innerHeight * 0.03, alignItems: "center", backgroundColor: this.state.sendHelpDisabled ? "rgba(255,255,255,0.4)" : "#fff", marginTop: window.innerHeight * 0.02, marginLeft: window.innerWidth * 0.01 }}>
                      <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

                        <Text style={{ color: "#000", fontWeight: "400", fontSize: "1rem", paddingHorizontal: window.innerWidth * 0.05, paddingVertical: window.innerHeight * 0.02, textAlign: "center", width: "100%" }}>
                        {this.props.translate("send.home")}
                        </Text>
                      </View>

                    </TouchableOpacity>
                  </View>
                }
                {
                  this.state.infoRequested &&
                  <View style={{ width: "50%" }}>
                    <Text style={{ marginTop: window.innerHeight * 0.02, color: "#fff", fontWeight: "500", padding: 0, fontSize: "1.5rem", paddingHorizontal: window.innerWidth * 0.01, textAlign: "left", width: "100%" }}>
                      ¡Recibido!
                    </Text>
                    <Text style={{ marginTop: window.innerHeight * 0.02, marginBottom: window.innerHeight * 0.02, color: "#fff", fontWeight: "400", fontSize: "1.2rem", paddingHorizontal: window.innerWidth * 0.01, textAlign: "left", width: "70%" }}>
                      Te contestaremos lo antes posible. ¡Muchas gracias por confiar en nosotros!
                    </Text>


                  </View>
                }
                <View style={{ width: "50%" }}>
                  <Image source={contact} style={{ borderTopRightRadius: 14, borderBottomRightRadius: 14, alignSelf: "center", width: "100%", flex: 1, zIndex: 0 }} resizeMode="cover" />

                </View>
              </View>
            </View>


          </View>



          {/**BOTTOM */}
          <View style={{ justifyContent: "center", alignItems: "center", width: "100%", height: 100, backgroundColor: "#000", marginTop: window.innerHeight * 0.05 }}>
            <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
            {this.props.translate("Buyness_2021.home")}
            </Text>
          </View>
        </View>
        <View style={{ position: "absolute", top: 0, width: window.innerWidth, height: window.innerHeight * 0.08, backgroundColor: "#000", flexDirection: "row", overflow: "hidden" }}>

          <View style={{ width: "15%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <Text style={{ textDecorationLine: "none", color: "#fff", fontWeight: "600", fontSize: "1.3rem", textAlign: "left" }}>
              Buyness
            </Text>
          </View>
          <View style={{ width: "65%", height: "100%", }}>

          </View>
          {false &&
            <Link to="/login" style={{ height: "100%", textDecoration: "none", color: "#000" }} >
              <View style={{ height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>

                <Text style={{ textDecorationLine: "none", color: "#000", fontWeight: "400", fontSize: "1rem", textAlign: "left" }}>
                  Inicia sesión
                </Text>



              </View>
            </Link>
          }
          {true &&
            <Link to="/login" style={{ height: "70%", alignSelf: "center", textDecoration: "none", color: "#000", marginRight: "2%" }} >
              <View style={{ height: "100%", alignSelf: "center", alignItems: "center", justifyContent: "center", backgroundColor: "#fff", borderRadius: 6 }}>
                <Text style={{ paddingHorizontal: 20, textDecorationLine: "none", color: "#000", fontWeight: "500", fontSize: "1rem", textAlign: "center" }}>
                {this.props.translate("sign_in.sign_in")}
                </Text>



              </View>
            </Link>
          }
        </View>
      </View>
    )
  }
}
