import * as React from 'react';
import MenuFavBrowser from './MenuFavBrowser';
import MenuFavPhone from './MenuFavPhone';

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { screenWidth: window.innerWidth };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions, false);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
}

updateWindowDimensions() {
   this.setState({ screenWidth: window.innerWidth });
}

  render() {

    return (

      <div style={{ width: "100%", height: "100%" }}>
      {
        this.state.screenWidth>760 &&
        <MenuFavBrowser/>
      }
      {
        this.state.screenWidth<=760 &&
        <MenuFavPhone/>
      }
      </div>


    )
  }
}