import * as React from 'react';
import { HiOutlineEmojiHappy } from 'react-icons/hi';
import { Text, View } from 'react-native';
import {
  useNavigate
} from "react-router-dom";
export default function Wrapper(props) {
  const navigate = useNavigate();

  return (
    <App
      navigate={navigate}
      {...props}
    />
  );
};



class App extends React.Component {
  constructor(props) {
    super(props);

  }



  render() {

    return (
      <View style={{ flexDirection: "row", zIndex: 0, position: "fixed", top: 0, width: "100%", height: window.innerHeight, backgroundColor: "#fff", alignItems: "center", overflow: "hidden", flexDirection: "row" }}>

        <View style={{ position: "absolute", top: 0, width: "100%", height: "100%", backgroundColor: "#68dc91", justifyContent: "center", alignItems: "center" }}>
          <HiOutlineEmojiHappy size="5em" color="#000" />
          <View style={{ height: 30 }} />
          <Text style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem", paddingHorizontal: 10, width: "100%", textAlign: "center" }}>
            Para visualizar los productos de este establecimiento haz pequeña la ventaña de tu navegador hasta que tenga el tamaño de un dispositivo móvil.
          </Text>
          <View style={{ height: 100 }} />
        </View>
      </View>
    )
  }
}
